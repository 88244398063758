import React, { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { CircularProgress, Menu, MenuItem, Tooltip, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMenu } from '../../../components/base/useMenu';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { useActiveProject } from '../../../store/projectState';
import useDeleteFolder from '../../../hooks/apiHooks/queryHooks/useDeleteFolder';

const FolderActionMenu = React.forwardRef(({ data, isLoading }, ref) => {
  const {
    requestCreateFolderModal,
    requestMoveToFolderModal,
    requestRenameFolderModal,
    requestFolderDeleteConfirm
  } = useContext(ApiDialogsAndModalsContext);
  const {
    foldersState: { clickedFolder },
    setFoldersState
  } = useContext(ApiTestContext);
  const folderId = clickedFolder?.id;
  const activeProject = useActiveProject();
  const { isDeletingFolder } = useDeleteFolder();

  ////////////////////////////////////////////////
  const { triggerProps, menuProps } = useMenu();
  const navigate = useNavigate();
  const onAddRequest = async () => {
    menuProps.onClose();
    navigate(`/projects/${activeProject.id}/api-suites/${folderId}/new`);
  };
  return (
    <div>
      <Tooltip title={'View more actions'}>
        <IconButton
          ref={ref}
          size={'small'}
          sx={{ p: '4px' }}
          aria-controls={menuProps.open ? 'action-menu' : undefined}
          aria-haspopup="true"
          disabled={isDeletingFolder}
          aria-expanded={menuProps.open ? 'true' : undefined}
          onClick={async (e) => {
            e.stopPropagation();
            triggerProps.onClick(e);
            setFoldersState((prev) => {
              return { ...prev, clickedFolder: data };
            });
          }}
          // {...triggerProps}
        >
          {isDeletingFolder ? (
            <CircularProgress size={18} color={'primary'} />
          ) : (
            <MoreHoriz color={'inherit'} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="action-menu"
        {...menuProps}
        PaperProps={{
          elevation: 0,
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          },
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem onClick={requestRenameFolderModal}>Rename</MenuItem>
        <MenuItem onClick={onAddRequest}>Add request</MenuItem>
        <MenuItem onClick={requestCreateFolderModal}>Add folder</MenuItem>
        <MenuItem
          onClick={() => {
            console.log(clickedFolder);
            setFoldersState((prev) => {
              return {
                ...prev,
                entityToMove: clickedFolder,
                entityType: 'folder'
              };
            });
            requestMoveToFolderModal();
          }}>
          Move{' '}
        </MenuItem>
        <MenuItem sx={{ color: 'red' }} onClick={requestFolderDeleteConfirm}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
});

export default FolderActionMenu;
