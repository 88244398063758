import { useParams } from 'react-router-dom';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { useCheckForTestPropsChanges, useGetCurrentTest, useSetTestHasNewChanges } from '..';
import { configureTestRequestData } from './useRunApiTest';
import { toast } from 'react-toastify';
import { useCheckUrlIsForNewTest, useUpdateNewTestOnSave } from '../trackTabsAndFetchTest';
import { navigate } from '@storybook/addon-links';
import useFetchApiFoldersAndTests from './useFetchFoldersAndTests';
import { useContext } from 'react';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { formatDate } from '../../../pages/ApiSuites/utils';

const useSaveApiTest = (tabIndex) => {
  const { refetchFoldersAndTests } = useFetchApiFoldersAndTests();
  const { testId, folderId } = useParams();
  const activeProject = useActiveProject();
  const { openSaveTestChangesDialog, openSaveTestExampleModal } = useContext(
    ApiDialogsAndModalsContext
  );
  const { testToCloseRef } = useContext(ApiTestContext);
  const {
    testPropsForRequest,
    testDetails: { name: testName },
    testRunResponse
  } = useGetCurrentTest(tabIndex);
  let endpoint = `/projects/${activeProject?.id}/api-tests/${testId ? `${testId}` : ''}`;
  if (openSaveTestExampleModal) {
    endpoint = `/projects/${activeProject?.id}/api-tests/${testId}/examples`;
  }
  const {
    mutateAsync: saveApiTest,
    isLoading: isSavingApiTest,
    isSuccess: savedApiTest
  } = useScandiumMutation(endpoint, {
    method: !!testId && !openSaveTestExampleModal ? 'PUT' : 'POST',
    onSuccess: (data) => {
      // console.log(data.data.data);
      refetchFoldersAndTests();
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });
  const handleSaveApiTest = async (testExampleName) => {
    let copyOfTestProps = JSON.parse(JSON.stringify(testPropsForRequest));
    if (openSaveTestChangesDialog) {
      // check if the save changes modal is on, it means this save is occuring when a test tab is about to be closed
      copyOfTestProps = testToCloseRef?.current.data.testPropsForRequest;
    }
    const { rawRequestBody } = copyOfTestProps;
    const configuredTestProps = configureTestRequestData(copyOfTestProps);
    let result = {
      savedTest: false,
      savedTestId: '',
      savedTestData: null
    };
    if (!testName) {
      toast.error('Please enter a test name');
      return;
    }
    if (!configuredTestProps?.data?.url) {
      toast.error('Please enter a request url');
      return;
    }
    // check for invalid json
    let {
      data: { mode, body: configuredBody }
    } = configuredTestProps;
    if (mode === 'json' && !!rawRequestBody.length && !configuredBody) {
      toast.error('request body is not valid json'); //json body in editor likely has an incorrret syntax
      return;
    }
    try {
      const testDataToSave = {
        name: openSaveTestExampleModal ? testExampleName : testName,
        ...configuredTestProps // data and assertions
        // response: configureTestRunResponseData(testRunResponse)
      };
      // console.log(testDataToSave);
      if (folderId) testDataToSave['folder_id'] = +folderId;
      const response = await saveApiTest(testDataToSave);
      // console.log(response);
      if (response.data.id || response.data.uuid) {
        result.savedTest = true;
        result.savedTestId = response.data.id;
        result.savedTestData = {
          testDetails: {
            name: response.data?.name || '',
            id: response.data?.id || '',
            'Created on': !!response.data?.created_at ? formatDate(response.data?.created_at) : '', // saving test as example would not have these fields in the response
            'Updated on': !!response.data?.updated_at ? formatDate(response.data?.updated_at) : '',
            Creator: response.data?.creator?.name || ''
          },
          documentationData: response?.data?.data
        };
      }
      //close the tab if necessary
    } catch (error) {
      toast.error(error?.message);
    }
    return result;
  };
  return { handleSaveApiTest, isSavingApiTest };
};
// remove body from the runresponsedata assigned for saving
const configureTestRunResponseData = (testRunResponse) => {
  let data = null;
  if (!testRunResponse.hasResponse) {
    data = null;
  } else {
    data = testRunResponse?.runResultDataForSaving;
    delete data?.body; // we dont save the body
  }
  return data;
};

export default useSaveApiTest;
