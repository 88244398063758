import { useContext, useEffect, useState } from 'react';
import { ApiTestContext } from '../../store/apiState/apiTestContext';
import { useParams } from 'react-router-dom';
import _lodash from 'lodash';
import { useDebounceTestUrl } from './useDebounce';
import { isValidUrl } from '../../pages/ApiSuites/utils';
import { useTableEntriesSetter } from './updateTestPropsHooks';

export const useGetCurrentTest = () => {
  const { exampleId, testId, newTestId } = useParams();
  const { testsTab, newTestDefault } = useContext(ApiTestContext);
  const _newTestDefault = JSON.parse(JSON.stringify(newTestDefault));
  const [activeTest, setActiveTest] = useState(_newTestDefault);
  const testIsExample = !!testId && !!exampleId;
  useEffect(() => {
    const currentTest = testsTab.testsList.find((test) => {
      if (!!testIsExample) {
        return test.testDetails.id === exampleId;
      } else {
        return test.testDetails.id === testId || test.testDetails.id === newTestId;
      }
    });
    if (!!currentTest) {
      setActiveTest(currentTest);
    }
  }, [testId, newTestId, exampleId, testsTab]);
  return activeTest;
}; //  this to get the test active at any time
export const useSetActiveTool = () => {
  const { testId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const handleSetActiveTool = (toolIndex) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (test?.testDetails.id === testId) {
          // console.log(toolIndex);
          test.uiProps.toolBar.activeTool = toolIndex;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleSetActiveTool };
};
export const useSetTestHasNewChanges = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const handleSetTestHasNewChanges = (value) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.hasNewChanges = value;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleSetTestHasNewChanges };
};

export const useCheckForTestPropsChanges = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const { testInitialData, hasNewChanges, testPropsForRequest, testRunResponse, testDetails } =
    useGetCurrentTest();
  const { handleSetTestHasNewChanges } = useSetTestHasNewChanges();
  const {
    url,
    params,
    authorizationHeader,
    headers,
    cookies,
    pathVariables,
    rawRequestBody,
    requestBodyMode,
    requestMethod,
    assertionsSelect,
    rawBodyLanguage,
    formData,
    formUrlEncoded,
    graphQLVariables
  } = testPropsForRequest;
  // const { hasResponse, responseTimeTaken, responseStatusCode } = testRunResponse;
  // const {
  //   contentTypeValue,
  //   isResponseBodyAFile,
  //   requestData,
  //   runResultDataForSaving,
  //   ...otherFields
  // } = testRunResponse;
  const currentTestData = {
    ...testPropsForRequest,
    testName: testDetails.name
    // runResponse: otherFields
  };
  useEffect(() => {
    const testPropsIsChanged = !_lodash.isEqual(testInitialData, currentTestData);
    if (testPropsIsChanged && hasNewChanges) {
      return;
    }
    // console.log(testInitialData);
    // console.log(currentTestData);
    handleSetTestHasNewChanges(testPropsIsChanged);
  }, [
    url,
    params,
    headers,
    authorizationHeader,
    cookies,
    pathVariables,
    rawRequestBody,
    requestBodyMode,
    requestMethod,
    assertionsSelect,
    rawBodyLanguage,
    formData,
    formUrlEncoded,
    graphQLVariables,
    testDetails
    // hasResponse,
    // responseTimeTaken,
    // responseStatusCode
  ]);
  const handleResetTestInitialDataAfterSaving = () => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testInitialData = JSON.parse(JSON.stringify(currentTestData));
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleResetTestInitialDataAfterSaving };
};

export const useUpdateQueryParamsByUrl = () => {
  const { debounceTestUrlValue } = useDebounceTestUrl();
  const {
    testPropsForRequest: { url, params: prevParams }
  } = useGetCurrentTest();
  const { handleQueryParamsUpdate } = useTableEntriesSetter();
  useEffect(() => {
    let newParams = [{ key: '', value: '', description: '' }];
    const authParams = prevParams.filter((entry) => {
      return entry.src === 'authorization';
    });
    if (!isValidUrl(url)) {
      if (!!authParams.length) {
        newParams = [...authParams, ...newParams];
      }
      handleQueryParamsUpdate(newParams);
      return;
    }
    let searchParamsPairs = new URL(url).search.substring(1).split('&');
    let spArray = [];
    searchParamsPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      if (!!key || !!value) {
        spArray.push({
          key: key || '',
          value: value || '',
          description: ''
        });
      }
    });
    newParams = [...spArray, ...newParams];
    newParams = newParams.map((entry, index) => {
      entry.description = prevParams[index]?.description || '';
      return entry;
    });
    if (!!authParams.length) {
      newParams = [...authParams, ...newParams];
    }
    handleQueryParamsUpdate(newParams);
    // console.log(newParams);
  }, [debounceTestUrlValue]);
};
