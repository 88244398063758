const GreenCheck = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.03513 0.50293C2.98039 0.50293 0.503906 2.97941 0.503906 6.03415C0.503906 9.0889 2.98039 11.5654 6.03513 11.5654C9.08988 11.5654 11.5664 9.0889 11.5664 6.03415C11.5664 2.97941 9.08988 0.50293 6.03513 0.50293ZM8.43267 5.09887C8.47681 5.04841 8.51042 4.98963 8.53152 4.92598C8.55261 4.86234 8.56076 4.79512 8.5555 4.72827C8.55023 4.66143 8.53164 4.59632 8.50084 4.53677C8.47003 4.47721 8.42763 4.42442 8.37612 4.3815C8.32461 4.33857 8.26504 4.30638 8.20091 4.28682C8.13677 4.26726 8.06938 4.26072 8.00268 4.2676C7.93598 4.27447 7.87133 4.29461 7.81254 4.32683C7.75374 4.35906 7.70198 4.40272 7.66031 4.45524L5.4981 7.04939L4.37928 5.93007C4.28445 5.83847 4.15743 5.78779 4.02559 5.78893C3.89374 5.79008 3.76763 5.84296 3.67439 5.93619C3.58116 6.02942 3.52828 6.15554 3.52714 6.28738C3.52599 6.41923 3.57667 6.54624 3.66827 6.64108L5.17679 8.1496C5.22619 8.19897 5.28535 8.23752 5.35047 8.26277C5.4156 8.28801 5.48528 8.29942 5.55506 8.29625C5.62483 8.29308 5.69319 8.27541 5.75576 8.24436C5.81833 8.21331 5.87374 8.16956 5.91847 8.11591L8.43267 5.09887Z"
        fill="#00CA08"
      />
    </svg>
  );
};

export default GreenCheck;
