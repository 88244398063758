import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../../components/base/Flex';
import OutlinedButton from '../../../../components/base/OutlinedButton';
import ContainedButton from '../../../../components/base/ContainedButton';
import CustomModal from '../../../../components/base/CustomModal';
import React, { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
import { ApiTestContext } from '../../../../store/apiState/apiTestContext';
import useFetchApiFoldersAndTests from '../../../../hooks/apiHooks/queryHooks/useFetchFoldersAndTests';

const RenameFolderModal = () => {
  const { openRenameFolderModal, closeRenameFolderModal } = useContext(ApiDialogsAndModalsContext);
  const {
    foldersState: { clickedFolder }
  } = useContext(ApiTestContext);
  const { refetchFoldersAndTests } = useFetchApiFoldersAndTests();
  //////////////////////////////////////////////////////////
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const suiteID = new URLSearchParams(search).get('suite_id') || '';
  const [name, setName] = React.useState(clickedFolder?.name);
  const activeProject = useActiveProject();
  const { mutateAsync: updateFolderName, isLoading: isUpdatingFolder } = useScandiumMutation(
    `/projects/${activeProject?.id}/folders/${clickedFolder?.id}`,
    {
      enabled: !!activeProject?.id,
      method: 'PATCH',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Folder name updated successfully');
        refetchFoldersAndTests();
        closeRenameFolderModal();
      }
    }
  );
  const handleUpdateFolderName = async (e) => {
    if (!name) {
      toast.error('Folder name is required');
      return;
    }
    await updateFolderName({
      name
    });
  };
  useEffect(() => {
    setName(clickedFolder.name);
  }, [clickedFolder]);
  return (
    <CustomModal open={openRenameFolderModal}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={closeRenameFolderModal}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <>
          <Typography
            as={'h4'}
            color="primary"
            sx={{
              fontSize: '1.4rem'
            }}>
            Rename Folder
          </Typography>
          <Typography
            as={'h4'}
            mb={4}
            sx={{
              fontSize: '0.9rem'
            }}></Typography>
          <Box mb={3}>
            <TextField
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              autoFocus
              size={'small'}
              // placeholder={`Name your ${adjustTitle()}`}
              placeholder="Rename Folder"
              fullWidth
            />
          </Box>
        </>
        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={closeRenameFolderModal}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!name}
            isLoading={isUpdatingFolder}
            onClick={handleUpdateFolderName}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default RenameFolderModal;
