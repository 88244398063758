import React, { useRef, useState } from 'react';
import { ApiDialogsAndModalsContextProvider } from './apiDialogsAndModalsContext';
export const testCaseAuthObject = {
  option: 'no-auth',
  bearer: '',
  basic: { username: '', password: '' },
  apiKey: { key: '', value: '', path: 'headers' },
  digestAuth: {
    username: '',
    password: '',
    realm: '',
    nonce: '',
    algorithm: 'MD5',
    qop: '',
    nc: '',
    cnonce: '',
    opaque: '',
    retry: true
  },
  oauth1: {
    oauth_callback: '',
    oauth_consumer_key: '',
    oauth_consumer_secret: '',
    oauth_nonce: '',
    oauth_signature: '',
    oauth_signature_method: 'HMAC-SHA1',
    oauth_timestamp: '',
    oauth_token: '',
    oauth_token_secret: '',
    oauth_verifier: '',
    oauth_version: '',
    realm: '',
    addEmptyFields: false,
    path: 'headers'
  },
  oauth2: {
    access_token: '',
    header_prefix: '',
    token_name: '',
    grant_type: '',
    callback_url: '',
    auth_url: '',
    access_token_url: '',
    client_ID: '',
    client_secret: '',
    scope: '',
    state: '',
    client_authentication: 'body',
    resource: [],
    audience: [],
    path: 'headers'
  }
};

const newTestInitialData = {
  url: '',
  params: [{ key: '', value: '', description: '' }],
  headers: [{ key: '', value: '', description: '' }],
  cookies: [{ key: '', value: '', expires_at: '' }],
  pathVariables: [],
  rawRequestBody: '',
  requestBodyMode: 'none',
  requestMethod: 'GET',
  authorizationHeader: { ...testCaseAuthObject },
  assertionsSelect: [],
  rawBodyLanguage: 'json',
  formData: [{ key: '', value: '', type: 'text', filename: '', description: '' }],
  formUrlEncoded: [{ key: '', value: '', description: '' }],
  graphQLVariables: '',
  testName: 'Untitled Test'
  // runResponse: {
  //   hasResponse: false,
  //   responseBody: '',
  //   responseStatusCode: '',
  //   responseSize: '',
  //   responseHeaders: [],
  //   responseAssertions: {},
  //   responseCookies: [],
  //   responseTimeTaken: ''
  // }
};

const newTestDefault = {
  testPropsForRequest: {
    url: '',
    params: [{ key: '', value: '', description: '' }],
    headers: [{ key: '', value: '', description: '' }],
    cookies: [{ key: '', value: '', expires_at: '' }],
    pathVariables: [],
    rawRequestBody: '',
    requestBodyMode: 'none',
    requestMethod: 'GET',
    authorizationHeader: JSON.parse(JSON.stringify(testCaseAuthObject)),
    assertionsSelect: [],
    rawBodyLanguage: 'json',
    formData: [{ key: '', value: '', type: 'text', filename: '', description: '' }],
    formUrlEncoded: [{ key: '', value: '', description: '' }],
    graphQLVariables: ''
  },
  testRunResponse: {
    hasResponse: false,
    runResultDataForSaving: {
      assertions: {},
      headers: [],
      size: 0,
      status_code: 0,
      time_taken: 0
    },
    responseBody: '',
    isResponseBodyAFile: false,
    responseStatusCode: '',
    responseSize: '',
    responseHeaders: [],
    responseAssertions: {},
    responseCookies: [],
    responseTimeTaken: '',
    requestData: {},
    contentTypeValue: ''
  },
  testDetails: {
    name: 'Untitled Test',
    id: '',
    'Created on': '',
    'Updated on': '',
    Creator: ''
    // parentId: ''
  },
  uiProps: {
    testAgent: {
      runAgent: '',
      agentPort: '',
      checkIfAgentIsOn: async () => {}
    },
    toolBar: {
      activeTool: null
    },
    testPropsTab: 0,
    testResponseTab: 0,
    assertionsFilterTab: 0,
    responseEditorProps: {
      wordWrap: 'on',
      responseEditorLanguage: 'json',
      lineNumbers: 'on',
      value: '',
      activeBTN: 'pretty',
      jsonResponseFields: {
        keys: [],
        fields: []
      }
    },
    testDocumentData: {
      authType: '',
      method: '',
      reqBody: '',
      reqHeaders: [],
      reqParams: [],
      url: '',
      mode: ''
    }
  },
  testInitialData: JSON.parse(JSON.stringify(newTestInitialData)),
  hasNewChanges: false,
  testType: 'new-test', // api-test || 'api-test-example
  isNewTest: true
};

export const ApiTestContext = React.createContext({
  newTestDefault: newTestDefault,
  globalVariables: [],
  setGlobalVariables: () => {},
  apiGlobalProps: {
    consoleData: []
  },
  setApiGlobalProps: () => {},
  foldersState: {
    clickedFolder: {},
    clickedTest: {},
    clickedTestExample: {},
    entityType: '',
    entityToMove: {},
    isDraggingId: null,
    foldersList: [],
    testsList: [],
    destinationFolder: {},
    paginate: '',
    searchFoldersValue: ''
  },
  setFoldersState: () => {},
  testsTab: {
    testsList: [],
    currentTabIndex: 0,
    currentTabId: ''
  },
  setTestsTab: () => {},
  massSelection: {
    active: false,
    selectedApiTests: [],
    selectedApiTestExamples: [],
    selectedFolders: [],
    selectedSuites: []
  },
  setMassSelection: () => {},
  testAgent: {
    runAgent: 'Cloud-Agent',
    agentPort: 12621,
    showLocalModeNotice: false,
    triggerQueryRunInBrowser: false,
    triggerMutationRunInBrowser: false,
    triggerConfigDataForAgentRun: false
  },
  setTestAgent: () => {},
  enableTestFetch: false,
  setEnableTestFetch: () => {},
  checkIfAgentIsOn: async () => {},
  testDescription: '',
  setTestDescription: () => {},
  variableSuggestionListRef: null,
  responseWindowRef: null,
  enableConfigDataForLocalOrAgentRunRef: false,
  testToCloseRef: {
    index: 0,
    data: {}
  }
});
export const ApiTestContextProvider = ({ children }) => {
  const [foldersState, setFoldersState] = useState({
    clickedFolder: {},
    clickedTest: {},
    clickedTestExample: {},
    entityType: '',
    entityToMove: {},
    isDraggingId: null,
    foldersList: [],
    testsList: [],
    destinationFolder: {},
    paginate: '',
    searchFoldersValue: ''
  });
  const [massSelection, setMassSelection] = useState({
    active: false,
    selectedApiTests: [],
    selectedApiTestExamples: [],
    selectedFolders: [],
    selectedSuites: []
  });
  const [testsTab, setTestsTab] = useState({
    testsList: [],
    currentTabIndex: 0,
    testToClose: {
      index: null,
      data: null
    },
    currentNewTestTabIndex: 0
  });
  const [globalVariables, setGlobalVariables] = useState([]);
  const [testDescription, setTestDescription] = useState('');
  const [enableTestFetch, setEnableTestFetch] = useState(false);
  const [apiGlobalProps, setApiGlobalProps] = useState({
    consoleData: []
  });
  const [testAgent, setTestAgent] = useState({
    runAgent: 'Cloud-Agent',
    agentPort: 12621,
    showLocalModeNotice: false,
    triggerQueryRunInBrowser: false,
    triggerMutationRunInBrowser: false,
    triggerConfigDataForAgentRun: false
  });
  const enableConfigDataForLocalOrAgentRunRef = useRef(false);
  const responseWindowRef = useRef(null);
  const variableSuggestionListRef = useRef(null);
  const testToCloseRef = useRef({
    index: 0,
    data: {}
  });
  return (
    <ApiTestContext.Provider
      value={{
        globalVariables,
        setGlobalVariables,
        foldersState,
        setFoldersState,
        massSelection,
        setMassSelection,
        testsTab,
        setTestsTab,
        testDescription,
        setTestDescription,
        enableTestFetch,
        setEnableTestFetch,
        newTestDefault,
        responseWindowRef,
        apiGlobalProps,
        setApiGlobalProps,
        testAgent,
        setTestAgent,
        variableSuggestionListRef,
        testToCloseRef,
        enableConfigDataForLocalOrAgentRunRef
      }}>
      <ApiDialogsAndModalsContextProvider>{children}</ApiDialogsAndModalsContextProvider>
    </ApiTestContext.Provider>
  );
};

// try fetching the folders and tests to check if the hook is working
