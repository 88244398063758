import React from 'react';
import Box from '@mui/material/Box';
import ContainedButton from '../../../components/base/ContainedButton';
import { ExpandMore } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { useGetCurrentTest } from '../../../hooks/apiHooks';
import { useSetRequestMethod } from '../../../hooks/apiHooks/updateTestPropsHooks';

const HttpMethodsMenu = ({ httpMethod, setHttpMethod }) => {
  const { menuProps, triggerProps } = useMenu();

  const {
    testPropsForRequest: { requestMethod }
  } = useGetCurrentTest();
  const { handleSetRequestMethod } = useSetRequestMethod();
  return (
    <Box>
      <ContainedButton
        {...triggerProps}
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          color: '#FFF',
          height: '100%',
          pr: 1
        }}
        color={'secondary'}>
        {requestMethod}
        <ExpandMore ml={1} color={'inherit'} />
      </ContainedButton>
      <Menu
        id="action-menu"
        {...menuProps}
        PaperProps={{
          elevation: 1,
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          },
          sx: {
            overflow: 'visible',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 62,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}>
        <MenuItem
          sx={{ color: '#53BB63', fontWeight: 500 }}
          onClick={() => {
            handleSetRequestMethod('GET');
            menuProps.onClose();
          }}>
          GET
        </MenuItem>
        <MenuItem
          sx={{ color: '#FFB100', fontWeight: 500 }}
          onClick={() => {
            handleSetRequestMethod('POST');
            menuProps.onClose();
          }}>
          POST
        </MenuItem>
        <MenuItem
          sx={{ color: '#00CCFF', fontWeight: 500 }}
          onClick={() => {
            handleSetRequestMethod('PUT');
            menuProps.onClose();
          }}>
          PUT
        </MenuItem>
        <MenuItem
          sx={{ color: '#E39FF6', fontWeight: 500 }}
          onClick={() => {
            handleSetRequestMethod('PATCH');
            menuProps.onClose();
          }}>
          PATCH
        </MenuItem>
        <MenuItem
          sx={{ color: '#FF775E', fontWeight: 500 }}
          onClick={() => {
            handleSetRequestMethod('DELETE');
            menuProps.onClose();
          }}>
          DELETE
        </MenuItem>
        <MenuItem
          sx={{ color: '#53BB63', fontWeight: 500 }}
          onClick={() => {
            handleSetRequestMethod('HEAD');
            menuProps.onClose();
          }}>
          HEAD
        </MenuItem>
        <MenuItem
          sx={{ color: '#B03060', fontWeight: 500 }}
          onClick={() => {
            handleSetRequestMethod('OPTIONS');
            menuProps.onClose();
          }}>
          OPTIONS
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setHttpMethod('CONNECT');
            menuProps.onClose();
          }}>
          CONNECT
        </MenuItem>
        <MenuItem
          onClick={() => {
            setHttpMethod('TRACE');
            menuProps.onClose();
          }}>
          TRACE
        </MenuItem> */}
      </Menu>
    </Box>
  );
};

export default HttpMethodsMenu;
