import { useState, useCallback, useRef, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../../components/base/Flex';
import OutlinedButton from '../../../../components/base/OutlinedButton';
import ContainedButton from '../../../../components/base/ContainedButton';
import CustomModal from '../../../../components/base/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useActiveProject } from '../../../../store/projectState';
import { useScandiumUpload } from '../../../../data-layer/upload';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../../../../mixpanel.constants';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
import useFetchApiFoldersAndTests from '../../../../hooks/apiHooks/queryHooks/useFetchFoldersAndTests';

const SavePostmanCollectionModal = () => {
  const { openImportPostmanCollectionModal, closeImportPostmanCollectionModal } = useContext(
    ApiDialogsAndModalsContext
  );
  const { refetchFoldersAndTests } = useFetchApiFoldersAndTests();
  const theme = useTheme();
  const activeProject = useActiveProject();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]?.type !== 'application/json') {
      toast.error('Only JSON files are allowed.');
      return;
    }

    setSelectedFile(acceptedFiles[0]);
    fileInputRef.current = acceptedFiles[0];
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/json': ['.json']
    },
    maxFiles: 1
  });

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedFile(null);
    }
  };

  const { mutateAsync: uploadPostmanCollection, isLoading: isUploadingFile } = useScandiumUpload(
    `/projects/${activeProject?.id}/api-tests/import`,
    {
      enabled: !!activeProject?.id && !!selectedFile,
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success(data.message);
        refetchFoldersAndTests();
        closeImportPostmanCollectionModal();

        trackMixPanel(TRACKING_IDS.TESTCASE_IMPORTED, {
          type: 'api'
        });
      }
    }
  );

  const handleSavePostmanCollection = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      toast.error('please select a file before saving');
      return;
    }

    const formData = new FormData();
    formData.append('attachment', selectedFile, selectedFile.name);

    await uploadPostmanCollection(formData);
  };

  return (
    <CustomModal open={openImportPostmanCollectionModal}>
      <Box
        component={'form'}
        onSubmit={handleSavePostmanCollection}
        encType={'multipart/form-data'}
        py={2}
        px={2}
        width={'80vw'}
        maxWidth={'500px'}
        position={'relative'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem',
            mb: 1
          }}>
          Upload a Postman Collection JSON File
        </Typography>

        <Typography
          as={'h4'}
          mb={1}
          sx={{
            fontSize: '0.9rem'
          }}>
          <Typography as={'span'} fontWeight={'bold'}>
            Note
          </Typography>
          : Please make sure your JSON file is in the correct format and contains a valid Postman
          collection.
        </Typography>

        <Typography
          as={'h4'}
          mb={2}
          sx={{
            fontSize: '0.9rem'
          }}>
          Ensure to also manually create matching variables in Scandium for each variable used in
          your Postman collection. Make sure to replicate both the variable names and their values
          accurately.
        </Typography>

        <Flex
          {...getRootProps()}
          sx={{
            bgcolor: theme.palette.background.lightGrey_dark,
            py: 5,
            cursor: 'pointer',
            justifyContent: 'center'
          }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography fontSize={'0.9rem'}>Drop the file here ...</Typography>
          ) : (
            <Flex sx={{ flexDirection: 'column', gap: 1 }}>
              <CloudUploadIcon color={'primary'} />
              <Typography fontSize={'0.9rem'}>
                Drag and drop a <span style={{ fontWeight: 'bold' }}>JSON</span> file here, or click
                to select one.
              </Typography>
            </Flex>
          )}
        </Flex>

        {selectedFile && (
          <Flex flexWrap={'wrap'}>
            <Typography as={'h6'} sx={{ fontSize: '0.9rem' }}>
              {selectedFile?.name}
            </Typography>
            <Tooltip title={'Delete the selected file'}>
              <IconButton size={'small'} onClick={clearFileInput}>
                <CloseIcon color={'error'} />
              </IconButton>
            </Tooltip>
          </Flex>
        )}

        <Flex sx={{ mt: 3 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton
            onClick={() => {
              closeImportPostmanCollectionModal();
              clearFileInput();
            }}>
            Cancel
          </OutlinedButton>
          <Tooltip title={!selectedFile ? 'Select a file before saving' : ''}>
            <ContainedButton
              disabled={!selectedFile || !!isUploadingFile}
              isLoading={isUploadingFile}
              type={'submit'}
              color={'primary'}
              sx={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all !important'
                }
              }}
              autoFocus>
              Save
            </ContainedButton>
          </Tooltip>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default SavePostmanCollectionModal;
