import { format, parseISO } from 'date-fns';
import { generateRandomBoolean } from '../Mobile/utils';
import { processVariableString, processVariableStringForApi } from '../Mobile/MobileTestPage';

export const methodColorMap = {
  get: '#53BB63',
  post: '#FFB100',
  put: '#00CCFF',
  patch: '#E39FF6',
  delete: '#FF775E',
  head: '#53BB63',
  options: '#B03060'
};
const checkValidJson = (text) => {
  if (
    /^[\],:{}\s]*$/.test(
      text
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    )
  ) {
    return true;
  } else {
    return false;
  }
};
export const cleanJson = (jsonString) => {
  try {
    const parsedJson = JSON.parse(jsonString);
    const cleanedJson = JSON.stringify(parsedJson);
    return cleanedJson;
  } catch (err) {
    return null;
  }
};

export const vJson = (json) => {
  try {
    const clean = json.replace(/\n/g, '').replace(/\s/g, '');
    const jsonObj = JSON.parse(clean);
    return jsonObj;
  } catch (err) {
    return null;
  }
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const getJSONResponseFields = (data) => {
  const fieldsSet = new Set();
  const extractFields = (obj, currentPath = []) => {
    for (const key in obj) {
      fieldsSet.add([...currentPath, key].join('.'));
      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          extractFields(obj[key][i], [...currentPath, key, i]);
        }
      } else if (typeof obj[key] === 'object') {
        extractFields(obj[key], [...currentPath, key]);
      }
    }
  };
  if (typeof data === 'object') {
    extractFields(data);
    let sortedFields = [...fieldsSet];
    // solves for name.1 to be name[1]
    sortedFields = sortedFields.map((item) => {
      const eachField = item.split('.');
      const newValue = eachField.map((value) => {
        if (!isNaN(value)) {
          return `[${value}]`;
        } else {
          return value;
        }
      });
      const result = newValue.join('.').replace(/\.\[/g, '[');
      return result;
    });
    return sortedFields;
  } else {
    return null;
  }
};
export const getJSONResponseKeys = (obj) => {
  const keysSet = new Set();
  function extractKeys(obj) {
    for (const key in obj) {
      keysSet.add(key);

      if (typeof obj[key] === 'object') {
        extractKeys(obj[key]);
      }
    }
  }
  if (typeof obj === 'object') {
    extractKeys(obj);
    return Array.from(keysSet).filter((key) => {
      return isNaN(key);
    });
  } else {
    return null; // I could further handle non-object input if needed
  }
};

export const extractPathVariablesKey = (url) => {
  // const matches = url.match(/\/:({{\w+}}|\w+)/g);
  // const matches = url.match(/\/:(\w+)/g);
  const matches = url.match(/\/:([^/]+)/g);
  const pathVariableKeys = matches ? matches.map((str) => str.slice(2)) : [];
  return pathVariableKeys;
};
export const swapPathVariablesInURL = (url, pathVariables) => {
  let newURL = url;
  if (!!pathVariables.length) {
    pathVariables.forEach(({ key, value }) => {
      if (!!value) {
        const regex = new RegExp(`:${key}`, 'g');
        newURL = url.replace(regex, value);
      }
    });
  }
  // console.log(newURL);
  return newURL;
};

export const formatDate = (date) => {
  const result = parseISO(date);
  const formattedDate = format(result, 'MMMM d, yyyy, h:mm a OOOO');
  return formattedDate;
};

export function generateRandomNewTest10Digits() {
  const min = 1000000000; // Minimum 10-digit number
  const max = 9999999999; // Maximum 10-digit number
  return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
}
export const configureAssertions = (assertionsArray) => {
  const newDropdowns = [];
  assertionsArray.map((assertion, index) => {
    if (assertion.name === 'status_code') {
      const assertionOperator = assertion.operator;
      const assertionValue = assertion.value;
      newDropdowns.push({
        selectedOption: 'status code',
        selectedOperator: assertion.operator || '',
        oneOf: assertionOperator === 'one_of' ? assertionValue : [],
        range: assertionOperator === 'range' ? assertionValue : [],
        value: assertionOperator !== 'one_of' && assertionOperator !== 'range' ? assertionValue : ''
      });
    } else if (assertion.name === 'response_body_type') {
      const assertionOperator = assertion.operator;
      const assertionValue = assertion.value;
      newDropdowns.push({
        selectedOption: 'response body type',
        selectedOperator: assertion.operator || '',
        oneOf: assertionOperator === 'one_of' ? assertionValue : [],
        range: [],
        value: assertionOperator !== 'one_of' ? assertionValue : ''
      });
    } else if (assertion.name === 'request_duration') {
      const assertionOperator = assertion.operator;
      const assertionValue = assertion.value;
      newDropdowns.push({
        selectedOption: 'request duration',
        selectedOperator: assertion.operator || '',
        range: assertionOperator === 'range' ? assertionValue : [],
        oneOf: [],
        value: assertionOperator !== 'range' ? assertionValue : ''
      });
    } else if (assertion.name === 'response_body_content') {
      assertion.value.map((value, index) => {
        newDropdowns.push({
          selectedOption: 'response body content',
          selectedOperator: value.operator,
          range: [],
          oneOf: [],
          value: value.value
        });
      });
    } else if (assertion.name === 'response_headers') {
      assertion.value.map((entry, index) => {
        newDropdowns.push({
          selectedOption: 'response header',
          range: [],
          oneOf: [],
          value: entry
        });
      });
    } else if (assertion.name === 'create_global_variable') {
      assertion.value.map((entry, index) => {
        let newEntry = {};
        newEntry.variableName = entry.variableName;
        if (!entry.value.startsWith('[')) {
          newEntry.value = `.${entry.value}`;
        } else {
          newEntry.value = entry.value;
        }
        newDropdowns.push({
          selectedOption: 'create global variable',
          selectedOperator: 'equal',
          range: [],
          oneOf: [],
          value: { ...newEntry, cascade: entry?.cascade }
        });
      });
    }
  });
  return newDropdowns;
};

export const jsonToObject = (jsonString) => {
  try {
    const clean = jsonString.replace(/\n/g, '');
    const jsonObj = JSON.parse(clean);
    return jsonObj;
  } catch (err) {
    return null;
  }
};

export const assertionSelectValidEntries = (dropdowns) => {
  const newAssertion = [];
  const filterEmptydropdowns = dropdowns.filter((assertion) => {
    let hasValidValues;
    if (assertion.selectedOption === 'response header') {
      hasValidValues = !!assertion.value.key && !!assertion.value.value;
    } else if (assertion.selectedOption === 'create global variable') {
      hasValidValues = !!assertion.value?.variableName?.trim() && !!assertion.value?.value?.trim();
      if (assertion?.value?.cascade !== true) {
        hasValidValues = !!assertion.value.value;
      }
    } else {
      const rangeValues = assertion.range.filter(Boolean).length > 1;
      hasValidValues = !!assertion?.value || !!assertion?.oneOf.length || !!rangeValues;
    }
    return !!hasValidValues;
  });
  filterEmptydropdowns.map((assertion) => {
    switch (assertion.selectedOption) {
      case 'status code':
        const obj = {};
        obj.name = 'status_code';
        obj.operator = assertion.selectedOperator;
        if (assertion.selectedOperator !== 'one_of' && assertion.selectedOperator !== 'range') {
          obj.value = assertion.value;
        } else if (assertion.selectedOperator === 'one_of') {
          obj.value = assertion.oneOf;
        } else if (assertion.selectedOperator === 'range') {
          obj.value = assertion.range;
        }
        newAssertion.push(obj);
        break;
      case 'response body type':
        const bodyTpyeObj = {};
        bodyTpyeObj.name = 'response_body_type';
        bodyTpyeObj.operator = assertion.selectedOperator;
        bodyTpyeObj.value = assertion.value;
        newAssertion.push(bodyTpyeObj);
        break;
      case 'request duration':
        const requestDurationObj = {};
        requestDurationObj.name = 'request_duration';
        requestDurationObj.operator = assertion.selectedOperator;
        requestDurationObj.value = assertion.value;
        if (requestDurationObj.operator === 'range') {
          requestDurationObj.value = assertion.range;
        }
        newAssertion.push(requestDurationObj);
        break;
      case 'response header':
        const responseHeadersObj = assertion.value;
        let headerEntry;
        headerEntry = newAssertion.find((item) => {
          return item.name === 'response_headers';
        });
        if (headerEntry) {
          headerEntry.value.push(responseHeadersObj);
        } else {
          newAssertion.push({ name: 'response_headers', value: [] });
          headerEntry = newAssertion.find((item) => {
            return item.name === 'response_headers';
          });
          headerEntry.value.push(responseHeadersObj);
        }
        break;
      case 'response body content':
        const responseBodyContent = {};
        responseBodyContent.value = assertion.value;
        responseBodyContent.operator = assertion.selectedOperator;

        let bodyContentEntry;
        bodyContentEntry = newAssertion.find((item) => {
          return item.name === 'response_body_content';
        });
        if (bodyContentEntry) {
          bodyContentEntry.value.push(responseBodyContent);
        } else {
          newAssertion.push({ name: 'response_body_content', value: [] });
          bodyContentEntry = newAssertion.find((item) => {
            return item.name === 'response_body_content';
          });
          bodyContentEntry.value.push(responseBodyContent);
        }
        break;
      case 'create global variable':
        // const objd = {...assertion}
        let globalVariableObj = {};
        globalVariableObj.variableName = assertion.value.variableName;
        globalVariableObj.value = assertion.value.value;
        globalVariableObj.cascade = assertion.value.cascade;

        if (globalVariableObj.value.startsWith('.')) {
          const valueWithoutDot = assertion.value.value.substring(1);
          globalVariableObj.value = valueWithoutDot;
        }
        let globalVariableEntry;
        globalVariableEntry = newAssertion.find((item) => {
          return item.name === 'create_global_variable';
        });
        if (globalVariableEntry) {
          globalVariableEntry.value.push(globalVariableObj);
        } else {
          newAssertion.push({ name: 'create_global_variable', value: [] });
          globalVariableEntry = newAssertion.find((item) => {
            return item.name === 'create_global_variable';
          });
          globalVariableEntry.value.push(globalVariableObj);
        }
        break;
    }
  });
  return newAssertion;
};
export const checkIfAgentIsOn = async (agentPort, cbOnSuccess, cbOnError = () => {}) => {
  let result = false;
  try {
    const response = await fetch(`http://localhost:${agentPort}/api/run-test`);
    if (response.ok) {
      result = true;
      cbOnSuccess();
    }
    if (!response.ok) {
      cbOnError();
    }
  } catch (err) {
    cbOnError();

    // console.log(err);
    // toast.info('Scandium Desktop Agent is not running on your device');
  }
  return result;
};
export const convertNullValuesToEmptyString = (arrayOfObjects) => {
  let newArray = arrayOfObjects.map((obj) => {
    // Iterate through each property of the object
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === null) {
        obj[key] = ''; // Replace null with empty string
      }
    }
    return obj;
  });
  return newArray;
};

export const updatedUrlWithQueryParams = (oldUrl, newQueryParams) => {
  if (!isValidUrl(oldUrl)) {
    return oldUrl;
  }
  const _params = newQueryParams?.filter(
    (param) => (param.key || param.value) && param.src !== 'authorization'
  );
  const searchParams = new URLSearchParams();
  _params.forEach((param) => {
    searchParams.append(param.key, param.value);
  });
  let updatedUrl = new URL(oldUrl);
  updatedUrl.search = decodeURIComponent(searchParams.toString());
  return decodeURIComponent(updatedUrl.href);
};

export const parseUrlOrAnyStringForglobalVariable = (url, variables) => {
  const regex = /(\{\{.*?\}\}|\/)/g;
  const splitUrl = url.split(regex).filter(Boolean);
  const newUrl = splitUrl.map((item) => {
    if (item.startsWith('{{') && !!variables.length) {
      const text = item.replace(/{{|}}/g, '');
      const entry = variables?.find((entry) => {
        return entry.name === text;
      });
      if (!!entry) {
        return entry.value;
      }
      return item;
    } else {
      return item;
    }
  });
  return newUrl.join('');
};
export const parseStringForGlobalAndDynamicValues = (str, variables) => {
  let parsedValue = processVariableStringForApi(str); // first check for dynamic variables
  parsedValue = parseUrlOrAnyStringForglobalVariable(parsedValue, variables); // then global variables
  // console.log(parsedValue);
  return parsedValue;
};

export const convertQueryParamsArrayToSearchParams = (paramsArray) => {
  const searchParams = new URLSearchParams();
  paramsArray.forEach((entry) => {
    searchParams.append(entry.key, entry.value);
    // return `${encodeURIComponent(entry.key)}=${encodeURIComponent(entry.value)}`;
  });
  return searchParams;
};
export const replaceDynamicBooleanValueInJSONBody = (str) => {
  return str.replace(/"{{([^}]+)}}"/g, (match, p1) => {
    const booleanValue = generateRandomBoolean();
    if (p1.trim() === 'boolean()') {
      return booleanValue; // Replace with the actual value returned by boolean()
    }
    return match; // Return the original match if it doesn't match
  });
};

export const fileParseToBase64String = async (file) => {
  // const textMap = ['text', 'document', 'plain', 'application/json', 'javascript', 'html'];
  // const otherMap = ['text', 'document', 'plain', 'application/json', 'javascript', 'html','image', 'pdf', 'video', 'jpeg', 'jpg', 'png'];
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    fileReader.onerror = (error) => {
      reject(new Error('failed to load file'));
    };
    fileReader.readAsDataURL(file);
  });
};
