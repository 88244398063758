import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { statusColors, statusMap } from '../../dashboard/components';
import Flex from '../../../components/base/Flex';
import { rgba } from '../../../utils/colors';
import PassedSvg from '../../../components/svg/PassedSvg';
import SkippedSvg from '../../../components/svg/SkippedSvg';
import FailedSvg from '../../../components/svg/FailedSvg';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { useGetCurrentTest } from '../../../hooks/apiHooks';

export const StatusBadge = ({ status, sx, width, height }) => {
  const _status = status in statusMap ? statusMap[status] : status;
  const iconMap = {
    passed: <PassedSvg width={width || 12} height={height || 12} />,
    failed: <FailedSvg width={width || 12} height={height || 12} />,
    skipped: <SkippedSvg />
  };

  return (
    <Flex
      alignItems={'center'}
      backgroundColor={rgba(statusColors[_status], 15)}
      px={'10px'}
      py={'6px'}
      color={statusColors[_status]}
      sx={{
        border: `1px solid ${statusColors[_status]}`,
        borderRadius: '4px',
        ...sx
      }}>
      {iconMap[_status] || null}
      <Typography textTransform={'capitalize'} fontSize={'0.75rem'} fontWeight={500} lineHeight={1}>
        {_status}
      </Typography>
    </Flex>
  );
};
export const StatusBadge2 = ({ status, sx, width, height }) => {
  const _status = status in statusMap ? statusMap[status] : status;
  const iconMap = {
    passed: <PassedSvg width={width || 12} height={height || 12} />,
    failed: <FailedSvg width={12} height={12} />,
    skipped: <SkippedSvg />
  };

  return (
    <Flex
      alignItems={'center'}
      backgroundColor={rgba(statusColors[_status], 15)}
      px={'10px'}
      py={'6px'}
      color={statusColors[_status]}
      sx={{
        border: `1px solid ${statusColors[_status]}`,
        borderRadius: '4px',
        ...sx
      }}>
      {iconMap[_status] || null}
      <Typography textTransform={'capitalize'} fontSize={'0.75rem'} fontWeight={500} lineHeight={1}>
        {_status}
      </Typography>
    </Flex>
  );
};
// these is used in suite runs page
export const AssertionComponentForStatusCode = ({ assertion, type, badgeProps, sx }) => {
  return (
    <Flex columnGap={3} sx={{ ...sx }}>
      <StatusBadge
        status={assertion[type].status}
        height={badgeProps.height}
        width={badgeProps.width}
      />
      <Typography variant={'body2'} fontWeight={1}>
        {type === 'status_code' &&
          `Expected status code to   ${assertion.status_code.expected}, Found status code ${assertion.status_code.received} `}
        {type === 'response_body_type' &&
          `Expected response body type to ${assertion.response_body_type.expected}`}
        {type === 'request_duration' &&
          `Request duration is not ${assertion.request_duration.expected} milliseconds`}
      </Typography>
    </Flex>
  );
};
// these is used in suite runs page
export const AssertionComponentForResponseHeaders = ({ assertion, type, badgeProps, sx }) => {
  return (
    <Flex columnGap={3} sx={{ ...sx }}>
      <StatusBadge status={assertion.status} height={badgeProps.height} width={badgeProps.width} />
      <Typography variant={'body2'} fontWeight={1}>
        {type === 'response_headers' &&
          (assertion.status === 'passed'
            ? `Expected header "${assertion.key}: ${assertion.expected}" to be present in the response headers`
            : `Expected header "${assertion.key}: ${assertion.expected}" is not present in the response headers`)}
        {type === 'response_body_content' &&
          (assertion.status === 'passed'
            ? `Response body content is true for - ${assertion.expected}`
            : `Response body content is not true for - ${assertion.expected}`)}
        {type === 'create_global_variable' &&
          (assertion.status === 'passed' ? (
            !!assertion.cascade ? (
              <Typography variant={'body2'}>
                Succesfully saved value of "
                <Typography color={'primary'} display={'inline'}>
                  response{!assertion.expected.startsWith('[') && '.'}
                </Typography>
                {assertion.expected}" as global variable`
              </Typography>
            ) : (
              <Typography variant={'body2'}>
                field{' '}
                <Typography color={'primary'} display={'inline'}>
                  "response.
                </Typography>
                {assertion.expected}" is present in JSON response
              </Typography>
            )
          ) : (
            <Typography variant={'body2'}>
              field : {assertion.expected} not present in response
            </Typography>
          ))}
      </Typography>
    </Flex>
  );
};
export const AllAssertionResults = () => {
  const {
    testRunResponse: { responseAssertions }
  } = useGetCurrentTest();
  return (
    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '200px', mt: 1 }}>
      {!!responseAssertions.status_code && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={responseAssertions.status_code.status} />
            <Typography variant={'body2'}>
              {`Expected status code to  ${responseAssertions.status_code.expected}, Found status code ${responseAssertions.status_code.received} `}
            </Typography>
          </Flex>
        </Box>
      )}

      {!!responseAssertions.request_duration && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={responseAssertions.request_duration.status} />
            <Typography variant={'body2'}>
              {`Expected request duration to ${responseAssertions.request_duration.expected} milliseconds`}
            </Typography>
          </Flex>
        </Box>
      )}
      {!!responseAssertions.response_body_type && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={responseAssertions.response_body_type.status} />
            <Typography variant={'body2'}>
              {`Expected response body type to ${responseAssertions.response_body_type.expected}`}
            </Typography>
          </Flex>
        </Box>
      )}

      {!!responseAssertions.response_body_content?.length &&
        responseAssertions.response_body_content.map((bodyContent, index) => (
          <Box key={index} mb={1}>
            <Flex columnGap={3}>
              <StatusBadge status={bodyContent.status} />
              <Typography variant={'body2'}>
                {bodyContent.status === 'passed'
                  ? `Response body content is true for - ${bodyContent.expected}`
                  : `Response body content is not true for - ${bodyContent.expected}`}
              </Typography>
            </Flex>
          </Box>
        ))}

      {!!responseAssertions.response_headers?.length &&
        responseAssertions.response_headers.map((header, index) => (
          <Flex columnGap={3} key={index} sx={{ mt: 1 }}>
            <StatusBadge status={header.status} />
            <Typography variant={'body2'}>
              {header.status === 'passed'
                ? `Expected header "${header.key}: ${header.expected}" to be present in the response headers`
                : `Expected header "${header.key}: ${header.expected}" is not present in the response headers`}
            </Typography>
          </Flex>
        ))}
      {!!responseAssertions.create_global_variable?.length &&
        responseAssertions.create_global_variable.map((result, index) => (
          <Flex columnGap={3} key={index} sx={{ mt: 1 }}>
            <StatusBadge status={result.status} />

            {result.status !== 'passed' && (
              <Typography variant={'body2'}>
                field : {result.expected} not present in response
              </Typography>
            )}
            {result.status === 'passed' && !result.cascade && (
              <Typography variant={'body2'}>
                field{' '}
                <Typography color={'primary'} display={'inline'}>
                  "response.
                </Typography>
                {result.expected}" is present in JSON response
              </Typography>
            )}
            {result.status === 'passed' && !!result.cascade && (
              <Typography variant={'body2'}>
                Successfully saved value of "
                <Typography color={'primary'} display={'inline'}>
                  response{!result.expected.startsWith('[') && '.'}
                </Typography>
                {result.expected}" as global variable`
              </Typography>
            )}
          </Flex>
        ))}
    </CustomScrollbar>
  );
};

export const PassedAssertionResults = () => {
  const {
    testRunResponse: { responseAssertions }
  } = useGetCurrentTest();
  return (
    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '200px', mt: 1 }}>
      {responseAssertions?.status_code?.status === 'passed' && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={'passed'} />
            <Typography variant={'body2'}>
              Expected status code to be {responseAssertions.status_code.expected}, Found status
              code {responseAssertions.status_code.received}
            </Typography>
          </Flex>
        </Box>
      )}
      {responseAssertions?.request_duration?.status === 'passed' && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={'passed'} />
            <Typography variant={'body2'}>
              Expected request duration to {responseAssertions.request_duration.expected}{' '}
              milliseconds
            </Typography>
          </Flex>
        </Box>
      )}
      {responseAssertions?.response_body_type?.status === 'passed' && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={'passed'} />
            <Typography variant={'body2'}>
              Expected response body type to {responseAssertions.response_body_type.expected}
            </Typography>
          </Flex>
        </Box>
      )}

      {!!responseAssertions?.response_body_content?.length &&
        responseAssertions.response_body_content
          .filter((bodyContent) => bodyContent.status === 'passed')
          .map((bodyContent, index) => (
            <Box key={index} mb={1}>
              <Flex columnGap={3}>
                <StatusBadge status={'passed'} />
                <Typography variant={'body2'}>
                  Response body content is true for - {bodyContent.expected}
                </Typography>
              </Flex>
            </Box>
          ))}

      {!!responseAssertions?.response_headers?.length &&
        responseAssertions.response_headers
          .filter((header) => header.status === 'passed')
          .map((header, index) => (
            <Flex columnGap={3} key={index} sx={{ mt: 1 }}>
              <StatusBadge status={'passed'} />
              <Typography variant={'body2'}>
                Expected header "{header.key}: {header.expected}" present in the response headers
              </Typography>
            </Flex>
          ))}

      {!!responseAssertions?.create_global_variable?.length &&
        responseAssertions.create_global_variable
          .filter((result) => result.status === 'passed')
          .map((result, index) => (
            <Flex columnGap={3} key={index} sx={{ mt: 1 }}>
              <StatusBadge status={'passed'} />
              {!!result.cascade && (
                <Typography variant={'body2'}>
                  Succesfully saved value of "
                  <Typography color={'primary'} display={'inline'}>
                    response{!result.expected.startsWith('[') && '.'}
                  </Typography>
                  {result.expected}" as global variable`
                </Typography>
              )}
              {!result.cascade && (
                <Typography variant={'body2'}>
                  field{' '}
                  <Typography color={'primary'} display={'inline'}>
                    "response.
                  </Typography>
                  {result.expected}" is present in JSON response
                </Typography>
              )}
            </Flex>
          ))}
    </CustomScrollbar>
  );
};

export const FailedAssertionResults = () => {
  const {
    testRunResponse: { responseAssertions }
  } = useGetCurrentTest();
  return (
    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '200px', mt: 1 }}>
      {responseAssertions.status_code?.status === 'failed' && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={'failed'} />
            <Typography variant={'body2'}>
              Expected status code to be {responseAssertions.status_code.expected}, Found status
              code {responseAssertions.status_code.received}
            </Typography>
          </Flex>
        </Box>
      )}
      {responseAssertions.request_duration?.status === 'failed' && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={'failed'} />
            <Typography variant={'body2'}>
              Expected request duration to {responseAssertions.request_duration.expected}{' '}
              milliseconds
            </Typography>
          </Flex>
        </Box>
      )}
      {responseAssertions.response_body_type?.status === 'failed' && (
        <Box mb={1}>
          <Flex columnGap={3}>
            <StatusBadge status={'failed'} />
            <Typography variant={'body2'}>
              Expected response body type to {responseAssertions.response_body_type.expected}`
            </Typography>
          </Flex>
        </Box>
      )}

      {!!responseAssertions.response_body_content?.length &&
        responseAssertions.response_body_content
          .filter((bodyContent) => bodyContent.status === 'failed')
          ?.map((bodyContent, index) => (
            <Box key={index} mb={1}>
              <Flex columnGap={3}>
                <StatusBadge status={'failed'} />
                <Typography variant={'body2'}>
                  Response body content is not true for - {bodyContent.expected}
                </Typography>
              </Flex>
            </Box>
          ))}

      {!!responseAssertions.response_headers?.length &&
        responseAssertions.response_headers
          .filter((header) => header.status === 'failed')
          ?.map((header, index) => (
            <Flex columnGap={3} key={index} sx={{ mt: 1 }}>
              <StatusBadge status={'failed'} />
              <Typography
                variant={
                  'body2'
                }>{`Expected header "${header.key}: ${header.expected}" not found in the response headers`}</Typography>
            </Flex>
          ))}
      {!!responseAssertions.create_global_variable?.length &&
        responseAssertions.create_global_variable
          .filter((result) => result.status === 'failed')
          .map((result, index) => (
            <Flex columnGap={3} key={index} sx={{ mt: 1 }}>
              <StatusBadge status={'failed'} />
              <Typography variant={'body2'}>
                field : "<Typography display={'inline'}>{result.expected}</Typography>", not present
                in response
              </Typography>
            </Flex>
          ))}
    </CustomScrollbar>
  );
};
