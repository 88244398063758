import { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  Tooltip,
  Divider,
  Skeleton,
  IconButton,
  Pagination,
  Checkbox
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import Flex from '../../../components/base/Flex';
import FolderActionMenu from './FolderActionMenu';
import TestActionMenu from './TestActionMenu';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import { toast } from 'react-toastify';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import EmptyState from '../../../components/base/EmptyState';
import ErrorState from '../../../components/base/ErrorState';
import { useTheme } from '@emotion/react';
import React from 'react';
import { DndContext, useSensors } from '@dnd-kit/core';
import { useDroppable } from '@dnd-kit/core';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { MouseSensor, useSensor } from '@dnd-kit/core';
import CustomModal from '../../../components/base/CustomModal';
import MoveToFolderSuggestion from './MoveToFolder';
import useFetchApiFoldersAndTests from '../../../hooks/apiHooks/queryHooks/useFetchFoldersAndTests';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { useDebounceSearchValue } from '../../../hooks/apiHooks/useDebounce';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';

export const colorMap = {
  get: '#53BB63',
  post: '#FFB100',
  put: '#00CCFF',
  patch: '#E39FF6',
  delete: '#FF775E',
  head: '#53BB63',
  options: '#B03060'
};
const Folder = ({ data }) => {
  const theme = useTheme();
  const { isOver, setNodeRef, over } = useDroppable({
    id: data.id,
    data: {
      name: data.name
    }
  });
  const folderRef = useRef(null);
  //////////////////////////////////
  const { folderId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const {
    foldersState: { isDraggingId }
  } = useContext(ApiTestContext);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box ref={setNodeRef}>
      <Box
        sx={{
          borderBottom: '0.05px solid #ccc',
          borderTop: '0.05px solid #ccc',
          borderRight: '0.05px solid #ccc',
          marginBottom: '2px',
          position: 'relative'
        }}>
        <Box
          onMouseEnter={() => setHoveredFolder(data.id)}
          onMouseLeave={() => setHoveredFolder(null)}
          sx={{
            bgcolor:
              data.id === +folderId ? 'rgba(36, 195, 217, 0.15)' : 'rgba(36, 195, 217, 0.05)',
            py: '10px',
            pl: '8px',
            pr: '4px',
            borderLeft: data.id === +folderId ? '3px solid #1958b8' : '',
            cursor: 'pointer',
            userSelect: 'none',
            transition: 'background-color 0.3s ease',
            '&: hover': {
              background: 'rgba(36, 195, 217, 0.2)'
            }
          }}
          onClick={(e) => e.currentTarget.contains(e.target) && handleClick()}>
          <Flex justifyContent={'space-between'} sx={{ maxHeight: 'max-content', minHeight: 0 }}>
            <Tooltip title={data.name}>
              <Typography
                variant={'body2'}
                fontWeight={400}
                sx={{
                  width: 'inherit',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  textOverflow: 'ellipsis'
                }}>
                {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                <FolderIcon sx={{ color: '#24C3D9', ml: 1, mr: '3px' }} /> {data.name}
              </Typography>
            </Tooltip>
            {!!isOver && isDraggingId !== data.id && (
              <Typography
                sx={{
                  position: 'absolute',
                  right: 50,
                  px: 2,
                  py: 0.8,
                  borderRadius: 2,
                  background: theme.palette.background.light_dark,
                  top: -10,
                  zIndex: 2
                }}>
                Move into {data.name}
              </Typography>
            )}
            {hoveredFolder === data.id && <FolderActionMenu data={data} ref={folderRef} />}
          </Flex>
        </Box>
      </Box>
      {isOpen &&
        data.subdirectories?.map((subfolder) => (
          <DraggableSubfolderWrapper key={subfolder.id} data={subfolder} />
        ))}
      {isOpen &&
        data.api_tests?.map((test, index) => (
          <TestRequest key={index} test={test} index={index} folderId={data.id} />
        ))}
      {isOpen && !data.api_tests && data.subdirectories?.length === 0 && (
        <Typography textAlign={'center'} variant={'body2'} py={1.5}>
          This folder is empty
        </Typography>
      )}
    </Box>
  );
};
const DraggableSubfolderWrapper = ({ data }) => {
  const {
    attributes,
    listeners,
    setNodeRef: draggableRef,
    transform
  } = useDraggable({
    id: data.id,
    data: {
      name: data.name,
      case: 'subfolder',
      parentId: data.parent_id
    }
  });
  const style = {
    transform: CSS.Translate.toString(transform)
  };

  return (
    <Box
      key={data.id}
      pl={1.5}
      sx={{ bgcolor: 'rgba(36, 195, 217, 0.05)', ...style }}
      {...attributes}
      {...listeners}
      ref={draggableRef}>
      <Folder data={data} />
    </Box>
  );
};

const TestRequest = ({ test, index, folderId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: test.id,
    data: {
      name: test?.name,
      case: 'test',
      folderId: test?.folder_id
    }
  });
  const style = {
    transform: CSS.Translate.toString(transform)
  };

  const { testId, exampleId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredTestCase, setHoveredTestCase] = useState(null);
  const testRef = useRef(null);
  const { setFoldersState } = useContext(ApiTestContext);
  const {
    massSelection,
    setMassSelection,
    foldersState: { testsList }
  } = useContext(ApiTestContext);

  const activeProject = useActiveProject();
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isOpen === false) {
      setFoldersState((prev) => {
        return { ...prev, clickedTest: test };
      });
    }
    setIsOpen(!isOpen);
  };
  const addNewPathSegment = () => {
    // Use the navigate function to update the URL
    navigate(
      `/projects/${activeProject?.id}/api-suites/${
        folderId ? `${folderId}/${test.id}/edit` : `${test.id}/edit`
      }`
    );
  };
  return (
    <Box ref={setNodeRef} sx={{ ...style }} {...attributes} {...listeners}>
      <Box
        pr={'4px'}
        py={'2px'}
        pl={folderId ? '14px' : '4px'}
        sx={{
          bgcolor:
            testId === test.id && !exampleId && !massSelection.active
              ? 'rgba(220,220,220, 0.3)'
              : 'transparent',
          borderLeft:
            testId === test.id && !exampleId && !massSelection.active ? '2px solid #1958B8' : '',
          '&: hover': {
            backgroundColor: theme.palette.hover.default
          }
        }}
        onClick={(e) => {
          if (!massSelection.active) {
            // setClickedTest(test);
            e.currentTarget.contains(e.target) && addNewPathSegment();
            return;
          }
        }}
        onMouseEnter={() => setHoveredTestCase(test.id)}
        onMouseLeave={() => setHoveredTestCase(null)}>
        <Flex
          justifyContent={'space-between'}
          sx={{
            cursor: 'pointer'
          }}>
          <Flex
            columnGap={0}
            sx={{
              width: '85%'
            }}>
            <IconButton
              size={'small'}
              onClick={handleClick}
              sx={{
                visibility: test.examples?.length ? 'visible' : 'hidden'
              }}>
              {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>

            {!!massSelection.active && (
              <IconButton
                size={'small'}
                sx={{
                  p: 0,
                  m: 0
                }}>
                <Checkbox
                  checked={!!massSelection.selectedApiTests.includes(test.id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMassSelection((prev) => {
                      let testIDs = [...prev.selectedApiTests];
                      if (testIDs.includes(test.id)) {
                        testIDs = testIDs.filter((id) => id !== test.id);
                      } else {
                        testIDs = testIDs.concat(test.id);
                      }
                      return { ...prev, selectedApiTests: testIDs };
                    });
                  }}
                />
              </IconButton>
            )}

            <Tooltip title={test.name}>
              <Flex
                variant={'body2'}
                sx={{
                  width: 'inherit',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                <Typography
                  component={'span'}
                  variant={'body2'}
                  textTransform={'uppercase'}
                  fontWeight={600}
                  sx={{
                    color: colorMap[test?.request_method?.toLowerCase()] || 'black'
                  }}>
                  {test.request_method}
                </Typography>
                <Typography
                  variant={'body2'}
                  component={'span'}
                  ml={1}
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}>
                  {test.name}
                </Typography>
              </Flex>
            </Tooltip>
          </Flex>
          {hoveredTestCase === test.id && (
            <TestActionMenu test={test} onEdit={addNewPathSegment} ref={testRef} />
          )}
        </Flex>
        {index + 1 < testsList.length && <Divider />}
      </Box>
      {isOpen &&
        test.examples?.map((example, index) => (
          <TestExample
            key={example.uuid}
            index={index}
            test={example}
            data={test}
            testId={test.id}
            folderId={folderId}
          />
        ))}
    </Box>
  );
};

const TestExample = ({ test, index, data, testId, folderId }) => {
  const navigate = useNavigate();
  const { exampleId } = useParams();
  const activeProject = useActiveProject();
  const [hoveredTestExample, setHoveredTestExample] = useState(null);
  const testRef = useRef(null);
  const addNewPathSegment = () => {
    if (exampleId !== test.uuid) {
      // resetInitialState();
    }
    // Use the navigate function to update the URL
    navigate(
      `/projects/${activeProject?.id}/api-suites/${
        folderId ? `${folderId}/${testId}/example/${test.uuid}` : `${testId}/example/${test.uuid}`
      }`
    );
  };
  const { setFoldersState, massSelection, setMassSelection } = useContext(ApiTestContext);
  return (
    <Box
      pr={'4px'}
      pl={'44px'}
      sx={{
        bgcolor: exampleId === test.uuid ? 'rgba(220,220,220, 0.5)' : 'transparent',
        borderLeft: exampleId === test.uuid ? '2px solid #1958B8' : '',
        borderBottom: '0.05px solid #ddd',
        '&: hover': {
          backgroundColor: 'rgba(220,220,220, 0.1)'
        }
      }}
      onClick={(e) => {
        if (!massSelection.active) {
          setFoldersState((prev) => {
            return { ...prev, clickedTestExample: test };
          });
          e.currentTarget.contains(e.target) && addNewPathSegment();
        }
      }}
      onMouseEnter={() => setHoveredTestExample(test.uuid)}
      onMouseLeave={() => setHoveredTestExample(null)}>
      <Flex
        justifyContent={'space-between'}
        sx={{
          cursor: 'pointer',
          py: '2px'
        }}>
        <Flex columnGap={0}>
          {/* <IconButton size={'small'} onClick={handleClick} sx={{ visibility: 'visible' }}>
            {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton> */}
          {!!massSelection.active && (
            <IconButton
              size={'small'}
              sx={{
                p: 0,
                m: 0
              }}>
              <Checkbox
                checked={!!massSelection.selectedApiTestExamples.includes(test.uuid)}
                onClick={(e) => {
                  e.stopPropagation();
                  setMassSelection((prev) => {
                    let testIDs = [...prev.selectedApiTestExamples];
                    if (testIDs.includes(test.uuid)) {
                      testIDs = testIDs.filter((uuid) => uuid !== test.uuid);
                    } else {
                      testIDs = testIDs.concat(test.uuid);
                    }
                    return { ...prev, selectedApiTestExamples: testIDs };
                  });
                }}
              />
            </IconButton>
          )}
          <Tooltip title={test.name}>
            <Flex
              variant={'body2'}
              columnGap={0}
              sx={{
                width: 'inherit',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                pl: exampleId === test.uuid ? '5px' : '5px'
              }}>
              <Typography
                component={'span'}
                variant={'body2'}
                sx={{
                  border: '2px solid black',
                  fontSize: '6px',
                  px: '2.2px',
                  py: '1px',
                  color: 'black',
                  fontWeight: 'bold'
                }}>
                e.g.
              </Typography>
              <Typography
                variant={'body2'}
                component={'span'}
                ml={'6px'}
                sx={{
                  width: 'inherit',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  alignSelf: 'stretch'
                }}>
                {test.name}
              </Typography>
            </Flex>
          </Tooltip>
        </Flex>
        {hoveredTestExample === test.uuid && (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            <TestActionMenu
              test={test}
              useCase={'test-example'}
              ref={testRef}
              onEdit={addNewPathSegment}
              // onDelete={requestExampleDeleteConfirm}
              // isLoading={isDeleting}
              // exampleId={test.uuid}
            />
          </Box>
        )}
      </Flex>
      {index + 1 < data.length && <Divider />}
      {/* <ConfirmationDialog
        title={'Are you sure you want to delete this request example?'}
        description={'When you delete a request example, the action cannot be undone.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      /> */}
    </Box>
  );
};

const TestFolders = () => {
  /////////////////////////////////////////////////////////////
  const { refetchFoldersAndTests, isFetchingAllFoldersAndTests, fetchFolderAndTestError } =
    useFetchApiFoldersAndTests();
  const {
    foldersState: { foldersList, testsList, clickedTest, paginate },
    setFoldersState
  } = useContext(ApiTestContext);
  const debounceSearchValue = useDebounceSearchValue();
  const activeProject = useActiveProject();
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 5 pixels before activating, this prevent the draggable listeners to collide with the click listener for test component. the dg context would think you want to drag but you just want to click to fetch the test
    activationConstraint: {
      distance: 5
    }
  });
  const sensors = useSensors(mouseSensor);
  const theme = useTheme();
  const [path, setPath] = useState([]);
  // move
  const { mutateAsync: moveEntityToFolder, isLoading: isMovingFolder } = useScandiumMutation(
    `/projects/${activeProject?.id}/folders/move`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        refetchFoldersAndTests();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  const { mutateAsync: moveTestToFolder, isLoading: isMovingTest } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/move`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        // if test moved id is equal to currently clicked test, update the url to rflect the folder it moved to
        refetchFoldersAndTests();
      },
      onError: (error) => {
        toast.error(error.message);
      },
      enabled: !!clickedTest.id
    }
  );
  const handleEntityMove = async (entitiesId, useCase) => {
    try {
      if (useCase === 'folder-folder') {
        await moveEntityToFolder(entitiesId);
      } else {
        await moveTestToFolder(entitiesId);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleDragStart = (event) => {
    setFoldersState((prev) => {
      return { ...prev, isDraggingId: event.active.id };
    });
    // setIsDraggingId(event.active.id);
  };
  const handleDragEnd = (event) => {
    setFoldersState((prev) => {
      return { ...prev, isDraggingId: null };
    });
    const { active, over } = event;
    if (!over || !active) {
      return;
    }
    let data = {
      folder_id: active.id,
      parent_id: over.id
    };
    if (active.id === over.id) {
      return;
    }
    if (active.data.current.case === 'test') {
      data = {
        api_test_id: active.id,
        folder_id: over.id
      };
      if (active.data.current.folderId === over.id) {
        // console.log('test already exist in destination folder');
        return;
      }
      handleEntityMove(data, 'test-folder');
    }
    if (active.data.current.case === 'subfolder') {
      if (active.data.current.parentId === over.id) {
        // console.log('subfolder already exist in destination folder');
        return;
      }
      handleEntityMove(data, 'folder-folder');
    }
  };
  // const handleFolderClick = (folder) => {
  //   setPath((prevPath) => [...prevPath, folder]);
  // };
  const [testsPage, setTestpage] = useState('');
  const handlePageChange = (e, value) => {
    setTestpage(value.toString());
  };
  const currentFolder = path.length > 0 ? path[path.length - 1] : null;
  const arrayOfNumbers = Array.from({ length: 10 }, (_, index) => index + 1);
  const allEntries = [...foldersList, ...testsList];
  // const paginate = allFoldersAndTests.test_cases?.data.meta;
  return (
    <Box
      mt={2}
      sx={{
        position: 'relative',
        pointerEvents: !!isMovingFolder || isMovingTest ? 'none' : ''
      }}>
      <Skeleton
        variant="rectangular"
        sx={{
          display: !!isMovingTest || !!isMovingFolder ? 'block' : 'none',
          width: '100%',
          height: '100%',
          position: 'absolute'
        }}
      />
      <CustomScrollbar
        sx={{
          height: '550px',
          // outline: '1px solid pink',
          overflowY: 'scroll',
          minHeight: '200px',
          maxHeight: '550px',
          overflowX: 'clip',
          pr: 0
        }}>
        <DndContext
          // collisionDetection={closestCenter}
          sensors={sensors}
          onDragCancel={() => {}}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}>
          {foldersList.map((folder) => (
            <Folder
              key={folder.id}
              data={folder}
              // onClick={handleFolderClick}
              // refetchFolders={refetchFoldersAndTests}
              // resetInitialState={resetInitialState}
            />
          ))}
          {testsList?.map((test, index) => (
            <TestRequest
              key={index}
              // requestSaveChangesDialog={requestSaveChangesDialog}
              index={index}
              test={test}
            />
          ))}
          {isFetchingAllFoldersAndTests && (
            <Box>
              {arrayOfNumbers.map((num) => (
                <Skeleton
                  animation={'wave'}
                  key={num}
                  variant={'rectangular'}
                  width={'100%'}
                  height={30}
                  sx={{ mb: 1 }}
                />
              ))}
            </Box>
          )}

          {!isFetchingAllFoldersAndTests && fetchFolderAndTestError ? (
            <ErrorState error={fetchFolderAndTestError} />
          ) : (
            !isFetchingAllFoldersAndTests &&
            !fetchFolderAndTestError &&
            !allEntries?.length &&
            (!!debounceSearchValue ? (
              <EmptyState
                width={'50px'}
                height={'50px'}
                title={'There are no matching results'}
                description={'Try searching for something else, or create a new test'}
              />
            ) : (
              <EmptyState
                width={'50px'}
                height={'50px'}
                title={"It's lonely here at the moment"}
                description={`Why not create a few tests or folders?`}
              />
            ))
          )}
        </DndContext>
      </CustomScrollbar>

      {paginate?.total > 15 && (
        <Pagination
          count={paginate?.last_page}
          page={+testsPage || 1}
          color="secondary"
          onChange={handlePageChange}
          size={'small'}
          sx={{
            '& .Mui-selected': {
              backgroundColor: '#24C3D9',
              color: '#ffffff'
            },
            '& .MuiPaginationItem-root:not(.Mui-selected)': {
              backgroundColor: '#1958B8',
              color: '#fff'
            },
            my: 0,
            ml: 2
          }}
        />
      )}
      {/* <CustomModal open={showDestinationFolders}>
        <MoveToFolderSuggestion />
      </CustomModal> */}
    </Box>
  );
};

export default TestFolders;
