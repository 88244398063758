import { getQueryFn, getReactQueryKey, useScandiumMutation, useScandiumQuery } from './utils';
import { useCookies } from 'react-cookie';

export const useDeleteTest = ({ projectId, testId, ...options }) => {
  return useScandiumMutation(`/projects/${projectId}/api-tests/${testId}`, {
    method: 'DELETE',
    ...options
  });
};

export const useFetchTests = ({ projectId, ...options }) => {
  return useScandiumQuery(`/projects/${projectId}/api-tests`, {
    enabled: !!projectId,
    ...options
  });
};

export const useFetchTest = ({ projectId, testId, ...options }) => {
  return useScandiumQuery(`/projects/${projectId}/api-tests/${testId}`, {
    enabled: !!projectId && !!testId,
    ...options
  });
};

export const useTestRuns = (options = {}) => {
  const [cookies] = useCookies(['token', 'email', 'name']);
  const mutationKey = getReactQueryKey('/projects/{project_id}/api-tests/runs');
  const mutationFn = ({ projectId, data, testId }) =>
    getQueryFn({
      path: `/projects/${projectId}/api-tests/run/${testId ? `${testId}` : ''}`,
      token: cookies?.token,
      method: 'PUT'
    })(data);
  return useScandiumMutation('', {
    mutationFn,
    mutationKey,
    ...options
  });
};
