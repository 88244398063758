import { useContext } from 'react';
import { FloatingVariableWindowContext } from '../store/floatingVariableWindowState';
import { ApiDialogsAndModalsContext } from '../store/apiState/apiDialogsAndModalsContext';
export const useFocusedState = () => {
  const {
    focusedInput,
    focusedInpuSetterFn,
    setFocusedInputPosition,
    setVariablesFilter,
    setIsEditorFocused
  } = useContext(FloatingVariableWindowContext);
  const { requestVariablesSuggestionList, closeVariablesSuggestionList } = useContext(
    ApiDialogsAndModalsContext
  );
  const handleVariableFloatingWindow = (event, setterFn) => {
    // make sure that editorRef is not focused
    setIsEditorFocused(false);
    focusedInput.current = event.target;
    focusedInpuSetterFn.current = setterFn;
    let cursorPosition = focusedInput.current.selectionStart;
    if (
      cursorPosition >= 2 &&
      event.target.value.substring(cursorPosition - 2, cursorPosition) === '{{'
    ) {
      requestVariablesSuggestionList();
    }
    const lastIndex = event.target.value.lastIndexOf('{{');
    if (lastIndex != -1) {
      const substring = event.target.value.substring(lastIndex + 2);
      setVariablesFilter(substring);
    } else {
      closeVariablesSuggestionList();
      setVariablesFilter('');
    }
    setFocusedInputPosition({
      top: focusedInput.current.getBoundingClientRect().y + window.scrollY + 40,
      left: focusedInput.current.getBoundingClientRect().x + window.scrollX - 50
    });
    // setFocusedInputPosition({
    //   top: event.pageY,
    //   left: event.pageX
    // });
  };
  // add a mousemove event that updates the position of the cursor
  return {
    handleVariableFloatingWindow
  };
};
