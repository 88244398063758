import { toast } from 'react-toastify';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useQueryClient } from 'react-query';

const useMoveTestOrFolder = (endPoint) => {
  const queryClient = useQueryClient();
  const { mutateAsync: moveTestOrFolder, isLoading: isMovingFolder } = useScandiumMutation(
    endPoint,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: 'get-apiFoldersAndTests' });
        toast.success(data.message);
      },
      onError: (error) => {
        toast.error(error?.message);
      }
    }
  );
  return { moveTestOrFolder, isMovingFolder };
};

export default useMoveTestOrFolder;
