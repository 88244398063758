import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TextField,
  Tooltip,
  Box
} from '@mui/material';
import { useContext } from 'react';
import { useFocusedState } from '../../../hooks/useFocusedState';
import Flex from '../../../components/base/Flex';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { Co2Sharp } from '@mui/icons-material';
import { useGetCurrentTest } from '../../../hooks/apiHooks';
import { useSetTestQueryParams } from '../../../hooks/apiHooks/updateTestPropsHooks';
import { Typography } from '@mui/material/node';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`
  }
}));

const PathVariablesTable = () => {
  const rowStyle = {
    height: '12px',
    outline: 'none',
    fontSize: '12px',
    marginTop: '4px'
  };
  ////////////////////////////////////////////////////////////////////////
  const { handleVariableFloatingWindow } = useFocusedState();
  const {
    testPropsForRequest: { pathVariables }
  } = useGetCurrentTest();
  const { handleSetPathVariable } = useSetTestQueryParams();
  return (
    <>
      {!!pathVariables.length && (
        <>
          <Typography mt={2} fontWeight={'medium'} fontSize={'0.8rem'}>
            Path Variables
          </Typography>
          <Table sx={{ width: '100%' }} size="small" aria-label={'a Query Param Table'}>
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Key</StyledTableCell>
                <StyledTableCell>Value</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pathVariables.map((variable, index) => (
                <TableRow key={index}>
                  <StyledTableCell
                    sx={{
                      textAlign: 'center'
                    }}></StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      size={'small'}
                      fullWidth
                      placeholder={'key'}
                      value={variable?.key}
                      variant={'standard'}
                      InputProps={{
                        disableUnderline: true
                      }}
                      inputProps={{
                        readOnly: true,

                        style: { ...rowStyle, opacity: 0.6, cursor: 'not-allowed' }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      size={'small'}
                      fullWidth
                      placeholder={'value'}
                      value={variable.value}
                      onChange={(e) => {
                        handleVariableFloatingWindow(e, () => {
                          handleSetPathVariable(e, index, 'value');
                        });
                        handleSetPathVariable(e, index, 'value');
                      }}
                      variant={'standard'}
                      InputProps={{
                        disableUnderline: true
                      }}
                      inputProps={{
                        style: { ...rowStyle }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Flex>
                      <TextField
                        size={'small'}
                        fullWidth
                        placeholder={'description'}
                        value={variable.description}
                        onChange={(e) => handleSetPathVariable(e, index, 'description')}
                        variant={'standard'}
                        InputProps={{
                          disableUnderline: true
                        }}
                        inputProps={{
                          readOnly: variable?.src === 'authorization',
                          style: {
                            ...rowStyle,
                            opacity: variable?.src === 'authorization' ? 0.6 : 1
                          }
                        }}
                      />
                    </Flex>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

export default PathVariablesTable;
