import React, { useContext, useEffect, useState } from 'react';
import CustomModal from '../../../components/base/CustomModal';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import Flex from '../../../components/base/Flex';
import Radio from '@mui/material/Radio';
import { CloseSharp } from '@mui/icons-material';
import { toast } from 'react-toastify';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';
import { checkIfAgentIsOn } from '../utils';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';

export const SelectTestRunAgent = () => {
  const { openTestRunAgentModal, closeTestRunAgentModal } = useContext(ApiDialogsAndModalsContext);
  const [agentIsActive, setAgentIsActive] = useState(false);
  const {
    testAgent: { runAgent, agentPort },
    setTestAgent
  } = useContext(ApiTestContext);
  const [portInput, setPortInput] = useState(agentPort);
  const [editPortMode, setEditPortMode] = useState(false);
  const [invalidPortError, setInvalidPortError] = useState(false);
  useEffect(() => {
    const checkForAgent = async () => {
      let result = await checkIfAgentIsOn(agentPort);
      if (!result && runAgent === 'Desktop-Agent') {
        setTestAgent((prev) => {
          return { ...prev, runAgent: 'Cloud-Agent' };
        });
      }
      setAgentIsActive(result);
    };
    checkForAgent();
  }, [openTestRunAgentModal]);
  return (
    <>
      <CustomModal
        open={openTestRunAgentModal}
        onClose={closeTestRunAgentModal}
        sx={{
          border: 'none'
        }}>
        <Stack
          sx={{
            px: 3,
            py: 1
          }}>
          <CloseSharp
            onClick={() => {
              closeTestRunAgentModal();
            }}
            fontSize={'medium'}
            fontWeight={'light'}
            color=""
            sx={{
              outline: '1px solid #8B8B8B',
              p: 0.4,
              borderRadius: 2,
              ml: 'auto',
              '&:hover': {
                background: '#8B8B8B',
                cursor: 'pointer'
              }
            }}
          />
          <Typography fontSize={22} mb={2}>
            Select A{' '}
            <Typography fontWeight={600} fontSize={22} display={'inline'}>
              {' '}
              Scandium Agent
            </Typography>
          </Typography>
          {/* <DownloadLink /> */}
          <Stack gap={2}>
            <Agent
              setAgentIsActive={setAgentIsActive}
              value={'Desktop-Agent'}
              title={'Desktop Agent'}
              text={
                'Send requests via the Scandium Desktop Agent running locally on the user’s computer'
              }>
              {!!agentIsActive ? (
                <Flex>
                  <TextField
                    type="number"
                    value={portInput}
                    defaultValue={agentPort}
                    disabled={!editPortMode}
                    onChange={(e) => {
                      const timeout = setTimeout(() => {
                        if (+e.target.value < 1024 || +e.target.value > 49151) {
                          setInvalidPortError(true);
                        } else {
                          setInvalidPortError(false);
                        }
                        clearTimeout(timeout);
                      }, 800);
                      setPortInput(e.target.value);
                    }}
                    size="small"
                    label="port"
                    placeholder="specify port"
                    sx={{
                      mt: 1,
                      mr: 1,
                      width: 140
                    }}
                  />
                  {!!editPortMode ? (
                    <IconButton disabled={!!invalidPortError}>
                      <CheckOutlinedIcon
                        sx={{
                          color: !!invalidPortError ? '8B8B8B' : '#0288d1'
                        }}
                        onClick={() => {
                          setTestAgent((prev) => {
                            return { ...prev, agentPort: portInput };
                          });
                          // setAgentPort(portInput);
                          setEditPortMode(false);
                        }}
                      />{' '}
                    </IconButton>
                  ) : (
                    <IconButton>
                      <ModeEditOutlinedIcon
                        onClick={() => {
                          setEditPortMode(true);
                        }}
                      />
                    </IconButton>
                  )}
                </Flex>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open('https://github.com/GetScandium/DesktopAgentReleases/releases'); // Opens URL in a new tab
                  }}>
                  Download
                </Button>
              )}
            </Agent>
            <Divider orientation={'horizontal'} flexItem />
            <Agent
              value={'Cloud-Agent'}
              title={'Cloud Agent'}
              text={'Send HTTP requests via Scandium’s cloud server'}></Agent>
            <Divider orientation={'horizontal'} flexItem />
            <Agent
              value={'Browser-Agent'}
              title={'Browser Agent'}
              text={'Sending your requests directly via the browser'}></Agent>
          </Stack>
          <Typography
            marginTop={2}
            color={'primary'}
            sx={{
              visibility: !invalidPortError && 'hidden'
            }}>
            * enter a value between 1024 and 49151
          </Typography>
          {/* <Stack marginTop={5} gap={1}>
            <Typography fontSize={20}> Your can also Auto-select</Typography>
            <Typography color={'#8B8B8B'}>
              {' '}
              Sandium will automatically select the best agent for your request.
            </Typography>
          </Stack> */}
        </Stack>
      </CustomModal>
    </>
  );
};
const Agent = ({ title, text, children, value, setAgentIsActive = () => {} }) => {
  const {
    testAgent: { runAgent, agentPort },
    setTestAgent
  } = useContext(ApiTestContext);

  return (
    <Flex
      sx={{
        alignItems: 'flex-start'
      }}>
      <Radio
        size="medium"
        sx={{
          mt: -0.7
        }}
        checked={value === runAgent}
        defaultChecked={value === runAgent}
        value={value}
        onChange={async (e) => {
          if (e.target.value === 'Desktop-Agent') {
            await checkIfAgentIsOn(
              agentPort,
              () => {
                toast.success('connected to desktop agent');
                setAgentIsActive(true);
                setTestAgent((prev) => {
                  return { ...prev, runAgent: e.target.value };
                });
                // setRunAgent(e.target.value);
                localStorage.setItem('scandium-mode', e.target.value);
              },
              () => {
                toast.info(`Scandium Desktop Agent is not active on port ${agentPort}`);
              }
            );
          } else {
            localStorage.setItem('scandium-mode', e.target.value);
            setTestAgent((prev) => {
              return { ...prev, runAgent: e.target.value };
            });
          }
        }}
      />
      <Stack gap={1}>
        <Typography fontSize={18} color={'primary'}>
          {title}
        </Typography>
        <Typography color={'#8B8B8B'} maxWidth={220}>
          {text}
        </Typography>
        {children}
      </Stack>
    </Flex>
  );
};
