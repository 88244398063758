import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// src\components\base\CustomModal.js
import CustomModal from '../../../components/base/CustomModal';
import StarSvg from '../../../components/svg/StarSvg';
import OutlinedButton from '../../../components/base/OutlinedButton';
import Flex from '../../../components/base/Flex';
import { Check, Close } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 10,
    padding: 8,
    paddingLeft: 6,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: 'start',
    borderRight: `2px solid ${theme.palette.background.white_black}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    borderRight: `2px solid ${theme.palette.background.white_black}`,
    fontSize: 10,
    textAlign: 'start',
    padding: 10
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const createTableRowData = (features, freeForever, premium, enterprise) => {
  return { features, freeForever, premium, enterprise };
};
const check = <Check color="primary" style={{ fontSize: '14px' }} />;
const close = <Close color="primary" fontSize="small" style={{ fontSize: '14px' }} />;
const rows = [
  createTableRowData('Users per Organization', 1, 'Unlimited', 'Unlimited'),
  createTableRowData('Projects', 'Unlimited', 'Unlimited', 'Unlimited'),
  createTableRowData('Test Cases', 'Unlimited', 'Unlimited', 'Unlimited'),
  createTableRowData('Local Executions', 'Unlimited', 'Unlimited', 'Unlimited'),
  createTableRowData('Serial Executions', '50 per month', '500 per month', 'Unlimited'),
  createTableRowData('Parallel Executions', check, check, check),
  createTableRowData('Scandium Cloud Runner', check, check, check),
  createTableRowData('CI/CD Integrations', check, check, check),
  createTableRowData('Self-help (Documentation)', check, check, check),
  createTableRowData('Data Retention', '15 days', '60 days', '365 days'),
  createTableRowData('Api Testing', check, check, check),
  createTableRowData('Email Testing', check, check, check),
  createTableRowData('Live Support', close, check, 'Dedicated CSM'),
  createTableRowData('Mobile App  Test Runs', close, '200mins per month', 'Unlimited'),
  createTableRowData('Turbo mode for faster executions', close, close, check),
  createTableRowData('Activity Audit Logs', close, close, check),
  createTableRowData('Web Accessibility Testing', close, close, check)
];

const SubscriptionFeaturesModal = ({ isSubFeaturesModalOpen, closeSubFeaturesModal }) => {
  const theme = useTheme();
  return (
    <CustomModal open={isSubFeaturesModalOpen} onClose={closeSubFeaturesModal}>
      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <StarSvg fill={theme.palette.background.white_black} />
                Features
              </StyledTableCell>
              <StyledTableCell align="right">Free Forever</StyledTableCell>
              <StyledTableCell align="right">Premium</StyledTableCell>
              <StyledTableCell align="right">Enterprise</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.features}>
                <StyledTableCell style={{ fontWeight: 700 }} component="th" scope="row">
                  {row.features}
                </StyledTableCell>
                <StyledTableCell align="right">{row.freeForever}</StyledTableCell>
                <StyledTableCell align="right">{row.premium}</StyledTableCell>
                <StyledTableCell align="right">{row.enterprise}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Flex>
        <OutlinedButton
          onClick={closeSubFeaturesModal}
          sx={{
            ml: 'auto',
            mt: 2,
            px: 3,
            width: 'fit-content',
            borderRadius: '4px'
          }}
          marginTop={6}>
          Upgrade
        </OutlinedButton>
      </Flex>
    </CustomModal>
  );
};

export default SubscriptionFeaturesModal;
