import { useContext, useEffect, useState } from 'react';
import { useGetCurrentTest } from '..';
import {
  assertionSelectValidEntries,
  convertQueryParamsArrayToSearchParams,
  fileParseToBase64String,
  getJSONResponseFields,
  getJSONResponseKeys,
  isValidUrl,
  jsonToObject,
  parseStringForGlobalAndDynamicValues,
  replaceDynamicBooleanValueInJSONBody,
  swapPathVariablesInURL
} from '../../../pages/ApiSuites/utils';
import { useGlobalVariables } from '../../../store/globalVariables';
import {
  functionToExtractResponseBody,
  functionToExtractResponseHeaders,
  runAssertions
} from '../../../pages/ApiSuites/localTestRunUtils';
import { useActiveProject } from '../../../store/projectState';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useMutation, useQuery } from 'react-query';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { toast } from 'react-toastify';
import { useUpdateGlobalVariables } from '.';
import { checkResponseHeadersForCookies, cookiesToArray } from './useRunApiTest';
import { useSetUIProps } from '../uiPropsHooks';
import { isNumber } from 'lodash';
import { useSetTestRunResponse } from '../updateTestPropsHooks';

export const configureTestDataForLocalOrAgentRun = async (
  testPropsForRequest,
  globalVariables,
  useCase
) => {
  const { url, params, pathVariables, requestBodyMode, rawBodyLanguage, requestMethod } =
    testPropsForRequest;
  let request_data = {};
  if (!url) {
    return {};
  }
  let _url = swapPathVariablesInURL(url, pathVariables);
  _url = parseStringForGlobalAndDynamicValues(_url, globalVariables);
  const queryParamsForFetch = configureQueryParamsForTestRun(params, globalVariables);
  const searchParams = convertQueryParamsArrayToSearchParams(queryParamsForFetch);
  let urlForFetch = new URL(_url);
  urlForFetch.search = searchParams;
  let { requestBody, requestBodyForConsole } = await configureBodyForLocalOrAgentRun(
    testPropsForRequest,
    globalVariables,
    useCase
  );
  let { headersForFetch } = ConfigureHeadersForLocalRun(testPropsForRequest, globalVariables);
  urlForFetch = urlForFetch.toString();
  request_data.url = urlForFetch;
  request_data.mode = requestBodyMode;
  request_data.headers = headersForFetch;
  request_data.body = requestBodyForConsole;
  request_data.request_method = requestMethod.toUpperCase();
  request_data.language = rawBodyLanguage;

  return { urlForFetch, requestBody, headersForFetch, request_data };
};
const configureBodyForLocalOrAgentRun = async (
  testPropsForRequest,
  globalVariables,
  useCase = 'browser-agent'
) => {
  const {
    requestBodyMode,
    formData,
    formUrlEncoded,
    rawRequestBody,
    rawBodyLanguage,
    graphQLVariables
  } = testPropsForRequest;
  let requestBodyForConsole;
  let requestBody = null;
  if (requestBodyMode === 'formData') {
    requestBodyForConsole = [];
    if (useCase === 'desktop-agent') {
      requestBody = formData.filter((item) => {
        return !!item.key && !!item.value;
      });
      requestBody = parseStringForGlobalAndDynamicValues(
        JSON.stringify(requestBody),
        globalVariables
      );
      requestBody = JSON.parse(requestBody);
      requestBodyForConsole = requestBody;
    } else {
      const formDataBody = new FormData();
      let pop = await Promise.all(
        formData.map(async (item) => {
          if (!!item.key && !!item.value) {
            if (item.type === 'text') {
              let parsedKey = parseStringForGlobalAndDynamicValues(item.key, globalVariables);
              let parsedValue = parseStringForGlobalAndDynamicValues(item.value, globalVariables);
              formDataBody.append(parsedKey, parsedValue);
              requestBodyForConsole.push({ key: parsedKey, value: parsedValue });
              requestBody = formDataBody;
              return;
            }
            if (item.type === 'file') {
              let parsedKey = parseStringForGlobalAndDynamicValues(item.key, globalVariables);
              if (item.value.startsWith('data:')) {
                formDataBody.append(parsedKey, item.rawFile, item?.filename);
                requestBodyForConsole.push({ key: parsedKey, value: item.filename });
                requestBody = formDataBody;
              } else if (item.value.startsWith('https:')) {
                let fileUrl = item.value.replace(/\n/g, '');
                let result = await fetch(fileUrl);
                result = await result.blob();
                requestBodyForConsole.push({ key: parsedKey, value: item.value });
                formDataBody.append(parsedKey, result);
                requestBody = formDataBody;
              }
            }
          }
          return item;
        })
      );
    }
  } else if (requestBodyMode === 'urlencoded') {
    let urlEncodedBody = [];
    formUrlEncoded.map((item) => {
      if (!!item.key && !!item.value) {
        let parsedKey = parseStringForGlobalAndDynamicValues(item.key, globalVariables);
        let parsedValue = parseStringForGlobalAndDynamicValues(item.value, globalVariables);
        urlEncodedBody.push({ key: parsedKey, value: parsedValue });
      }
    });
    urlEncodedBody = urlEncodedBody
      .map((item) => {
        const encodedKey = encodeURIComponent(item.key);
        const encodedValue = encodeURIComponent(item.value);
        return `${encodedKey}=${encodedValue}`;
      })
      .join('&');
    requestBodyForConsole = urlEncodedBody;
    requestBody = urlEncodedBody;
  } else if (requestBodyMode === 'raw' && rawBodyLanguage === 'graphql' && !!rawRequestBody) {
    let parsedRawContent = parseStringForGlobalAndDynamicValues(rawRequestBody, globalVariables);
    requestBody = JSON.stringify({
      query: parsedRawContent,
      variables: jsonToObject(graphQLVariables)
    });
    requestBodyForConsole = requestBody;
  } else if (requestBodyMode === 'none') {
    requestBody = null;
    requestBodyForConsole = requestBody;
  } else {
    let parsedRawContent = rawRequestBody;
    // json,jsvavscript,html e.t.c
    if (rawBodyLanguage === 'json') {
      parsedRawContent = rawRequestBody.replace(/\/\/.*$/gm, '').replace(/\/\*[\s\S]*?\*\//g, '');
      parsedRawContent = replaceDynamicBooleanValueInJSONBody(parsedRawContent);
    }
    parsedRawContent = parseStringForGlobalAndDynamicValues(parsedRawContent, globalVariables);
    requestBody = parsedRawContent;
    requestBodyForConsole = requestBody;
  }
  return { requestBody, requestBodyForConsole };
};
const configureQueryParamsForTestRun = (paramsArray, globalVariables) => {
  let filterEmptyParams = paramsArray.filter((entry) => {
    return entry.src === 'authorization' || (!!entry.value && !!entry.key);
  });
  filterEmptyParams = JSON.parse(
    parseStringForGlobalAndDynamicValues(JSON.stringify(filterEmptyParams), globalVariables)
  );
  return filterEmptyParams;
};
const ConfigureHeadersForLocalRun = (testPropsForRequest, globalVariables) => {
  const { headers, requestBodyMode, rawBodyLanguage } = testPropsForRequest;
  let _headers = [...headers]?.filter((header) => header.key && header.value);
  //swap headers values for dynamic vlaues, then global variables if present
  _headers = parseStringForGlobalAndDynamicValues(JSON.stringify(_headers), globalVariables);
  _headers = JSON.parse(_headers);
  let headersForFetch = {};
  // const requestDataHeaders = _headers;
  _headers = _headers.forEach((entry) => {
    headersForFetch[entry.key] = entry.value;
  });
  //set content-Type if present
  const ContentTypeIsPresent = Object.keys(headersForFetch).find((key) => {
    return key.toLowerCase() === 'content-type';
  });
  if (!ContentTypeIsPresent) {
    if (requestBodyMode === 'formData') {
      headersForFetch = {};
    } else if (requestBodyMode === 'urlencoded') {
      headersForFetch['Content-Type'] = 'application/x-www-form-urlencoded';
    } else if (requestBodyMode === 'raw') {
      if (rawBodyLanguage === 'json' || rawBodyLanguage === 'graphql') {
        headersForFetch['Content-Type'] = 'application/json';
      } else if (rawBodyLanguage === 'xml' || rawBodyLanguage === 'javascript') {
        headersForFetch['Content-Type'] = `application/${rawBodyLanguage}`;
      } else if (rawBodyLanguage === 'plaintext') {
        headersForFetch['Content-Type'] = `text/plain`;
      }
    } else {
      headersForFetch['Content-Type'] = `text/plain`;
    }
  }
  return { headersForFetch };
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const useConfigLocalRunQueryData = () => {
  const { testPropsForRequest } = useGetCurrentTest();
  const {
    testAgent: { triggerQueryRunInBrowser, runAgent },
    enableConfigDataForLocalOrAgentRunRef
  } = useContext(ApiTestContext);

  const { requestMethod, assertionsSelect } = testPropsForRequest;
  const { globalVariables } = useGlobalVariables();
  const { updateGlobalVariables } = useUpdateGlobalVariables();
  const handleUpdateVariablesIfAsserted = (newEntry) => {
    updateGlobalVariables({
      variables: [...globalVariables, newEntry]
    });
  };
  const handleConfigLocalRunQueryData = async () => {
    // don't configure data unless browser or desktop agent is selected
    if (runAgent !== 'Browser-Agent') {
      return;
    }
    if (requestMethod === 'GET' && !triggerQueryRunInBrowser) {
      return;
    }
    // if (requestMethod !== 'GET' && !enableConfigDataForLocalOrAgentRunRef.current) {
    //   return;
    // }
    // console.log('tried to config for browser');
    const { urlForFetch, requestBody, headersForFetch, request_data } =
      await configureTestDataForLocalOrAgentRun(testPropsForRequest, globalVariables);
    if (headersForFetch['Content-Type'] === 'multipart/form-data') {
      delete headersForFetch['Content-Type'];
    }
    const testAssertions = assertionSelectValidEntries(assertionsSelect);
    const queryData = {
      urlForFetch,
      requestMethod,
      requestBody,
      testAssertions,
      headersForFetch,
      request_data,
      handleUpdateVariablesIfAsserted
    };
    return queryData;
  };
  return { handleConfigLocalRunQueryData };
};
export const useHandleGETQueryInBrowser = () => {
  const {
    testAgent: { triggerQueryRunInBrowser },
    setTestAgent
  } = useContext(ApiTestContext);
  const handleCancelGetQuery = () => {
    setTestAgent((prev) => {
      return { ...prev, triggerQueryRunInBrowser: false };
    });
  };
  const { handleConfigLocalRunQueryData } = useConfigLocalRunQueryData();
  const queryData = handleConfigLocalRunQueryData();
  const { localRunQueryFn } = collectConfiguredDataForLocalQueryFn(queryData);
  const { handleUpdateUIOnRunSuccess } = useUpdateUIOnSuccess();
  const { handleUpdateUIOnRunError } = useUpdateUIonError();
  return useQuery({
    enabled: triggerQueryRunInBrowser,
    queryKey: ['run-query-locally'],
    queryFn: localRunQueryFn,
    onSuccess: (data) => {
      // enableConfigDataForLocalOrAgentRunRef.current = false;
      handleCancelGetQuery();
      handleUpdateUIOnRunSuccess(data);
    },
    onError: (error) => {
      // console.log(error);
      handleCancelGetQuery();
      handleUpdateUIOnRunError(error);
    }
  });
};
export const useHandleMutationQueryInBrowser = (queryData) => {
  const { localRunQueryFn } = collectConfiguredDataForLocalQueryFn(queryData);
  const { handleUpdateUIOnRunSuccess } = useUpdateUIOnSuccess();
  const { handleUpdateUIOnRunError } = useUpdateUIonError();
  return useMutation({
    queryKey: ['run-mutation-locally'],
    enabled: false,
    mutationFn: async () => {
      await queryData;
      return localRunQueryFn();
    },
    onSuccess: (data) => {
      // console.log(data);
      handleUpdateUIOnRunSuccess(data);
    },
    onError: (error) => {
      // console.log(error);
      handleUpdateUIOnRunError(error);
    }
  });
};

export const useHandleLocalRun = () => {
  const {
    testPropsForRequest: { requestMethod, url }
  } = useGetCurrentTest();
  const { setTestAgent, enableConfigDataForLocalOrAgentRunRef } = useContext(ApiTestContext);
  const handleTriggerBrowserQueryRun = () => {
    setTestAgent((prev) => {
      return { ...prev, triggerQueryRunInBrowser: true };
    });
  };
  const { handleConfigLocalRunQueryData } = useConfigLocalRunQueryData();
  const queryData = handleConfigLocalRunQueryData();
  //<------------------------------------------------------------------->
  const { isFetching: isRunningApiTestQueryInBrowser } = useHandleGETQueryInBrowser();
  //<------------------------------------------------------------------->
  const { mutateAsync: runTestMutationInBrowser, isLoading: isRunningApiTestMutationInBrowser } =
    useHandleMutationQueryInBrowser(queryData);
  //<------------------------------------------------------------------->
  const isRunningApiTestLocally =
    isRunningApiTestQueryInBrowser || isRunningApiTestMutationInBrowser;
  const handleRunApiTestInBrowser = async () => {
    if (!url) {
      toast.error('Please enter a valid url');
      return;
    }
    if (requestMethod === 'GET') {
      handleTriggerBrowserQueryRun();
    } else {
      await queryData;
      // enableConfigDataForLocalOrAgentRunRef.current = true;
      runTestMutationInBrowser();
    }
  };
  return { handleRunApiTestInBrowser, isRunningApiTestLocally };
};

const collectConfiguredDataForLocalQueryFn = (data) => {
  const localRunQueryFn = async () => {
    let _data;
    let dataForError = {
      hasResponse: true,
      runResultDataForSaving: {
        assertions: {},
        headers: [],
        size: 0,
        status_code: 0,
        time_taken: 0
      },
      responseBody: '',
      isResponseBodyAFile: false,
      responseStatusCode: 0,
      responseSize: 0,
      responseHeaders: [],
      responseAssertions: {},
      responseCookies: [],
      responseTimeTaken: 0,
      contentTypeValue: ''
    };
    try {
      _data = await data;
    } catch (error) {
      // error while calculating request data
      const errorObject = new Error(error?.message);
      dataForError.runResultDataForSaving = {
        ...dataForError.runResultDataForSaving,
        body: error?.message || 'Failed to run test'
      };
      error.type = 'preFetch error';
      errorObject.data = { ...dataForError, responseBody: JSON.stringify(error, null, 2) };
      throw error;
    }
    const {
      urlForFetch,
      requestMethod,
      headersForFetch,
      requestBody,
      testAssertions,
      handleUpdateVariablesIfAsserted,
      request_data
    } = _data;
    // requestDataCopyForError = request_data;
    try {
      const startTime = performance.now();
      const response = await fetch(urlForFetch, {
        method: requestMethod.toUpperCase() || 'GET',
        headers: { ...headersForFetch },
        body: requestMethod.toUpperCase() === 'GET' ? null : requestBody
      });
      const endTime = performance.now();
      const time_taken = endTime - startTime;
      if (response) {
        const { body, isBlob, contentType } = await functionToExtractResponseBody(response);
        const headers = functionToExtractResponseHeaders(response.headers);
        const size = response.headers.get('content-length') || JSON.stringify(body).length;
        const allAssertionResult = runAssertions(
          testAssertions,
          response.status,
          body,
          headers,
          contentType,
          time_taken,
          handleUpdateVariablesIfAsserted
        );
        const data = {
          status_code: response.status,
          body,
          headers,
          size,
          time_taken,
          isBlob,
          contentType,
          assertions: allAssertionResult,
          request_data
        };

        return {
          status: response.status,
          message: 'Ran API Test Successfully',
          data
        };
      }
    } catch (error) {
      let runData = { ...request_data, isError: true };
      error.requestData = runData;
      error.type = 'postFetch error';
      dataForError.runResultDataForSaving = {
        ...dataForError.runResultDataForSaving,
        body: error?.message || 'Failed to fetch, please try again'
      };
      error.data = {
        ...dataForError,
        responseBody: error?.message || 'Failed to fetch, please try again'
      };
      throw error;
    }
  };
  return { localRunQueryFn };
};

const useUpdateUIOnSuccess = () => {
  const { handleResponseEditorProps } = useSetUIProps();
  const { handleSetTestRunResponse, handleScrollToResponse } = useSetTestRunResponse();
  const { setApiGlobalProps } = useContext(ApiTestContext);
  const handleUpdateUIOnRunSuccess = async (data) => {
    const runResult = data.data;
    const runResultDataForSaving = JSON.parse(JSON.stringify(runResult));
    let cookieHeader = checkResponseHeadersForCookies(runResult.headers);
    const contentTypeValue = runResult.contentType;
    let bodyLanguage = contentTypeValue?.split(';')[0].split('/')[1] || 'plaintext';
    bodyLanguage === 'plain' && (bodyLanguage = 'plaintext');
    let responseBody = '';
    let isResponseBodyAFile = false;
    let responseStatusCode;
    let responseSize = 0;
    let responseHeaders = [];
    let responseAssertions = {};
    let responseCookies = [];
    let requestData = {};
    let responseTimeTaken = '';
    if (!runResult.body) {
      responseBody = 'No response body from server';
    }
    requestData = runResult?.request_data;
    requestData.log_error = runResult?.log_error;
    if (isNumber(runResult?.status_code)) {
      if (runResult.status_code === 0) {
        requestData.isError = true;
      }
    }
    if (!!cookieHeader) {
      cookieHeader = cookiesToArray(cookieHeader);
      responseCookies = cookieHeader;
    }
    if (contentTypeValue.includes('json')) {
      responseBody = JSON.stringify(runResult?.body, null, 2);
      const dataForDynamicJsonFieldHighlight = runResult?.body;
      handleResponseEditorProps({
        jsonResponseFields: {
          keys: getJSONResponseKeys(dataForDynamicJsonFieldHighlight),
          fields: getJSONResponseFields(dataForDynamicJsonFieldHighlight)
        }
      });
    } else {
      responseBody = runResult?.body;
    }
    if (!!runResult.isBlob) {
      isResponseBodyAFile = true;
      let base64String = await fileParseToBase64String(responseBody);
      base64String = base64String.split(',')[1];
      responseBody = base64String;
    }
    if (!!runResult?.headers) {
      responseHeaders = runResult?.headers;
    }
    if (!!runResult?.size) {
      const _size = +runResult?.size;
      responseSize = _size;
    }
    responseStatusCode = runResult?.status_code;
    if (!!runResult?.assertions) {
      responseAssertions = runResult?.assertions;
    }
    responseTimeTaken = runResult?.time_taken;
    let responseData = {
      hasResponse: true,
      runResultDataForSaving,
      responseBody,
      isResponseBodyAFile,
      responseStatusCode,
      responseSize,
      responseHeaders,
      responseAssertions,
      responseCookies,
      responseTimeTaken,
      contentTypeValue
    };
    handleScrollToResponse();
    handleResponseEditorProps({
      responseEditorLanguage: bodyLanguage
    });
    handleSetTestRunResponse(responseData);
    setApiGlobalProps((prev) => {
      const prevConsoleData = [...prev.consoleData];
      return { ...prev, consoleData: [...prevConsoleData, requestData] };
    });
    // no need to check for file url for formdata base64 string request
  };
  return { handleUpdateUIOnRunSuccess };
};

const useUpdateUIonError = () => {
  const { handleResponseEditorProps } = useSetUIProps();
  const { handleSetTestRunResponse, handleScrollToResponse } = useSetTestRunResponse();
  const { setApiGlobalProps } = useContext(ApiTestContext);
  const handleUpdateUIOnRunError = (error) => {
    let errorObject = JSON.parse(JSON.stringify(error));
    handleScrollToResponse();
    handleSetTestRunResponse(errorObject.data);
    handleResponseEditorProps({
      responseEditorLanguage: 'plaintext'
    });
    setApiGlobalProps((prev) => {
      const prevConsoleData = [...prev.consoleData];
      return { ...prev, consoleData: [...prevConsoleData, errorObject.requestData] };
    });
  };
  return { handleUpdateUIOnRunError };
};
