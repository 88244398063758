import Flex from '../../../../components/base/Flex';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from '@mui/material/node';
import { useTheme } from '@emotion/react';
import { useParams } from 'react-router-dom';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { useGetCurrentTest, useSetActiveTool } from '../../../../hooks/apiHooks';

const ToolBar = () => {
  const theme = useTheme();
  const { testId } = useParams();
  const { handleSetActiveTool } = useSetActiveTool();
  return (
    <Flex
      minWidth={30}
      minHeight={'400px'}
      sx={{
        display: testId ? 'flex' : 'none',
        borderRadius: 1,
        py: 1,
        rowGap: 2,
        flexDirection: 'column'
        // boxShadow: `-2px 0px 5px ${theme.palette.background.shadow}`
      }}>
      <ToolBarIcon
        toolTipText={'View test details'}
        icon={'info'}
        onClick={() => {
          handleSetActiveTool(0);
        }}
      />
      <ToolBarIcon
        toolTipText={'Documentation'}
        icon={'document'}
        onClick={() => {
          // console.log(testDocumentData);
          handleSetActiveTool(1);
        }}
      />
    </Flex>
  );
};
const ToolBarIcon = ({ icon, toolTipText, onClick }) => {
  const { testDetails } = useGetCurrentTest();
  return (
    <>
      {icon === 'info' && (
        <Tooltip title={toolTipText} arrow>
          <IconButton>
            <InfoIcon
              color={testDetails?.id ? 'primary' : 'disabled'}
              onClick={onClick}
              sx={{
                pointerEvents: !testDetails?.id ? 'none' : 'auto',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            />
          </IconButton>
        </Tooltip>
      )}
      {icon === 'document' && (
        <Tooltip title={toolTipText} arrow>
          {icon === 'document' && (
            <IconButton>
              <ArticleOutlinedIcon
                color={testDetails?.id ? 'primary' : 'disabled'}
                onClick={onClick}
                sx={{
                  pointerEvents: !testDetails?.id ? 'none' : 'auto',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
              />
            </IconButton>
          )}
        </Tooltip>
      )}
    </>
  );
};
export default ToolBar;
