/**
 * The routes defined in this file are primarily used for the sidebar and the App.js file. In the future, it is fine for the sidebar to be manually defined for each page that needs it.
 *
 * It is not advisable to use these routes directly as a link reference in components
 *
 */
export const AIAgentRoutes = {
  homepage: {
    name: 'ScandiumAIAgent',
    path: '/scandium-ai-agent'
  }
};
export const ProjectRoutes = {
  dashboard: {
    name: 'Dashboard',
    path: '/dashboard',
    search: ''
  },
  joinProject: {
    name: 'Join Project',
    path: '/join-project/:inviteCode'
  },
  mailBox: {
    name: 'Mailbox',
    path: '/projects/:projectId/mailbox'
  },
  folders: {
    name: 'Test cases',
    path: '/folders',
    search: '?page=1',
    children: {
      index: {
        name: 'Project Folders',
        path: '.',
        search: '?page=1'
      },
      newFolder: {
        name: 'New Folder',
        path: 'new-folder'
      },
      newTest: {
        name: 'New Test',
        path: 'tests/new'
      }
    }
  },
  folder: {
    name: 'Folder',
    path: '/folders/:folderId',
    search: '?page=1',
    children: {
      index: {
        name: 'Folder Contents',
        path: '.',
        search: '?page=1'
      },
      newFolder: {
        name: 'New Folder',
        path: 'new-folder'
      },
      newTest: {
        name: 'New Test',
        path: 'tests/new'
      }
    }
  },
  runs: {
    name: 'Results',
    path: '/test-results',
    search: '?page=1'
  },
  groups: {
    name: 'Groups',
    path: '/test-groups',
    search: ''
  },
  reports: {
    name: 'Reports',
    path: '/reports',
    search: '?date_from=2018-20-12&date_to=2023-12-06'
  },
  suites: {
    name: 'Suites',
    path: '/test-suites',
    search: '?page=1',
    children: {
      index: {
        name: 'Test Suites',
        path: '.',
        search: '?page=1'
      },
      newSuite: {
        name: 'New Suite',
        path: 'new'
      }
    }
  },
  suitesRuns: {
    name: 'Suites Results',
    path: '/suites-results',
    search: '?page=1'
  },
  apiSuites: {
    name: 'API',
    path: '/api-suites',
    children: {
      index: {
        name: 'Test Cases',
        path: '.'
      },
      newSuite: {
        name: 'API',
        path: '/api-suites/new'
      }
    }
  },
  mobileTesting: {
    name: 'Apps',
    path: '/mobile-testing',
    search: '?page=1',
    children: {
      index: {
        name: 'Mobile',
        path: '.',
        search: '?page=1'
      },
      newTest: {
        name: 'New App',
        path: 'new'
      }
    }
  },
  mobileTestRuns: {
    name: 'Runs & Results',
    path: '/mobile-test-runs',
    search: '?page=1'
  },
  accountUsage: {
    name: 'Account Usage',
    path: ':projectId/accountUsage'
  }
};

const AuthenticationRoutes = {
  register: {
    name: 'Register',
    path: '/register'
  },
  registerInvitee: {
    name: 'RegisterInvitee',
    path: '/register/:inviteCode'
  },
  login: {
    name: 'Login',
    path: '/'
  },
  verifyEmail: {
    name: 'VerifyEmail',
    path: '/verify-email'
  },
  resetPassword: {
    name: 'Register',
    path: '/reset-password'
  },
  verifyOtp: {
    name: 'VerifyOtp',
    path: '/verify-otp'
  },
  changePassword: {
    name: 'ChangePassword',
    path: '/change-password'
  }
};

export const AppRoutes = {
  ...AuthenticationRoutes,
  ...ProjectRoutes,
  projects: {
    name: 'Projects',
    path: '/projects',
    children: {
      index: {
        name: 'Projects',
        path: '.'
      },
      newProject: {
        name: 'New Project',
        path: 'new-project'
      },
      editProject: {
        name: 'Edit Project',
        path: ':projectId/editProject'
      },
      members: {
        name: 'Members',
        path: 'members'
      },
      notifications: {
        name: 'Notifications',
        path: 'notifications-settings'
      },
      variables: {
        name: 'Global Variables',
        path: 'global-variables'
      },
      defaults: {
        name: 'Default Settings',
        path: 'default-settings'
      }
    }
  },
  settings: {
    name: 'Settings',
    path: '/settings',
    children: {
      personalInformation: {
        name: 'Personal information',
        path: 'profile'
      },
      changePassword: {
        name: 'Change password',
        path: 'change-password'
      }
    }
  },
  organizations: {
    name: 'Organizations',
    path: '/organizations',
    children: {
      index: {
        name: 'Members',
        path: '.'
      },
      domains: {
        name: 'Domains',
        path: 'domains'
      },
      projects: {
        name: 'Projects',
        path: 'projects'
      }
    }
  }
};
