import Flex from '../../../../components/base/Flex';
import React, { useContext, useEffect } from 'react';
import DeleteSvg from '../../../../components/svg/DeleteSvg';
import AddIcon from '@mui/icons-material/Add';
import ContainedButton from '../../../../components/base/ContainedButton';
import OutlinedButton from '../../../../components/base/OutlinedButton';
// import { useScandiumQuery } from '../../../data-layer/utils';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import CustomScrollbar from '../../../TestPage/components/CustomScrollbar';
import { toast } from 'react-toastify';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useQueryClient } from 'react-query';
import { Box, Chip, Divider, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FloatingVariableWindowContext } from '../../../../store/floatingVariableWindowState';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
import CustomModal from '../../../../components/base/CustomModal';
import { GlobalVariablesContext } from '../../../../store/globalVariables';
import { ApiTestContext } from '../../../../store/apiState/apiTestContext';
import { useSetTestAssertions } from '../../../../hooks/apiHooks/updateAssertions';
const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'max-content',
  minHeight: '200px',
  bgcolor: 'background.paper',
  borderRadius: '.5rem',
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
};
const CreateGVAssertionModal = () => {
  const { openCreateGVAssertionModal, closeCreateGVAssertionModal } = useContext(
    ApiDialogsAndModalsContext
  );
  const { saveHighLightedTextState, setSaveHighLightedTextState } = useContext(
    FloatingVariableWindowContext
  );
  const { handleAddNewAssertion } = useSetTestAssertions();
  const handleAddPathToAssertions = () => {
    const assertionObj = {
      variableName: saveHighLightedTextState.fieldEntry.name,
      value: saveHighLightedTextState.fieldEntry.path.startsWith('[')
        ? saveHighLightedTextState.fieldEntry.path
        : `.${saveHighLightedTextState.fieldEntry.path}`
    };
    const newValue = {
      selectedOption: 'create global variable',
      value: { ...assertionObj, cascade: true },
      selectedOperator: 'equal',
      headerKey: '',
      range: [],
      oneOf: []
    };
    handleAddNewAssertion(newValue);
    closeCreateGVAssertionModal();
    toast.success('Added to assertions', {
      autoClose: 800
    });
  };
  return (
    <CustomModal open={openCreateGVAssertionModal} onClose={closeCreateGVAssertionModal}>
      <Box sx={boxStyle}>
        <Stack
          spacing={5}
          sx={{
            width: '500px'
          }}>
          <Typography
            fontSize={13}
            sx={{
              opacity: 0.7,
              textAlign: 'start'
            }}>
            On subsequent successful requests, Scandium will automatically save the value of the
            specified field (if present) in the response, as a global variable.
          </Typography>
          <Flex sx={{ flex: '98%', gap: '2rem' }}>
            <TextField
              autoFocus
              label={'Variable name'}
              variant={'outlined'}
              value={saveHighLightedTextState.fieldEntry.name}
              size={'small'}
              onChange={(e) => {
                setSaveHighLightedTextState((prev) => {
                  return { ...prev, fieldEntry: { ...prev.fieldEntry, name: e.target.value } };
                });
              }}
              sx={{ flex: 0.55, mt: 1 }}
            />
            <TextField
              multiline
              label={'response path'}
              variant={'outlined'}
              value={
                saveHighLightedTextState.fieldEntry.path.startsWith('[')
                  ? `response${saveHighLightedTextState.fieldEntry.path}`
                  : `response.${saveHighLightedTextState.fieldEntry.path}`
              }
              size={'small'}
              InputProps={{
                readOnly: true,
                style: {
                  opacity: 0.7,
                  fontWeight: 'lighter'
                }
              }}
              sx={{ flex: 0.55, mt: 1 }}
            />
          </Flex>
          <Typography
            color={'primary'}
            fontSize={13}
            sx={{
              opacity: 0.7,
              textAlign: 'start'
            }}>
            * This entry can be deleted from the Assertions Tab
          </Typography>
          <Flex sx={{ width: '60%', mb: 4, mt: 10, alignSelf: 'flex-end' }}>
            <ContainedButton
              isTextEmpty={!saveHighLightedTextState.fieldEntry.name}
              onClick={() => {
                handleAddPathToAssertions();
              }}
              loadingProps={{ size: 14 }}
              sx={{ flex: '60%' }}>
              Create Assertion
            </ContainedButton>
            <OutlinedButton
              onClick={() => {
                closeCreateGVAssertionModal();
              }}
              sx={{ flex: '50%' }}>
              Close
            </OutlinedButton>
          </Flex>
        </Stack>
      </Box>
    </CustomModal>
  );
};

export default CreateGVAssertionModal;
