import { useContext } from 'react';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
import {
  useDeleteSingleTest,
  useDeleteTestExample,
  useDuplicateTest
} from '../../../../hooks/apiHooks/queryHooks';
import useMassDeletion from '../../../../hooks/apiHooks/queryHooks/useMassDelete';
import { ApiTestContext } from '../../../../store/apiState/apiTestContext';
import useDeleteFolder from '../../../../hooks/apiHooks/queryHooks/useDeleteFolder';

export const DeleteSelectedApiTestsPopUp = () => {
  const { massDelete, isMassDeleting } = useMassDeletion();
  const { massSelection } = useContext(ApiTestContext);
  const { DeleteSelectedApiTestsDialog } = useContext(ApiDialogsAndModalsContext);
  return (
    <DeleteSelectedApiTestsDialog
      runRequest={() => {
        return massDelete({
          // folders: [...massSelection.selectedFolders],
          test_cases: [...massSelection.selectedApiTests],
          examples: [...massSelection.selectedApiTestExamples]
        });
      }}
      runningRequest={isMassDeleting}
      // requestSuccessful={deleteSuccessful}
      title={`Are you sure you want to proceed`}
      description={'This process is irreversible'}
      confirmLabel={'Delete'}
      confirmColor={'error'}
    />
  );
};
export const DeleteSingleTestPopUp = () => {
  const { isDeletingTest, deleteTest } = useDeleteSingleTest();
  const { DeleteSelectedTestDialog } = useContext(ApiDialogsAndModalsContext);
  return (
    <DeleteSelectedTestDialog
      runningRequest={isDeletingTest}
      runRequest={deleteTest}
      title={'Are you sure you want to delete this test request?'}
      description={'When you delete a test request, the action cannot be undone.'}
      confirmLabel={'Delete'}
      confirmColor={'error'}
    />
  );
};
export const DeleteTestExamplePopUp = () => {
  const { deleteTestExample, isDeletingTestExample } = useDeleteTestExample();
  const { DeleteTestExampleDialog } = useContext(ApiDialogsAndModalsContext);
  return (
    <DeleteTestExampleDialog
      runningRequest={isDeletingTestExample}
      runRequest={deleteTestExample}
      title={'Are you sure you want to delete this request example?'}
      description={'When you delete a request example, the action cannot be undone.'}
      confirmLabel={'Delete'}
      confirmColor={'error'}
    />
  );
};

export const DuplicateTestPopUp = () => {
  const { isDuplicating, duplicateTest } = useDuplicateTest();
  const { DuplicateTestDialog } = useContext(ApiDialogsAndModalsContext);
  return (
    <DuplicateTestDialog
      runningRequest={isDuplicating}
      runRequest={duplicateTest}
      title={'Are you sure you want to duplicate this test request?'}
      description={
        'By duplicating this test request, a new request will be created. The new test request will be identical to the original, and you can edit it as needed.'
      }
      confirmLabel={'Duplicate'}
    />
  );
};

export const FolderDeletePopUp = () => {
  const { FolderDeleteConfirmationDialog } = useContext(ApiDialogsAndModalsContext);
  const { deleteFolder, isDeletingFolder } = useDeleteFolder();
  return (
    <FolderDeleteConfirmationDialog
      runningRequest={isDeletingFolder}
      runRequest={deleteFolder}
      title={'Are you sure you want to delete this folder?'}
      description={'When you delete a folder, the action cannot be undone.'}
      confirmLabel={'Delete'}
      confirmColor={'error'}
    />
  );
};
