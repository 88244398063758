import React, { useContext, useEffect, useState } from 'react';
import { extractPathVariablesKey } from '../../pages/ApiSuites/utils';
import { ApiTestContext } from '../../store/apiState/apiTestContext';
import { useGetCurrentTest } from '.';
import {  useTableEntriesSetter } from './updateTestPropsHooks';

export const useDebounceSearchValue = (delay = 1200) => {
  const {
    foldersState: { searchFoldersValue }
  } = useContext(ApiTestContext);
  const [debounceSearchValue, setDebounceSearchValue] = useState(searchFoldersValue);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounceSearchValue(searchFoldersValue);
    }, delay);
    return () => clearTimeout(timeout);
  }, [searchFoldersValue]);
  return { debounceSearchValue };
};
export const useDebouncePathVariable = (delay = 250) => {

  const {
    testPropsForRequest: { url, pathVariables: prevPathVariables }
  } = useGetCurrentTest();
  const { handlePathVariablesUpdate } = useTableEntriesSetter();
  useEffect(() => {
    const pathVariablesKeys = extractPathVariablesKey(url);
    const timeout = setTimeout(() => {
      if (!!pathVariablesKeys?.length) {
        const newPathVariables = pathVariablesKeys.map((key, index) => {
          return {
            key,
            value: prevPathVariables[index]?.value || '',
            description: prevPathVariables[index]?.description || ''
          };
        });
        handlePathVariablesUpdate(newPathVariables);
      } else {
        handlePathVariablesUpdate([]);
      }
    }, delay);
    return () => clearTimeout(timeout);
  }, [url]);
  // return { debounceSearchValue };
};

export const useDebounceTestUrl = (delay = 500) => {
  const {
    testPropsForRequest: { url }
  } = useGetCurrentTest();
  const [debounceTestUrlValue, setDebounceTestUrlValue] = useState(url);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounceTestUrlValue(url);
    }, delay);
    return () => clearTimeout(timeout);
  }, [url]);
  return { debounceTestUrlValue };
};
