import { Box, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material/node';
import Editor from 'react-simple-code-editor';
import Flex from '../../../components/base/Flex';
import { toast } from 'react-toastify';
import { Fragment, useContext } from 'react';
import CopySvg from '../../../components/svg/CopySvg';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { useTheme } from '@emotion/react';
import { CloseRounded } from '@mui/icons-material';
import { highlight, languages } from 'prismjs';

import 'prismjs/themes/prism.css'; // Optional: choose a theme
import 'prismjs/components/prism-json.min.js';
import 'prismjs/components/prism-graphql';
import { jsonToObject } from '../utils';
import { useGetCurrentTest, useSetActiveTool } from '../../../hooks/apiHooks';

const Documentation = () => {
  const { handleSetActiveTool } = useSetActiveTool();
  const {
    uiProps: {
      testDocumentData,
      toolBar: { activeTool }
    }
  } = useGetCurrentTest();
  const { url, method, reqParams, reqHeaders, mode, authType, reqBody } = testDocumentData;
  const copyURLToClipBoard = () => {
    navigator.clipboard.writeText(url);
    toast.success('copied to clipboard', {
      autoClose: 1000
    });
  };
  const copyReqBodyToClipBoard = () => {
    navigator.clipboard.writeText(JSON.stringify(reqBody));
    toast.success('copied to clipboard', {
      autoClose: 1000
    });
  };
  return (
    <>
      {!!testDocumentData.url && activeTool === 1 && (
        <CustomScrollbar
          sx={{
            pr: 2,
            mr: 0.5,
            // outline: '1px solid black',
            maxHeight: '400px',
            maxWidth: '300px',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}>
          <Stack
            gap={1}
            sx={{
              py: 2,
              pl: 1.5
              // pr: 3
            }}>
            <Flex>
              <Typography fontWeight={'bold'}>Test Documentation</Typography>
              <IconButton
                sx={{
                  ml: 'auto'
                }}
                onClick={() => {
                  handleSetActiveTool(null);
                }}>
                <CloseRounded
                  sx={{
                    fontSize: 18
                  }}
                />
              </IconButton>
            </Flex>
            <SingleEntry name={'URL'} label={method} addCopy={copyURLToClipBoard}>
              <Flex>
                <Typography
                  fontSize={12}
                  // maxWidth={'50%'}
                  sx={{
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    p: 0.5,
                    borderRadius: 1,
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word'
                  }}>
                  {url}
                </Typography>
              </Flex>
              <Divider orientation={'horizontal'} flexItem />
            </SingleEntry>
            {authType !== 'no-auth' && (
              <SingleEntry name={'Authorization'} label={authType}>
                <DisplayForAuth type={authType} />
              </SingleEntry>
            )}
            {!!reqParams?.length && (
              <SingleEntry name={'Request Params'} addKey={true}>
                <Stack gap={1}>
                  <DisplayForArrayEntries data={reqParams} />
                </Stack>
              </SingleEntry>
            )}
            {!!reqHeaders?.length && (
              <SingleEntry name={'Request Headers'} addKey={true}>
                <Stack gap={1}>
                  <DisplayForArrayEntries data={reqHeaders} />
                </Stack>
              </SingleEntry>
            )}
            {!!reqBody && mode !== 'none' && (
              <SingleEntry name={'Request Body'} label={mode} addCopy={copyReqBodyToClipBoard}>
                <Stack gap={1}>
                  <DisplayForBody data={reqBody} mode={mode} />
                </Stack>
              </SingleEntry>
            )}
          </Stack>
        </CustomScrollbar>
      )}
    </>
  );
};

const FlexEntries = ({ value1, value2 }) => {
  return (
    <Stack gap={1}>
      <Flex px={1}>
        <Typography fontSize={12}>{value1}</Typography>
        <Typography fontSize={12} ml={'auto'}>
          {value2}
        </Typography>
      </Flex>
      <Divider orientation={'horizontal'} flexItem />
    </Stack>
  );
};
const DisplayForArrayEntries = ({ data, type }) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <Fragment key={index}>
            <Flex px={0.5}>
              <Typography fontSize={12}> {item?.key}</Typography>
              <Typography
                maxWidth={'40%'}
                sx={{
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word'
                }}
                fontSize={12}
                ml={'auto'}>
                {item?.filename ? item?.filename : item?.value}
              </Typography>
            </Flex>
            <Divider orientation={'horizontal'} flexItem />
          </Fragment>
        );
      })}
    </>
  );
};
const DisplayForBody = ({ data, mode }) => {
  const theme = useTheme();
  return (
    <>
      {(mode === 'formData' || mode === 'urlencoded') && <DisplayForArrayEntries data={data} />}

      {mode !== 'formData' && mode !== 'urlencoded' && (
        <Flex>
          <RawTypesDisplay mode={mode} code={data} />
          <Divider orientation={'horizontal'} flexItem />
        </Flex>
      )}
    </>
  );
};
const DisplayForAuth = ({ type }) => {
  return (
    <>
      {type === 'bearer' && <FlexEntries value1="token" value2="**token**" />}
      {(type === 'basic' || type === 'digestAuth') && (
        <>
          <FlexEntries value1="Username" value2="**username**" />
          <FlexEntries value1="Password" value2="**password**" />
        </>
      )}
      {type === 'apiKey' && (
        <>
          <FlexEntries value1="Key" value2="**key**" />
          <FlexEntries value1="Value" value2="**value**" />
        </>
      )}
      {type === 'oauth1' && (
        <>
          <FlexEntries value1="Consumer Key" />
          <FlexEntries value1="Consumer Secret" value2="**value**" />
          <FlexEntries value1="Token" value2="**token**" />
          <FlexEntries value1="Token Secret" value2="**token-secret**" />
        </>
      )}
    </>
  );
};

const SingleEntry = ({ name, children, addKey, label = '', addCopy }) => {
  const theme = useTheme();
  const titlelabel = {
    bearer: 'Bearer token',
    oauth1: 'Oauth 1.0',
    basic: 'Basic Auth',
    formData: 'Form Data',
    urlEncoded: 'Form UrlEncoded',
    json: 'raw: json',
    html: 'raw: html',
    javascript: 'raw: javascript',
    graphql: 'raw: graphql'
  };
  return (
    <Stack gap={0.5} width={'100%'}>
      <Flex py={1} alignItems={'center'}>
        <Typography color={'primary'} fontWeight={'medium'}>
          {name}
          <Typography
            color={theme.palette.custom.contrastText}
            fontWeight={'medium'}
            ml={1}
            display={'inline'}>
            {titlelabel[label] || label}
          </Typography>
        </Typography>
        {addCopy && (
          <Tooltip title="Copy">
            <IconButton
              sx={{
                ml: 3
              }}
              onClick={() => {
                addCopy();
              }}>
              <CopySvg />
            </IconButton>
          </Tooltip>
        )}
      </Flex>

      {!!addKey && (
        <Flex>
          <Typography fontWeight={600} color={theme.palette.text.black_grey} fontSize={13}>
            Key
          </Typography>
          <Typography
            fontWeight={600}
            color={theme.palette.text.black_grey}
            fontSize={13}
            ml={'auto'}>
            Value
          </Typography>{' '}
        </Flex>
      )}
      {children}
      {/* <Divider /> */}
    </Stack>
  );
};

const RawTypesDisplay = ({ mode, code }) => {
  const theme = useTheme();
  return (
    <>
      <Editor
        value={JSON.stringify(code, null, 2)}
        highlight={(code) => {
          if (mode === 'json') {
            return highlight(jsonToObject(code), languages.json, 'json');
          } else if (mode === 'javascript') {
            return highlight(jsonToObject(code), languages.javascript, 'javascript');
          } else if (mode === 'graphql') {
            let grapgQLCode = JSON.parse(code)?.query;
            return highlight(grapgQLCode, languages.graphql, 'graphql');
          } else if (mode === 'html') {
            return highlight(JSON.parse(code), languages.html, 'html');
          } else if (mode === 'xml') {
            return highlight(JSON.parse(code), languages.html, 'xml');
          } else {
            return JSON.parse(code);
          }
        }}
        style={{
          outline: `1px solid rgba(82,82,87,0.5)`,
          borderRadius: 4,
          padding: '10px',
          minWidth: '250px',
          fontSize: 12
        }}
        readOnly={true}
      />
    </>
  );
};
export default Documentation;
