import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
  typography: {
    fontFamily: ['Exo', 'sans-serif'].join(','),
    b1: {
      fontWeight: 400,
      fontSize: '0.5rem'
    }
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          hover: {
            default: '#f3f3f3',
            blue: '#1958B8'
          },
          background: {
            purple: '#DEEAFD',
            inActive: 'rgba(200, 200, 200, 0.2)',
            white_black: '#fff',
            light_dark: '#fff',
            lightGrey_dark: '#f5f5f5',
            default: '#fff',
            testPagePanel: '#fff',
            toolbar: '#fff',
            lowerToolBar: '#F1F7FF',
            suiteList: '#f9f9f9',
            testCaseList: '#F6FAFF',
            shadow: 'rgba(180,180,180, .6)',
            reportCard: '#EBFDFF',

            activeSchedule: 'rgba(25, 88, 184, 0.13)'
          },
          primary: {
            main: '#1958B8'
          },
          secondary: {
            main: '#24C3D9'
          },
          button: {
            border: '#1958B8',
            textContained: '#fff',
            textOutlined: '#1958B8'
          },
          table: {
            main: '#E3E3E3',
            outline: '#1958B8',
            mobileOutline: '#E3E3E3'
          },
          custom: {
            buttonBorder: '#1958B8',
            light: '#ffa726',
            main: '#f57c00',
            dark: '#ef6c00',
            contrastText: 'rgba(0, 0, 0, 0.4)'
          },
          text: {
            green: '#00CA08',
            dgrey_lgrey: '#ffffffb3',
            black_grey: '#000000',
            black_white: '#000000',
            white_black: '#ffffff',
            resultTableText: '#787878',
            custom: '#1958B8'
          },
          radio: {
            main: '#1958B8'
          },
          outline: {
            modal: '#CDCDCD'
          },
          svg: {
            main: '#24C3D9',
            primary: '#1958B8'
          }
        }
      : {
          hover: {
            default: 'rgb(85,85, 85)',
            blue: 'rgba(25, 88, 184, 0.8)'
          },
          background: {
            purple: '#DEEAFD',
            inActive: 'rgba(200, 200, 200, 0.3)',
            light_dark: 'rgba(140, 140, 140)',
            white_black: 'rgba(35, 35, 35)',
            lightGrey_dark: 'rgba(40,40,40)',
            default: '#0d1117',
            testPagePanel: '#0d1117',
            toolbar: '#000000',
            lowerToolBar: '#000000',
            suiteList: 'rgba(200, 200, 200, 0.2)',
            testCaseList: 'rgba(200, 200, 200, 0.2)',
            shadow: 'rgba(75,75,75, .6)',
            reportCard: 'rgba(40,40,40)',
            activeSchedule: 'rgba(25, 88, 184, 0.7)'
          },
          primary: {
            main: '#35baf6',
            dark: '#303034'
          },
          secondary: {
            main: '#ffffff4d',
            dark: '#ffffff4d'
          },
          button: {
            border: '#ffffff4d',
            textContained: '#ffffffb3',
            textOutlined: '#ffffffb3'
          },
          table: {
            main: 'rgba(82,82,87,0.5)',
            outline: 'rgba(82,82,87,0.5)',
            mobileOutline: 'rgba(82,82,87,0.5)'
          },
          custom: {
            main: '#f57c00',
            dark: '#ef6c00',
            contrastText: 'rgba(225, 225, 225, 0.6)'
          },
          text: {
            green: '#00CA08',
            dgrey_lgrey: '#ffffffb6',
            black_grey: '#ffffffb3',
            black_white: '#fff',
            white_black: '#000000',

            resultTableText: '#ffffffb3',
            custom: '#ffffffb3'
          },
          radio: {
            main: '#ffffffb3'
          },
          outline: {
            modal: '#5e6062'
          },
          svg: {
            main: '#5e6062',
            primary: '#5e6062'
          }
        })
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Exo';
        }
      `
    }
  }
});
export const ColorContext = React.createContext({
  mode: '',
  setMode: () => {}
});

export const AppThemeProvider = ({ children }) => {
  const [mode, setMode] = useState('light');
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorContext.Provider value={{ mode, setMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorContext.Provider>
  );
};
