import { useContext } from 'react';
import { ApiTestContext } from '../../store/apiState/apiTestContext';
import { useParams } from 'react-router-dom';

const useUIPropsSetter = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const handleUIPropsUpdate = (newPropsObj) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.uiProps = { ...test.uiProps, ...newPropsObj };
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleUIPropsUpdate };
};

export const useSetUIProps = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const { handleUIPropsUpdate } = useUIPropsSetter();
  const handleAssertionsFilterTabChange = (index) => {
    handleUIPropsUpdate({ assertionsFilterTab: index });
  };
  const handleSetTestDocumentation = (obj) => {
    handleUIPropsUpdate({ testDocumentData: obj });
  };
  const handleSetTestPropsTab = (tabIndex = 0) => {
    handleUIPropsUpdate({ testPropsTab: tabIndex });
  };
  const handleResponseTabChange = (value) => {
    handleUIPropsUpdate({ testResponseTab: value });
  };
  const handleResponseEditorProps = (newEditorValues) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.uiProps.responseEditorProps = {
            ...test.uiProps.responseEditorProps,
            ...newEditorValues
          };
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return {
    handleSetTestDocumentation,
    handleResponseTabChange,
    handleResponseEditorProps,
    handleSetTestPropsTab,
    handleAssertionsFilterTabChange
  };
};
