import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useActiveProject } from '../../store/projectState';
import { ApiTestContext } from '../../store/apiState/apiTestContext';
import { useContext, useEffect } from 'react';
import { generateRandomNewTest10Digits } from '../../pages/ApiSuites/utils';
import useFetchSingleTest from './queryHooks/useFetchSingleTest';

export const useCheckUrlIsForNewTest = () => {
  const { pathname } = useLocation();
  if (pathname.includes('new')) {
    return true;
  } else {
    return false;
  }
};
export const useTrackTabsAndFetchTest = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const testIsNew = useCheckUrlIsForNewTest();
  const activeProject = useActiveProject();
  const { exampleId, testId, newTestId, folderId } = useParams();
  const { testsTab, setTestsTab, newTestDefault, setEnableTestFetch } = useContext(ApiTestContext);
  const testIsExample = !!testId && !!exampleId;
  const testIsATestCase = !!testId && !exampleId;
  // console.log(folderId);
  useEffect(() => {
    return () => {
      setTestsTab((prev) => {
        return {
          ...prev,
          testsList: [],
          currentTabIndex: 0
        };
      });
    };
  }, []);
  useEffect(() => {
    if (testIsNew && !newTestId) {
      const uuid = generateRandomNewTest10Digits();
      if (!!folderId) {
        // console.log('new test');
        navigate(`/projects/${activeProject?.id}/api-suites/${folderId}/new/${uuid}`);
        return;
      } else {
        navigate(`/projects/${activeProject?.id}/api-suites/new/${uuid}`);
        return;
      }
    } else if (testIsNew && !!newTestId) {
      const prevNewTest = testsTab.testsList.find((test, index) => {
        if (test?.testDetails?.id === newTestId) {
          setTestsTab((prev) => {
            return { ...prev, currentTabIndex: index, currentTabId: newTestId };
          });
        }
        return test.testDetails.id === newTestId;
      });
      if (!prevNewTest) {
        let _newTestDefault = JSON.parse(JSON.stringify(newTestDefault)); // prevents referencing
        _newTestDefault.testDetails.id = newTestId;
        setTestsTab((prev) => {
          return {
            ...prev,
            testsList: [...testsTab.testsList, _newTestDefault],
            currentTabIndex: !prev?.testsList.length ? 0 : prev?.testsList?.length,
            currentTabId: newTestId
          };
        });
      }
    } else if (testIsExample) {
      const fetchedExample = testsTab.testsList.find((test, index) => {
        if (test?.testDetails?.id === exampleId) {
          // console.log('test example exists, i did not fetch it');
          setTestsTab((prev) => {
            return { ...prev, currentTabIndex: index, currentTabId: exampleId };
          });
        }
        return test.testDetails.id === exampleId;
      });
      if (!fetchedExample) {
        setEnableTestFetch(true);
      }
      return;
    } else if (testIsATestCase) {
      const fetchedTest = testsTab.testsList.find((test, index) => {
        if (test?.testDetails?.id === testId) {
          // console.log('testcase exists, i did not fetch it');
          setTestsTab((prev) => {
            return { ...prev, currentTabIndex: index, currentTabId: testId };
          });
        }
        return test.testDetails.id === testId;
      });
      if (!fetchedTest) {
        console.log('fetch triggered');
        setEnableTestFetch(true);
      }
      return;
    }
  }, [testId, exampleId, newTestId, folderId, pathname]);
}; // use this to check tabs once a new test is added and fetch the new test if necessary
export const useUpdateNewTestOnSave = () => {
  const { testsTab, setTestsTab } = useContext(ApiTestContext);
  const { newTestId, folderId } = useParams();
  const navigate = useNavigate();
  const activeProject = useActiveProject();
  const handleUpdateNewTestOnSave = (savedTestId) => {
    setTestsTab((prev) => {
      //chnage type to api-test
      const newTestTabsList = [...testsTab.testsList].map((test) => {
        if (test?.testDetails?.id === savedTestId) {
          test.testType = 'api-test';
        }
        return test;
      });
      return { ...prev, testsList: newTestTabsList };
    });
    //navigate to the test
    navigate(
      `/projects/${activeProject?.id}/api-suites/${
        folderId ? `${folderId}/${savedTestId}/edit` : `${savedTestId}/edit`
      }`
    );
  };
  return { handleUpdateNewTestOnSave };
};

export const useCloseTestTab = () => {
  const activeProject = useActiveProject();
  const navigate = useNavigate();
  const { newTestId, testId, exampleId } = useParams();
  const {
    testsTab: { testsList, currentTabIndex },
    setTestsTab,
    testToCloseRef
  } = useContext(ApiTestContext);
  const handleCloseTestTab = () => {
    let newTabIndex;
    const isCurrentlyOpened = testToCloseRef.current?.index === currentTabIndex; // the tab is  currently opened
    // console.log('closed' testToCloseRef.current?.index);
    const newList = [...testsList].filter((test) => {
      return test.testDetails.id !== testToCloseRef.current.data.testDetails.id;
    });
    if (!isCurrentlyOpened) {
      newList.map((test, index) => {
        if (
          test.testDetails.id === newTestId ||
          test.testDetails.id === testId ||
          test.testDetails.id === exampleId
        ) {
          newTabIndex = index; // tracks the currently active tab and maintains it
        }
      });
    } else if (isCurrentlyOpened) {
      if (testToCloseRef.current?.index === 0) {
        newTabIndex = 0;
      } else {
        newTabIndex = testToCloseRef.current?.index - 1; // shifts the tab backwards if active tab was closed
      }
    }
    let nextActiveTest = newList.find((test, index) => index === newTabIndex);
    setTestsTab((prev) => {
      // console.log('set to', newTabIndex);
      return {
        ...prev,
        testsList: newList,
        currentTabIndex: newTabIndex
      };
    });
    if (!isCurrentlyOpened) {
      return; // no need to  nav
    } else {
      if (nextActiveTest?.testType === 'new-test') {
        navigate(
          `/projects/${activeProject?.id}/api-suites/new/${nextActiveTest?.testDetails?.id}`
        );
      } else if (nextActiveTest?.testType === 'api-test') {
        navigate(
          `/projects/${activeProject?.id}/api-suites/${nextActiveTest?.testDetails?.id}/edit`
        );
      } else if (nextActiveTest?.testType === 'api-test-example') {
        navigate(
          `/projects/${activeProject?.id}/api-suites/${nextActiveTest?.testDetails?.parentId}/example/${nextActiveTest?.testDetails?.id}`
        );
      }
    }
  };
  return { handleCloseTestTab };
};

export const useTabsFunctions = () => {
  const { testId, exampleId, newTestId } = useParams();
  const activeProject = useActiveProject();
  const {
    testsTab: { testsList, currentTabIndex },
    setTestsTab
  } = useContext(ApiTestContext);
  const navigate = useNavigate();
  const handleNavToTest = (test) => {
    if (test?.testType === 'new-test') {
      navigate(`/projects/${activeProject?.id}/api-suites/new/${test?.testDetails?.id}`);
    } else if (test?.testType === 'api-test') {
      navigate(`/projects/${activeProject?.id}/api-suites/${test?.testDetails?.id}/edit`);
    } else if (test?.testType === 'api-test-example') {
      navigate(
        `/projects/${activeProject?.id}/api-suites/${test?.testDetails?.parentId}/example/${test?.testDetails?.id}`
      );
    }
  };
  const handleCloseInactiveTabs = () => {
    setTestsTab((prev) => {
      const newList = testsList.filter((test, index) => {
        return index === currentTabIndex;
      });
      return {
        ...prev,
        testsList: newList,
        currentTabIndex: 0,
        currentTabId: newList[0].testDetails.id
      };
    });
  };
  const handleCloseSavedTabs = () => {
    const newList = testsList.filter((test) => {
      return test.hasNewChanges === true;
    });
    let currentTestIndex = 0;
    let currentTestIsPresent = newList.find((test, index) => {
      if (
        test.testDetails.id === newTestId ||
        test.testDetails.id === testId ||
        test.testDetails.id === exampleId
      ) {
        currentTestIndex = index;
      }
      return (
        test.testDetails.id === newTestId ||
        test.testDetails.id === testId ||
        test.testDetails.id === exampleId
      );
    });
    if (!newList.length) {
      setTestsTab((prev) => {
        return {
          ...prev,
          testsList: newList,
          currentTabIndex: 0,
          currentTabId: ''
        };
      });
      navigate(`/projects/${activeProject?.id}/api-suites/new`);
    } else {
      // if current tab was not filtered
      if (!!currentTestIsPresent) {
        setTestsTab((prev) => {
          return {
            ...prev,
            testsList: newList,
            currentTabIndex: currentTestIndex,
            currentTabId: currentTestIsPresent.testDetails.id
          };
        });
        // no need to nav
      } else {
        setTestsTab((prev) => {
          return {
            ...prev,
            testsList: newList,
            currentTabIndex: 0,
            currentTabId: newList[0].testDetails.id
          };
        });
        handleNavToTest(newList[0]);
      }
    }
  };
  const handleResetTestTabs = () => {
    setTestsTab((prev) => {
      return {
        ...prev,
        testsList: [],
        currentTabIndex: 0
      };
    });
    navigate(`/projects/${activeProject?.id}/api-suites/new`);
  };
  return { handleCloseInactiveTabs, handleCloseSavedTabs, handleNavToTest, handleResetTestTabs };
};
