import Flex from '../../../../components/base/Flex';
import React, { useContext, useEffect } from 'react';
import DeleteSvg from '../../../../components/svg/DeleteSvg';
import AddIcon from '@mui/icons-material/Add';
import ContainedButton from '../../../../components/base/ContainedButton';
import OutlinedButton from '../../../../components/base/OutlinedButton';
// import { useScandiumQuery } from '../../../data-layer/utils';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import CustomScrollbar from '../../../TestPage/components/CustomScrollbar';
import { toast } from 'react-toastify';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useQueryClient } from 'react-query';
import { Box, Chip, Divider, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FloatingVariableWindowContext } from '../../../../store/floatingVariableWindowState';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
import CustomModal from '../../../../components/base/CustomModal';
import { GlobalVariablesContext } from '../../../../store/globalVariables';
import { ApiTestContext } from '../../../../store/apiState/apiTestContext';
import { useUpdateGlobalVariables } from '../../../../hooks/apiHooks/queryHooks';

export const GlobalVariablesModal = () => {
  const { openGVModal, closeGVModal } = useContext(ApiDialogsAndModalsContext);
  const {
    globalVariables: fetchedVariables,
    errorFetchingVariables,
    refetchVariables,
    fetchedGlobalVariables
  } = useContext(GlobalVariablesContext);
  const { globalVariables, setGlobalVariables } = useContext(ApiTestContext);
  const { updateGlobalVariables, isSavingGlobalVariables } = useUpdateGlobalVariables();
  useEffect(() => {
    if (!!fetchedGlobalVariables) {
      setGlobalVariables(fetchedVariables);
    }
  }, [fetchedGlobalVariables, fetchedVariables]);
  //////////////////////////////////////////////////////////////////
  // const { globalVariables } = globalVariableState;
  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    minHeight: '200px',
    bgcolor: 'background.paper',
    borderRadius: '.5rem',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };
  // const { saveHighLightedTextState, setSaveHighLightedTextState } = useContext(
  //   FloatingVariableWindowContext
  // );
  // const closeModal = async () => {
  //   if (useCase !== 'automate_test') {
  //     setGlobalVariableState((prev) => {
  //       return { ...prev, openModal: false };
  //     });
  //     return;
  //   }
  // };
  // const handleAddPathToAssertions = () => {
  //   const obj = {
  //     variableName: saveHighLightedTextState.fieldEntry.name,
  //     value: saveHighLightedTextState.fieldEntry.path.startsWith('[')
  //       ? saveHighLightedTextState.fieldEntry.path
  //       : `.${saveHighLightedTextState.fieldEntry.path}`
  //   };
  //   setDropdowns((prev) => {
  //     const newValue = {
  //       selectedOption: 'create global variable',
  //       value: { ...obj, cascade: true },
  //       selectedOperator: 'equal',
  //       headerKey: '',
  //       range: [],
  //       oneOf: []
  //     };
  //     return [...prev, newValue];
  //   });
  //   toast.success('Added to assertions', {
  //     autoClose: 800
  //   });
  // };

  // get the variables and set variables state once you get them
  // const {
  //   isError: failedToFetch,
  //   refetch,
  //   isSuccess
  // } = useScandiumQuery(`/projects/${activeProject?.id}/variables`, {
  //   queryKey: ['global-variables'],
  //   onSuccess: (data) => {
  //     const variables = data.data;
  //     const variables2 = [...variables]; //this prevents data coupling
  //     setGlobalVariableState((prev) => {
  //       return { ...prev, globalVariables: variables };
  //     });
  //     setVariableServerState(variables2);
  //   },
  //   enabled: !!activeProject?.id
  // });
  // post a new variable list after changes have been made
  // const { mutateAsync: updateGlobalVariables, isLoading: isSavingVariables } = useScandiumMutation(
  //   `/projects/${activeProject?.id}/variables`,
  //   {
  //     enabled: globalVariables.length > 0,
  //     onError: (error) => {},
  //     onSuccess: (data) => {
  //       toast.success(data.message, {
  //         autoClose: 800
  //       });
  //       queryClient.invalidateQueries({ queryKey: ['fetch-global-variables'] });
  //       // queryClient.invalidateQueries({ queryKey: ['global-variables-suggestion-list'] });
  //     }
  //   }
  // );
  const handleAddVariable = () => {
    setGlobalVariables((prev) => {
      const newArray = [...prev];
      newArray.push({ name: '', value: '' });
      return newArray;
    });
  };

  const handleSaveVariables = async () => {
    const checkEmptyEntry = globalVariables.filter((item) => {
      return item.value === '' || item.name === '';
    });
    if (checkEmptyEntry.length > 0 && globalVariables.length > 1) {
      toast.error('Empty fields are not allowed', {
        autoClose: 500
      });
      return;
    } else if (checkEmptyEntry.length === 1 && globalVariables.length === 1) {
      if (globalVariables[0].name === '' && globalVariables[0].value === '') {
        await updateGlobalVariables({ variables: [] });
        return;
      } else {
        toast.error('Empty fields are not allowed', {
          autoClose: 500
        });
        return;
      }
    }
    updateGlobalVariables({ variables: globalVariables });
  };
  // sample fetch array
  return (
    <CustomModal open={openGVModal} onClose={closeGVModal} aria-labelledby={'Global variables'}>
      <Box sx={boxStyle}>
        <Flex justifyContent={'space-between'} alignItems={'flex-end'} mb={6} columnGap={8}>
          <Typography>
            Variables you add here will be available to all steps within the test
          </Typography>
          <Chip
            label={`Add new variable`}
            onClick={handleAddVariable}
            size={'small'}
            color={'primary'}
            icon={<AddIcon />}
            sx={{
              borderRadius: '0.3rem',
              px: 1,
              py: 2,
              mt: 2
            }}
          />
        </Flex>
        <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '300px', mt: 1 }}>
          {errorFetchingVariables && (
            <Box>
              <IconButton
                onClick={() => {
                  refetchVariables();
                }}>
                <RefreshOutlinedIcon />
                <Typography>Click to Refresh</Typography>
              </IconButton>
            </Box>
          )}
          {globalVariables.length > 0 && fetchedGlobalVariables && (
            <Box>
              {globalVariables.map((item, index) => {
                return <VariableComponent variableObject={item} key={item.uuid} index={index} />;
              })}
              <Flex sx={{ width: '40%', mb: 3, mt: 10, ml: 'auto' }}>
                <ContainedButton
                  isLoading={isSavingGlobalVariables}
                  onClick={() => {
                    handleSaveVariables();
                  }}
                  loadingProps={{ size: 14 }}
                  sx={{ flex: '50%' }}>
                  Save
                </ContainedButton>
                <OutlinedButton
                  onClick={() => {
                    closeGVModal();
                    refetchVariables();
                  }}
                  sx={{ flex: '50%' }}>
                  Close
                </OutlinedButton>
              </Flex>
            </Box>
          )}
        </CustomScrollbar>
        {globalVariables.length < 1 && (
          <Typography
            sx={{
              fontSize: 18,
              fontStyle: 'italic',
              justifySelf: 'center',

              color: 'rgba(0, 0, 0, 0.5)'
            }}>
            {' '}
            You have no saved variable{' '}
          </Typography>
        )}

        {/* {useCase === 'save_path' && (
          <Stack
            spacing={5}
            sx={{
              width: '500px'
            }}>
            <Typography
              fontSize={13}
              sx={{
                opacity: 0.7,
                textAlign: 'start'
              }}>
              On subsequent successful requests, Scandium will automatically save the value of the
              specified field (if present) in the response, as a global variable.
            </Typography>
            <Flex sx={{ flex: '98%', gap: '2rem' }}>
              <TextField
                autoFocus
                label={'Variable name'}
                variant={'outlined'}
                value={saveHighLightedTextState.fieldEntry.name}
                size={'small'}
                onChange={(e) => {
                  setSaveHighLightedTextState((prev) => {
                    return { ...prev, fieldEntry: { ...prev.fieldEntry, name: e.target.value } };
                  });
                }}
                sx={{ flex: 0.55, mt: 1 }}
              />
              <TextField
                multiline
                label={'response path'}
                variant={'outlined'}
                value={
                  saveHighLightedTextState.fieldEntry.path.startsWith('[')
                    ? `response${saveHighLightedTextState.fieldEntry.path}`
                    : `response.${saveHighLightedTextState.fieldEntry.path}`
                }
                size={'small'}
                InputProps={{
                  readOnly: true,
                  style: {
                    opacity: 0.7,
                    fontWeight: 'lighter'
                  }
                }}
                sx={{ flex: 0.55, mt: 1 }}
              />
            </Flex>
            <Typography
              color={'primary'}
              fontSize={13}
              sx={{
                opacity: 0.7,
                textAlign: 'start'
              }}>
              * This entry can be deleted from the Assertions Tab
            </Typography>
            <Flex sx={{ width: '60%', mb: 4, mt: 10, alignSelf: 'flex-end' }}>
              <ContainedButton
                isTextEmpty={!saveHighLightedTextState.fieldEntry.name}
                onClick={() => {
                  handleAddPathToAssertions();
                  closeModal();
                }}
                loadingProps={{ size: 14 }}
                sx={{ flex: '60%' }}>
                Create Assertion
              </ContainedButton>
              <OutlinedButton
                onClick={() => {
                  closeModal();
                }}
                sx={{ flex: '50%' }}>
                Close
              </OutlinedButton>
            </Flex>
          </Stack>
        )} */}
      </Box>
    </CustomModal>
  );
};
const VariableComponent = ({ variableObject, index }) => {
  const { globalVariables, setGlobalVariables } = useContext(ApiTestContext);
  const isLastInput = globalVariables.length - 1 === index ? true : false;
  const handleInputChange = (e, field) => {
    setGlobalVariables((prev) => {
      const newArray = [...prev];
      if (field === 'name') {
        newArray[index].name = e.target.value;
      } else {
        newArray[index].value = e.target.value;
      }
      return newArray;
    });
  };

  const handleVariableDelete = () => {
    setGlobalVariables((prev) => {
      if (index === 0 && prev.length === 1) {
        return [{ name: '', value: '' }];
      }
      const newArray = prev.filter((_, position) => {
        return index !== position;
      });
      return newArray;
    });
  };

  return (
    <Flex sx={{ marginBottom: 2, mr: 1.5 }} justifyContent={'space between'}>
      <Flex sx={{ alignItems: 'center', gap: 2, width: '100%', mb: 2 }}>
        <Flex sx={{ flex: '98%', gap: '4rem' }}>
          <TextField
            autoFocus={isLastInput}
            label={'Variable name'}
            variant={'outlined'}
            value={variableObject.name}
            size={'small'}
            onChange={(e) => {
              handleInputChange(e, 'name');
            }}
            sx={{ flex: 0.55, mt: 1 }}
          />
          <TextField
            label={'Variable value'}
            variant={'outlined'}
            value={variableObject.value}
            size={'small'}
            onChange={(e) => {
              handleInputChange(e, 'value');
            }}
            sx={{ flex: 0.5, mt: 1 }}
          />
        </Flex>

        <Tooltip title={'Delete this input fields'}>
          <Box sx={{ cursor: 'pointer', flex: '2%' }} onClick={handleVariableDelete}>
            <DeleteSvg width={16} height={18} />
          </Box>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
