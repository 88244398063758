import { useContext } from 'react';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { useScandiumMutation } from '../../../data-layer/utils';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import useFetchApiFoldersAndTests from './useFetchFoldersAndTests';
import { useQueryClient } from 'react-query';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';
import { useGlobalVariables } from '../../../store/globalVariables';

export const useDeleteSingleTest = () => {
  const {
    foldersState: { clickedTest }
  } = useContext(ApiTestContext);
  const queryClient = useQueryClient();
  const activeProject = useActiveProject();
  const { testId } = useParams();
  const navigate = useNavigate();
  const { mutateAsync: deleteTest, isLoading: isDeletingTest } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest?.id}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        queryClient.invalidateQueries(['get-apiFoldersAndTests']);
        // Check if the deleted testId matches the testId from the URL, if it does, navigate to the api base url
        if (testId === clickedTest.id) {
          // resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  return { deleteTest, isDeletingTest };
};
export const useDeleteTestExample = () => {
  const {
    foldersState: { clickedTestExample, clickedTest }
  } = useContext(ApiTestContext);
  const queryClient = useQueryClient();

  const activeProject = useActiveProject();
  const { exampleId } = useParams();
  const navigate = useNavigate();
  const { mutateAsync: deleteTestExample, isLoading: isDeletingTestExample } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest.id}/examples/${clickedTestExample?.uuid}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        queryClient.invalidateQueries(['get-apiFoldersAndTests']);
        if (exampleId === clickedTestExample?.uuid) {
          // resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  return { deleteTestExample, isDeletingTestExample };
};
export const useDuplicateTest = () => {
  const {
    foldersState: { clickedTest }
  } = useContext(ApiTestContext);
  const activeProject = useActiveProject();
  const queryClient = useQueryClient();
  const { mutateAsync: duplicateTest, isLoading: isDuplicating } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest.id}/duplicate`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        // refetchFoldersAndTests();
        queryClient.invalidateQueries(['get-apiFoldersAndTests']);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  return { duplicateTest, isDuplicating };
};

export const useSaveTestDescription = () => {
  const activeProject = useActiveProject();
  const {
    foldersState: { clickedTest }
  } = useContext(ApiTestContext);
  const { closeTestDescriptionModal } = useContext(ApiDialogsAndModalsContext);
  const queryClient = useQueryClient();
  const { mutateAsync: saveDescription, isLoading: isSavingDescription } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest.id}`,
    {
      method: 'PUT',
      onSuccess: () => {
        // console.log(data);
        closeTestDescriptionModal();
        queryClient.invalidateQueries(['get-apiFoldersAndTests']);
        toast.success('Test description updated', {
          autoClose: 1000
        });
      },
      onError: () => {
        toast.error('Failed to save, please try again', {
          autoClose: 1000
        });
      },
      enabled: !!clickedTest.id
    }
  );
  return { saveDescription, isSavingDescription };
};

export const useUpdateGlobalVariables = () => {
  const activeProject = useActiveProject();
  const { refetchVariables } = useGlobalVariables();
  const { mutateAsync: updateGlobalVariables, isLoading: isSavingGlobalVariables } =
    useScandiumMutation(`/projects/${activeProject?.id}/variables`, {
      onError: (error) => {},
      onSuccess: (data) => {
        toast.success(data.message, {
          autoClose: 800
        });
        refetchVariables();
      }
    });
  return { updateGlobalVariables, isSavingGlobalVariables };
};
