import React from 'react';
import useAwaitModal from '../../hooks/useAwaitModal';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';

export const ApiDialogsAndModalsContext = React.createContext({
  requestTestRunAgentModal: () => {},
  requestCreateGVAssertionModal: () => {},
  requestImportPostmanCollectionModal: () => {},

  requestCreateFolderModal: () => {},
  requestRenameFolderModal: () => {},
  requestRenameTestModal: () => {},
  requestTestDescriptionModal: () => {},
  requestGVModal: () => {},
  requestFolderDeleteConfirm: () => {},
  requestSystemVariablesModal: () => {},
  requestVariablesSuggestionList: () => {},
  requestTestsDeletionDialog: () => {},
  requestSingleTestDeletionDialog: () => {},
  requestTestExampleDeletionDialog: () => {},
  requestSaveTestChangesDialog: () => {},
  requestMoveToFolderModal: () => {},
  openTestRunAgentModal: () => {},
  closeTestRunAgentModal: () => {},
  openGVModal: () => {},
  closeGVModal: () => {},
  openCreateGVAssertionModal: false,
  closeCreateGVAssertionModal: () => {},
  openImportPostmanCollectionModal: false,
  closeImportPostmanCollectionModal: () => {},
  openCreateFolderModal: false,
  closeCreateFolderModal: () => {},
  openRenameFolderModal: false,
  closeRenameFolderModal: () => {},
  openRenameTestModal: false,
  closeRenameTestModal: () => {},
  openMoveToFolderModal: false,
  closeMoveToFolderModal: () => {},
  requestSaveTestExampleModal: () => {},
  openSaveTestExampleModal: false,
  closeSaveTestExampleModal: () => {},
  openTestDescriptionModal: false,
  closeTestDescriptionModal: () => {},
  openSystemVariablesModal: false,
  closeSystemVariablesModal: () => {},
  openVariablesSuggestionList: false,
  closeVariablesSuggestionList: () => {},
  FolderDeleteConfirmationDialog: () => {},
  DeleteSelectedApiTestsDialog: () => {},
  DeleteTestExampleDialog: () => {},
  DeleteSelectedTestDialog: () => {},
  requestTestDuplicateDialog: () => {},
  DuplicateTestDialog: () => {},
  openSaveTestChangesDialog: false,
  closeSaveTestChangesDialog: () => {}
});

export const ApiDialogsAndModalsContextProvider = ({ children }) => {
  const [
    requestCreateGVAssertionModal,
    { open: openCreateGVAssertionModal, onClose: closeCreateGVAssertionModal }
  ] = useAwaitModal();
  const [
    requestImportPostmanCollectionModal,
    { open: openImportPostmanCollectionModal, onClose: closeImportPostmanCollectionModal }
  ] = useAwaitModal();
  const [
    requestSaveTestExampleModal,
    { open: openSaveTestExampleModal, onClose: closeSaveTestExampleModal }
  ] = useAwaitModal();
  const [
    requestTestRunAgentModal,
    { open: openTestRunAgentModal, onClose: closeTestRunAgentModal }
  ] = useAwaitModal();
  const [
    requestSystemVariablesModal,
    { open: openSystemVariablesModal, onClose: closeSystemVariablesModal }
  ] = useAwaitModal();
  const [
    requestVariablesSuggestionList,
    { open: openVariablesSuggestionList, onClose: closeVariablesSuggestionList }
  ] = useAwaitModal();
  const [requestGVModal, { open: openGVModal, onClose: closeGVModal }] = useAwaitModal();
  const [
    requestCreateFolderModal,
    { open: openCreateFolderModal, onClose: closeCreateFolderModal }
  ] = useAwaitModal();
  const {
    requestConfirm: requestFolderDeleteConfirm,
    ConfirmationDialog: FolderDeleteConfirmationDialog
  } = useConfirmDialog();
  const [
    requestRenameFolderModal,
    { open: openRenameFolderModal, onClose: closeRenameFolderModal }
  ] = useAwaitModal();
  const [requestRenameTestModal, { open: openRenameTestModal, onClose: closeRenameTestModal }] =
    useAwaitModal();
  const [
    requestTestDescriptionModal,
    { open: openTestDescriptionModal, onClose: closeTestDescriptionModal }
  ] = useAwaitModal();
  const [
    requestMoveToFolderModal,
    { open: openMoveToFolderModal, onClose: closeMoveToFolderModal }
  ] = useAwaitModal();
  const {
    requestConfirm: requestTestsDeletionDialog,
    ConfirmationDialog: DeleteSelectedApiTestsDialog
  } = useConfirmDialog();
  const {
    requestConfirm: requestSingleTestDeletionDialog,
    ConfirmationDialog: DeleteSelectedTestDialog
  } = useConfirmDialog();
  const {
    requestConfirm: requestTestExampleDeletionDialog,
    ConfirmationDialog: DeleteTestExampleDialog
  } = useConfirmDialog();
  const { requestConfirm: requestTestDuplicateDialog, ConfirmationDialog: DuplicateTestDialog } =
    useConfirmDialog();
  const [
    requestSaveTestChangesDialog,
    { open: openSaveTestChangesDialog, onClose: closeSaveTestChangesDialog }
  ] = useAwaitModal();

  return (
    <ApiDialogsAndModalsContext.Provider
      value={{
        requestTestRunAgentModal,
        openTestRunAgentModal,
        closeTestRunAgentModal,
        requestGVModal,
        openGVModal,
        closeGVModal,
        requestCreateGVAssertionModal,
        openCreateGVAssertionModal,
        closeCreateGVAssertionModal,
        requestTestDescriptionModal,
        openTestDescriptionModal,
        closeTestDescriptionModal,
        requestMoveToFolderModal,
        openMoveToFolderModal,
        closeMoveToFolderModal,
        requestCreateFolderModal,
        openCreateFolderModal,
        closeCreateFolderModal,
        requestRenameFolderModal,
        openRenameFolderModal,
        closeRenameFolderModal,
        requestRenameTestModal,
        openRenameTestModal,
        closeRenameTestModal,
        requestSystemVariablesModal,
        openSystemVariablesModal,
        closeSystemVariablesModal,
        requestImportPostmanCollectionModal,
        openImportPostmanCollectionModal,
        closeImportPostmanCollectionModal,
        requestVariablesSuggestionList,
        openVariablesSuggestionList,
        closeVariablesSuggestionList,
        requestFolderDeleteConfirm,
        FolderDeleteConfirmationDialog,
        requestTestsDeletionDialog,
        DeleteSelectedApiTestsDialog,
        requestSingleTestDeletionDialog,
        DeleteSelectedTestDialog,
        requestTestDuplicateDialog,
        DuplicateTestDialog,
        requestTestExampleDeletionDialog,
        DeleteTestExampleDialog,
        requestSaveTestChangesDialog,
        openSaveTestChangesDialog,
        closeSaveTestChangesDialog,
        requestSaveTestExampleModal,
        openSaveTestExampleModal,
        closeSaveTestExampleModal
      }}>
      {children}
    </ApiDialogsAndModalsContext.Provider>
  );
};
