import { useParams } from 'react-router-dom';
import { ApiTestContext } from '../../store/apiState/apiTestContext';
import { useContext } from 'react';
import { useGetCurrentTest } from '.';
import { v4 as uuidv4 } from 'uuid';
import { fileParser2 } from '../../pages/ApiSuites/components/QueryBody';
import { toast } from 'react-toastify';
import { isValidUrl, updatedUrlWithQueryParams } from '../../pages/ApiSuites/utils';

export const useTableEntriesSetter = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const handleQueryCookiesUpdate = (newCookiesArray) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.cookies = newCookiesArray;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  const handleQueryParamsUpdate = (newParamsArray) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.params = newParamsArray;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  const handlePathVariablesUpdate = (newPathVariables) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.pathVariables = newPathVariables;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  const handleQueryHeadersUpdate = (newHeadersArray) => {
    // console.log(new);
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.headers = newHeadersArray;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  const handleFormQueryBodyUpdate = (newEntriesArray, useCase) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          if (useCase === 'form-data') {
            test.testPropsForRequest.formData = newEntriesArray;
          } else {
            test.testPropsForRequest.formUrlEncoded = newEntriesArray;
          }
        }
        return test;
      });
      //set testPropsForRequest in  setTestTab can be hooked
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return {
    handleQueryParamsUpdate,
    handleQueryCookiesUpdate,
    handleFormQueryBodyUpdate,
    handleQueryHeadersUpdate,
    handlePathVariablesUpdate
  };
};

export const useSetTestUrl = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const handleSetTestUrl = (value) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          // console.log(test.testDetails);
          test.testPropsForRequest.url = value;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleSetTestUrl };
};
export const useSetRequestMethod = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const handleSetRequestMethod = (value) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.requestMethod = value;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleSetRequestMethod };
};
export const useSetTestQueryParams = () => {
  const {
    testPropsForRequest: { params, pathVariables, url: testUrl }
  } = useGetCurrentTest();
  const { handleQueryParamsUpdate, handlePathVariablesUpdate } = useTableEntriesSetter();
  const { handleSetTestUrl } = useSetTestUrl();
  const handleSetQueryParams = (e, index, field) => {
    const currentTestParams = [...params];
    currentTestParams[index][field] = e.target.value;
    // Add a new row if both key and value are entered in the last row
    const lastRow = currentTestParams[currentTestParams.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      currentTestParams.push({ key: '', value: '', description: '' });
    }
    let newUrlWithParams = updatedUrlWithQueryParams(
      testUrl,
      JSON.parse(JSON.stringify(currentTestParams))
    );
    handleQueryParamsUpdate(currentTestParams);
    if (!!newUrlWithParams) {
      handleSetTestUrl(newUrlWithParams);
    }
  };
  const handleQueryParamsDelete = (index) => {
    let currentTestParams = [...params];
    if (currentTestParams.length > 1) {
      currentTestParams.splice(index, 1);
    } else if (currentTestParams.length === 1) {
      currentTestParams[index] = { key: '', value: '', description: '' };
    }
    const lastRow = currentTestParams[currentTestParams.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      currentTestParams.push({ key: '', value: '', description: '' });
    }
    let newUrlWithParams = updatedUrlWithQueryParams(
      testUrl,
      JSON.parse(JSON.stringify(currentTestParams))
    );
    handleQueryParamsUpdate(currentTestParams);
    if (!!newUrlWithParams) {
      handleSetTestUrl(newUrlWithParams);
    }
  };
  const handleSetPathVariable = (e, index, field) => {
    let currentPathVariables = [...pathVariables];
    currentPathVariables[index][field] = e.target.value;
    handlePathVariablesUpdate(currentPathVariables);
  };
  return { handleSetQueryParams, handleQueryParamsDelete, handleSetPathVariable };
};

export const useSetTestQueryHeaders = () => {
  const {
    testPropsForRequest: { headers }
  } = useGetCurrentTest();
  const { handleQueryHeadersUpdate } = useTableEntriesSetter();
  const handleSetQueryHeaders = (e, index, field) => {
    let currentTestHeaders = [...headers];
    currentTestHeaders[index][field] = e.target.value;
    currentTestHeaders = currentTestHeaders.filter((headers, index) => {
      return headers.value || headers.key || headers.description || index === 0;
    });
    // Add a new row if both key and value are entered in the last row
    const lastRow = currentTestHeaders[currentTestHeaders.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      currentTestHeaders.push({ key: '', value: '', description: '' });
    }
    handleQueryHeadersUpdate(currentTestHeaders);
  };
  const handleHeadersDelete = (index) => {
    let currentTestHeaders = [...headers];
    if (currentTestHeaders.length > 1) {
      currentTestHeaders.splice(index, 1);
    } else if (currentTestHeaders.length === 1) {
      currentTestHeaders[index] = { key: '', value: '', description: '' };
    }
    if (currentTestHeaders[0]?.src === 'auto' && currentTestHeaders.length === 1) {
      currentTestHeaders = [...currentTestHeaders, { key: '', value: '', description: '' }];
    }
    handleQueryHeadersUpdate(currentTestHeaders);
  };
  return { handleSetQueryHeaders, handleHeadersDelete };
};

export const useSetQueryCookies = () => {
  const {
    testPropsForRequest: { cookies }
  } = useGetCurrentTest();
  const { handleQueryCookiesUpdate } = useTableEntriesSetter();
  const handleSetQueryCookies = (e, index, field) => {
    let currentTestCookies = [...cookies];
    if (field === 'expires_at' && !!e.target.value) {
      // this changes the expiration to standard format using the seconds value exnterd by the user
      const expireFormat = new Date(Date.now() + e.target.value * 1000).toUTCString();
      currentTestCookies[index][field] = expireFormat;
    } else {
      currentTestCookies[index][field] = e.target.value;
    }
    // Add a new row if both key and value are entered in the last row
    const lastRow = currentTestCookies[currentTestCookies.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      currentTestCookies.push({ key: '', value: '', expires_at: '' });
    }
    handleQueryCookiesUpdate(currentTestCookies);
  };
  const handleCookiesDelete = (index) => {
    let currentTestCookies = [...cookies];
    if (currentTestCookies.length > 1) {
      currentTestCookies.splice(index, 1);
    } else if (currentTestCookies.length === 1) {
      currentTestCookies[index] = { key: '', value: '', expires_at: '' };
    }
    handleQueryCookiesUpdate(currentTestCookies);
  };
  return { handleSetQueryCookies, handleCookiesDelete };
};

export const useSetQueryBody = () => {
  const { testId, newTestId, exampleId } = useParams();
  const {
    testPropsForRequest: { formData, formUrlEncoded, headers, rawBodyLanguage }
  } = useGetCurrentTest();
  const { setTestsTab } = useContext(ApiTestContext);
  const { handleQueryHeadersUpdate, handleFormQueryBodyUpdate } = useTableEntriesSetter();
  const handleSetRawLanguage = (value) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.rawBodyLanguage = value;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  const handleSetRequestBodyMode = (value) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.requestBodyMode = value;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  const handleAddContentTypeHeader = (value) => {
    let currentTestHeaders = [...headers];
    if (value === 'raw') {
      value = rawBodyLanguage;
    }
    let headerValues = {
      urlencoded: 'application/x-www-form-urlencoded',
      formData: 'multipart/form-data',
      json: 'application/json',
      plaintext: 'text/plain',
      javascript: 'application/javascript',
      html: 'application/html',
      xml: 'application/xml',
      graphql: 'application/json'
    };
    let newHeaders = [];
    const contentType = currentTestHeaders.find(
      (header) => header.key.toLowerCase() === 'content-type'
    );
    if (value === 'none') {
      newHeaders = currentTestHeaders.filter(
        (header) => header.key.toLowerCase() !== 'content-type'
      );
      handleQueryHeadersUpdate(newHeaders);
    } else {
      if (!!contentType) {
        // update the existing content-type header
        newHeaders = currentTestHeaders.map((header) => {
          if (header.key.toLowerCase() === 'content-type') {
            header.value = headerValues[value];
            header.src = 'auto';
          }
          return header;
        });
      } else {
        // create a new content-type header
        newHeaders = [
          {
            key: 'Content-Type',
            value: headerValues[value],
            src: 'auto',
            description: ''
          },
          ...currentTestHeaders
        ];
      }
      handleQueryHeadersUpdate(newHeaders);
    }
  };
  const handleSetFormData = async (e, index, field) => {
    const updatedFormData = [...formData];
    // change the value to '' incase it was previously holding data due to selection of a file value
    if (field === 'type') {
      updatedFormData[index].value = '';
    }
    if (e.target.type === 'file') {
      const selectedFile = e.target.files[0];
      let uuid = uuidv4();

      updatedFormData.forEach((entry) => {
        if (entry?.id && entry.id === uuid) {
          uuid = uuidv4();
        }
      });
      if (selectedFile.size > 25000000) {
        toast.warning('file size should not exceed 25mb');
        return;
      }
      updatedFormData[index]['filename'] = selectedFile.name;
      const data = await fileParser2(e.target.files[0]);
      updatedFormData[index]['filename'] = selectedFile.name;
      const base64String = await data;
      updatedFormData[index][field] = base64String;
      updatedFormData[index]['rawFile'] = e.target.files[0];
      // updatedForm[index]['file_url'] = '';
      updatedFormData[index].id = uuid;
    } else if (field === 'clearValues') {
      updatedFormData[index]['filename'] = '';
      updatedFormData[index]['value'] = '';
      // updatedFormData[index]['file_url'] = '';
      updatedFormData[index]['id'] = '';
    } else {
      updatedFormData[index][field] = e.target.value;
    }
    const lastRow = updatedFormData[updatedFormData.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      updatedFormData.push({ key: '', value: '', type: 'text', filename: '', description: '' });
    }
    handleFormQueryBodyUpdate(updatedFormData, 'form-data');
  };
  const handleFormDataRowDelete = (index) => {
    const updatedFormData = [...formData];
    if (updatedFormData.length > 1) {
      updatedFormData.splice(index, 1);
      if (index === updatedFormData.length) {
        updatedFormData.push({ key: '', value: '', type: 'text', filename: '', description: '' });
      }
    } else if (updatedFormData.length === 1) {
      updatedFormData[index] = { key: '', value: '', description: '', type: 'text', filename: '' };
    }
    handleFormQueryBodyUpdate(updatedFormData, 'form-data');
  };
  const handleSetFormUrlEncoded = (e, index, field) => {
    const updatedFormUrlEncodedEntries = [...formUrlEncoded];
    updatedFormUrlEncodedEntries[index][field] = e.target.value;
    const lastRow = updatedFormUrlEncodedEntries[updatedFormUrlEncodedEntries.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      updatedFormUrlEncodedEntries.push({ key: '', value: '', description: '' });
    }
    handleFormQueryBodyUpdate(updatedFormUrlEncodedEntries, 'form-urlencoded');
  };
  const handleFormUrlEncodedRowDelete = (index) => {
    const updatedFormUrlEncoded = [...formUrlEncoded];
    if (updatedFormUrlEncoded.length > 1) {
      updatedFormUrlEncoded.splice(index, 1);
      if (index === updatedFormUrlEncoded.length) {
        updatedFormUrlEncoded.push({ key: '', value: '', description: '' });
      }
    } else if (updatedFormUrlEncoded.length === 1) {
      updatedFormUrlEncoded[index] = { key: '', value: '', description: '' };
    }
    handleFormQueryBodyUpdate(updatedFormUrlEncoded, 'form-urlencoded');
  };
  const handleSetRawRequestBody = (value) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.rawRequestBody = value;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  const handleSetGraphQlVariables = (value) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.graphQLVariables = value;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return {
    handleSetRawLanguage,
    handleSetRequestBodyMode,
    handleAddContentTypeHeader,
    handleSetFormData,
    handleFormDataRowDelete,
    handleSetFormUrlEncoded,
    handleFormUrlEncodedRowDelete,
    handleSetRawRequestBody,
    handleSetGraphQlVariables
  };
};

export const useSetAuthorizationHeader = () => {
  const { testId, newTestId, exampleId } = useParams();

  const { setTestsTab } = useContext(ApiTestContext);
  const handleSetAuthorizationHeader = (newAuthObject) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.authorizationHeader = newAuthObject;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleSetAuthorizationHeader };
};

export const useSetTestRunResponse = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab, responseWindowRef } = useContext(ApiTestContext);
  const handleScrollToResponse = () => {
    responseWindowRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleSetTestRunResponse = (dataObj) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testRunResponse = dataObj;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleSetTestRunResponse, handleScrollToResponse };
};

export const useSetTestDetails = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const handleSetTestDetails = (newObj) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testDetails = { ...test.testDetails, ...newObj };
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleSetTestDetails };
};
