export const StackSvg = ({ fill, width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4134 8.42583C15.4134 8.37613 15.3985 8.32758 15.3707 8.28643C15.3428 8.24528 15.3033 8.21341 15.2571 8.19494L10.5514 6.31271L15.2571 4.43048C15.3033 4.41205 15.3429 4.3802 15.3708 4.33904C15.3987 4.29789 15.4136 4.24931 15.4136 4.1996C15.4136 4.14988 15.3987 4.1013 15.3708 4.06015C15.3429 4.01899 15.3033 3.98714 15.2571 3.96871L7.79908 0.985485C7.73982 0.961869 7.67376 0.961869 7.6145 0.985485L0.156443 3.96871C0.110269 3.98714 0.0706818 4.01899 0.0427941 4.06015C0.0149065 4.1013 0 4.14988 0 4.1996C0 4.24931 0.0149065 4.29789 0.0427941 4.33904C0.0706818 4.3802 0.110269 4.41205 0.156443 4.43048L4.86216 6.31271L0.156443 8.19494C0.110269 8.21337 0.0706818 8.24522 0.0427941 8.28638C0.0149065 8.32754 0 8.37611 0 8.42583C0 8.47554 0.0149065 8.52412 0.0427941 8.56527C0.0706818 8.60643 0.110269 8.63828 0.156443 8.65671L4.86216 10.5389L0.156443 12.4212C0.110269 12.4396 0.0706818 12.4714 0.0427941 12.5126C0.0149065 12.5538 0 12.6023 0 12.6521C0 12.7018 0.0149065 12.7503 0.0427941 12.7915C0.0706818 12.8327 0.110269 12.8645 0.156443 12.8829L7.6145 15.8662C7.67376 15.8898 7.73982 15.8898 7.79908 15.8662L15.2571 12.8829C15.3033 12.8645 15.3429 12.8327 15.3708 12.7915C15.3987 12.7503 15.4136 12.7018 15.4136 12.6521C15.4136 12.6023 15.3987 12.5538 15.3708 12.5126C15.3429 12.4714 15.3033 12.4396 15.2571 12.4212L10.5514 10.5389L15.2571 8.65671C15.3033 8.63824 15.3428 8.60638 15.3707 8.56522C15.3985 8.52407 15.4134 8.47552 15.4134 8.42583ZM0.918097 4.1996L7.70679 1.48424L14.4955 4.1996L7.70679 6.91495L0.918097 4.1996ZM14.4955 12.6521L7.70679 15.3674L0.918097 12.6521L5.53152 10.8068L7.6145 11.6399C7.67376 11.6636 7.73982 11.6636 7.79908 11.6399L9.88206 10.8068L14.4955 12.6521ZM7.70679 11.1412L0.918097 8.42583L5.53152 6.58058L7.6145 7.41371C7.67376 7.43732 7.73982 7.43732 7.79908 7.41371L9.88206 6.58058L14.4955 8.42583L7.70679 11.1412Z"
        fill={fill || '#24C3D9'}
      />
    </svg>
  );
};
