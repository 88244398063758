import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { useGetCurrentTest } from '../../../hooks/apiHooks';
import NoCookiesSvg from '../../../components/svg/NoCookiesSvg';
import Flex from '../../../components/base/Flex';
import { Typography } from '@mui/material/node';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`
  }
}));

const ResponseCookies = () => {
  const {
    testRunResponse: { responseCookies }
  } = useGetCurrentTest();
  return (
    <>
      {!!responseCookies.length ? (
        <CustomScrollbar sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '250px', mt: 1 }}>
          <Table sx={{ width: '100%', mt: 2 }} size="small" aria-label={'Response Header Table'}>
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>{''}</StyledTableCell> */}
                <StyledTableCell>Key</StyledTableCell>
                <StyledTableCell>Value</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {responseCookies?.map((item, i) => (
                <TableRow key={i}>
                  <StyledTableCell sx={{ minWidth: 250 }}>{item.key}</StyledTableCell>
                  <StyledTableCell>
                    {Array.isArray(item.value) ? item.value[0] : item.value}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CustomScrollbar>
      ) : (
        <Flex justifyContent={'center'} flexDirection={'column'} mt={2}>
          <NoCookiesSvg />
          <Typography mt={2} fontSize={'0.9rem'} textAlign={'center'}>
            No cookies received from the server
          </Typography>
          <Typography mt={1} textAlign={'center'} fontSize={'0.75rem'}>
            All your cookies and their associated domains will appear here.
          </Typography>
        </Flex>
      )}
    </>
  );
};

export default ResponseCookies;
