import { SvgIcon } from '@mui/material';

const StarSvg = ({ width = 22, height = 22, disabled, fill = 'gold', ...props }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox="0 0 120 120"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <polygon
        points="50,15 61,35 85,40 66,57 70,83 50,70 30,83 34,57 15,40 39,35"
        fill={disabled ? 'gray' : fill ? fill : 'white'}
      />
    </SvgIcon>
  );
};
export default StarSvg;
