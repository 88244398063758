import { Box, Chip, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useActiveProject } from '../../../store/projectState';
import { DateFormatSelect } from '../../Mobile/components/MobileFunctionVariables';
import {
  generateDateOffset,
  generateDatetimeOffset,
  generateRandomAlpha,
  generateRandomAlphanumeric,
  generateRandomInRange,
  generateRandomNumber,
  generateTimeOffset,
  generateRandomBoolean
} from '../../Mobile/utils';
import { FloatingVariableWindowContext } from '../../../store/floatingVariableWindowState';
import { useTheme } from '@emotion/react';
import { useSetQueryBody } from '../../../hooks/apiHooks/updateTestPropsHooks';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';

/**
 *
 * @param { boolean } open
 * @param { () => void } closeSystemVariablesModal
 * @param { ({meta: {}, title: any, id: string, steps: any}) => void } handleUpdate - callback function that will be called to update the state
 * @param inputValue
 * @returns {JSX.Element}
 * @constructor
 */

const CreateSystemVariableForApi = ({
  open,
  setRawContent,
  handleUpdate,
  inputValue,
  cursorIndex,
  event,
  property,
  onCompleteInsertion = () => {}
}) => {
  const theme = useTheme();
  const { focusedInput, focusedInpuSetterFn, editorRef, isEditorFocused } = useContext(
    FloatingVariableWindowContext
  );
  const { handleSetRawRequestBody } = useSetQueryBody();
  const { openSystemVariablesModal, closeSystemVariablesModal } = useContext(
    ApiDialogsAndModalsContext
  );
  const [selectedTab, setSelectedTab] = React.useState(0);
  const activeProject = useActiveProject();
  // Check the URL to determine whether the test is on playground or production
  const currentUrl = window.location.href;
  const isPlayground = currentUrl.includes('playground');
  const [randomNum, setRandomNum] = React.useState({ value: '', sampleOutput: '' });
  const [randomNumberInRange, setRandomNumberInRange] = React.useState({
    min: '',
    max: '',
    sampleOutput: ''
  });
  const [randomAlphaString, setRandomAlphaString] = React.useState({
    stringLength: '5',
    charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    sampleOutput: 'itKRJ'
  });
  const [randomAlphaNumericString, setRandomAlphaNumericString] = React.useState({
    stringLength: '7',
    charset: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    sampleOutput: 'JlU1ZZcr'
  });
  const [formattedDate, setFormattedDate] = React.useState({
    offSet: '',
    format: 'YYYY-MM-DD',
    sampleOutput: ''
  });
  const [dateTime, setDateTime] = React.useState({ value: '', sampleOutput: '' });
  const [timeInMilliseconds, setTimeInMilliseconds] = React.useState({
    value: '',
    sampleOutput: '1723330021122'
  });

  const [randomEmail, setRandomEmail] = React.useState({
    stringLength: '5',
    charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    time: '',
    sampleOutput: `79685948.${activeProject?.email_identifier}@${
      isPlayground ? 'playmail.getscandium.com' : 'testmail.getscandium.com'
    }`
  });

  const ALPHA_REGEX = /^[a-zA-Z-]+$/;
  const ALPHANUMERIC_REGEX = /^[a-zA-z0-9-]+$/;
  // This function generate number of the given length
  const randomNumberGenerator = (length) => {
    if (length <= 0) {
      return;
    }
    const _random = generateRandomNumber(length);
    setRandomNum((prev) => ({ ...prev, sampleOutput: _random }));
  };

  // This function generate number within the given min and max
  const generateRandomNumberInRange = (min, max) => {
    if (min >= max) {
      return;
    }

    const _min = parseInt(min, 10);
    const _max = parseInt(max, 10);
    const randomNumber = generateRandomInRange(_min, _max);
    setRandomNumberInRange((prev) => ({ ...prev, sampleOutput: randomNumber }));
  };

  // This function generate letters of the given length, selecting from the given character set
  const generateRandomAlphaStringSample = (length, charset) => {
    if (!length || !charset.length) return;

    const result = generateRandomAlpha(length, charset);
    setRandomAlphaString((prev) => ({ ...prev, sampleOutput: result }));
  };

  // This function generate letters of the given length, selecting from the given character set
  const generateRandomEmailStringSample = (offsetMilliseconds) => {
    if (offsetMilliseconds === '') return;

    const targetDatetime = generateTimeOffset(offsetMilliseconds);

    const emailSample = `${targetDatetime}.${activeProject?.email_identifier}@${
      isPlayground ? 'playmail.getscandium.com' : 'testmail.getscandium.com'
    }`;
    setRandomEmail((prev) => ({ ...prev, sampleOutput: emailSample }));
  };

  // This function generate alphanumeric characters of the given length, selecting from the given character set
  const generateRandomAlphanumericStringSample = (length, charset) => {
    if (!length || !charset.length) return;

    const result = generateRandomAlphanumeric(length, charset);
    setRandomAlphaNumericString((prev) => ({ ...prev, sampleOutput: result }));
  };

  // This function generate date in given format from the current date offset in days by given number
  function generateFormattedDate(offsetDays, dateFormat) {
    if (offsetDays === '') return;
    const formattedDate = generateDateOffset(offsetDays, dateFormat);
    setFormattedDate((prev) => ({ ...prev, sampleOutput: formattedDate }));
  }

  //This function generate datetime from the current datetime that is offset by milliseconds
  const generateOffsetDatetime = (offsetMilliseconds) => {
    if (offsetMilliseconds === '') return;
    const targetDatetime = generateDatetimeOffset(offsetMilliseconds);
    setDateTime((prev) => ({ ...prev, sampleOutput: targetDatetime }));
  };

  //This function generate time in milliseconds from the current datetime that is offset by milliseconds
  const generateTimeInMilliseconds = (offsetMilliseconds) => {
    if (offsetMilliseconds === '') return;
    const targetDatetime = generateTimeOffset(offsetMilliseconds);
    setTimeInMilliseconds((prev) => ({ ...prev, sampleOutput: targetDatetime }));
  };

  // These function will update the variables based on object properties
  const updater = (_variableName) => {
    if (!!editorRef.current && !!isEditorFocused) {
      const { lineNumber, column } = editorRef.current.getPosition();
      const charAfterCursor = editorRef.current
        .getModel()
        .getLineContent(lineNumber)
        .substring(column - 1, column + 2);
      let endColumn = !charAfterCursor.startsWith('}')
        ? column
        : charAfterCursor.startsWith('}}')
        ? column + 2
        : column + 1;
      const range = {
        startLineNumber: lineNumber,
        startColumn: column - 2,
        endLineNumber: lineNumber,
        endColumn: endColumn
      };
      editorRef.current.executeEdits('', [
        {
          range: range,
          text: _variableName
        }
      ]);
      handleSetRawRequestBody(editorRef.current.getValue());
      return;
    }
    let cursorPosition = focusedInput.current.selectionStart;
    let newValue = `
    ${focusedInput.current.value.slice(
      0,
      cursorPosition - 2
    )}${_variableName}${focusedInput.current.value.slice(cursorPosition)} `;
    focusedInput.current.value = newValue.trim();
    focusedInput.current.focus();
    focusedInpuSetterFn.current();
  };

  // handle insert {{num()}} variables
  const handleInsertBoolean = () => {
    const _variable = `{{boolean()}}`;
    updater(_variable);
    setRandomNum((prev) => ({ ...prev, value: '' }));
    closeSystemVariablesModal();
  };
  // handle insert {{num()}} variables
  const handleInsert = () => {
    if (randomNum.value <= 0) {
      toast.error('Please enter a positive integer');
      return;
    }

    const _variable = `{{num(${randomNum.value})}}`;
    updater(_variable);
    setRandomNum((prev) => ({ ...prev, value: '' }));
    closeSystemVariablesModal();
  };

  // handle insert {{range()}} variables
  const handleInsertRangeVariable = () => {
    if (+randomNumberInRange.min >= +randomNumberInRange.max) {
      toast.error('Invalid range: min must be less than max');
      return;
    }

    if (randomNumberInRange.min === '' || randomNumberInRange.max === '') {
      toast.error('Min and max are required');
      return;
    }

    const _variable = `{{range(${randomNumberInRange.min}, ${randomNumberInRange.max})}}`;

    updater(_variable);
    setRandomNumberInRange((prev) => ({ ...prev, max: '', min: '' }));
    closeSystemVariablesModal();
  };

  // handle insert {{alpha(5, ‘a-zA-z’)}} variables
  const handleInsertAlphaVariable = () => {
    if (!randomAlphaString.stringLength || !randomAlphaString.charset?.length) {
      toast.error('Please provide a length and a random alpha string');
      return;
    }
    const defaultCharset = randomAlphaString.charset.includes(
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );
    const _variable = !!defaultCharset
      ? `{{alpha(${randomAlphaString.stringLength})}}`
      : `{{alpha(${randomAlphaString.stringLength}, ${randomAlphaString.charset})}}`;

    updater(_variable);
    setRandomAlphaString((prev) => ({ ...prev, stringLength: '' }));
    closeSystemVariablesModal();
  };

  // handle insert random email address
  const handleInsertRandomEmail = () => {
    if (!randomEmail.stringLength || !randomEmail.charset?.length) {
      toast.error('Please provide a length and a random alpha string');
      return;
    }

    const _variable = `{{time(${randomEmail.time})}}.${activeProject.email_identifier}@${
      isPlayground ? 'playmail.getscandium.com' : 'testmail.getscandium.com'
    }`;

    updater(_variable);
    setRandomEmail((prev) => ({ ...prev, stringLength: 5 }));
    closeSystemVariablesModal();
  };

  // handle insert {{alphanum(10, ‘a-zA-z0-9’)}}  variables
  const handleInsertAlphaNumericVariable = () => {
    if (!randomAlphaNumericString.stringLength || !randomAlphaNumericString.charset?.length) {
      toast.error('Please provide a length and a random alphaNumeric string');
      return;
    }
    const defaultCharset = randomAlphaNumericString.charset.includes(
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );
    const _variable = !!defaultCharset
      ? `{{alphanum(${randomAlphaNumericString.stringLength})}}`
      : `{{alphanum(${randomAlphaNumericString.stringLength}, ${randomAlphaNumericString.charset})}}`;
    updater(_variable);
    setRandomAlphaNumericString((prev) => ({ ...prev, stringLength: '' }));
    closeSystemVariablesModal();
  };

  // handle insert {{date(10, ‘DD/MM/YYYY’)}}  variables
  const handleInsertDateVariable = () => {
    if (!formattedDate.offSet || !formattedDate.format) {
      toast.error('Please provide a date format and offset value');
      return;
    }

    const _variable = `{{date(${formattedDate.offSet}, ${formattedDate.format})}}`;

    updater(_variable);
    setFormattedDate((prev) => ({ ...prev, offSet: '' }));
    closeSystemVariablesModal();
  };

  // handle insert {{datetime(10)}}  variables
  const handleInsertDatetimeVariable = () => {
    if (dateTime.value === '') {
      toast.error('Please provide an offset in milliseconds');
      return;
    }

    const _variable = `{{datetime(${dateTime.value})}}`;

    updater(_variable);
    setDateTime((prev) => ({ ...prev, value: '' }));
    closeSystemVariablesModal();
  };

  // handle insert {{datetime(10)}}  variables
  const handleInsertTimeInMillisecondsVariable = () => {
    if (timeInMilliseconds.value === '') {
      toast.error('Please provide an offset in milliseconds');
      return;
    }

    const _variable = `{{time(${timeInMilliseconds.value})}}`;

    updater(_variable);
    setTimeInMilliseconds((prev) => ({ ...prev, value: '' }));
    closeSystemVariablesModal();
  };

  const options = [
    { badge: 'Email', label: 'Generate random email address' },
    { badge: 'Num', label: 'Generate number of the given length' },
    { badge: 'Range', label: 'Generate number within the given min and max' },
    {
      badge: 'Alpha',
      label: 'Generate letters of the given length, selecting from the given character set'
    },
    {
      badge: 'Alphanumeric',
      label:
        'Generate alphanumeric characters of the given length, selecting from the given character set'
    },
    {
      badge: 'Date',
      label: 'Generate date in given format from the current date offset in days by given number'
    },
    {
      badge: 'Datetime',
      label: 'Generate datetime from the current datetime that is offset by milliseconds'
    },
    { badge: 'Time', label: 'Generate time that is offset from current time in milliseconds' },
    { badge: 'Boolean', label: 'Generate a boolean value: true or false' }
  ];

  return (
    <CustomModal open={openSystemVariablesModal}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'800px'} maxHeight={'80%'} position={'relative'}>
        <IconButton
          onClick={closeSystemVariablesModal}
          sx={{
            position: 'absolute',
            top: '5px',
            right: '14px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color="primary"
          // mb={3}
          sx={{
            fontSize: '1.2rem'
          }}>
          Insert Function
        </Typography>

        <Typography
          as={'h4'}
          mb={1.5}
          sx={{
            fontSize: '0.9rem'
          }}>
          Select one from the option below
        </Typography>

        <Flex alignItems={'normal'} columnGap={4}>
          <Flex
            sx={{
              flex: '50%',
              flexDirection: 'column'
            }}>
            <CustomScrollbar sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
              {options.map((option, index) => (
                <Flex
                  key={index + option}
                  onClick={() => setSelectedTab(index)}
                  mb={1.5}
                  sx={{
                    backgroundColor:
                      selectedTab === index
                        ? 'rgba(36, 195, 217, 0.33)'
                        : theme.palette.background.suiteList,
                    cursor: 'pointer',
                    py: 1,
                    px: 2.5,
                    borderRadius: '6px',
                    width: '100%',
                    alignItems: 'normal',
                    '&:hover': {
                      backgroundColor:
                        selectedTab === index
                          ? 'rgba(36, 195, 217, 0.39)'
                          : theme.palette.hover.default
                    }
                  }}>
                  <Typography fontSize={'0.9rem'}>
                    <Typography>
                      <Chip
                        label={option.badge}
                        color={'primary'}
                        sx={{ fontSize: '0.8rem', borderRadius: '5px', mb: '2px' }}
                        size={'small'}
                      />
                    </Typography>
                    {/* <Typography
                    color={'primary'}
                    sx={{ fontSize: '0.75rem', borderRadius: '6px', mr: 1 }}
                  >{option.badge}</Typography> */}
                    {option.label}
                  </Typography>
                </Flex>
              ))}
            </CustomScrollbar>
          </Flex>
          <Flex
            sx={{
              flex: '50%',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column'
            }}>
            {selectedTab === 0 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <TextField
                  size={'small'}
                  value={randomEmail.time}
                  type={'number'}
                  label={'Offset in milliseconds'}
                  placeholder={'6'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) => {
                    setRandomEmail((prev) => ({
                      ...prev,
                      time: e.target.value
                    }));
                    generateRandomEmailStringSample(+e.target.value);
                  }}
                />
                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography
                      fontSize={'0.8rem'}
                      color={'black'}
                      sx={{
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all'
                      }}
                      display={'flex'}
                      flexWrap={'wrap'}>
                      {randomEmail.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertRandomEmail()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 1 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <TextField
                  size={'small'}
                  value={randomNum.value}
                  type={'number'}
                  label={'Number of digits'}
                  placeholder={'6'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) => {
                    setRandomNum((prev) => ({ ...prev, value: e.target.value }));
                    randomNumberGenerator(+e.target.value);
                  }}
                />

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography fontSize={'0.95'} color={'black'}>
                      {randomNum.sampleOutput?.toString()}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton fullWidth onClick={() => handleInsert()} sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 2 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Flex columnGap={3}>
                  <TextField
                    size={'small'}
                    value={randomNumberInRange.min}
                    type={'number'}
                    label={'Min Number'}
                    placeholder={'5'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setRandomNumberInRange((prev) => ({ ...prev, min: e.target.value }));
                      generateRandomNumberInRange(+e.target.value, randomNumberInRange.max);
                    }}
                  />
                  <TextField
                    size={'small'}
                    value={randomNumberInRange.max}
                    type={'number'}
                    label={'Max Number'}
                    placeholder={'10'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setRandomNumberInRange((prev) => ({ ...prev, max: e.target.value }));
                      generateRandomNumberInRange(randomNumberInRange.min, +e.target.value);
                    }}
                  />
                </Flex>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography
                      sx={{
                        color: 'black'
                      }}
                      fontSize={'0.95'}>
                      {randomNumberInRange.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertRangeVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 3 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Flex columnGap={3}>
                  <TextField
                    size={'small'}
                    value={randomAlphaString.stringLength}
                    type={'number'}
                    label={'Length'}
                    placeholder={'5'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setRandomAlphaString((prev) => ({ ...prev, stringLength: e.target.value }));
                      generateRandomAlphaStringSample(+e.target.value, randomAlphaString.charset);
                    }}
                  />
                  <TextField
                    size={'small'}
                    value={randomAlphaString.charset}
                    type={'text'}
                    label={'Character set'}
                    placeholder={'abc...z'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== '' && !ALPHA_REGEX.test(value)) {
                        return;
                      }
                      setRandomAlphaString((prev) => ({ ...prev, charset: e.target.value }));
                      generateRandomAlphaStringSample(
                        randomAlphaString.stringLength,
                        e.target.value
                      );
                    }}
                  />
                </Flex>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography fontSize={'0.95'} color={'black'}>
                      {randomAlphaString.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertAlphaVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 4 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Flex columnGap={3}>
                  <TextField
                    size={'small'}
                    value={randomAlphaNumericString.stringLength}
                    type={'number'}
                    label={'Length'}
                    placeholder={'5'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setRandomAlphaNumericString((prev) => ({
                        ...prev,
                        stringLength: e.target.value
                      }));
                      generateRandomAlphanumericStringSample(
                        +e.target.value,
                        randomAlphaNumericString.charset
                      );
                    }}
                  />
                  <TextField
                    size={'small'}
                    value={randomAlphaNumericString.charset}
                    type={'text'}
                    label={'Character set'}
                    placeholder={'0123abczABCZ'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== '' && !ALPHANUMERIC_REGEX.test(value)) {
                        return;
                      }
                      setRandomAlphaNumericString((prev) => ({ ...prev, charset: e.target.value }));
                      generateRandomAlphanumericStringSample(
                        randomAlphaNumericString.stringLength,
                        e.target.value
                      );
                    }}
                  />
                </Flex>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography fontSize={'0.95'} color={'black'}>
                      {randomAlphaNumericString.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertAlphaNumericVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 5 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Flex columnGap={3}>
                  <TextField
                    size={'small'}
                    value={formattedDate.offSet}
                    type={'number'}
                    label={'Offset in days'}
                    placeholder={'5'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setFormattedDate((prev) => ({
                        ...prev,
                        offSet: e.target.value
                      }));
                      generateFormattedDate(+e.target.value, formattedDate.format);
                    }}
                  />
                  <DateFormatSelect
                    formattedDate={formattedDate}
                    setDateFormat={setFormattedDate}
                    generateFormattedDate={generateFormattedDate}
                  />
                </Flex>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography fontSize={'0.95'} color={'black'}>
                      {formattedDate.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertDateVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 6 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <TextField
                  size={'small'}
                  value={dateTime.value}
                  type={'number'}
                  label={'Offset in milliseconds'}
                  placeholder={'120000'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) => {
                    setDateTime((prev) => ({
                      ...prev,
                      value: e.target.value
                    }));
                    generateOffsetDatetime(+e.target.value);
                  }}
                />

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography fontSize={'0.8rem'} color={'black'}>
                      {dateTime.sampleOutput?.toString()}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertDatetimeVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 7 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <TextField
                  size={'small'}
                  value={timeInMilliseconds.value}
                  type={'number'}
                  label={'Offset in milliseconds'}
                  placeholder={'6'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) => {
                    setTimeInMilliseconds((prev) => ({
                      ...prev,
                      value: e.target.value
                    }));
                    generateTimeInMilliseconds(+e.target.value);
                  }}
                />

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography fontSize={'0.95'} color={'black'}>
                      {timeInMilliseconds.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertTimeInMillisecondsVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}
            {selectedTab === 8 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Typography>Generate a random boolean value</Typography>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: '#F6FAFF',
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <SampleOutputTitle />
                    <Typography
                      fontSize={'1.0rem'}
                      color={'primary'}
                      sx={{
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all'
                      }}
                      display={'flex'}
                      flexWrap={'wrap'}>
                      {!!generateRandomBoolean() ? 'true' : 'false'}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertBoolean()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton
                      fullWidth
                      onClick={() => closeSystemVariablesModal()}
                      sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </CustomModal>
  );
};
const SampleOutputTitle = () => {
  return (
    <Typography
      sx={{
        color: 'black'
      }}
      fontSize={'0.9rem'}>
      Sample output
    </Typography>
  );
};

export default CreateSystemVariableForApi;
