import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import OutlinedButton from './OutlinedButton';
import ContainedButton from './ContainedButton';
import React from 'react';
import useAwaitModal from '../../hooks/useAwaitModal';

export const ConfirmDialog = ({
  open,
  useCase,
  handleClose,
  onConfirm,
  onSaveTest,
  isSavingTest,
  title = 'Are you sure you want to proceed?',
  description,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  confirmColor = 'primary',
  saveTestColor = 'secondary',
  fetchingData,
  circleSize
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 2 }}>
        <OutlinedButton onClick={handleClose}>{cancelLabel}</OutlinedButton>
        <ContainedButton
          circleSize={circleSize}
          color={confirmColor}
          onClick={onConfirm}
          autoFocus
          isLoading={fetchingData}>
          {useCase === 'save-changes' && confirmLabel[1]}
          {useCase !== 'save-changes' && confirmLabel}
        </ContainedButton>
        {useCase === 'save-changes' && (
          <ContainedButton
            circleSize={circleSize}
            sx={{
              border: 'none'
            }}
            color={saveTestColor}
            onClick={onSaveTest}
            autoFocus
            isLoading={isSavingTest}>
            {confirmLabel[2]}
          </ContainedButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const useConfirmDialog = () => {
  const [requestConfirm, { open, onClose, onComplete }] = useAwaitModal();
  const ConfirmationDialog = ({
    useCase,
    handleSaveTest,
    isSavingTest,
    cancelLabel,
    confirmLabel,
    title,
    description,
    confirmColor,
    runRequest,
    runningRequest,
    requestSuccessful,
    circleSize
  }) => (
    <ConfirmDialog
      useCase={useCase}
      isSavingTest={isSavingTest}
      open={open}
      title={title}
      description={description}
      circleSize={circleSize}
      confirmColor={confirmColor}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      handleClose={onClose}
      fetchingData={runningRequest}
      onSaveTest={async () => {
        const result = await handleSaveTest(); //save test
        if (result) {
          onComplete(); // close dialog
          runRequest(); // fetch next test
          return;
        }
      }}
      onConfirm={async () => {
        if (useCase === 'save-changes') {
          runRequest();
          onComplete();
          return;
        }
        if (runRequest) {
          const result = await runRequest();
          if (result) {
            setTimeout(() => {
              onComplete();
            }, 500);
          }
        } else {
          onComplete();
        }
      }}
    />
  );

  return {
    ConfirmationDialog,
    requestConfirm,
    toggleDialog: { open, onClose }
  };
};
