import { Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import React, { useContext } from 'react';
import { FloatingVariableWindowContext } from '../../../store/floatingVariableWindowState';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { useGetCurrentTest } from '../../../hooks/apiHooks';

export const SaveTextAsVariable = () => {
  const {
    editorCursorPosition,
    saveHighLightedTextState,
    setSaveHighLightedTextState
    // jsonResponseFields
  } = useContext(FloatingVariableWindowContext);
  ////////////////////////////////////////////////////
  const { requestCreateGVAssertionModal, requestGVModal } = useContext(ApiDialogsAndModalsContext);
  const { setGlobalVariables } = useContext(ApiTestContext);
  const {
    uiProps: {
      responseEditorProps: { jsonResponseFields }
    }
  } = useGetCurrentTest();
  const handleOnClick = (path, isPath) => {
    setSaveHighLightedTextState((prev) => {
      return { ...prev, showModal: false };
    });
    if (!!isPath) {
      setSaveHighLightedTextState((prev) => {
        return {
          ...prev,
          fieldEntry: {
            name: '',
            path
          }
        };
      });
      requestCreateGVAssertionModal();
      return;
    }
    requestGVModal();
    setGlobalVariables((prev) => {
      return [...prev, { name: '', value: saveHighLightedTextState.highLightedValue }];
    });
    // setGlobalVariableState((prev) => {
    //   return {
    //     ...prev,
    //     openModal: true,
    //     useCase: 'add_variables',
    //     globalVariables: [
    //       ...prev.globalVariables,
    //       { name: '', value: saveHighLightedTextState.highLightedValue }
    //     ]
    //   };
    // });
  };
  return (
    <Box
      boxShadow={'1px 1px 5px rgba(0, 0, 0, .2)'}
      sx={{
        position: 'absolute',
        display: !!saveHighLightedTextState.showModal ? 'flex' : 'none',
        // gap: 1,
        minWidth: '200px',
        flexDirection: 'column',
        alignItems: 'center',
        top: editorCursorPosition.top + 8,
        left: editorCursorPosition.left - 30,
        padding: 2,
        py: 1,
        px: 0.5,
        mb: 5,
        backgroundColor: 'white',
        borderRadius: 1,
        ':hover': {
          cursor: 'pointer'
        }
      }}>
      {!!saveHighLightedTextState.showModal && (
        <>
          <Box
            sx={{
              display: 'flex',
              px: 1,
              justifyContent: 'space-between',
              minWidth: '100%'
            }}>
            <InfoIcon color="primary" fontSize="small" />
            {saveHighLightedTextState.useCase === 'text' && (
              <Typography
                fontSize={12}
                color={'primary'}
                sx={{
                  ':hover': {
                    textDecoration: 'underline'
                  }
                }}
                onClick={handleOnClick}>
                Save text as variable
              </Typography>
            )}
            {saveHighLightedTextState.useCase === 'field' && (
              <Box>
                <Typography fontSize={13}>Save field path as variable</Typography>
              </Box>
            )}
            <CloseIcon
              onClick={() => {
                setSaveHighLightedTextState((prev) => {
                  return { ...prev, showModal: false };
                });
              }}
              fontSize="small"
              color="disabled"
              sx={
                {
                  // ml: 'auto'
                }
              }
            />
          </Box>
          {saveHighLightedTextState.useCase === 'field' && (
            <CustomScrollbar
              sx={{
                overflowY: 'auto',
                overflowX: 'visible',
                maxHeight: '150px',
                minWidth: '250px'
              }}>
              <List
                sx={{
                  px: 1
                }}>
                {jsonResponseFields.fields.map((path, index) => {
                  if (path.endsWith(saveHighLightedTextState.highLightedValue))
                    return (
                      <>
                        <ListItem
                          onClick={() => {
                            handleOnClick(path, true);
                          }}
                          key={index}
                          color="primary"
                          sx={{
                            minWidth: '100%',
                            padding: 0,
                            textAlign: 'center',
                            '&:hover': {
                              background: 'rgba(224, 224, 224, .3)'
                            }
                          }}>
                          <ListItemText
                            sx={{
                              textAlign: 'start',
                              letterSpacing: 1
                            }}>
                            <Typography fontSize={12} sx={{ opacity: 0.6 }} display={'inline'}>
                              {path.startsWith('[') ? 'response' : 'response.'}
                            </Typography>
                            <Typography fontSize={12} color={'primary'} display={'inline'}>
                              {path}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <Divider sx={{ height: 1, borderWidth: 1 }} />
                      </>
                    );
                })}
              </List>
            </CustomScrollbar>
          )}
        </>
      )}
    </Box>
  );
};
