import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../../components/base/Flex';
import OutlinedButton from '../../../../components/base/OutlinedButton';
import ContainedButton from '../../../../components/base/ContainedButton';
import CustomModal from '../../../../components/base/CustomModal';
import React, { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import { useParams } from 'react-router-dom';
import useSaveApiTest from '../../../../hooks/apiHooks/queryHooks/useSaveApiTest';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';

const SaveApiTestExampleModal = () => {
  const [testExampleName, setTestExampleName] = React.useState('');
  const { testId, folderId } = useParams();
  const activeProject = useActiveProject();
  const { handleSaveApiTest, isSavingApiTest } = useSaveApiTest();
  const { openSaveTestExampleModal, closeSaveTestExampleModal } = useContext(
    ApiDialogsAndModalsContext
  );
  const saveExample = async () => {
    if (!testExampleName) {
      toast.error('Example name is required');
      return;
    }
    const result = await handleSaveApiTest(testExampleName);
    if (result.savedTest) {
      closeSaveTestExampleModal();
      setTestExampleName('');
    }
  };

  // const { mutateAsync: saveTestExample, isLoading } = useScandiumMutation(
  //   `/projects/${activeProject?.id}/api-tests/${testId}/examples`,
  //   {
  //     enabled: !!activeProject?.id,
  //     onError: (error) => {
  //       toast.error(error.message);
  //     },
  //     onSuccess: (data) => {
  //       toast.success(data.message);
  //       refetch();
  //       onComplete();
  //       setName('');
  //     }
  //   }
  // );

  // const handleSaveTestExample = async (e) => {
  //   if (!name) {
  //     toast.error('Example name is required');
  //     return;
  //   }

  //   const testData = {
  //       name,
  //       data,
  //       response,
  //       assertions
  //   }

  //   if (folderId) testData['folder_id'] = +folderId;

  //   await saveTestExample(testData);
  // };

  return (
    <CustomModal open={openSaveTestExampleModal}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={closeSaveTestExampleModal}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Save test example
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          Input a name below
        </Typography>

        <Box mb={3}>
          <TextField
            onChange={(e) => {
              setTestExampleName(e.target.value);
            }}
            value={testExampleName}
            autoFocus
            size={'small'}
            placeholder={`Name your test example`}
            fullWidth
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={closeSaveTestExampleModal}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!testExampleName}
            isLoading={isSavingApiTest}
            onClick={saveExample}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default SaveApiTestExampleModal;
