import { useScandiumMutation } from '../../../data-layer/utils';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useActiveProject } from '../../../store/projectState';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounceSearchValue } from '../useDebounce';

const useMassDeletion = () => {
  const navigate = useNavigate();
  const activeProject = useActiveProject();
  const { debounceSearchValue } = useDebounceSearchValue();
  const { massSelection, setMassSelection } = useContext(ApiTestContext);
  const queryClient = useQueryClient();
  const { testId } = useParams();
  const { mutateAsync: massDelete, isLoading: isMassDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/multi-delete`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(`${data.message}`, { autoClose: 800 });
        queryClient.invalidateQueries(['get-apiFoldersAndTests']);
        if (!!massSelection.selectedApiTests.includes(testId)) {
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
        setMassSelection({
          active: false,
          selectedApiTests: [],
          selectedApiTestExamples: [],
          selectedFolders: [],
          selectedSuites: []
        });
      },
      onError: (error) => {
        toast.error(error?.message, {
          autoClose: 800
        });
      }
    }
  );
  return { massDelete, isMassDeleting };
};
export default useMassDeletion;
