import React from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Box, CircularProgress, Menu, MenuItem, Tooltip } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { useContext } from 'react';
import { useActiveProject } from '../../../store/projectState';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { useDeleteSingleTest, useDuplicateTest } from '../../../hooks/apiHooks/queryHooks';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';

const TestActionMenu = React.forwardRef(({ test, onEdit, useCase }, ref) => {
  const handleEdit = () => {
    menuProps.onClose();
    onEdit();
  };
  /////////////////////////////////////////////////////////////////
  const { triggerProps, menuProps } = useMenu();
  const activeProject = useActiveProject();
  const readyOnly = activeProject?.read_only;
  const { setFoldersState, setTestDescription } = useContext(ApiTestContext);
  const {
    requestSingleTestDeletionDialog,
    requestTestExampleDeletionDialog,
    requestRenameTestModal,
    requestTestDuplicateDialog,
    requestMoveToFolderModal,
    requestTestDescriptionModal
  } = useContext(ApiDialogsAndModalsContext);
  const { isDuplicating } = useDuplicateTest();
  let requestInProgresss = isDuplicating;
  return (
    <div>
      <Tooltip title={'View more actions'}>
        <IconButton
          ref={ref}
          size={'small'}
          sx={{ p: '4px' }}
          aria-controls={menuProps.open ? 'action-menu' : undefined}
          aria-haspopup="true"
          disabled={requestInProgresss}
          aria-expanded={menuProps.open ? 'true' : undefined}
          onClick={async (e) => {
            e.stopPropagation();
            triggerProps.onClick(e);
            if (!!test) {
              if (useCase === 'test-example') {
                setFoldersState((prev) => {
                  return { ...prev, clickedTestExample: test };
                });
              } else {
                setFoldersState((prev) => {
                  return { ...prev, clickedTest: test };
                });
              }
            }
          }}>
          {requestInProgresss ? (
            <CircularProgress size={18} color={'primary'} />
          ) : (
            <MoreHoriz color={'inherit'} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="action-menu"
        {...menuProps}
        PaperProps={{
          elevation: 0,
          style: {
            width: 200,
            minHeight: 100,
            paddingLeft: 5,
            paddingRight: 5
          },
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem onClick={handleEdit}>Open</MenuItem>
        {useCase !== 'test-example' && (
          <Box>
            <MenuItem disabled={!!readyOnly} onClick={requestRenameTestModal}>
              Rename
            </MenuItem>
            <MenuItem disabled={!!readyOnly} onClick={requestTestDuplicateDialog}>
              Duplicate
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFoldersState((prev) => {
                  return { ...prev, entityToMove: test, entityType: 'test-case' };
                });
                requestMoveToFolderModal();
              }}
              disabled={!!readyOnly}>
              Move
            </MenuItem>
            <MenuItem
              onClick={() => {
                setTestDescription(test?.documentation || '');
                requestTestDescriptionModal();
              }}
              disabled={!!readyOnly}>
              Add description
            </MenuItem>
          </Box>
        )}
        <MenuItem
          disabled={!!readyOnly}
          sx={{ color: 'red' }}
          onClick={
            useCase === 'test-example'
              ? requestTestExampleDeletionDialog
              : requestSingleTestDeletionDialog
          }>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
});

export default TestActionMenu;
