const GlobalVariables = { value: [] };

function parseFieldPath(path) {
  let regex = /(\w+)|\[(\d+)\]/g;
  let matches = [];
  let match;
  while ((match = regex.exec(path)) !== null) {
    matches.push(match[1] || parseInt(match[2]));
  }
  return matches;
}
const checkStatusCodeOrResponseBodyTypeOrRequestDuration = (valueFromRun, assertObj) => {
  //code = content-type or requestduration or statuscode
  let result = {
    status: '',
    expected: '',
    received: valueFromRun
  };
  switch (assertObj.operator) {
    case 'equal':
      if (assertObj.name === 'status_code') {
        result.expected = `equal ${assertObj.value}`;
        +assertObj.value === +valueFromRun && (result.status = 'passed');
        +assertObj.value != +valueFromRun && (result.status = 'failed');
        +assertObj.value != +valueFromRun && (result.status = 'failed');
      }

      if (assertObj.name === 'response_body_type') {
        result.expected = `be of type ${assertObj.value}`;
        valueFromRun.includes(assertObj.value) && (result.status = 'passed');
        !valueFromRun.includes(assertObj.value) && (result.status = 'failed');
      }
      // result = { [assertObj.name]: result };

      break;
    case 'not_equal':
      if (assertObj.name === 'status_code') {
        result.expected = `not equal ${assertObj.value}`;
        +assertObj.value !== +valueFromRun && (result.status = 'passed');
        +assertObj.value === +valueFromRun && (result.status = 'failed');
      }

      if (assertObj.name === 'response_body_type') {
        result.expected = `not be of type ${assertObj.value}`;
        !valueFromRun.includes(assertObj.value) && (result.status = 'passed');
        valueFromRun.includes(assertObj.value) && (result.status = 'failed');
      }
      // result = { [assertObj.name]: result };
      break;
    case 'less_than':
      result.expected = `be less than ${assertObj.value}`;
      +valueFromRun < +assertObj.value && (result.status = 'passed');
      !(+valueFromRun < +assertObj.value) && (result.status = 'failed');
      // result = { [assertObj.name]: result };
      break;
    case 'greater_than':
      result.expected = `be greater than ${assertObj.value}`;
      +valueFromRun > +assertObj.value && (result.status = 'passed');
      !(+valueFromRun > +assertObj.value) && (result.status = 'failed');
      // result = { [assertObj.name]: result };
      break;
    case 'range':
      result.expected = `be within ${assertObj.value[0]} and ${assertObj.value[1]}`;
      valueFromRun >= assertObj.value[0] &&
        valueFromRun <= assertObj.value[1] &&
        (result.status = 'passed');
      !(valueFromRun >= assertObj.value[0] && valueFromRun <= assertObj.value[1]) &&
        (result.status = 'failed');
      // result = { [assertObj.name]: result };
      break;
    case 'one_of':
      let check = [];
      result.expected = `be one of ${JSON.stringify(assertObj.value)}`;
      if (assertObj.name === 'response_body_type') {
        check = assertObj.value.filter((value) => {
          return valueFromRun.includes(value);
        });
        !!check.length ? (result.status = 'passed') : (result.status = 'failed');
      } else {
        check = assertObj.value.filter((value) => {
          return value == valueFromRun;
        });
        !!check.length ? (result.status = 'passed') : (result.status = 'failed');
      }
      // result = { [assertObj.name]: result };
      break;
  }

  return result;
};
const checkForHeaders = (responseHeaders, assertObj) => {
  // let result = { response_headers: [] };
  let result = [];
  assertObj.value.map((headerOption) => {
    const isPresent = responseHeaders.find((header) => {
      return header.key.toLowerCase() === headerOption.key.toLowerCase();
    });
    if (!!isPresent) {
      const obj = {
        key: headerOption.key,
        expected: headerOption.value,
        received: isPresent.value
      };
      if (isPresent.value.includes(headerOption.value)) {
        obj.status = 'passed';
        result.push(obj);
      } else {
        obj.status = 'failed';
        result.push(obj);
      }
    } else {
      result.push({
        key: headerOption.key,
        status: 'failed',
        expected: headerOption.value,
        received: ''
      });
    }
  });
  return result;
};

const checkForBodyContent = (responseBody, assertObj) => {
  let result = [];
  // let result = { response_body_content: [] };
  responseBody = JSON.stringify(responseBody);
  assertObj.value.map((bodyContentOption) => {
    const eachResult = {
      status: '',
      expected: `${bodyContentOption.operator}  "${bodyContentOption.value}"`
    };
    switch (bodyContentOption.operator) {
      case 'equal':
        bodyContentOption.value == responseBody && (eachResult.status = 'passed');
        bodyContentOption.value != bodyContentOption && (eachResult.status = 'failed');
        result.push(eachResult);
        break;
      case 'not_equal':
        bodyContentOption.value != responseBody && (eachResult.status = 'passed');
        bodyContentOption.value == bodyContentOption && (eachResult.status = 'failed');
        result.push(eachResult);
        break;
      case 'contains':
        responseBody.includes(bodyContentOption.value) && (eachResult.status = 'passed');
        !responseBody.includes(bodyContentOption.value) && (eachResult.status = 'failed');
        result.push(eachResult);
        break;
    }
  });
  return result;
};
const checkForFieldInJSONResponse = (
  responseBody,
  assertionObj,
  handleUpdateVariablesIfAsserted
) => {
  // let result = { create_global_variable: [] };
  let result = [];
  assertionObj.value.forEach(async (entry) => {
    let eachResult = {
      key: entry.variableName,
      expected: entry.value,
      cascade: entry.cascade
    };
    const eachField = parseFieldPath(entry.value);
    let isFieldPresent = responseBody; //e.g {name:"x", "age": [{one: 1}, {two:2}]}
    eachField.map((value) => {
      isFieldPresent = isFieldPresent[value];
    });
    if (!!isFieldPresent) {
      eachResult.status = 'passed';
      eachResult.received = isFieldPresent;
    }
    !isFieldPresent && (eachResult.status = 'failed');
    result.push(eachResult);
    if (!!isFieldPresent && !!entry.cascade) {
      await handleUpdateVariablesIfAsserted({
        name: entry.variableName,
        value: JSON.stringify(isFieldPresent)
      });
    }
  });
  return result;
};
export const runAssertions = (
  assertions,
  status,
  body,
  headers,
  contentType,
  time_taken,
  handleUpdateVariablesIfAsserted
) => {
  const allAssertionResult = {
    // status_code: null,
    // response_body_type: null,
    // request_duration: null,
    // response_headers: null,
    // response_body_content: null,
    // create_global_variable: null,
  };
  assertions.forEach(async (assertion) => {
    switch (assertion.name) {
      case 'status_code':
        let statusResult = checkStatusCodeOrResponseBodyTypeOrRequestDuration(status, assertion);
        allAssertionResult.status_code = statusResult;
        break;
      case 'response_body_type':
        let bodyTypeResult = checkStatusCodeOrResponseBodyTypeOrRequestDuration(
          contentType,
          assertion
        );
        allAssertionResult.response_body_type = bodyTypeResult;
        break;
      case 'request_duration':
        let durationResult = checkStatusCodeOrResponseBodyTypeOrRequestDuration(
          time_taken,
          assertion
        );
        allAssertionResult.request_duration = durationResult;
        break;
      case 'response_headers':
        let headersResult = checkForHeaders(headers, assertion);
        allAssertionResult.response_headers = headersResult;
        break;
      case 'response_body_content':
        let bodyContentResult = checkForBodyContent(body, assertion);
        allAssertionResult.response_body_content = bodyContentResult;
        break;
      case 'create_global_variable':
        if (contentType.includes('json')) {
          let jsonResponseFieldResult = checkForFieldInJSONResponse(
            body,
            assertion,
            handleUpdateVariablesIfAsserted
          );
          allAssertionResult.create_global_variable = jsonResponseFieldResult;
        }

        break;
    }
  });
  return allAssertionResult; // allAssertionResult is an object
};
export const functionToExtractResponseBody = async (response) => {
  const textBodyTypes = [
    'text',
    'html',
    'text/plain',
    'text/html',
    'application/javascript',
    'application/xml'
    // 'application/octet-stream'
  ];
  const blobBodyTypes = [
    'image/jpeg',
    'image/png',
    'jpeg',
    'png',
    'application/pdf'
    // 'application/octet-stream'
  ];
  let body;
  let isBlob = false;
  let contentType = 'text/plain';
  if (!!response.headers.get('Content-type' || 'content-type')) {
    contentType = response.headers.get('Content-type' || 'content-type');
  }
  try {
    if (contentType.includes('json')) {
      body = await response.json();
    } else if (textBodyTypes.includes(contentType.split(';')[0])) {
      body = await response.text();
    } else if (blobBodyTypes.includes(contentType)) {
      body = await response.blob();
      isBlob = true;
    } else {
      body = await response.text();
    }
  } catch (error) {
    throw error;
  }
  return { body, isBlob, contentType };
};

export const functionToExtractResponseHeaders = (_headers) => {
  let headers = [];
  _headers.forEach((value, name) => {
    headers.push({ key: name, value: value });
  });
  return headers;
};
