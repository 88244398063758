import { useContext } from 'react';
import Flex from '../../components/base/Flex';
import { useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import { AccountDetails } from './components/accountDetails';
import UsageStatistics from './components/usageStatistics';
import UserContext from '../../store/userState';
import { Loader } from 'rsuite';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import OutlinedButton from '../../components/base/OutlinedButton';
import { Box } from '@mui/material/node';

const AccountUsage = () => {
  const { userData } = useContext(UserContext);
  // console.log(userData);
  const {
    data,
    isFetching: isFetchingCompanyDetails,
    isSuccess: fetchedCompanyDetails,
    refetch
  } = useScandiumQuery(`company/${userData?.user?.active_company?.id}`, {
    enabled: userData?.user?.active_company?.id
  });
  return (
    <Flex
      sx={{
        px: '6rem',
        pt: '1rem',
        gap: '5rem',
        justifyContent: 'center',
        alignItems: !isFetchingCompanyDetails ? 'flex-start' : 'center',
        height: 'fit-content',
        minHeight: '70vh',
        maxHeight: '100vh',
        overflowY: 'hidden',
        pb: 0,
        boxSizing: 'border-box'
      }}>
      {isFetchingCompanyDetails ? (
        <Box>
          <PageLoader height={'100px'} />{' '}
        </Box>
      ) : fetchedCompanyDetails ? (
        <>
          <AccountDetails companyData={data?.data} />
          <UsageStatistics companyData={data?.data} />
        </>
      ) : (
        <ErrorState
          cta={
            <OutlinedButton
              onClick={() => {
                refetch();
              }}>
              Retry
            </OutlinedButton>
          }
        />
      )}
    </Flex>
  );
};

export default AccountUsage;
