import { Box, Stack, Typography } from '@mui/material/node';
import Flex from '../../../components/base/Flex';
import BrowserSvg from '../../../components/svg/BrowserSvg';
import { UsersSvg } from '../../../components/svg/UsersSvg';
import { StackSvg } from '../../../components/svg/StackSvg';
import { useTheme } from '@emotion/react';

const UsageStatistics = ({ companyData }) => {
  const theme = useTheme();
  const remoteRuns = companyData.usage?.remoteRuns;
  const allowedRemoteRuns = companyData.usage?.allowedRemoteRuns;
  const noOfUsers = companyData?.usage?.users;
  const allowedNoOfUsers = companyData?.settings?.max_allowed_users;
  const noOfProjects = companyData.usage?.projects;
  const allowedNoOfProjects = companyData?.settings?.max_allowed_projects;
  const parallelRuns = companyData?.settings?.max_concurrency;
  return (
    <Flex
      flexDirection={'column'}
      sx={{
        gap: 2,
        alignItems: 'flex-start',
        flexDirection: 'column',
        flex: 1
      }}>
      <Typography fontWeight={500}>Usage Statistics</Typography>
      <Stack
        gap={2}
        sx={{
          borderRadius: 3,
          background: theme.palette.background.reportCard,
          width: '100%',
          padding: '15px 40px'
        }}>
        <Typography fontWeight={600} color={'primary'}>
          Web
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gap: '25px',
            gridTemplateColumns: '1.7fr 1.3fr',
            gridTemplateRows: '200px 200px'
          }}>
          <UsageCardItem
            title="Remote Runs"
            icon={<BrowserSvg height={28} width={30} fill={'#24C3D9'} />}
            numerator={remoteRuns}
            denominator={allowedRemoteRuns}
          />
          <UsageCardItem
            title="No. of Users"
            icon={<UsersSvg height={28} width={30} />}
            numerator={noOfUsers}
            denominator={allowedNoOfUsers}
          />
          <UsageCardItem
            title="No. of Projects"
            icon={<StackSvg height={28} width={30} />}
            numerator={noOfProjects}
            denominator={allowedNoOfProjects}
          />
          <UsageCardItem
            numerator={parallelRuns}
            title="Parallel Runs"
            icon={<BrowserSvg height={28} width={30} fill={'#24C3D9'} />}
          />
        </Box>
      </Stack>
    </Flex>
  );
};

const UsageCardItem = ({ title, icon, text, numerator, denominator }) => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent={'space-around'}
      sx={{
        background: theme.palette.background.light_dark,
        borderRadius: 3,
        py: '2rem',
        pt: '3rem',
        pl: '3rem',
        fontSize: 40
      }}>
      {/* <Box> */}
      <Typography
        sx={{
          width: 'fit-content',
          background: theme.palette.background.reportCard,
          px: 1,
          pt: 0.5,
          borderRadius: 1
        }}>
        {icon}
      </Typography>
      {/* </Box> */}
      <Typography fontSize={14}>{title}</Typography>
      <Typography fontWeight={600} fontSize={36} color={'primary'}>
        {numerator}
        {denominator && (
          <Typography fontSize={36} display={'inline'}>
            {`/${denominator}`}
          </Typography>
        )}
      </Typography>
    </Stack>
  );
};

export default UsageStatistics;
