import { Divider, Stack, Typography } from '@mui/material/node';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import OutlinedButton from '../../../components/base/OutlinedButton';
import Flex from '../../../components/base/Flex';
import GreenCheck from '../../../components/svg/GreenCheck';

import { useContext } from 'react';
import UserContext from '../../../store/userState';
import { capitalize } from 'lodash';
import { useTheme } from '@emotion/react';
import { RedCloseSvg } from '../../../components/svg/RedCloseSvg';
import SubscriptionFeaturesModal from '../modals/SubscriptionFeaturesModal';
import useAwaitModal from '../../../hooks/useAwaitModal';
export const AccountDetails = ({ companyData }) => {
  const theme = useTheme();
  const { userData } = useContext(UserContext);
  // console.log(companyData);
  const [openSubFeaturesModal, { open: isSubFeaturesModalOpen, onClose: closeSubFeaturesModal }] =
    useAwaitModal();
  let status = userData?.user?.id === companyData?.user_id ? 'Organization Owner' : 'Member';
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={2}
      sx={{
        flex: 1
      }}>
      {/* <Stack>
      {/* <Stack>
        <Typography fontWeight={600} fontSize={36}>
          {capitalize(userData?.user?.name)}
        </Typography>
        <Flex>
          <Typography fontSize={18}>{companyData?.name}.</Typography>
          <Typography
            sx={{
              background: theme.palette.background.purple,
              py: 0.8,
              px: 1.5,
              borderRadius: 5
            }}
            fontSize={9}>
            {status}
          </Typography>
        </Flex>
      </Stack> */}
      <Stack width={'100%'}></Stack>
      <Stack width={'100%'}>
        <Typography fontWeight={500} fontSize={14}>
          Plan Type
        </Typography>
        <Flex>
          <Typography color={theme.palette.text.green} fontWeight={600} fontSize={36}>
            {companyData?.active_subscription_plan || 'Free'}
          </Typography>
          {/* {status === 'Organization Owner' && (
            <OutlinedButton
              onClick={openSubFeaturesModal}
              sx={{
                ml: 'auto',
                px: 3,
                width: 'fit-content',
                borderRadius: '4px'
              }}
              marginTop={4}>
              Upgrade
            </OutlinedButton>
          )} */}
        </Flex>
      </Stack>
      <SubscriptionList companyData={companyData} />
      <SubscriptionFeaturesModal
        isSubFeaturesModalOpen={isSubFeaturesModalOpen}
        closeSubFeaturesModal={closeSubFeaturesModal}
      />
    </Flex>
  );
};

const SubscriptionList = ({ companyData }) => {
  // console.log(companyData);
  const allowedNoOfUsers = companyData?.settings?.max_allowed_users;
  const allowedNoOfProjects = companyData?.settings?.max_allowed_projects;
  const allowedRemoteRuns = companyData.usage?.allowedRemoteRuns;
  const parallelRuns = companyData?.settings?.max_concurrency;
  const sampleArray = [
    { text: 'Users per Organization', value: allowedNoOfUsers },
    { text: 'Projects', value: allowedNoOfProjects },
    { text: 'Test Cases', value: 'Unlimited' },
    { text: 'Local Executions', value: 'Unlimited' },
    { text: 'Monthly Remote Runs', value: allowedRemoteRuns },
    { text: 'Serial Execution', value: <GreenCheck /> },
    { text: 'Parallel Executions', value: parallelRuns },
    { text: 'Scandium Cloud Runner', value: <GreenCheck /> },
    { text: 'CI/CD Integrations', value: <GreenCheck /> },
    { text: 'Self-help (Documentation)', value: <GreenCheck /> },
    { text: 'Data Retention', value: '60 days' },
    { text: 'Api Testing', value: <GreenCheck /> },
    { text: 'Email Testing', value: <GreenCheck /> },
    { text: 'Live Support', value: <GreenCheck /> }
    // { text: 'Mobile App Test Runs', value: '200mins/month' },
    // { text: 'Turbo mode for faster executions', value: <RedCloseSvg /> },
    // { text: 'Activity Audit Logs', value: <RedCloseSvg /> },
    // { text: 'Web Accessibility Testing', value: <RedCloseSvg /> }
  ];
  return (
    <CustomScrollbar
      width={'100%'}
      paddingRight={2}
      paddingBottom={4}
      maxHeight={400}
      sx={{
        overflowY: 'scroll'
      }}>
      {sampleArray.map((item) => {
        return <SubscriptionItem name={item.text} value={item.value} />;
      })}
    </CustomScrollbar>
  );
};
const SubscriptionItem = ({ name, value }) => {
  return (
    <Stack gap={1} paddingTop={1}>
      <Flex justifyItems={'center'} alignItems={'center'}>
        <Typography>{name}</Typography>
        <Typography marginBottom={0} marginLeft={'auto'}>
          {value}
        </Typography>
      </Flex>
      <Divider flexItem />
    </Stack>
  );
};
