import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../../components/base/Flex';
import OutlinedButton from '../../../../components/base/OutlinedButton';
import ContainedButton from '../../../../components/base/ContainedButton';
import CustomModal from '../../../../components/base/CustomModal';
import React, { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
import { ApiTestContext } from '../../../../store/apiState/apiTestContext';
import useFetchApiFoldersAndTests from '../../../../hooks/apiHooks/queryHooks/useFetchFoldersAndTests';

const RenameTestModal = () => {
  const { openRenameTestModal, closeRenameTestModal } = useContext(ApiDialogsAndModalsContext);
  const {
    foldersState: { clickedTest }
  } = useContext(ApiTestContext);
  const { refetchFoldersAndTests } = useFetchApiFoldersAndTests();
  //////////////////////////////////////////////////////////
  const { folderId } = useParams();
  const [name, setName] = React.useState(clickedTest?.name);
  const activeProject = useActiveProject();

  const { mutateAsync: updateTestName, isLoading: isUpdatingTest } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest?.id}`,
    {
      enabled: !!activeProject?.id,
      method: 'PUT',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Test name updated successfully');
        refetchFoldersAndTests();
        closeRenameTestModal();
      }
    }
  );
  const handleUpdateTestName = async (e) => {
    if (!name) {
      toast.error('Test name is required');
      return;
    }
    const testData = {
      name
    };
    if (folderId) testData['folder_id'] = folderId;
    await updateTestName(testData);
  };
  useEffect(() => {
    setName(clickedTest.name);
  }, [clickedTest]);
  return (
    <CustomModal open={openRenameTestModal}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={closeRenameTestModal}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <>
          <Typography
            as={'h4'}
            color="primary"
            sx={{
              fontSize: '1.4rem'
            }}>
            Rename Test
          </Typography>
          <Typography
            as={'h4'}
            mb={4}
            sx={{
              fontSize: '0.9rem'
            }}></Typography>
          <Box mb={3}>
            <TextField
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              autoFocus
              size={'small'}
              placeholder="Rename Test"
              fullWidth
            />
          </Box>
        </>
        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={closeRenameTestModal}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!name}
            isLoading={isUpdatingTest}
            onClick={handleUpdateTestName}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default RenameTestModal;
