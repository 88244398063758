export const UsersSvg = ({ fill, width = 19, height = 14 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75837 6.44027C2.54289 6.44027 1.5541 5.46047 1.5541 4.25651C1.5541 3.0524 2.54289 2.07259 3.75837 2.07259C4.9737 2.07259 5.96234 3.05225 5.96234 4.25651C5.96234 5.46062 4.9737 6.44027 3.75837 6.44027ZM3.75837 2.68549C2.88077 2.68549 2.16701 3.39027 2.16701 4.25651C2.16701 5.12259 2.88077 5.82737 3.75837 5.82737C4.63568 5.82737 5.34944 5.12259 5.34944 4.25651C5.34944 3.39027 4.63568 2.68549 3.75837 2.68549Z"
        fill="#24C3D9"
      />
      <path
        d="M4.64511 12.4867H0.800994C0.359273 12.4867 0 12.1297 0 11.6908V10.6884C0 8.63631 1.68593 6.96669 3.75837 6.96669C4.57343 6.96669 5.34929 7.22107 6.00139 7.70214C6.13756 7.8027 6.16659 7.99453 6.06604 8.13084C5.96548 8.26686 5.7738 8.29589 5.63748 8.19549C5.09147 7.79267 4.44175 7.57959 3.75837 7.57959C2.02396 7.57959 0.612903 8.97433 0.612903 10.6884V11.6908C0.612903 11.7917 0.697297 11.8738 0.800994 11.8738H4.64526C4.81434 11.8738 4.95171 12.011 4.95171 12.1803C4.95171 12.3493 4.81434 12.4867 4.64511 12.4867Z"
        fill="#24C3D9"
      />
      <path
        d="M18.199 12.2548H14.3549C14.1858 12.2548 14.0484 12.1176 14.0484 11.9483C14.0484 11.7792 14.1858 11.6419 14.3549 11.6419H18.1992C18.3029 11.6419 18.3872 11.5599 18.3872 11.459V10.4566C18.3872 8.74225 16.9762 7.34781 15.2418 7.34781C14.5174 7.34781 13.8102 7.59725 13.2504 8.05034C13.1191 8.15673 12.9262 8.13653 12.8195 8.005C12.7131 7.87347 12.7332 7.68044 12.865 7.5739C13.5334 7.03297 14.3775 6.7349 15.2418 6.7349C17.3141 6.7349 19 8.40438 19 10.4566V11.459C19 11.8977 18.6407 12.2548 18.199 12.2548Z"
        fill="#24C3D9"
      />
      <path
        d="M15.2416 6.28884C14.0263 6.28884 13.0377 5.30918 13.0377 4.10508C13.0377 2.90097 14.0263 1.92116 15.2416 1.92116C16.4571 1.92116 17.4459 2.90082 17.4459 4.10508C17.4459 5.30933 16.457 6.28884 15.2416 6.28884ZM15.2416 2.53406C14.3643 2.53406 13.6506 3.23884 13.6506 4.10508C13.6506 4.97131 14.3643 5.67594 15.2416 5.67594C16.1192 5.67594 16.833 4.97131 16.833 4.10508C16.833 3.23884 16.1191 2.53406 15.2416 2.53406Z"
        fill="#24C3D9"
      />
      <path
        d="M13.6592 13.4839H5.34076C4.78816 13.4839 4.33865 13.0377 4.33865 12.4891V11.0792C4.33865 8.26162 6.6538 5.96937 9.5 5.96937C12.3462 5.96937 14.6613 8.26162 14.6613 11.0792V12.4891C14.6613 13.0377 14.2118 13.4839 13.6592 13.4839ZM9.5 6.58243C6.99198 6.58243 4.95156 8.5998 4.95156 11.0794V12.4893C4.95156 12.6998 5.12618 12.8711 5.34076 12.8711H13.6591C13.8737 12.8711 14.0483 12.6998 14.0483 12.4893V11.0794C14.0484 8.5998 12.008 6.58243 9.5 6.58243Z"
        fill="#24C3D9"
      />
      <path
        d="M9.39346 5.6113C7.83187 5.6113 6.56118 4.35272 6.56118 2.8055C6.56118 1.25843 7.83172 0 9.39346 0C10.9553 0 12.2259 1.25858 12.2259 2.80565C12.2259 4.35287 10.9552 5.6113 9.39346 5.6113ZM9.39346 0.612903C8.16975 0.612903 7.17408 1.5966 7.17408 2.80565C7.17408 4.01485 8.16975 4.99854 9.39346 4.99854C10.6173 4.99854 11.613 4.01485 11.613 2.80565C11.613 1.5966 10.6173 0.612903 9.39346 0.612903Z"
        fill={fill || '#24C3D9'}
      />
    </svg>
  );
};
