import { pluralize } from './strings';

export const getDuration = (duration) => {
  const { s, m, h } = splitDuration(duration);
  return `${`${h}`.padStart(2, '0')}:${`${m}`.padStart(2, '0')}:${`${s}`.padStart(2, '0')}`;
};

export const splitDuration = (duration) => {
  const millisecs = duration % 1000;
  const totalSecs = Math.round(duration / 1000);
  const secs = totalSecs % 60;
  const totalMins = Math.floor(totalSecs / 60);
  const mins = totalMins % 60;
  const hours = Math.floor(totalMins / 60);

  return { ms: millisecs, s: secs, m: mins, h: hours };
};

export const getMoreReadableDuration = (duration) => {
  const { ms, s, m, h } = splitDuration(duration);
  return `${h ? `${h}${pluralize(h, 'hr')} ` : ''}${m ? `${m}${pluralize(m, 'min')} ` : ''}${
    s ? `${s}${pluralize(s, 'sec')} ` : ''
  }${ms ? `${ms}ms` : ''}`;
};

export const getDateTime = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const formattedDate = inputDate.toLocaleString('en-US', options);
  return formattedDate;
};

export const formatMailDateTime = (inputDateString) => {
  const inputDate = new Date(inputDateString);
  const currentDate = new Date();

  // Check if the date is from the current day
  if (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  ) {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };

    return inputDate.toLocaleString('en-US', options);
  } else {
    const options = {
      month: 'short',
      day: 'numeric'
    };

    return inputDate.toLocaleString('en-US', options);
  }
};
// 3000ms
export const convertTimeToSeconds = (timeInMilliSeconds = 0) => {
  let time;
  if (timeInMilliSeconds >= 1000) {
    time = `${(timeInMilliSeconds / 1000).toFixed(2)} seconds`;
  } else {
    time = `${Number(timeInMilliSeconds).toFixed(2)} ms`;
  }
  return time;
};
