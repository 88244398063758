import { useEffect, useCallback, useState, useRef } from 'react';
import { Box, Tab, Tabs, Typography, IconButton, Tooltip, Badge } from '@mui/material';
import { BsRecordCircle } from 'react-icons/bs';
import { v4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { useFetchFolders } from '../../data-layer/project-management';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import jsFileDownload from 'js-file-download';
import Flex from '../../components/base/Flex';
import ContainedButton from '../../components/base/ContainedButton';
import DeviceConfig from './components/DeviceConfig';
import OutlinedButton from '../../components/base/OutlinedButton';
import SaveSvg from '../../components/svg/SaveSvg';
import { tabStyle } from './Mobile';
import TextButton from '../../components/base/TextButton';
import ReplaySvg from '../../components/svg/ReplaySvg';
import ClearSvg from '../../components/svg/ClearSvg';
import Widgets from './components/Widgets';
import ListSteps from './components/StepsListView';
import GridViewSvg from '../../components/svg/GridViewSvg';
import ListViewSvg from '../../components/svg/ListViewSvg';
import StepsGridView from './components/StepsGridView';
import NetworkLogsTable from './components/NetworkLogsTable';
import { toast } from 'react-toastify';
import StopSvg from '../../components/svg/StopSvg';
import ConsoleLogs from './components/ConsoleLogs';
import RestartSvg from '../../components/svg/ReStartSvg';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import useAwaitModal from '../../hooks/useAwaitModal';
import SaveNewTest from './components/SaveNewTest';
import { useActiveProject } from '../../store/projectState';
import PageLoader from '../../components/PageLoader';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useBlocker from '../../hooks/useBlocker';
import { getCurrentDate } from '../TestPage/utils';
import { useSaveTestRun, useSaveTestSteps } from '../../data-layer/test-management';
import ErrorState from '../../components/base/ErrorState';
import EmptyState from '../../components/base/EmptyState';
import EditTestMenu from './components/EditTestMenu';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { BulkStepActionsTrigger } from './components/BulkStepActions';
import AppSource from './components/AppSource';
import AssertionModal from './components/AssertionModal';
import ErrorBoundary from '../../components/base/ErrorBoundary';
import {
  generateDateOffset,
  generateDatetimeOffset,
  generateRandomAlpha,
  generateRandomAlphanumeric,
  generateRandomInRange,
  generateRandomNumber,
  generateTimeOffset,
  generateRandomBoolean
} from './utils';
import { androidDevices, iOSDevices } from './constant';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';
const variableRegex = /{{(\w+)\((.*)\)}}/;

const variableHandlers = {
  boolean: () => generateRandomBoolean(),
  time: (args) => generateTimeOffset(parseInt(args[0], 10)),
  num: (args) => generateRandomNumber(parseInt(args[0], 10)),
  range: (args) => generateRandomInRange(parseInt(args[0], 10), parseInt(args[1], 10)),
  alpha: (args) => generateRandomAlpha(parseInt(args[0], 10), args[1]),
  alphanum: (args) => generateRandomAlphanumeric(parseInt(args[0], 10), args[1]),
  date: (args) => generateDateOffset(parseInt(args[0], 10), args[1]),
  datetime: (args) => generateDatetimeOffset(parseInt(args[0], 10))
};

const generateVariableValue = (variable) => {
  const match = variable.match(variableRegex);
  if (!match) {
    return variable;
  }
  const [, type, argsString] = match;
  const args = argsString.split(',').map((arg) => arg.trim());
  const handler = variableHandlers[type];

  if (!handler) {
    toast.warn(`Unknown variable type - ${type}`);
  }

  const interpolationOccurred = handler ? true : false;

  return {
    value: handler ? handler(args) : variable,
    interpolationOccurred
  };
};

export const processVariableStringForApi = (str) => {
  return str.replace(variableRegex, generateVariableValue(str).value);
};

export const processVariableString = (str) => {
  const interpolatedValues = str.match(/{{[^}]+}}/g) || [];
  const processedStr = str.replace(/{{[^}]+}}/g, (match) => {
    const { value, interpolationOccurred } = generateVariableValue(match);
    if (interpolationOccurred) {
      interpolatedValues.push(value);
    }
    return value;
  });

  return {
    value: processedStr,
    interpolatedValues
  };
};

const MobileTestPage = ({ previewMode }) => {
  const theme = useTheme();
  const { publicKey, testId, folderId, projectId, batchId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const platform = queryParams.get('platform') || 'ios';

  const platformDropdownOptions = platform === 'ios' ? iOSDevices : androidDevices;
  const _defaultOs = platformDropdownOptions[0].os[platformDropdownOptions[0].os.length - 1];

  const [defaultDeviceIdentifier, setDefaultDeviceIdentifier] = useState(
    platformDropdownOptions[0].device_identifier
  );

  const [savedTest, setSavedTest] = useState(null);
  const [defaultOS, setDefaultOS] = useState(savedTest?.operating_system || _defaultOs);

  const [sessionActive, setSessionActive] = useState(false);
  const [step_timeout, setStepTimeout] = useState(15000);
  const [pre_step_delay, setPreStepDelay] = useState(1000);
  const [isUnsaved, setUnsaved] = useState(false);
  const [isUnsavedRun, setUnsavedRun] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(platformDropdownOptions[0]);
  const [osVersion, setOsVersion] = useState(defaultOS);
  const [orientation, setOrientation] = useState('portrait');
  const [selectedTab, setSelectedTab] = useState(0);
  const [viewType, setViewType] = useState('list');
  const [openRow, setOpenRow] = useState(null);
  const [actions, setActions] = useState([]);
  const [playbackEvents, setPlaybackEvents] = useState(null);
  const [networkLogs, setNetworkLogs] = useState([]);
  const [consoleLogs, setConsoleLogs] = useState([]);
  const [session, setSession] = useState(null);
  const [client, setClient] = useState(null);
  const [isReplaying, setIsReplaying] = useState(false);
  const [appSource, setAppSource] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [isInspecting, setIsInspecting] = useState(false);
  const [assertionMode, setAssertionMode] = useState(false);
  const startedReplayAtRef = useRef(null);
  const assertionModeRef = useRef(false);
  const endReplayAtRef = useRef(null);
  const iframeRef = useRef(null);
  const insertEventsAtRef = useRef(null);
  const startRecordingRef = useRef(null);
  const assertErrorRef = useRef(false);

  const [selectedStepIndexes, setSelectedStepIndexes] = useState([]);
  const selectedSteps = actions.filter((_, index) => selectedStepIndexes.includes(index));

  const activeProject = useActiveProject();
  const { isFeatureEnabled: isMobileTestingEnabled } = useFeatureEnabled('mobile-app-testing');

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const toggleAssertionModeRef = () => {
    assertionModeRef.current = !assertionModeRef.current;
  };

  useDocumentTitle(testId ? `Edit Test${savedTest?.id ? `: ${savedTest.name}` : ''}` : 'New Test');

  const [
    requestSaveTestModal,
    { open: openSaveTestModal, onClose: onCloseSaveTestModal, onComplete: completeSaveTestModal }
  ] = useAwaitModal();

  const [
    requestAssertionModal,
    { open: openAssertionModal, onClose: onCloseAssertionModal, onComplete: completeAssertionModal }
  ] = useAwaitModal();

  const { mutateAsync: saveTest, isLoading: isSavingTest } = useScandiumMutation(
    `/projects/${activeProject?.id}/mobile-tests/apps/${publicKey}/test-cases/${
      savedTest?.id || ''
    }`,
    {
      enabled: !!activeProject?.id,
      method: savedTest?.id ? 'PATCH' : 'POST',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        setSavedTest(data.data);
        openSaveTestModal && completeSaveTestModal();

        toast.success(data.message);

        trackMixPanel(TRACKING_IDS.TEST_SAVED, {
          type: 'mobile'
        });
      }
    }
  );

  const { data: activeFolder } = useFetchFolders(activeProject?.id, {
    folderId,
    enabled: !!activeProject?.id && !!folderId,
    select: (data) => data.data
  });

  const { mutateAsync: _saveTestSteps, isLoading: isSavingSteps } = useSaveTestSteps();
  const saveTestSteps = async (data) => {
    try {
      await _saveTestSteps(data);
      setUnsaved(false);
    } catch (e) {
      setUnsaved(true);
      toast.error('Failed to save test steps. Please try again');
    }
  };

  const { mutateAsync: _saveTestRun } = useSaveTestRun();
  const saveTestRun = async (testId, { startTime, endTime, results } = {}) => {
    const ranEvents = results || playbackEvents || [];
    if (!ranEvents.length) return;

    try {
      const __appetizeClient = await window.appetize.getClient('#appetize');
      const {
        name: device_name,
        orientation,
        osVersion: operating_system
      } = __appetizeClient?.device || {};

      await _saveTestRun({
        testId,
        projectId,
        folderId,
        data: {
          test_runs: ranEvents.map((event) => ({
            step_id: event.id,
            ...event,
            time_taken: event.duration
          })),
          started_at: startTime,
          finished_at: endTime,
          operating_system: operating_system,
          orientation,
          device_name,
          duration: endTime - startTime,
          platform,
          publicKey,
          build_number: null,
          status: ranEvents.some((event) => event.status === 'error') ? 'error' : 'success'
        }
      });
      setUnsavedRun(false);
      trackMixPanel(TRACKING_IDS.TEST_RUN, {
        saved: true,
        type: 'mobile',
        duration: endTime - startTime
      });
    } catch (e) {
      setUnsavedRun(true);
      toast.error('Failed to save test run. Please try again by clicking the save button');
      trackMixPanel(TRACKING_IDS.TEST_RUN, {
        saved: false,
        type: 'mobile',
        duration: endTime - startTime
      });
    }
  };

  const updateActionsWithDefaults = (actionsArray) => {
    return actionsArray.map((action) => {
      if (!action.hasOwnProperty('step_delay')) {
        action.step_delay = 1000;
      }
      if (!action.hasOwnProperty('timeout')) {
        action.timeout = 15000;
      }
      return action;
    });
  };

  const {
    data: testResponse,
    error: fetchTestError,
    isLoading: isFetchingTest,
    refetch: silentRefetchTest
  } = useScandiumQuery(
    `/projects/${activeProject?.id}/mobile-tests/apps/${publicKey}/test-cases/${testId}`,
    {
      enabled: !!activeProject?.id && !!publicKey && !!testId && !!isMobileTestingEnabled,
      onSuccess: (data) => {
        setSavedTest(data.data);
        setName(data.data.name);
        setStepTimeout(data.data?.step_timeout || 0);
        setPreStepDelay(data.data?.pre_step_delay || 0);
        setDescription(data.data.description);
        setDefaultOS(data.data.operating_system);
        setOsVersion(data.data.operating_system);
        setOrientation(data.data.orientation);
        setDefaultDeviceIdentifier(data.data.device_name);

        const defaultSelectedDevice = platformDropdownOptions.find(
          (device) => device.device_identifier === data.data.device_name
        );
        setSelectedDevice(defaultSelectedDevice);

        if (!actions.length && !!data.data.steps) {
          const __events = processKeyPressEvents(
            data.data.steps[data.data.steps.length - 1]?.meta || []
          );
          const updatedActions = updateActionsWithDefaults(__events);
          setActions(updatedActions);
        }
      }
    }
  );

  const testData = testResponse?.data;

  const {
    data: previousRun,
    isLoading: isFetchingRuns,
    refetch: refetchPreviousRuns
  } = useScandiumQuery(`/projects/${activeProject?.id}/test-runs/${batchId}`, {
    enabled: !!activeProject?.id && !!testId && !!batchId,
    select: (data) => data?.data
  });

  // Navigate away when unsaved or unsavedRun changes to false
  useEffect(() => {
    if (!isUnsaved && !isUnsavedRun) {
      // If there is no testId in the url
      if (!testId && !!savedTest && client) {
        if (folderId) {
          navigate(
            `/projects/${activeProject?.id}/mobile-testing/${publicKey}/${savedTest?.id}/${folderId}/edit?platform=${platform}`
          );
        } else {
          navigate(
            `/projects/${activeProject?.id}/mobile-testing/${publicKey}/${savedTest?.id}/edit?platform=${platform}`
          );
        }
      }
    }
  }, [isUnsaved, testId, isUnsavedRun, savedTest]);

  const onSaveTestCase = async () => {
    if (!name) {
      toast.error('Please provide a test name');
    }

    const appetizeClient = await window.appetize.getClient('#appetize');

    const {
      type: device_name,
      orientation,
      osVersion: operating_system
    } = appetizeClient.device || {};

    const testData = {
      name: name || savedTest?.name,
      description: description || savedTest?.description,
      device_name,
      orientation,
      operating_system,
      step_timeout,
      pre_step_delay,
      ...(folderId && { folder_id: +folderId })
    };

    const data = await saveTest(testData);

    // if there are no events, the save is complete
    if (!actions.length) setUnsaved(false);

    await Promise.all([
      actions.length &&
        saveTestSteps({
          testId: data.data.id,
          folderId,
          projectId: activeProject.id,
          data: {
            test_steps: actions.map((event) => ({
              ...event,
              error: undefined,
              status: undefined,
              variableValue: undefined
            }))
          }
        }),
      data?.data?.id &&
        isUnsavedRun &&
        startedReplayAtRef.current &&
        endReplayAtRef.current &&
        saveTestRun(data.data.id, {
          endTime: endReplayAtRef.current,
          startTime: startedReplayAtRef.current
        })
    ]);
  };

  const handleSaveTest = async () => {
    if (testId) {
      onSaveTestCase();
    } else {
      requestSaveTestModal();
    }
  };

  const clearAppState = () => {
    setActions([]);
    setConsoleLogs([]);
    setNetworkLogs([]);
  };

  const handleClearActions = () => {
    if (actions?.length || consoleLogs?.length || networkLogs?.length) {
      const confirmChange = window.confirm('Clear all recorded actions?');
      if (confirmChange) {
        clearAppState();
      }
    } else clearAppState();

    setUnsaved(true);
  };

  const MESSAGE_TYPES = {
    SESSION_REQUESTED: 'sessionRequested',
    SESSION_ENDED: 'sessionEnded'
  };

  const handleSessionRequested = () => {
    setSessionActive(true);
  };

  const handleSessionEnded = () => {
    setSessionActive(false);
    setIsReplaying(false);
    insertEventsAtRef.current = null;
    startRecordingRef.current = null;
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === 'https://appetize.io') {
        const messageData = event.data;
        switch (messageData) {
          // case MESSAGE_TYPES.SESSION_REQUESTED:
          //   handleSessionRequested();
          //   break;
          case MESSAGE_TYPES.SESSION_ENDED:
            handleSessionEnded();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const insertItemAt = (index, newArray, newItem) => {
    if (index < 0 || index > newArray.length) {
      toast.error('Invalid index');
      return newArray;
    }

    newArray.splice(index, 0, newItem);

    return newArray;
  };

  const setupSessionListeners = useCallback((session) => {
    const actionHandler = (action) => {
      if (!startRecordingRef.current || !!batchId) return;

      const id = v4();
      const actionWithId = { ...action, id, timeout: 15000, step_delay: 1000 };

      if (action?.element) {
        setSelectedElement(action.element);
      }

      if (!!assertionModeRef.current) {
        requestAssertionModal();
        return;
      }

      setActions((prevActions) => {
        let newActions;

        const actionAtIndex = prevActions[insertEventsAtRef?.current];

        if (insertEventsAtRef.current !== null) {
          if (actionAtIndex?.type === 'type' && actionWithId.type === 'keypress') {
            const insertIndex = insertEventsAtRef.current + 1;
            newActions = processKeyPressEvents([
              ...insertItemAt(insertIndex, prevActions, actionWithId)
            ]);
          } else {
            const insertIndex = insertEventsAtRef.current + 1;
            newActions = processKeyPressEvents([
              ...insertItemAt(insertIndex, prevActions, actionWithId)
            ]);
            insertEventsAtRef.current += 1;
          }
        } else {
          newActions = [...processKeyPressEvents([...prevActions, actionWithId])];
        }

        return newActions;
      });

      setUnsaved(true);
    };

    const networkHandler = (data) => {
      if (data.request && data.response) {
        setNetworkLogs((prevNetworkLogs) => [...prevNetworkLogs, data]);
      }
    };

    const logHandler = (data) => {
      setConsoleLogs((prevLogs) => [...prevLogs, data.message]);
    };

    const inActivityHandler = (data) => {
      toast.warning(
        'Session is about to timeout due to inactivity. Any user interaction or a heartbeat will reset the timeout.'
      );
    };

    const errorHandler = (error) => {
      toast.error('An error occurred');
    };

    session.on('action', actionHandler);
    session.on('network', networkHandler);
    session.on('log', logHandler);
    session.on('inactivityWarning', inActivityHandler);
    session.on('error', errorHandler);
  }, []);

  useEffect(() => {
    const initializeAppetize = async () => {
      try {
        const appetizeClient = await window.appetize.getClient('#appetize');
        setClient(appetizeClient);

        configureAppetizeClient(appetizeClient);
      } catch (error) {
        toast.error('Error initializing emulator');
        console.error(error);
      }
    };

    const configureAppetizeClient = (client) => {
      client.on('session', (session) => {
        setupSessionListeners(session);
        setSession(session);
      });

      client.on('error', (error) => {
        toast.error(error?.message);
        console.error(error);
      });

      client.on('sessionRequested', (data) => {
        handleSessionRequested();
      });
    };

    if (iframeRef.current) {
      initializeAppetize();
    }
  }, []);

  const handleBeforeUnload = (e) => {
    if (!isUnsaved || !isUnsavedRun || !isReplaying) return undefined;
    const warning =
      'Your app is in session, and you might have unsaved changes. Are you sure you want to leave this page?';
    e.returnValue = warning;
    return warning;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUnsaved, isUnsavedRun, isReplaying]);

  const toggleView = () => {
    setViewType(viewType === 'list' ? 'grid' : 'list');
  };

  useBlocker(
    'Your app is in session, and you might have unsaved changes. Are you sure you want to leave this page',
    !!(isReplaying || isUnsaved || isUnsavedRun)
  );

  async function updateConfig(originalConfig, update) {
    const appetizeClient = await window.appetize.getClient('#appetize');

    const updatedConfig = { ...originalConfig, ...update };

    try {
      if (!client) {
        await appetizeClient.setConfig(updatedConfig);
        setClient(appetizeClient);
      } else {
        await client.setConfig(updatedConfig);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const startSession = async () => {
    const appetizeClient = await window.appetize.getClient('#appetize');

    startRecordingRef.current = true;

    trackMixPanel(TRACKING_IDS.RECORDING_STARTED, {
      type: 'mobile'
    });

    try {
      if (!client) {
        setClient(appetizeClient);
        const appetizeSession = await appetizeClient.startSession();
        setSession(appetizeSession);
      } else {
        const appetizeSession = await client.startSession();
        setSession(appetizeSession);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleEndSession = async () => {
    if (session) {
      try {
        await session.end();
        toast.success('Session ended! 🛑');
        setSessionActive(false);
      } catch (e) {
        toast.error(e?.message || 'Sorry an error occurred');
      }
    }

    trackMixPanel(TRACKING_IDS.RECORDING_STOPPED, {
      type: 'mobile'
    });
  };

  // Function to handle error during playback
  const handlePlaybackError = async (errorAction, errorMessage, updatedActions) => {
    if (!errorAction?.type) return;

    const errorMap = {
      continue: 'error',
      ignore: 'ignored',
      fail: 'error'
    };

    const errorIndex = actions.findIndex((recordedAction) => {
      return (
        recordedAction.id === errorAction.id ||
        (recordedAction.type === errorAction.type &&
          recordedAction.element?.path === errorAction.element?.path)
      );
    });

    const onError = errorAction.onError || 'fail';

    updateActionStatus(errorAction, errorMap[onError]);

    if (onError === 'fail') {
      toast.error(`Test failed and exited on step ${errorIndex + 1}`);
    }

    if (testId && onError === 'fail') {
      await saveTestRun(testId, {
        endTime: endReplayAtRef.current,
        startTime: startedReplayAtRef.current,
        results: updatedActions
      });
    }
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // Helper function
  const updateActionStatus = (targetAction, status, otherProps = {}) => {
    setActions((prevActions) => {
      const updatedActions = [...prevActions];
      const index = updatedActions.findIndex((action) => action.id === targetAction.id);
      if (index !== -1) {
        updatedActions[index] = { ...otherProps, ...updatedActions[index], status };
      }
      setPlaybackEvents(updatedActions);
      return updatedActions;
    });

    setUnsavedRun(true);
  };

  // Function for precondition checks
  const conditionHandlers = {
    'always-run': () => true,
    skip: () => false,
    'component-visible': async (
      _session,
      { conditions: { attribute_property, attribute_value } }
    ) => {
      const element = await _session.findElement({
        attributes: {
          [attribute_property]: attribute_value
        }
      });
      return !!element;
    },
    'component-not-visible': async (
      _session,
      { conditions: { attribute_property, attribute_value } }
    ) => {
      const element = await _session.findElement({
        attributes: {
          [attribute_property]: attribute_value
        }
      });
      return !element;
    },
    'component-contains-text': async (
      _session,
      { conditions: { attribute_property, attribute_value } }
    ) => {
      const element = await _session.findElement({
        attributes: {
          [attribute_property]: attribute_value
        }
      });
      const assertionValue =
        element?.attributes['text'] ||
        element?.attributes['content-desc'] ||
        element?.attributes['accessibilityLabel'];
      return !!assertionValue;
    },
    'component-not-contains-text': async (
      _session,
      { conditions: { attribute_property, attribute_value } }
    ) => {
      const element = await _session.findElement({
        attributes: {
          [attribute_property]: attribute_value
        }
      });
      const assertionValue =
        element?.attributes['text'] ||
        element?.attributes['content-desc'] ||
        element?.attributes['accessibilityLabel'];
      return !assertionValue;
    }
  };

  const checkActionPreconditions = async (action, _session) => {
    try {
      const conditionType = action.conditions?.type || 'always-run';
      const handler = conditionHandlers[conditionType];

      if (!handler) {
        toast.error(`Unsupported condition type: ${conditionType}`);
        throw new Error(`Unsupported condition type: ${conditionType}`);
      }

      return await handler(_session, action);
    } catch (error) {
      console.error('Error checking action preconditions:', error);
      return false;
    }
  };

  // Function to check assertion status
  const assertElementAndValue = async (_session, action) => {
    let element = undefined;
    let assertionResult = false;

    if (action.attributes) {
      for (const attributeType in action.attributes) {
        const attributeValue = action.attributes[attributeType];

        element = await _session.findElement({
          attributes: {
            [attributeType]: attributeValue
          }
        });

        if (element) break;
      }
    }

    if (!element) {
      if (action.subtype === 'component not present') {
        updateActionStatus(action, 'success');
        return;
      } else {
        const error = {
          message: 'Assertion failed: Element not found',
          playback: { action }
        };
        throw error;
      }
    }

    const assertionValue =
      element?.attributes['text'] ||
      element?.attributes['content-desc'] ||
      element?.attributes['accessibilityLabel'];
    if (!assertionValue && (action.assertType === 'text' || action.assertType === 'numeric')) {
      const error = {
        message: 'Assertion failed: Element is present but has no content',
        playback: { action }
      };
      throw error;
    }

    switch (action.subtype) {
      case 'component present':
        if (!element) {
          const error = {
            message: 'Assertion failed: Component not found',
            playback: { action }
          };
          throw error;
        } else {
          assertionResult = true;
        }
        break;

      case 'component not present':
        if (element) {
          const error = {
            message: 'Assertion failed: Component found, but should not be present',
            playback: { action }
          };
          throw error;
        }
        break;
      case 'text has content and should be equal':
        assertionResult = assertionValue === action.expectedValue;
        break;
      case 'text has content and should not be equal':
        assertionResult = assertionValue !== action.expectedValue;
        break;
      case 'text has content and should contain':
        assertionResult = assertionValue.includes(action.expectedValue);
        break;
      case 'text has content and should not contain':
        assertionResult = !assertionValue.includes(action.expectedValue);
        break;
      case 'number equal to':
        assertionResult = parseFloat(assertionValue) === parseFloat(action.expectedValue);
        break;
      case 'number greater than':
        assertionResult = parseFloat(assertionValue) > parseFloat(action.expectedValue);
        break;
      case 'number greater than or equal to':
        assertionResult = parseFloat(assertionValue) >= parseFloat(action.expectedValue);
        break;
      case 'number is less than':
        assertionResult = parseFloat(assertionValue) < parseFloat(action.expectedValue);
        break;
      case 'number is less than or equal to':
        assertionResult = parseFloat(assertionValue) <= parseFloat(action.expectedValue);
        break;
      case 'number is in-between':
        const _assertionValue = parseFloat(
          element?.attributes['text'] ||
            element?.attributes['content-desc'] ||
            element?.attributes['accessibilityLabel']
        );
        if (
          !_assertionValue ||
          _assertionValue < parseFloat(action.rangeStart) ||
          _assertionValue > parseFloat(action.rangeEnd)
        ) {
          const error = {
            message: `Assertion failed: Value should be between ${action.rangeStart} and ${action.rangeEnd} (inclusive), but is ${assertionValue}`,
            playback: { action }
          };
          throw error;
        } else {
          assertionResult = true;
        }
        break;
      default:
        if (!element) {
          const error = {
            message: `Assertion failed: Element not found`,
            playback: { action }
          };
          throw error;
        }
    }

    if (!assertionResult && (action.assertType === 'text' || action.assertType === 'numeric')) {
      const error = {
        message: `Assertion failed: ${action.subtype} - Expected: ${action.expectedValue}, Actual: ${assertionValue}`,
        playback: { action }
      };
      throw error;
    }

    updateActionStatus(action, 'success');
  };

  // New function to encapsulate selector permutation logic
  const tryPlayActionWithAttributes = async (
    _session,
    action,
    timeout,
    updatedActions,
    actionsArray,
    updateStatus = true
  ) => {
    const usedSelectors = new Set(); // Track used selectors to avoid duplicates

    let lastError = null; // Store the last error
    for (const [attributeKey, selectorValue] of Object.entries(action.element.attributes)) {
      if (usedSelectors.has(selectorValue)) {
        continue; // Skip already used selectors
      }
      usedSelectors.add(selectorValue);

      const attributeLength = Object.keys(action.element.attributes).length;
      const retryTimeout = Math.ceil(timeout / attributeLength);

      try {
        await _session.playAction(
          {
            ...action,
            element: {
              ...action.element,
              attributes: {
                [attributeKey]: selectorValue
              }
            }
          },
          { retryTimeout }
        );
        if (updateStatus) {
          updateActionStatus(action, 'success');
          updatedActions[actionsArray.indexOf(action)].status = 'success';
        }
        lastError = null;
        break;
      } catch (err) {
        if (!err.message.includes('No element found for selector')) {
          throw err;
        }

        lastError = err; // Store the last error
      } finally {
      }
    }

    if (lastError && lastError.message.includes('No element found for selector')) {
      throw lastError; // Rethrow the last error if all attempts failed
    }
  };

  const playActionsIndividually = async (_session, actionsArray) => {
    try {
      const updatedActions = [...actionsArray];

      for (const action of actionsArray) {
        try {
          // Precondition check
          const shouldRunAction = await checkActionPreconditions(action, _session);
          if (!shouldRunAction) {
            updateActionStatus(action, 'skipped');
            updatedActions[actionsArray.indexOf(action)].status = 'skipped';
            continue; // Skip to the next iteration
          }

          const timeout = step_timeout > 0 ? step_timeout : action.timeout || 10000;
          const delayValue = pre_step_delay > 0 ? pre_step_delay : action.step_delay || 1000;
          const actionIndex = actionsArray.indexOf(action);

          await delay(delayValue);

          if (action.type === 'type') {
            const { value: processedValue, interpolatedValues } = processVariableString(
              action.value
            );
            await _session.type(processedValue);

            updateActionStatus(action, 'success', {
              variableValue: interpolatedValues.length > 0 ? processedValue : undefined
            });

            updatedActions[actionIndex] = {
              ...action,
              status: 'success',
              variableValue: interpolatedValues.length > 0 ? processedValue : undefined
            };
          } else if (action.type === 'biometry') {
            await _session.biometry({ match: action.value });
            updateActionStatus(action, 'success');
            updatedActions[actionIndex].status = 'success';
          } else if (action.type === 'assert') {
            await assertElementAndValue(_session, action);
            updatedActions[actionIndex].status = 'success';
          } else if (action.type === 'openUrl') {
            await _session.openUrl(action.url);
            updateActionStatus(action, 'success');
            updatedActions[actionIndex].status = 'success';
          } else {
            try {
              await _session.playAction(action, { timeout });
              updateActionStatus(action, 'success');
              updatedActions[actionIndex].status = 'success';
            } catch (error) {
              if (error.message.includes('No element found for selector')) {
                await tryPlayActionWithAttributes(
                  _session,
                  action,
                  timeout,
                  updatedActions,
                  actionsArray
                );
              } else {
                throw error;
              }
            }
          }
        } catch (err) {
          const onError = action.onError || 'fail';

          endReplayAtRef.current = Date.now();
          if (onError === 'fail') {
            toast.error(err.message);
          }

          // Update the action's status based on onError
          updatedActions[actionsArray.indexOf(action)].status =
            onError === 'ignore' ? 'ignored' : 'error';
          updatedActions[actionsArray.indexOf(action)].error = err.message;
          await handlePlaybackError(err.playback?.action, err.message, updatedActions);
          if (onError === 'fail') return;
        }
      }
      endReplayAtRef.current = Date.now();

      const allPassed = updatedActions.every((step) => step.status === 'success');
      const failedCount = updatedActions.filter((step) => step.status === 'error').length;
      const skippedCount = updatedActions.filter((step) => step.status === 'skipped').length;
      const ignoredCount = updatedActions.filter((step) => step.status === 'ignored').length;
      const passCount = updatedActions.filter((step) => step.status === 'success').length;

      if (allPassed) {
        toast.success('Playback completed! All tests passed 🎉');
      } else {
        toast.info(
          `Test complete! ${passCount} steps passed, ${skippedCount} skipped, ${ignoredCount} ignored, ${failedCount} failed`
        );
      }

      if (testId) {
        saveTestRun(testId, {
          endTime: endReplayAtRef.current,
          startTime: startedReplayAtRef.current,
          results: updatedActions
        });
      }
    } catch (err) {
      toast.error('Sorry, an error occurred');
    }
  };

  const replayToStartRecordingAtIndex = async (_session, actionsArray) => {
    for (const action of actionsArray) {
      // Precondition check
      const shouldRunAction = await checkActionPreconditions(action, _session);
      if (!shouldRunAction) {
        continue;
      }

      const timeout = step_timeout > 0 ? step_timeout : action.timeout || 10000;
      const delayValue = pre_step_delay > 0 ? pre_step_delay : action.step_delay || 1000;

      await delay(delayValue);

      if (action.type !== 'assert') {
        // Skip actions with type "assert"
        if (action.type === 'type') {
          const { value: processedValue } = processVariableString(action.value);
          await _session.type(processedValue);
        } else if (action.type === 'biometry') {
          await _session.biometry({ match: action.value });
        } else if (action.type === 'openUrl') {
          await _session.openUrl(action.url);
        } else {
          try {
            await _session.playAction(action, { timeout });
          } catch (error) {
            if (error.message.includes('No element found for selector')) {
              await tryPlayActionWithAttributes(
                _session,
                action,
                timeout,
                undefined,
                undefined,
                false
              );
            } else {
              throw error;
            }
          }
        }
      }
    }
  };

  const runPlayback = async (_recordedActions) => {
    try {
      toast.success('Session playback initiated!');
      const newSession = await client.startSession();
      setSession(newSession);
      setIsReplaying(true);
      startedReplayAtRef.current = Date.now();

      trackMixPanel(TRACKING_IDS.START_REPLAY, {
        type: 'mobile'
      });

      await playActionsIndividually(newSession, _recordedActions);

      endReplayAtRef.current = Date.now();

      setIsReplaying(false);
    } catch (error) {
      setIsReplaying(false);
      endReplayAtRef.current = Date.now();
      toast.error(error.message);
    }
  };

  const handlePlaybackActions = async () => {
    if (isReplaying) return;

    if (!actions?.length) {
      toast.error('No actions to playback!');
      return;
    }

    try {
      const confirmPlayback = window.confirm(
        'This will restart the app and replay the recorded actions'
      );

      if (confirmPlayback) {
        startRecordingRef.current = false;
        assertErrorRef.current = false;

        const recordedActions = actions.map(
          ({ status, error, interpolatedString, ...rest }) => rest
        );

        setActions(recordedActions);
        setPlaybackEvents([]);

        if (sessionActive) {
          await session.reinstallApp();
        } else {
          const appetizeClient = await window.appetize.getClient('#appetize');
          setClient(appetizeClient);
        }

        await runPlayback(recordedActions);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRecordingActionFromIndex = async (insertAtIndex) => {
    if (isReplaying) return;

    const appetizeClient = await window.appetize.getClient('#appetize');
    setClient(appetizeClient);

    try {
      const confirmPlayback = window.confirm(
        'Are you certain you wish to initiate recording from this point? Doing so will reset the application and replay the preceding steps before allowing you to commence recording.'
      );

      if (confirmPlayback) {
        startRecordingRef.current = false;

        const recordedActions = actions.map(({ status, error, ...rest }) => rest);
        const actionsSubArray = recordedActions.slice(0, insertAtIndex + 1);

        if (sessionActive) {
          await session.end();
        }

        toast.success('Session playback initiated!');
        const newSession = await appetizeClient.startSession();
        setSession(newSession);
        setIsReplaying(true);

        await replayToStartRecordingAtIndex(newSession, actionsSubArray);
        setIsReplaying(false);
        startRecordingRef.current = true;
        insertEventsAtRef.current = insertAtIndex || null;
        toast.info('You can now begin your recording.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRestartApp = async () => {
    const shouldRestart = window.confirm(
      'Are you sure you want to clear recording and restart the app?'
    );

    if (shouldRestart && session) {
      clearAppState();
      await session.restartApp();
    }
  };

  const handleInspectToggle = async (showAppSource = false) => {
    try {
      if (!isInspecting || !showAppSource) {
        const ui = await session.getUI();
        setAppSource(ui);
      }
      showAppSource && setIsInspecting(!isInspecting);
    } catch (e) {
      console.error(e);
    }
  };

  const updateActionProperties = (actions, index, newProperties) => {
    // Error handling
    if (!Array.isArray(actions) || typeof index !== 'number' || typeof newProperties !== 'object') {
      throw new TypeError(
        'Invalid input types. Actions must be an array, index must be a number, and newProperties must be an object.'
      );
    }
    if (index < 0 || index >= actions.length) {
      throw new RangeError('Index out of range.');
    }

    // Create a new array with the updated object
    const updatedActions = [
      ...actions.slice(0, index),
      { ...actions[index], ...newProperties },
      ...actions.slice(index + 1)
    ];

    return updatedActions;
  };

  /**
   * ======================
   * Drag and drop handlers
   * ======================
   */

  const reorderTestActions = useCallback(
    (dragIndex, hoverIndex) => {
      if (hoverIndex === dragIndex) return;

      const newActions = actions.map(({ preview, ...rest }) => rest);
      const draggedTest = newActions[dragIndex];

      newActions.splice(dragIndex, 1);
      newActions.splice(hoverIndex, 0, { ...draggedTest, preview: true });
      setActions(newActions);
      setUnsaved(true);
    },
    [actions, setActions, setUnsaved]
  );

  const handleDragEnd = useCallback(() => {
    const newActions = actions.map(({ preview, ...test }) => test);
    setActions(newActions);
    setUnsaved(true);
  }, [actions, setActions, setUnsaved]);

  const findActionIndex = useCallback(
    (id) => {
      return actions.findIndex((action) => action.id === id);
    },
    [actions]
  );

  const errorStep = (playbackEvents || previousRun?.items)
    ?.slice()
    .reverse()
    .find((item) => item.status === 'error');

  const failedStepIndex = findActionIndex(errorStep?.id);

  const handleInputFieldUpdate = (index, property, value) => {
    const updatedActions = updateActionProperties(actions, index, { [property]: value });

    setActions(updatedActions);
    setUnsaved(true);
  };

  const handleExportActions = async () => {
    const recordedActions = actions.map((action) => {
      const { status, errorMessage, ...rest } = action;
      return rest;
    });

    const jsonActions = JSON.stringify(recordedActions, null, 2);
    jsFileDownload(jsonActions, `${getCurrentDate()} actions .json`);
  };

  // Check if actions imported is of a valid format
  const isExpectedFormat = (data) => {
    if (!Array.isArray(data)) {
      return false;
    }

    const isValidElement = (element) => {
      return (
        element &&
        typeof element === 'object' &&
        element.hasOwnProperty('type') &&
        ((element.type === 'tap' &&
          element.hasOwnProperty('element') &&
          element.hasOwnProperty('coordinates') &&
          element.hasOwnProperty('localPosition')) ||
          (element.type === 'swipe' &&
            element.hasOwnProperty('element') &&
            element.hasOwnProperty('coordinates') &&
            element.hasOwnProperty('localPosition')) ||
          (element.type === 'type' && element.hasOwnProperty('value')) ||
          (element.type === 'keypress' &&
            element.hasOwnProperty('key') &&
            element.hasOwnProperty('shiftKey')))
      );
    };

    return data.every(isValidElement);
  };

  const handleActionsImport = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type === 'application/json' || file.name.endsWith('.json')) {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const jsonData = JSON.parse(e.target.result);

            if (isExpectedFormat(jsonData)) {
              setActions(jsonData);
              setUnsaved(true);
            } else {
              toast.error('Invalid JSON file format. Please upload a valid JSON file.');
            }
          } catch (error) {
            toast.error('Error parsing JSON file');
          }
        };

        reader.readAsText(file);
      } else {
        toast.error('Invalid file type. Please upload a JSON file.');
      }
    }
  };

  // handle steps selection
  const toggleStepsSelection = (stepId) => {
    const index = selectedStepIndexes.indexOf(stepId);
    if (index === -1) {
      setSelectedStepIndexes([...selectedStepIndexes, stepId]);
    } else {
      setSelectedStepIndexes(selectedStepIndexes.filter((id) => id !== stepId));
    }
  };

  // select all steps
  const selectAllSteps = () => {
    const allStepIds = actions.map((step) => step.id);
    const allSelected = allStepIds.every((id) => selectedStepIndexes.includes(id));

    if (allSelected) {
      // Currently all are selected -> Deselect all
      setSelectedStepIndexes([]);
    } else {
      // Some or none are selected -> Select all
      setSelectedStepIndexes(allStepIds);
    }
  };

  // Duplicate steps and place the duplicates immediately after the original
  const duplicateSelectedSteps = (steps) => {
    const updatedActions = steps.reduce((acc, currentItem) => {
      acc.push(currentItem);
      if (selectedStepIndexes.includes(currentItem.id)) {
        const duplicatedItem = { ...currentItem, id: v4() };
        acc.push(duplicatedItem);
      }
      return acc;
    }, []);

    setActions(updatedActions);
    setUnsaved(true);
    setSelectedStepIndexes([]);
  };

  const deleteSelectedSteps = (events) => {
    const updatedActions = events.filter((action) => !selectedStepIndexes.includes(action.id));
    setActions(updatedActions);
    setUnsaved(true);
    setSelectedStepIndexes([]);
  };

  const isPageLoading = isFetchingTest || isFetchingRuns;

  if (!isMobileTestingEnabled) {
    return (
      <Box
        sx={{
          marginTop: { xs: '8rem', sm: '12rem' }
        }}>
        <EmptyState
          title={'Access Required for Mobile Testing'}
          maxWidth="50%"
          description={
            "Oops! It seems like you don't have the necessary permissions to access this premium Mobile Testing Page. Upgrade your account to unlock advanced features and elevate your testing experience. Don't miss out on the benefits – secure your premium access now!"
          }
        />
      </Box>
    );
  }

  // if (isPageLoading) return <PageLoader />;
  if (fetchTestError)
    return (
      <Box mt={12}>
        <ErrorState error={fetchTestError} />
      </Box>
    );

  /**
   * This function processes an array of event objects. For each event of type "keypress" with an alphanumeric character,
   */
  const processKeyPressEvents = (events) => {
    let newActions = [];

    for (let i = 0; i < events.length; i++) {
      let currentEvent = events[i];
      if (currentEvent.type === 'keypress') {
        if (/^.$/i.test(currentEvent.character)) {
          if (newActions.length > 0 && newActions[newActions.length - 1].type === 'type') {
            newActions[newActions.length - 1].value += currentEvent.character;
          } else {
            const id = v4();

            newActions.push({
              type: 'type',
              value: currentEvent.character,
              id
            });
          }
        } else if (currentEvent.character === 'Backspace') {
          if (newActions.length > 0 && newActions[newActions.length - 1].type === 'type') {
            newActions[newActions.length - 1].value = newActions[newActions.length - 1].value.slice(
              0,
              -1
            );
          }
        } else {
          newActions.push(currentEvent);
        }
      } else {
        newActions.push(currentEvent);
      }
    }
    return newActions;
  };

  const convertPressEvents = (events) => {
    const convertedEvents = [];

    for (const event of events) {
      if (event.type === 'type') {
        const value = event.value;
        const _id = event.id;
        const startTime = convertedEvents[convertedEvents.length - 1].time + 1;

        for (let i = 0; i < value.length; i++) {
          const character = value[i];
          const id = _id;

          const keypressEvent = {
            type: 'keypress',
            character: character,
            shiftKey: false,
            time: startTime + i * 0.65, // Increase time progressively
            id
          };

          convertedEvents.push(keypressEvent);
        }
      } else {
        convertedEvents.push(event);
      }
    }

    return convertedEvents;
  };

  return (
    <ErrorBoundary>
      <Box
        sx={{
          mt: '9rem',
          ml: { xs: '1.5rem', sm: '3rem', md: '4rem' },
          mr: { xs: '1.5rem', sm: '2rem', md: '2rem' }
        }}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}>
          <DeviceConfig
            platformDropdownOptions={platformDropdownOptions}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            osVersion={osVersion}
            setOsVersion={setOsVersion}
            orientation={orientation}
            setOrientation={setOrientation}
            updateConfig={updateConfig}
            sessionActive={sessionActive}
            session={session}
            activeFolder={activeFolder}
            name={name}
            client={client}
            testResponse={testResponse}
          />

          <Flex alignItems={'flex-start'}>
            <Tooltip title={'Restart the app'}>
              <IconButton
                onClick={handleRestartApp}
                size={'small'}
                disabled={!sessionActive || !!previewMode}>
                <RestartSvg />
              </IconButton>
            </Tooltip>
            <OutlinedButton
              disabled={previewMode}
              startIcon={
                sessionActive && (isReplaying || startRecordingRef?.current) ? (
                  <StopSvg />
                ) : (
                  <BsRecordCircle color={theme.palette.svg.main} size="1rem" />
                )
              }
              onClick={
                sessionActive && (isReplaying || startRecordingRef?.current)
                  ? handleEndSession
                  : () => startSession()
              }>
              {sessionActive && (isReplaying || startRecordingRef?.current)
                ? 'End session'
                : 'Record'}
            </OutlinedButton>
            <OutlinedButton
              disabled={!actions?.length || !!batchId}
              startIcon={
                isReplaying ? (
                  <CircularProgress size={14} color={'primary'} />
                ) : (
                  <ReplaySvg fill={theme.palette.svg.primary} />
                )
              }
              onClick={handlePlaybackActions}>
              {isReplaying ? 'Replaying...' : 'Run'}
            </OutlinedButton>
            {!previewMode && (
              <>
                <Badge
                  color={'secondary'}
                  variant={'standard'}
                  badgeContent={!(isUnsaved || isUnsavedRun) ? undefined : ' '}>
                  <ContainedButton
                    isLoading={isSavingTest || isSavingSteps}
                    loadingProps={{ size: 16 }}
                    onClick={() => handleSaveTest()}
                    startIcon={<SaveSvg fill={theme.palette.svg.main} />}
                    sx={{
                      borderTopLeftRadius: '0.4rem',
                      borderBottomLeftRadius: '0.4rem',
                      borderTopRightRadius: testId ? '0rem' : '0.4rem',
                      borderBottomRightRadius: testId ? '0rem' : '0.4rem'
                    }}
                    color={'primary'}>
                    Save
                  </ContainedButton>
                </Badge>
                {!!testId && <EditTestMenu requestSaveTestModal={requestSaveTestModal} />}
              </>
            )}
            {!!previewMode && !!testId && (
              <ContainedButton
                as={NavLink}
                sx={{ textDecoration: 'none', display: 'inline-flex' }}
                to={`/projects/${activeProject?.id}/mobile-testing/${publicKey}/${testId}/${
                  folderId ? `${folderId}/edit?platform=${platform}` : `edit?platform=${platform}`
                }`}
                isLoading={isSavingTest || isSavingSteps}
                loadingProps={{ size: 16 }}>
                Edit Test
              </ContainedButton>
            )}
          </Flex>
        </Flex>

        <Flex
          sx={{
            minHeight: '100vh',
            alignItems: orientation === 'portrait' ? 'flex-start' : 'center',
            justifyContent: orientation === 'portrait' ? 'flex-start' : 'center',
            flexDirection: orientation === 'portrait' ? 'row' : 'column',
            mt: 1
          }}>
          <Box
            component={'iframe'}
            ref={iframeRef}
            id={'appetize'}
            src={`https://appetize.io/embed/${publicKey}?device=${defaultDeviceIdentifier}&osVersion=${defaultOS}&record=true&proxy=intercept&debug=true&language=en&centered=both&toast=top&scale=auto`}
            sx={{
              width: orientation === 'portrait' ? 'max-content' : '100%',
              minHeight: orientation === 'portrait' ? '80vh' : '50vh',
              display: 'flex',
              alignSelf: orientation === 'portrait' ? 'flex-start' : 'flex-start'
            }}
            frameborder="0"
            scrolling="no"
          />
          <Widgets
            orientation={orientation}
            setOrientation={setOrientation}
            session={session}
            platform={platform}
            osVersion={osVersion}
            onInspect={() => handleInspectToggle(true)}
            setUnsaved={setUnsaved}
            setActions={setActions}
            selectedElement={selectedElement}
            assertionMode={assertionMode}
            setAssertionMode={setAssertionMode}
            toggleAssertionModeRef={toggleAssertionModeRef}
            assertionModeRef={assertionModeRef}
            insertEventsAtRef={insertEventsAtRef}
            startRecordingRef={startRecordingRef}
          />

          {isInspecting && (
            <AppSource
              appSource={appSource}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              sessionActive={sessionActive}
              handleInspectToggle={() => handleInspectToggle(false)}
            />
          )}
        </Flex>

        <Box mb={4}>
          <Box
            sx={{
              width: '100%',
              height: '55vh',
              border: `2px solid ${theme.palette.table.outline}`,
              mt: 2,
              borderTopRightRadius: '0.5rem',
              borderTopLeftRadius: '0.5rem'
            }}>
            <Flex
              alignItems={'center'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              sx={{ borderBottom: `2px solid ${theme.palette.table.outline}` }}>
              <Tabs
                value={selectedTab}
                onChange={(event, value) => setSelectedTab(value)}
                aria-label={'Test cases tabs'}
                indicatorColor={'secondary'}
                sx={{ borderBottom: '3px solid #E3E3E3', ml: 2 }}
                TabIndicatorProps={{
                  sx: { height: 4 }
                }}>
                <Tab label={'Steps'} sx={{ ...tabStyle }} />
                <Tab label={'Network Logs'} sx={{ ...tabStyle }} />
                <Tab label={'Debug Logs'} sx={{ ...tabStyle }} />
              </Tabs>

              {selectedStepIndexes.length > 0 && (
                <BulkStepActionsTrigger
                  selectedSteps={selectedSteps}
                  onDeleteSteps={() => {
                    deleteSelectedSteps(actions);
                  }}
                  onDuplicateSteps={() => {
                    duplicateSelectedSteps(actions);
                  }}
                />
              )}
            </Flex>
            {selectedTab === 0 && (
              <>
                <ListSteps
                  actions={previousRun?.items || actions}
                  runResults={playbackEvents || previousRun?.items}
                  refetchPreviousRuns={refetchPreviousRuns}
                  failureTag={previousRun?.failure_tag}
                  setActions={setActions}
                  setUnsaved={setUnsaved}
                  toggleStepsSelection={toggleStepsSelection}
                  selectedStepIndexes={selectedStepIndexes}
                  selectAllSteps={selectAllSteps}
                  handleInputFieldUpdate={handleInputFieldUpdate}
                  handleRecordingActionFromIndex={handleRecordingActionFromIndex}
                  reorderTestActions={reorderTestActions}
                  handleDragEnd={handleDragEnd}
                  findActionIndex={findActionIndex}
                  failedStepIndex={failedStepIndex}
                  errorStep={errorStep}
                />
                {!actions?.length && (
                  <Flex
                    columnGap={3}
                    sx={{ justifyContent: 'center', py: '6%', flexDirection: 'column' }}>
                    <Typography variant={'body1'} textAlign={'center'}>
                      Interactions with the device <br /> will appear hear
                    </Typography>
                  </Flex>
                )}
              </>
            )}

            {selectedTab === 1 && (
              <NetworkLogsTable
                openRow={openRow}
                setOpenRow={setOpenRow}
                networkLogs={networkLogs}
              />
            )}
            {selectedTab === 2 && <ConsoleLogs consoleLogs={consoleLogs} />}
          </Box>
          {selectedTab === 0 && (
            <Flex
              sx={{
                py: 1,
                border: `2px solid ${theme.palette.table.outline}`,
                borderTop: 'none',
                px: 3,
                mt: errorStep && 2,
                borderBottomLeftRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem'
              }}>
              <TextButton
                disabled={
                  (!actions?.length && !consoleLogs?.length && !networkLogs?.length) || !!batchId
                }
                startIcon={<ClearSvg />}
                color={'inherit'}
                onClick={() => handleClearActions()}>
                Clear
              </TextButton>

              <Tooltip title={'Export actions to JSON'}>
                <TextButton
                  startIcon={<CloudDownloadIcon />}
                  size={'small'}
                  onClick={handleExportActions}
                  disabled={!actions?.length}>
                  Export
                </TextButton>
              </Tooltip>
            </Flex>
          )}
        </Box>

        <SaveNewTest
          open={openSaveTestModal}
          onClose={onCloseSaveTestModal}
          onComplete={completeSaveTestModal}
          name={name}
          setName={setName}
          step_timeout={step_timeout}
          setStepTimeout={setStepTimeout}
          pre_step_delay={pre_step_delay}
          setPreStepDelay={setPreStepDelay}
          description={description}
          setDescription={setDescription}
          onSaveTestCase={onSaveTestCase}
          isSavingTest={isSavingTest}
        />

        <AssertionModal
          open={openAssertionModal}
          onClose={onCloseAssertionModal}
          onComplete={completeAssertionModal}
          setUnsaved={setUnsaved}
          setActions={setActions}
          selectedElement={selectedElement}
          assertionMode={assertionMode}
          setAssertionMode={setAssertionMode}
          assertionModeRef={assertionModeRef}
          insertEventsAtRef={insertEventsAtRef}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default MobileTestPage;
