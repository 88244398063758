import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import { useCookies } from 'react-cookie';
import {
  Typography,
  Box,
  InputAdornment,
  Divider,
  Tab,
  Tabs,
  CircularProgress,
  Chip,
  IconButton,
  Tooltip,
  TextField,
  Button,
  Stack,
  styled,
  Fade,
  Badge,
  useTheme
} from '@mui/material';
import axios from 'axios';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LinearProgress from '@mui/material/LinearProgress';
import AddIcon from '@mui/icons-material/Add';
import OutlinedButton from '../../components/base/OutlinedButton';
import Flex from '../../components/base/Flex';
import ContainedButton from '../../components/base/ContainedButton';
import { toast } from 'react-toastify';
import { EditableTitle } from '../TestPage/components/EditableTitle';
import { EditableIndicator } from '../TestPage/components/TestProperties';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SendSvg from '../../components/svg/SendSvg';
import SaveSvg from '../../components/svg/SaveSvg';
import ResponseSvg from '../../components/svg/ResponseSvg';
import HttpMethodsMenu from './components/HttpMethodsMenu';
import QueryParamsTable from './components/QueryParamsTable';
import Headers from './components/Headers';
import Authorization from './components/Authorization';
import CookiesTabPage from './components/Cookies';
import QueryBody, { fileParser2 } from './components/QueryBody';
import ResponseEditor from './components/Response';
import ResponseHeader from './components/ResponseHeader';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import GetTestTitle from '../TestPage/components/GetTestTitleModal';
import prettyBytes from 'pretty-bytes';
import TestAssertionss from './components/TestAssertionss';
import CustomScrollbar from '../TestPage/components/CustomScrollbar';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import TestFolders from './components/TestFolders';
import useAwaitModal from '../../hooks/useAwaitModal';
import { useFocusedState } from '../../hooks/useFocusedState';
import CreateFolderModal from './components/Modals/CreateFolderModal';
import FeatureFlagsContext from '../../store/featureFlagsState';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import { VariablesList } from './components/VariablesListWindow';
import { SaveTextAsVariable } from './components/SaveTextAsVariable';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { convertTimeToSeconds } from '../../utils/time';
import { Close } from '@mui/icons-material';
import DeleteSvg from '../../components/svg/DeleteSvg';
import { useDebouncePathVariable, useDebounceTestUrl } from '../../hooks/apiHooks/useDebounce';
import ResponseCookies from './components/ResponseCookies';
import BrowserSvg from '../../components/svg/BrowserSvg';
import { SelectTestRunAgent } from './components/TestRunAgentModal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { rgba } from '../../utils/colors';
import { Console } from './components/Console';
import CreateSystemVariableForApi from './components/ApiCreateSystemVariable';
import { parseISO, format } from 'date-fns';
import TestDetails from './components/Toolbar/TestDetails';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';
import ToolBar from './components/Toolbar/Toolbar';
import Documentation from './components/Documentation';
import PathVariablesTable from './components/PathVariables';
import { ApiTestContext } from '../../store/apiState/apiTestContext';
import { ApiDialogsAndModalsContext } from '../../store/apiState/apiDialogsAndModalsContext';
import RenameFolderModal from './components/Modals/RenameFolderModal';
import RenameTestModal from './components/Modals/RenameTestModal';
import {
  DeleteSelectedApiTestsPopUp,
  DeleteSingleTestPopUp,
  DeleteTestExamplePopUp,
  DuplicateTestPopUp,
  FolderDeletePopUp
} from './components/Modals';
import MoveToFolderModal from './components/Modals/MoveToFolderModal';
import TestDescriptionModal from './components/Modals/TestDescriptionModal';
import useFetchSingleTest, {
  configureTestDocumentData
} from '../../hooks/apiHooks/queryHooks/useFetchSingleTest';
import {
  useCheckUrlIsForNewTest,
  useTrackTabsAndFetchTest,
  useUpdateNewTestOnSave
} from '../../hooks/apiHooks/trackTabsAndFetchTest';
import {
  useCheckForTestPropsChanges,
  useGetCurrentTest,
  useSetTestHasNewChanges,
  useUpdateQueryParamsByUrl
} from '../../hooks/apiHooks';
import TestsTabs from './components/TestsTab';
import { useSetTestDetails, useSetTestUrl } from '../../hooks/apiHooks/updateTestPropsHooks';
import { useSetTestAssertions } from '../../hooks/apiHooks/updateAssertions';
import useRunApiTest from '../../hooks/apiHooks/queryHooks/useRunApiTest';
import { isNumber } from 'lodash';
import { useSetUIProps } from '../../hooks/apiHooks/uiPropsHooks';
import AssertionsResultTab from './components/TestResponse/AssertionsResultTab';
import { GlobalVariablesModal } from './components/Modals/GlobalVariablesModal';
import CreateGVAssertionModal from './components/Modals/CreateGVAssertionModal';
import useSaveApiTest from '../../hooks/apiHooks/queryHooks/useSaveApiTest.js';
import { SaveChangesToApiTestDialog } from './components/Modals/Dialogs.js';
import SaveApiTestExampleModal from './components/Modals/SaveApiTestExampleModal.jsx';
import { useHandleLocalRun } from '../../hooks/apiHooks/queryHooks/useRunApiTestLocally.js';
import { useHandleDesktopAgentRun } from '../../hooks/apiHooks/queryHooks/useRunApiTestByDesktopAgent.js';
import SavePostmanCollectionModal from './components/Modals/SavePostmanCollectionModal.jsx';
import { ResizableBox } from 'react-resizable';

export const tabStyle = {
  textTransform: 'none',
  textAlign: 'left',
  py: 1,
  fontSize: '0.85rem',
  minHeight: 0,
  maxHeight: 'max-content',
  minWidth: 0,
  maxWidth: 'max-content',
  mr: 6,
  '&: hover': {
    color: '#1958B8'
  }
};
const ChooseRunAgent = () => {
  const { requestTestRunAgentModal } = useContext(ApiDialogsAndModalsContext);
  const {
    testAgent: { runAgent, agentPort }
  } = useContext(ApiTestContext);
  return (
    <Tooltip title={runAgent === 'Desktop-Agent' ? `port ${agentPort} ` : ''}>
      <Box
        onClick={() => {
          requestTestRunAgentModal();
        }}
        sx={{
          // outline: '1.5px solid rgba(160,160,160, 1)',
          borderRadius: 1.5,
          px: 1.5,
          marginLeft: 'auto',
          marginRight: 3,
          py: 0.2,
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        {runAgent === 'Cloud-Agent' && (
          <Flex sx={{ mr: 1 }}>
            <CloudOutlinedIcon color="primary" />
            <Typography color={'primary'}>Cloud Agent</Typography>
            <ArrowDropDownIcon />
          </Flex>
        )}
        {runAgent === 'Browser-Agent' && (
          <Flex>
            {' '}
            <BrowserSvg width={15} height={15} />
            <Typography color={'primary'}>Browser Agent</Typography> <ArrowDropDownIcon />
          </Flex>
        )}
        {runAgent === 'Desktop-Agent' && (
          <Flex>
            <DesktopWindowsOutlinedIcon color="primary" fontSize="12px" />
            <Typography color={'primary'}>Desktop Agent</Typography>
            <ArrowDropDownIcon
              sx={
                {
                  // ml: .5
                }
              }
            />
          </Flex>
        )}
      </Box>
    </Tooltip>
  );
};

export const TabPanel = ({ children, value, index }) => {
  return (
    <div hidden={value !== index} style={{ width: '100%' }}>
      {value === index && <Box sx={{ minHeight: '300px' }}>{children}</Box>}
    </div>
  );
};

const statusCodeColorMap = {
  2: '#00CA08',
  4: rgba('#EF1B1B', 70)
};
const getStatusText = (statusCode) => {
  const statusMap = {
    200: 'Ok',
    201: 'Created',
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    422: 'Unprocessable Entity',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout'
  };

  return statusMap[statusCode] || '';
};
const NewApi = () => {
  //////////////////////////////////////////////////////////////// new imp
  const theme = useTheme();
  const { handleResetTestInitialDataAfterSaving } = useCheckForTestPropsChanges();
  const { handleUpdateNewTestOnSave } = useUpdateNewTestOnSave();
  useDebouncePathVariable(500);
  const {
    testsTab,
    foldersState,
    massSelection,
    setMassSelection,
    setFoldersState,
    responseWindowRef,
    variableSuggestionListRef,
    testAgent: { showLocalModeNotice, runAgent },
    setTestAgent
  } = useContext(ApiTestContext);
  const { clickedFolder, searchFoldersValue } = foldersState;
  const {
    requestCreateFolderModal,
    requestTestsDeletionDialog,
    requestGVModal,
    requestImportPostmanCollectionModal,
    openVariablesSuggestionList,
    closeVariablesSuggestionList
  } = useContext(ApiDialogsAndModalsContext);
  const isNewTest = useCheckUrlIsForNewTest();
  const { handleSetTestHasNewChanges } = useSetTestHasNewChanges();
  const { isFetchingSingleTest, handleRefetchTest } = useFetchSingleTest();
  useTrackTabsAndFetchTest();
  useDebounceTestUrl();
  useUpdateQueryParamsByUrl();
  const { handleRunApiTestInBrowser, isRunningApiTestLocally } = useHandleLocalRun();
  const { handleRunApiTest, isRunningApiTest } = useRunApiTest();
  const { handleRunApiTestByAgent, isRunningApiTestByAgent } = useHandleDesktopAgentRun();
  const { handleSaveApiTest, isSavingApiTest } = useSaveApiTest();
  const { handleSetTestDetails } = useSetTestDetails();
  const isRequestRunning =
    isFetchingSingleTest ||
    isSavingApiTest ||
    isRunningApiTest ||
    isRunningApiTestLocally ||
    isRunningApiTestByAgent;
  const {
    testPropsForRequest: { url, assertionsSelect, rawRequestBody, params, headers, cookies },
    testRunResponse: {
      hasResponse,
      responseStatusCode,
      responseSize,
      responseTimeTaken,
      responseBody,
      responseHeaders,
      responseAssertions
    },
    uiProps: { testResponseTab, testPropsTab },
    testDetails: { name: testName, id: currentTestId },
    hasNewChanges
  } = useGetCurrentTest();
  const { handleSetTestUrl } = useSetTestUrl();
  const { handleAddNewAssertion } = useSetTestAssertions();
  const { handleResponseTabChange, handleSetTestPropsTab, handleSetTestDocumentation } =
    useSetUIProps();
  //////////////////////////////////////////////////////////////// new imp
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeProject = useActiveProject();
  const { testId, projectId, folderId, exampleId, newTestId } = useParams();
  const { featureFlags, isFeatureFlagsLoading } = useContext(FeatureFlagsContext);
  const readyOnly = activeProject?.read_only;
  //////////////////////////////////////////////////////////new dialog
  const { handleVariableFloatingWindow } = useFocusedState();
  ////////////////////////////////////////////////////////////////
  useEffect(() => {
    const clickListener = (e) => {
      if (!!openVariablesSuggestionList) {
        if (!variableSuggestionListRef.current.contains(e.target)) {
          closeVariablesSuggestionList();
        }
      }
    };
    window.addEventListener('click', clickListener);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, [openVariablesSuggestionList]);

  const isAssertionResultsNotNull = useMemo(() => {
    if (!!responseAssertions) {
      return Object.keys(responseAssertions).length > 0
        ? Object.values(responseAssertions).some((value) => value !== null)
        : false;
    }
  }, [responseAssertions]);

  useDocumentTitle(testId ? `Edit Test${currentTestId ? `: ${testName}` : ''}` : 'New API');
  const numberOfHeaders = () => {
    return headers.filter((header) => !!header.key.trim()).length;
  };
  // Check for valid query params
  const hasValidQueryParams = params.some((item) => {
    return item.key?.trim() !== '' && item.value?.trim() !== '';
  });
  const hasValidQueryHeaders = headers.some((item) => {
    return item.key?.trim() !== '' && item.value?.trim() !== '';
  });
  // Check for valid cookies
  const hasValidCookies = cookies.some((item) => {
    return item.key?.trim() !== '' && item.value?.trim() !== '';
  });

  const { isFeatureEnabled: showAPIMenu } = useFeatureEnabled('api-testing');
  useEffect(() => {
    const value = localStorage.getItem('scandium-mode');
    if (!!value) {
      setTestAgent((prev) => {
        return { ...prev, runAgent: value };
      });
    } else {
      localStorage.setItem('scandium-mode', 'Cloud-Agent');
    }
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!!hasNewChanges) {
        const message = 'You have unsaved changes, Are you sure you want to leave';
        event.returnValue = 'You have unsaved changes, Are you sure you want to leave';
        return message;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasNewChanges]);

  return (
    <Box
      sx={{
        // outline: '3px solid green',
        marginTop: { xs: '3.5rem', sm: '9rem' },
        ml: { xs: '1.5rem', sm: '3rem', md: '4rem' },
        mr: { xs: '1.5rem', sm: '2rem', md: '2rem' },
        mb: '2rem',
        color: 'inherit',
        height: '100vh'
        // overflowY: 'hidden'
      }}>
      {!isFeatureFlagsLoading && showAPIMenu && (
        <>
          {!!testsTab.testsList.length && <TestsTabs />}
          <Box>
            <Flex columnGap={0} alignItems={'flex-end'}>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  textDecoration: 'none',
                  textAlign: 'left',
                  fontSize: '1.1rem',
                  cursor: 'pointer'
                }}>
                {clickedFolder?.name || `Test case`} /
              </Typography>
              <Tooltip title={testName}>
                <EditableTitle
                  value={testName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditableIndicator showHelper />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    handleSetTestDetails({ name: e.target.value });
                  }}
                  sx={{
                    px: 0,
                    width: 'fit-content'
                  }}
                />
              </Tooltip>
              <ChooseRunAgent />
            </Flex>
          </Box>
          <Flex
            sx={{
              flexDirection: { xs: 'column', md: 'column', lg: 'row' },
              alignItems: { xs: 'flex-start', lg: 'center' }
              // mt: 1,
              // justifyContent: 'space-between'
            }}>
            <Flex columnGap={0} alignItems={'stretch'} minWidth={'600px'}>
              <HttpMethodsMenu />
              <TextField
                required
                value={url}
                disabled={!!readyOnly}
                onChange={(event) => {
                  handleSetTestUrl(event.target.value);
                  handleVariableFloatingWindow(event, () => handleSetTestUrl(event.target.value));
                  if (event.target.value.includes('localhost')) {
                    setTestAgent((prev) => {
                      return { ...prev, showLocalModeNotice: true };
                    });
                    // setShowLocalModeNotice(true);
                  } else {
                    setTestAgent((prev) => {
                      return { ...prev, showLocalModeNotice: false };
                    });
                    // setShowLocalModeNotice(false);
                  }
                }}
                size={'small'}
                autoFocus={pathname.includes('new')}
                fullWidth
                placeholder={'Enter URL or paste text'}
                InputProps={{
                  sx: {
                    paddingLeft: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                  },
                  endAdornment: !isFetchingSingleTest ? undefined : (
                    <InputAdornment position="end">
                      <CircularProgress size={12} color={'secondary'} />
                    </InputAdornment>
                  )
                }}
              />
            </Flex>
            <Flex
              ml={{ lg: 'auto', xs: '', md: '' }}
              my={1}
              sx={{
                gap: 2
              }}>
              {showLocalModeNotice && runAgent !== 'Browser-Agent' && (
                <Fade
                  timeout={{
                    enter: 800,
                    exit: 300
                  }}
                  in={showLocalModeNotice && runAgent !== 'Browser-Agent'}>
                  <Chip
                    sx={{
                      borderRadius: 2
                    }}
                    variant="filled"
                    color="primary"
                    onDelete={() => {
                      setTestAgent((prev) => {
                        return { ...prev, showLocalModeNotice: false };
                      });
                    }}
                    label={
                      'seems your server is running on your computer, consider switching to Browser Mode'
                    }
                  />
                </Fade>
              )}
              <SelectTestRunAgent />
              <ContainedButton
                isLoading={isRunningApiTest || isRunningApiTestLocally || isRunningApiTestByAgent}
                disabled={isRunningApiTest || isRunningApiTestLocally || isRunningApiTestByAgent}
                loadingProps={{ size: 16 }}
                onClick={() => {
                  runAgent === 'Cloud-Agent' && handleRunApiTest();
                  runAgent === 'Browser-Agent' && handleRunApiTestInBrowser();
                  runAgent === 'Desktop-Agent' && handleRunApiTestByAgent();
                }}
                startIcon={
                  <SendSvg
                    fill={theme.palette.svg.main}
                    sx={{
                      transform: runAgent === 'Browser-Agent' ? 'rotate(180deg)' : ''
                    }}
                  />
                }>
                Send
              </ContainedButton>
              {/* )} */}
              <Tooltip title={'Save request'}>
                <OutlinedButton
                  isLoading={isSavingApiTest}
                  disabled={
                    isSavingApiTest || !!exampleId || !!readyOnly || projectId !== activeProject?.id
                  }
                  loadingProps={{ size: 16 }}
                  onClick={async () => {
                    // console.log(assertionsSelect);
                    const { savedTest, savedTestId, savedTestData } = await handleSaveApiTest();
                    if (!!savedTest) {
                      const testDocumentData = configureTestDocumentData(
                        savedTestData.documentationData
                      );
                      handleSetTestDocumentation(testDocumentData);
                      handleSetTestHasNewChanges(false);
                      handleResetTestInitialDataAfterSaving();
                      // handle the above before changing the testDetails, they use the test id.
                      handleSetTestDetails(savedTestData.testDetails);
                      if (!!isNewTest) {
                        handleUpdateNewTestOnSave(savedTestId);
                      }
                    }
                  }}
                  endIcon={
                    !!hasNewChanges && (
                      <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '5px' }} />
                    )
                  }
                  startIcon={<SaveSvg fill={theme.palette.svg.main} />}>
                  Save
                </OutlinedButton>
              </Tooltip>
            </Flex>
          </Flex>
          <Divider sx={{ mt: 1 }} />

          <Flex // main section
            columnGap={0}
            alignItems="flex-start"
            padding={2}>
            <ResizableBox
              width={300}
              minHeight={500}
              height={600}
              axis="x"
              minConstraints={[230, 400]}
              maxConstraints={[500, 400]}
              resizeHandles={['e']}
              style={{
                // outline: '1px solid yellow',
                position: 'relative',
                paddingRight: '2px' // Add border to the right of the resizable box
              }}>
              <Flex
                sx={{
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  // flex: '30%',
                  // minWidth: '200px',
                  // maxWidth: 'inherit',
                  alignItems: 'normal',
                  pt: 1.5,
                  bgcolor: theme.palette.background.s1,
                  borderLeft: `0.5px solid #ccc`
                  // borderBottom: '0.5px solid #ccc'
                  // overflowX: 'scroll'
                }}>
                <Flex justifyContent={'flex-end'} columnGap={1} sx={{ mr: 1 }}>
                  <Tooltip title={'Create or manage your variables'}>
                    <Button
                      disabled={!!massSelection.active}
                      disableRipple
                      onClick={() => {
                        requestGVModal();
                      }}
                      sx={{ py: 0, px: 0, '&: hover': { background: 'transparent' } }}>
                      Variable
                    </Button>
                  </Tooltip>
                  <Tooltip title={'Create a new test'}>
                    <Button
                      disabled={!!massSelection.active}
                      disableRipple={true}
                      onClick={() => {
                        if (!newTestId && !testId && !exampleId) {
                          return;
                        }
                        navigate(`/projects/${activeProject?.id}/api-suites/new`);
                      }}
                      sx={{ py: 0, px: 0, '&: hover': { background: 'transparent' } }}>
                      New
                    </Button>
                  </Tooltip>
                  <Tooltip title={'Import a POSTMAN Collection'}>
                    <Button
                      disabled={!!massSelection.active}
                      onClick={() => requestImportPostmanCollectionModal()}
                      disableRipple={true}
                      sx={{ py: 0, px: 0, '&: hover': { background: 'transparent' } }}>
                      Import
                    </Button>
                  </Tooltip>
                </Flex>

                <>
                  <Flex columnGap={0} sx={{ mt: 1, px: 1.5 }}>
                    <TextField
                      disabled={!!massSelection.active}
                      size={'small'}
                      value={searchFoldersValue}
                      onChange={(e) => {
                        setFoldersState((prev) => {
                          return { ...prev, searchFoldersValue: e.target.value };
                        });
                        // setSearchValue(e.target.value);
                      }}
                      fullWidth
                      placeholder="Search"
                      inputProps={{
                        style: {
                          height: '14px',
                          mb: 4
                        }
                      }}
                    />
                    <Tooltip title={'Create new folder'}>
                      <IconButton
                        disabled={!!massSelection.active}
                        size={'small'}
                        sx={{ borderRadius: '8px' }}
                        onClick={requestCreateFolderModal}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      sx={{
                        display:
                          !massSelection.selectedApiTests.length &&
                          !massSelection.selectedApiTestExamples.length
                            ? 'none'
                            : ''
                      }}
                      onClick={() => {
                        requestTestsDeletionDialog();
                      }}>
                      <DeleteSvg width={'18px'} height={'18px'} />
                    </IconButton>
                    <Tooltip title={!massSelection.active ? 'select multiple' : ''}>
                      <IconButton size={'small'} sx={{ borderRadius: '16px' }}>
                        {!massSelection.active ? (
                          <PlaylistAddCheckIcon
                            onClick={() => {
                              setMassSelection((prev) => {
                                return { ...prev, active: true };
                              });
                            }}
                            fontSize="medium"
                            sx={{
                              mr: 1
                            }}
                          />
                        ) : (
                          <Close
                            onClick={() => {
                              setMassSelection({
                                active: false,
                                selectedApiTests: [],
                                selectedApiTestExamples: [],
                                selectedFolders: [],
                                selectedSuites: []
                              });
                            }}
                            sx={{
                              color: 'rgba(100,100,100, 0.7)',
                              '&:hover': {
                                color: 'rgba(100,100,100)'
                              }
                            }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Flex>
                  <TestFolders />
                </>
              </Flex>
            </ResizableBox>
            <Flex
              sx={{
                // outline: '5px solid black',
                flexDirection: 'column',
                flex: '70%',
                alignItems: 'normal',
                pt: 1,
                pl: 2,
                overflowX: 'hidden'
              }}>
              {isRequestRunning && (
                <Box sx={{ width: '100%', mt: -1 }}>
                  <LinearProgress />
                </Box>
              )}
              <Flex
                flexDirection={'column'}
                sx={{
                  // outline: '3px solid red',
                  position: 'relative',
                  columnGap: 0.7,
                  alignItems: 'flex-start'
                }}>
                <Box>
                  <Tabs
                    value={testPropsTab}
                    onChange={(event, value) => handleSetTestPropsTab(value)}
                    indicatorColor={'secondary'}
                    variant="scrollable"
                    scrollButtons={true}
                    sx={{
                      borderBottom: `3px solid ${theme.palette.table.main}`,
                      minHeight: 0,
                      maxHeight: 'max-content'
                    }}
                    TabIndicatorProps={{
                      sx: { height: 3, m: 0 }
                    }}>
                    <Tab
                      label={
                        <Flex columnGap={'5px'}>
                          <Typography fontSize={'0.85rem'}>Params</Typography>
                          {!!hasValidQueryParams && (
                            <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                          )}
                        </Flex>
                      }
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />

                    <Tab
                      // disabled
                      disableRipple={true}
                      label={'Authorization'}
                      sx={{
                        ...tabStyle
                      }}
                    />
                    <Tab
                      label={
                        <Flex columnGap={'5px'}>
                          <Typography fontSize={'0.85rem'}>
                            Headers{' '}
                            <Typography display={'inline'} fontSize={11}>
                              {!!hasValidQueryHeaders ? `( ${numberOfHeaders()} )` : ''}
                            </Typography>
                          </Typography>
                          {!!hasValidQueryHeaders && (
                            <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                          )}
                        </Flex>
                      }
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />
                    <Tab
                      label={
                        <Flex columnGap={'5px'}>
                          <Typography fontSize={'0.85rem'}>Body</Typography>
                          {!!rawRequestBody && (
                            <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                          )}
                        </Flex>
                      }
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />
                    <Tab
                      label={
                        <Flex columnGap={'5px'}>
                          <Typography fontSize={'0.85rem'}>Cookies</Typography>
                          {hasValidCookies && (
                            <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                          )}
                        </Flex>
                      }
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />
                    <Tab
                      label={'Assertions'}
                      disableRipple={true}
                      sx={{
                        ...tabStyle
                      }}
                    />
                  </Tabs>
                </Box>
                <Box
                  width={'100%'}
                  gap={1}
                  position={'relative'}
                  flexDirection={'row'}
                  alignItems={'start'}
                  sx={{
                    display: 'flex'
                  }}>
                  <TabPanel value={testPropsTab} index={0}>
                    <Typography mt={2} fontWeight={'medium'} fontSize={'0.8rem'}>
                      Query Params
                    </Typography>
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '230px' }}>
                      <QueryParamsTable />
                      <PathVariablesTable />
                      {/* </>
                      )} */}
                    </CustomScrollbar>
                  </TabPanel>
                  <Box
                    sx={{
                      width: '100%',
                      display: testPropsTab !== 1 ? 'none' : ''
                    }}>
                    <Authorization />
                  </Box>
                  <TabPanel value={testPropsTab} index={2}>
                    <Typography mt={2} fontSize={'0.8rem'}>
                      Headers
                    </Typography>
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '350px' }}>
                      <Headers />
                    </CustomScrollbar>
                  </TabPanel>
                  <TabPanel value={testPropsTab} index={3}>
                    <QueryBody />
                  </TabPanel>
                  <TabPanel value={testPropsTab} index={4}>
                    <Typography mt={2} fontSize={'0.8rem'}>
                      Cookies
                    </Typography>
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '250px' }}>
                      <CookiesTabPage />
                    </CustomScrollbar>
                  </TabPanel>
                  <TabPanel value={testPropsTab} index={5}>
                    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '400px' }}>
                      <Box sx={{ width: '50%', pt: 3 }}>
                        {!!assertionsSelect.length &&
                          assertionsSelect.map((dropdown, index) => (
                            <Box key={index}>
                              <Flex
                                alignItems={'normal'}
                                justifyContent={'flex-start'}
                                flexDirection={'column'}
                                key={index}>
                                <TestAssertionss
                                  key={index}
                                  dropdown={dropdown}
                                  index={index}
                                  dropDownArray={assertionsSelect}
                                />
                              </Flex>
                              <Divider sx={{ mb: 1.5, width: '95.5%' }} />
                            </Box>
                          ))}

                        <Chip
                          label={`Add new`}
                          onClick={() => {
                            handleAddNewAssertion();
                          }}
                          size={'small'}
                          color={'primary'}
                          icon={<AddIcon />}
                          sx={{
                            borderRadius: '0.3rem',
                            py: 2,
                            mt: 1
                          }}
                        />
                      </Box>
                    </CustomScrollbar>
                  </TabPanel>

                  <Divider orientation="vertical" flexItem />
                  <ToolBar />
                  <Box
                    sx={{
                      position: 'absolute',
                      zIndex: 15,
                      background: theme.palette.background.white_black,
                      boxShadow: ` -3px -3px 12px ${theme.palette.background.shadow}`,
                      borderRadius: 2,
                      top: 30,
                      right: 42
                    }}>
                    <Documentation />
                    <TestDetails />
                  </Box>
                </Box>
              </Flex>
              <Box
                sx={{ borderTop: `3px solid ${theme.palette.custom.grey}` }}
                ref={responseWindowRef}>
                {!isNumber(responseStatusCode) && (
                  <Box height={'235px'}>
                    <Typography fontSize={'0.9rem'} mt={2}>
                      Response
                    </Typography>
                    <Flex justifyContent={'center'} flexDirection={'column'}>
                      <ResponseSvg width={90} height={90} />
                      {!url.length ? (
                        <Typography variant={'body2'} textAlign={'center'} mt={1}>
                          Enter a URL and click <br /> send to get a response
                        </Typography>
                      ) : (
                        <Typography variant={'body2'} textAlign={'center'} mt={1}>
                          Click Send to get a response
                        </Typography>
                      )}
                    </Flex>
                  </Box>
                )}
                {!!hasResponse && (
                  <>
                    <Flex justifyContent={'space-between'}>
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={testResponseTab}
                        onChange={(event, value) => handleResponseTabChange(value)}
                        indicatorColor={'secondary'}
                        sx={{
                          borderBottom: `2px solid ${theme.palette.table.main}`,
                          minHeight: 0,
                          maxHeight: 'max-content'
                        }}
                        TabIndicatorProps={{
                          sx: { height: 3 }
                        }}>
                        <Tab
                          disabled={!responseBody}
                          label={'Body'}
                          disableRipple={true}
                          sx={{
                            ...tabStyle,
                            mr: 3
                          }}
                        />

                        <Tab
                          label={'Cookies'}
                          disableRipple={true}
                          sx={{
                            ...tabStyle,
                            mr: 3
                          }}
                        />
                        <Tab
                          disabled={responseHeaders?.length > 0 ? false : true}
                          label={
                            <Flex columnGap={'2px'}>
                              <Typography fontSize={'0.85rem'}>Headers</Typography>
                              <Typography
                                fontSize={'0.80rem'}
                                color={'primary'}
                                sx={{
                                  borderRadius: '100%',
                                  p: '3px'
                                }}>{`(${responseHeaders?.length})`}</Typography>
                            </Flex>
                          }
                          disableRipple={true}
                          sx={{
                            ...tabStyle,
                            mr: 3
                          }}
                        />
                        <Tab
                          label={
                            <Flex columnGap={'1px'}>
                              <Typography fontSize={'0.85rem'}>Assertion result</Typography>
                              {!!Object.values(responseAssertions).filter(Boolean).flat()
                                .length && (
                                <Badge
                                  sx={{
                                    ml: 2.5
                                  }}
                                  color={
                                    !!Object.values(responseAssertions)
                                      .filter(Boolean)
                                      .flat()
                                      .filter((result) => result.status === 'failed').length
                                      ? 'error'
                                      : 'primary'
                                  }
                                  badgeContent={
                                    <Flex
                                      borderRadius={10}
                                      sx={{
                                        mt: 0.2,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}>
                                      <Typography fontSize={10}>
                                        {
                                          Object.values(responseAssertions)
                                            .filter(Boolean)
                                            .flat()
                                            .filter((result) => result.status === 'passed').length
                                        }
                                        <Typography display={'inline'} fontSize={10}>
                                          /
                                          {
                                            Object.values(responseAssertions).filter(Boolean).flat()
                                              .length
                                          }
                                        </Typography>
                                      </Typography>
                                    </Flex>
                                  }
                                />
                              )}
                            </Flex>
                          }
                          disableRipple={true}
                          sx={{
                            ...tabStyle,
                            mr: 3
                          }}
                        />
                        <Tab
                          label={
                            <Flex columnGap={'5px'}>
                              <Typography fontSize={'0.85rem'}>Console</Typography>
                              {/* {!!isAssertionResultsNotNull && (
                                <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                              )} */}
                            </Flex>
                          }
                          disableRipple={true}
                          sx={{
                            ...tabStyle,
                            mr: 3
                          }}
                        />
                      </Tabs>
                      <Flex columnGap={1}>
                        <Typography fontSize={'0.8rem'} fontWeight={'medium'}>
                          Status:{' '}
                          <Typography
                            display={'inline'}
                            fontSize={'0.8rem'}
                            color={
                              statusCodeColorMap[responseStatusCode?.toString().charAt(0)] || ''
                            }>
                            {' '}
                            {responseStatusCode}
                          </Typography>
                          <Typography
                            ml={0.4}
                            display={'inline'}
                            color={
                              statusCodeColorMap[responseStatusCode?.toString().charAt(0)] || ''
                            }>
                            {getStatusText(responseStatusCode)}
                          </Typography>
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography fontSize={'0.8rem'} fontWeight={'medium'}>
                          Time taken:
                          <Typography
                            ml={0.4}
                            fontSize={11}
                            display={'inline'}
                            style={{ fontWeight: 'light' }}>
                            {convertTimeToSeconds(responseTimeTaken)}
                          </Typography>
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography fontSize={'0.8rem'} fontWeight={'medium'}>
                          Size:
                          <Typography
                            ml={0.4}
                            fontSize={11}
                            display={'inline'}
                            style={{ fontWeight: 'light' }}>
                            {!isNaN(+responseSize) ? prettyBytes(+responseSize) : 0}
                          </Typography>
                        </Typography>
                      </Flex>
                    </Flex>
                    <TabPanel value={testResponseTab} index={0}>
                      <Box mb={2}>
                        <ResponseEditor />
                      </Box>
                    </TabPanel>
                    <TabPanel value={testResponseTab} index={1}>
                      <ResponseCookies />
                    </TabPanel>
                    <TabPanel value={testResponseTab} index={2}>
                      <ResponseHeader />
                    </TabPanel>
                    <TabPanel value={testResponseTab} index={3}>
                      <AssertionsResultTab />
                    </TabPanel>
                    <TabPanel value={testResponseTab} index={4}>
                      <Console />
                    </TabPanel>
                  </>
                )}
              </Box>
            </Flex>
          </Flex>
        </>
      )}
      {isFeatureFlagsLoading && <PageLoader />}
      {!isFeatureFlagsLoading && !showAPIMenu && (
        <ErrorState
          title={'Permission Denied'}
          description={
            "We're sorry, but you do not have the necessary permissions to access this page."
          }
        />
      )}
      <GlobalVariablesModal />
      <CreateGVAssertionModal />
      <VariablesList />
      <SaveTextAsVariable />
      <SavePostmanCollectionModal />
      <DeleteSelectedApiTestsPopUp />
      <CreateFolderModal />
      <RenameFolderModal />
      <RenameTestModal />
      <MoveToFolderModal />
      <SaveApiTestExampleModal />
      <TestDescriptionModal />
      <DuplicateTestPopUp />
      <SaveChangesToApiTestDialog />
      <DeleteSingleTestPopUp />
      <DeleteTestExamplePopUp />
      <FolderDeletePopUp />
      <CreateSystemVariableForApi />
    </Box>
  );
};
export default NewApi;
