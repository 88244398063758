import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useScandiumMutation, useScandiumQuery } from '../data-layer/utils';
export const ProjectContext = React.createContext({
  projects: [],
  fetchProjectsError: null,
  isProjectsLoading: false,
  setActiveProject: () => {},
  fetchProjects: () => {}
});
export const useActiveProject = () => {
  const params = useParams();
  const { projects } = useContext(ProjectContext);

  return (
    (params.projectId && projects.find((project) => project.id === params.projectId)) || projects[0]
  );
};

export const ProjectContextProvider = ({ children }) => {
  const [, setCookie] = useCookies(['token', 'email']);
  const [filterByTags, setFilterByTags] = useState(null);
  const {
    data: projects = [],
    error: fetchProjectsError,
    isLoading: isProjectsLoading,
    refetch: fetchProjects
  } = useScandiumQuery('/projects', {
    // Only trigger fetch when we have refreshed the token
    params: {
      tag: filterByTags?.name?.en || undefined
    },
    onSuccess: (data) => {
      setCookie('projectType', data[0].type, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });
    },
    select: (data) => {
      let projects = data.projects.sort((a, b) => {
        return b.last_accessed_at < a.last_accessed_at || b.last_accessed_at == null ? -1 : 1;
      });
      return projects;
    }
  });
  const { mutateAsync } = useScandiumMutation('/switch-project', {
    onSuccess: async (data) => {
      if (data.project.id !== projects[0]) await fetchProjects();
    }
  });
  const setActiveProject = async (projectId) => {
    return await mutateAsync({
      project_id: projectId
    });
  };
  return (
    <ProjectContext.Provider
      value={{
        projects,
        setActiveProject,
        isProjectsLoading,
        fetchProjects,
        fetchProjectsError,
        filterByTags,
        setFilterByTags
      }}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
