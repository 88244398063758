import { toast } from 'react-toastify';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { useContext } from 'react';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

const useDeleteFolder = () => {
  const activeProject = useActiveProject();
  const queryClient = useQueryClient();
  const { folderId } = useParams();
  const navigate = useNavigate();
  const {
    foldersState: { clickedFolder }
  } = useContext(ApiTestContext);
  const { mutateAsync: deleteFolder, isLoading: isDeletingFolder } = useScandiumMutation(
    `/projects/${activeProject?.id}/folders/${clickedFolder?.id}`,
    {
      enabled: !!activeProject?.id,
      method: 'DELETE',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Folder deleted successfully');
        if (folderId === clickedFolder?.id) {
          // console.log(data);
          // resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
        queryClient.invalidateQueries(['get-apiFoldersAndTests']);
      }
    }
  );
  return { deleteFolder, isDeletingFolder };
};

export default useDeleteFolder;
