import { Box } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useActiveProject } from '../../../store/projectState';
import { useScandiumQuery } from '../../../data-layer/utils';
import { TestAutomationSuites } from './components/TestAutomationSuites';
import Flex from '../../../components/base/Flex';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import PageLoader from '../../../components/PageLoader';

import { ApiTestContext } from '../../../store/apiState/apiTestContext';

const ApiSuiteAutomation = () => {
  const activeProject = useActiveProject();
  const {} = useScandiumQuery(`/projects/${activeProject?.id}/folders?target_resource=api-test`, {
    enabled: !!activeProject?.id,
    select: (data) => data.data
  });
  const {} = useScandiumQuery(`/projects/${activeProject?.id}/api-tests`, {
    enabled: !!activeProject?.id,
    select: (data) => data.data.data
  });
  const { isFeatureEnabled: showAPIAutomationMenu } = useFeatureEnabled('api-automation');
  const { setMassSelection } = useContext(ApiTestContext);
  useEffect(() => {
    setMassSelection({
      active: false,
      selectedApiTests: [],
      selectedApiTestExamples: [],
      selectedFolders: [],
      selectedSuites: []
    });
  }, []);

  return (
    <Box
      sx={{
        marginTop: { xs: '3.5rem', sm: '9rem' },
        mr: { xs: '1.5rem', sm: '2rem', md: '2rem' },
        color: 'inherit',
        maxHeight: 'max-content'
      }}>
      <Flex columnGap={0} alignItems="flex-start" sx={{ width: '100%' }}>
        {!showAPIAutomationMenu ? (
          <Box
            sx={{
              width: '200px',
              height: 'auto',
              margin: 'auto'
            }}>
            <PageLoader height={'100px'} />
          </Box>
        ) : (
          <TestAutomationSuites />
        )}
      </Flex>
    </Box>
  );
};

export default ApiSuiteAutomation;
