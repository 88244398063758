import { useContext } from 'react';
import { ApiTestContext } from '../../store/apiState/apiTestContext';
import { useParams } from 'react-router-dom';
import { useGetCurrentTest } from '.';
import { toast } from 'react-toastify';

const useAssertionsSetter = () => {
  const { testId, newTestId, exampleId } = useParams();
  const { setTestsTab } = useContext(ApiTestContext);
  const handleTestAssertionsUpdate = (newAssertionsList = []) => {
    setTestsTab((prev) => {
      const prevTestsTabs = [...prev.testsList];
      const newTestsTabs = prevTestsTabs.map((test) => {
        if (
          test?.testDetails.id === newTestId ||
          test?.testDetails.id === testId ||
          test?.testDetails.id === exampleId
        ) {
          test.testPropsForRequest.assertionsSelect = newAssertionsList;
        }
        return test;
      });
      return { ...prev, testsList: newTestsTabs };
    });
  };
  return { handleTestAssertionsUpdate };
};

export const useSetTestAssertions = () => {
  const { handleTestAssertionsUpdate } = useAssertionsSetter();
  const activeTest = useGetCurrentTest();
  // const checkAssertionExist = (assertionType) => {
  //   let assert = false;
  //   if (!!activeTest?.testPropsForRequest?.assertionsSelect) {
  //     let currentAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
  //     if (!!currentAssertions) {
  //       assert = currentAssertions?.find((item) => {
  //         return item?.selectedOption === assertionType;
  //       });
  //     }
  //     console.log(currentAssertions);
  //   }

  //   return assert;
  // };
  const handleAddNewAssertion = (newAssertionEntry) => {
    let currentAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    if (!!newAssertionEntry) {
      currentAssertions = [...currentAssertions, newAssertionEntry];
    } else {
      currentAssertions = [
        ...currentAssertions,
        { selectedOption: '', selectedOperator: '', range: [], oneOf: [], value: '' }
      ];
    }
    handleTestAssertionsUpdate(currentAssertions);
  };
  const handleDeleteAssertion = (index) => {
    let currentAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    let newAssertions = [...currentAssertions.toSpliced(index, 1)];
    handleTestAssertionsUpdate(newAssertions);
  };
  const handleSelectAssertionOption = (index, value) => {
    const configValue = () => {
      let _value = '';
      if (value === 'create global variable') {
        _value = { variableName: '', value: '', cascade: true };
      }
      if (value === 'response header') {
        _value = { key: '', value: '' };
      }
      return _value;
    };
    let newAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    newAssertions[index] = {
      ...newAssertions[index],
      selectedOption: value,
      value: configValue(),
      selectedOperator: value !== 'request duration' ? 'equal' : 'less_than',
      range: [],
      oneOf: []
    };
    handleTestAssertionsUpdate(newAssertions);
  };
  const handleOperatorSelect = (index, value) => {
    let currentAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    const initialOperator = currentAssertions[index].selectedOperator;
    const assertType = currentAssertions[index].selectedOption;
    let newAssertions = [...currentAssertions];
    newAssertions[index] = {
      ...newAssertions[index],
      selectedOperator: value
      // range: ''
      //dont know remember why i want the range cleared when an operator changes, no thing seems broken a.t.m
    };
    if (assertType === 'response body type') {
      if (initialOperator === 'one_of') {
        newAssertions[index] = {
          ...newAssertions[index],
          value: ''
        };
      }
      if (value === 'one_of') {
        newAssertions[index] = {
          ...newAssertions[index],
          value: newAssertions[index].oneOf
        };
      }
    }
    handleTestAssertionsUpdate(newAssertions);
  };
  const handleStatusCodeListEdit = (assertionIndex, codeIndex) => {
    let newAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    newAssertions[assertionIndex].oneOf.splice(codeIndex, 1);
    console.log(newAssertions);
    handleTestAssertionsUpdate(newAssertions);
  };
  const handleOneOfInsertions = (index, value) => {
    let newAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    newAssertions[index].oneOf.push(value);
    console.log(newAssertions);
    handleTestAssertionsUpdate(newAssertions);
  };
  const handleAssertionValueFieldChange = (index, value) => {
    let newAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    const selectedOperator = newAssertions[index]?.selectedOperator;
    if (selectedOperator === 'one_of') {
      newAssertions[index] = { ...newAssertions[index], oneOf: value };
    } else {
      newAssertions[index] = { ...newAssertions[index], value };
    }
    handleTestAssertionsUpdate(newAssertions);
  };
  const handleAssertHeaderValue = (index, field, value) => {
    let newAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    newAssertions[index].value[field] = value.trim();
    handleTestAssertionsUpdate(newAssertions);
  };
  const handleCreateVariableFromJson = (index, field, value) => {
    let newAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    if (field === 'variableName') newAssertions[index].value[field] = value.trim();
    else if (field === 'value') {
      newAssertions[index].value[field] = value.slice(8).trim();
    } else {
      newAssertions[index].value[field] = value;
    }
    handleTestAssertionsUpdate(newAssertions);
  };
  const handleRangeLimit = (index, value, position) => {
    let newAssertions = [...activeTest?.testPropsForRequest?.assertionsSelect];
    let newRange = newAssertions[index].range;
    if (newRange === '') {
      newRange = [];
    }
    newRange[position] = value;

    newAssertions[index] = {
      ...newAssertions[index],
      range: newRange
    };
    console.log(newAssertions);
    if (position === 0 && !!newAssertions[index].range[1]) {
      if (Number(value) > Number(newAssertions[index].range[1])) {
        if (!toast.isActive('range-warning'))
          toast.warning(`lower limit value should NOT exceed upper limit value`, {
            toastId: 'range-warning',
            autoClose: 2000,
            hideProgressBar: true
          });
      }
    }
    if (position === 1 && !!newAssertions[index].range[0]) {
      if (Number(value) < Number(newAssertions[index].range[0])) {
        if (!toast.isActive('range-warning'))
          toast.warning(`upper limit value SHOULD exceed lower limit value`, {
            toastId: 'range-warning',
            autoClose: 2000,
            hideProgressBar: true
          });
      }
    }
    handleTestAssertionsUpdate(newAssertions);
  };
  return {
    // checkAssertionExist,
    handleRangeLimit,
    handleAddNewAssertion,
    handleDeleteAssertion,
    handleOneOfInsertions,
    handleAssertionValueFieldChange,
    handleSelectAssertionOption,
    handleOperatorSelect,
    handleStatusCodeListEdit,
    handleAssertHeaderValue,
    handleCreateVariableFromJson
  };
};
