import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TextField,
  Box,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import { useFocusedState } from '../../../hooks/useFocusedState';
import Flex from '../../../components/base/Flex';
import { useGetCurrentTest } from '../../../hooks/apiHooks';
import { useSetQueryCookies } from '../../../hooks/apiHooks/updateTestPropsHooks';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`
  }
}));

const CookiesTabPage = () => {
  const rowStyle = {
    height: '12px',
    outline: 'none',
    fontSize: '12px',
    marginTop: '4px'
  };
  /////////////////////////////////////
  const { handleVariableFloatingWindow } = useFocusedState();
  const {
    testPropsForRequest: { cookies }
  } = useGetCurrentTest();
  const { handleSetQueryCookies, handleCookiesDelete } = useSetQueryCookies();
  return (
    <Table sx={{ width: '98%' }} size="small" aria-label={'a Query Param Table'}>
      <TableHead>
        <TableRow>
          <StyledTableCell>{''}</StyledTableCell>
          <StyledTableCell>Key</StyledTableCell>
          <StyledTableCell>Value</StyledTableCell>
          <StyledTableCell>Expires In</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cookies.map((param, index) => (
          <TableRow key={index}>
            <StyledTableCell>{''}</StyledTableCell>
            <StyledTableCell>
              <TextField
                size={'small'}
                fullWidth
                placeholder={'key'}
                value={param.key}
                onClick={(e) => {
                  handleSetQueryCookies(e, index, 'key');
                }}
                onChange={(e) => {
                  handleVariableFloatingWindow(e);
                  handleSetQueryCookies(e, index, 'key');
                }}
                variant={'standard'}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  style: {
                    ...rowStyle
                  }
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                size={'small'}
                fullWidth
                placeholder={'value'}
                value={param.value}
                onClick={(e) => {
                  handleSetQueryCookies(e, index, 'value');
                }}
                onChange={(e) => {
                  handleVariableFloatingWindow(e);
                  handleSetQueryCookies(e, index, 'value');
                }}
                variant={'standard'}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  style: {
                    ...rowStyle
                  }
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Flex>
                <TextField
                  size={'small'}
                  fullWidth
                  type="number"
                  placeholder={'expiration value in seconds'}
                  value={param.description}
                  onChange={(e) => handleSetQueryCookies(e, index, 'expires_at')}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    inputMode: 'numeric',
                    style: {
                      ...rowStyle
                    }
                  }}
                />
                {(!!param.key || !!param.value) && (
                  <Tooltip title={'Delete'}>
                    <Box
                      sx={{ cursor: 'pointer', flex: '2%' }}
                      onClick={() => {
                        handleCookiesDelete(index);
                      }}>
                      <DeleteSvg width={16} height={18} />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default CookiesTabPage;
