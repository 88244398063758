import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ContainedButton from '../../../../components/base/ContainedButton';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TextField, Typography } from '@mui/material';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import { useContext, useState } from 'react';
import { MuiMarkdown } from 'mui-markdown';
import { ApiTestContext } from '../../../../store/apiState/apiTestContext';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
import { useSaveTestDescription } from '../../../../hooks/apiHooks/queryHooks';

const TestDescriptionModal = () => {

  /////////////////////////////////////////////////////
  const { testDescription, setTestDescription } = useContext(ApiTestContext);
  const { closeTestDescriptionModal, openTestDescriptionModal } = useContext(
    ApiDialogsAndModalsContext
  );
  const { saveDescription, isSavingDescription } = useSaveTestDescription();
  const handleSaveDescription = async () => {
    const response = await saveDescription({ documentation: testDescription });
  };
  return (
    <Dialog
      open={openTestDescriptionModal}
      onClose={closeTestDescriptionModal}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 10
        }
      }}>
      <IconButton sx={{ ml: 'auto', pr: '20px', pt: '20px' }} onClick={closeTestDescriptionModal}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogTitle color={'primary'} fontSize={25}>
        {' '}
        Add description
      </DialogTitle>
      <DialogContent>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: 'gray',
                borderWidth: 1
              }
            }
          }}
          // autoFocus
          margin="dense"
          id="name"
          fullWidth
          variant="outlined"
          size="lg"
          minRows={10}
          multiline
          value={testDescription}
          onChange={(e) => {
            setTestDescription(e.target.value);
          }}
        />
        <Typography
          sx={{
            mt: 2,
            fontSize: 18,
            color: 'rgba(0, 0, 0, 0.8)'
          }}>
          Preview{' '}
        </Typography>

        <h3>
          <ReactMarkdown>{testDescription}</ReactMarkdown>{' '}
        </h3>

        <ContainedButton
          isTextEmpty={testDescription.trim() === ''}
          isLoading={isSavingDescription}
          onClick={() => {
            // console.log(testDescription);
            handleSaveDescription();
          }}
          loadingProps={{ size: 14 }}
          sx={{ flex: '100%', width: '100%', mt: 5, pt: 1.5, pb: 1.5, fontSize: 15 }}>
          Save
        </ContainedButton>
      </DialogContent>
    </Dialog>
  );
};

export default TestDescriptionModal;
