import { useContext } from 'react';
import { useGetCurrentTest } from '..';
import {
  assertionSelectValidEntries,
  convertQueryParamsArrayToSearchParams,
  fileParseToBase64String,
  getJSONResponseFields,
  getJSONResponseKeys,
  isValidUrl,
  jsonToObject,
  parseStringForGlobalAndDynamicValues,
  replaceDynamicBooleanValueInJSONBody,
  swapPathVariablesInURL
} from '../../../pages/ApiSuites/utils';
import { useGlobalVariables } from '../../../store/globalVariables';
import {
  functionToExtractResponseBody,
  functionToExtractResponseHeaders,
  runAssertions
} from '../../../pages/ApiSuites/localTestRunUtils';
import { useActiveProject } from '../../../store/projectState';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useMutation, useQuery } from 'react-query';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { toast } from 'react-toastify';
import { useUpdateGlobalVariables } from '.';
import { checkResponseHeadersForCookies, cookiesToArray } from './useRunApiTest';
import { useSetUIProps } from '../uiPropsHooks';
import { isNumber } from 'lodash';
import { useSetTestRunResponse } from '../updateTestPropsHooks';
import { configureTestDataForLocalOrAgentRun } from './useRunApiTestLocally';

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const useConfigAgentRunQueryData = () => {
  const { testPropsForRequest } = useGetCurrentTest();
  const {
    testAgent: { runAgent }
  } = useContext(ApiTestContext);
  const { requestMethod, assertionsSelect, requestBodyMode, rawBodyLanguage } = testPropsForRequest;
  const { globalVariables } = useGlobalVariables();
  const { updateGlobalVariables } = useUpdateGlobalVariables();
  const handleUpdateVariablesIfAsserted = (newEntry) => {
    updateGlobalVariables({
      variables: [...globalVariables, newEntry]
    });
  };
  const handleConfigAgentRunQueryData = async () => {
    if (runAgent !== 'Desktop-Agent') {
      return;
    }
    // if (!triggerConfigDataForAgentRun) {
    //   return;
    // }
    // console.log('tried to config for agent');
    const { urlForFetch, requestBody, request_data, headersForFetch } =
      await configureTestDataForLocalOrAgentRun(
        testPropsForRequest,
        globalVariables,
        'desktop-agent'
      );
    // if (headersForFetch['Content-Type'] === 'multipart/form-data') {
    //   delete headersForFetch['Content-Type'];
    // }
    const testAssertions = assertionSelectValidEntries(assertionsSelect);
    const queryData = {
      url: urlForFetch,
      request_method: requestMethod,
      mode: requestMethod.toUpperCase() === 'GET' ? 'none' : requestBodyMode,
      language: rawBodyLanguage,
      headers: headersForFetch,
      requestBody: requestMethod.toUpperCase() === 'GET' ? null : requestBody,
      testAssertions,
      request_data,
      handleUpdateVariablesIfAsserted
    };
    return queryData;
  };
  return { handleConfigAgentRunQueryData };
};
export const useHandleRunApiTestByAgent = () => {
  const {
    testAgent: { agentPort }
  } = useContext(ApiTestContext);
  const { handleConfigAgentRunQueryData } = useConfigAgentRunQueryData();
  const queryData = handleConfigAgentRunQueryData();
  const { desktopAgentRunQueryFn } = collectConfiguredDataForAgentQueryFn(queryData, agentPort);
  const { handleUpdateUIOnAgentRunSuccess } = useUpdateUIOnAgentRunSuccess();
  const { handleUpdateUIOnRunError } = useUpdateUIonError();
  return useMutation({
    queryKey: ['run-mutation-by-agent'],
    mutationFn: desktopAgentRunQueryFn,
    onSuccess: (data) => {
      // console.log(data);
      handleUpdateUIOnAgentRunSuccess(data);
    },
    onError: (error) => {
      handleUpdateUIOnRunError(error);
    }
  });
};
export const useHandleDesktopAgentRun = () => {
  const {
    testPropsForRequest: { url }
  } = useGetCurrentTest();
  const { setTestAgent } = useContext(ApiTestContext);
  const { mutateAsync: runApiTestByAgent, isLoading: isRunningApiTestByAgent } =
    useHandleRunApiTestByAgent();
  const handleRunApiTestByAgent = async () => {
    if (!isValidUrl(url)) {
      toast.error('Please enter a valid url');
      return;
    }
    setTestAgent((prev) => ({ ...prev, triggerConfigDataForAgentRun: true }));
    try {
      await runApiTestByAgent();
    } catch (error) {
      toast.error('Failed to run test. Please try again');
      throw error; // may not be necessary
    }
  };
  return { handleRunApiTestByAgent, isRunningApiTestByAgent };
};
const collectConfiguredDataForAgentQueryFn = (data, agentPort) => {
  const desktopAgentRunQueryFn = async () => {
    let dataForError = {
      hasResponse: true,
      runResultDataForSaving: {
        assertions: {},
        headers: [],
        size: 0,
        status_code: 0,
        time_taken: 0
      },
      responseBody: '',
      isResponseBodyAFile: false,
      responseStatusCode: 0,
      responseSize: 0,
      responseHeaders: [],
      responseAssertions: {},
      responseCookies: [],
      responseTimeTaken: 0,
      contentTypeValue: ''
    };
    let _data;
    try {
      _data = await data;
    } catch (error) {
      // error thrown while calculating request data
      const errorObject = new Error(error?.message);
      error.type = 'preFetch error';
      errorObject.data = { ...dataForError, responseBody: JSON.stringify(error, null, 2) };
      throw error;
    }
    const { testAssertions, handleUpdateVariablesIfAsserted } = _data;
    let response;
    try {
      response = await fetch(`http://localhost:${agentPort}/api/run-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(_data)
      });
    } catch (error) {
      // error thrown while making request to scandium agent, src is from browser
      const errorObject = new Error(error?.message);
      dataForError.runResultDataForSaving = {
        ...dataForError.runResultDataForSaving,
        body: error?.message || 'Failed to run test'
      };
      error.type = 'postFetch error';
      errorObject.data = { ...dataForError, responseBody: error?.message };
      throw errorObject;
    }
    let result = await response.json();

    if (!response.ok) {
      // error status returned by scandium agent
      let errorObject = new Error();
      errorObject.type = 'postFetch error'; //src is from scandium agent
      errorObject.data = {
        ...dataForError,
        runResultDataForSaving: {
          ...dataForError.runResultDataForSaving,
          body: result?.message || 'Failed to run test'
        },
        requestData: result?.request_data,
        responseStatusCode: result?.status,
        responseBody: result?.message
      };
      throw errorObject;
    }
    const allAssertionResult = runAssertions(
      testAssertions,
      result.status_code,
      result.body,
      result.headers,
      result.contentType,
      result.size,
      handleUpdateVariablesIfAsserted
    );
    let addToResult = { ...result, assertions: allAssertionResult };
    return addToResult;
  };
  return { desktopAgentRunQueryFn };
};
const useUpdateUIOnAgentRunSuccess = () => {
  const { handleResponseEditorProps } = useSetUIProps();
  const { handleSetTestRunResponse, handleScrollToResponse } = useSetTestRunResponse();
  const { setApiGlobalProps } = useContext(ApiTestContext);
  const handleUpdateUIOnAgentRunSuccess = async (data) => {
    const runResult = data;
    const runResultDataForSaving = JSON.parse(JSON.stringify(runResult)); // prevents data referencing
    let cookieHeader = checkResponseHeadersForCookies(runResult.headers);
    const contentTypeValue = runResult.contentType;
    let bodyLanguage = contentTypeValue?.split(';')[0].split('/')[1] || 'plaintext';
    bodyLanguage === 'plain' && (bodyLanguage = 'plaintext');
    let responseBody = '';
    let isResponseBodyAFile = false;
    let responseStatusCode;
    let responseSize = 0;
    let responseHeaders = [];
    let responseAssertions = {};
    let responseCookies = [];
    let requestData = {};
    let responseTimeTaken = '';
    if (!runResult.body) {
      responseBody = 'No response body from server';
    }
    requestData = runResult?.request_data;
    requestData.log_error = runResult?.log_error;
    if (isNumber(runResult?.status_code)) {
      if (runResult.status_code === 0) {
        requestData.isError = true;
      }
    }
    if (!!cookieHeader) {
      cookieHeader = cookiesToArray(cookieHeader);
      responseCookies = cookieHeader;
    }
    if (contentTypeValue.includes('json')) {
      responseBody = JSON.stringify(runResult?.body, null, 2);
      const dataForDynamicJsonFieldHighlight = runResult?.body;
      handleResponseEditorProps({
        jsonResponseFields: {
          keys: getJSONResponseKeys(dataForDynamicJsonFieldHighlight),
          fields: getJSONResponseFields(dataForDynamicJsonFieldHighlight)
        }
      });
    } else {
      responseBody = runResult?.body;
    }
    if (!!runResult.isBlob) {
      isResponseBodyAFile = true;
      let base64String = await fileParseToBase64String(responseBody);
      base64String = base64String.split(',')[1];
      responseBody = base64String;
    }
    if (!!runResult?.headers) {
      responseHeaders = runResult?.headers;
    }
    if (!!runResult?.size) {
      const _size = +runResult?.size;
      responseSize = _size;
    }
    responseStatusCode = runResult?.status_code;
    if (!!runResult?.assertions) {
      responseAssertions = runResult?.assertions;
    }
    responseTimeTaken = runResult?.time_taken;
    let responseData = {
      hasResponse: true,
      runResultDataForSaving,
      responseBody,
      isResponseBodyAFile,
      responseStatusCode,
      responseSize,
      responseHeaders,
      responseAssertions,
      responseCookies,
      responseTimeTaken,
      contentTypeValue
    };
    handleScrollToResponse();
    handleResponseEditorProps({
      responseEditorLanguage: bodyLanguage
    });
    handleSetTestRunResponse(responseData);
    setApiGlobalProps((prev) => {
      const prevConsoleData = [...prev.consoleData];
      return { ...prev, consoleData: [...prevConsoleData, requestData] };
    });
    // no need to check for file url for formdata base64 string request
  };
  return { handleUpdateUIOnAgentRunSuccess };
};

const useUpdateUIonError = () => {
  const { handleResponseEditorProps } = useSetUIProps();
  const { handleSetTestRunResponse, handleScrollToResponse } = useSetTestRunResponse();
  const { setApiGlobalProps } = useContext(ApiTestContext);
  const handleUpdateUIOnRunError = (error) => {
    //instances of errors
    //errors due to calc fetch body pre-fetch, toast  this error
    // errors due to fetch e.g network errors - after fetch
    // errors due to 500 response from server (!ok) - after fetch, this error also has a json response
    let errorObject = JSON.parse(JSON.stringify(error));
    handleScrollToResponse();
    handleSetTestRunResponse(errorObject.data);
    handleResponseEditorProps({
      responseEditorLanguage: 'plaintext'
    });
    setApiGlobalProps((prev) => {
      const prevConsoleData = [...prev.consoleData];
      return { ...prev, consoleData: [...prevConsoleData, errorObject.requestData] };
    });
  };
  return { handleUpdateUIOnRunError };
};
