import Editor from '@monaco-editor/react';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Chip, Divider, Input, TextField, tableCellClasses } from '@mui/material';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import { styled } from '@mui/material/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Flex from '../../../components/base/Flex';
import UploadFileSvg from '../../../components/svg/UploadFileSvg';
import { useMenu } from '../../../components/base/useMenu';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useFocusedState } from '../../../hooks/useFocusedState';
import { ColorContext } from '../../../AppTheme';
import { FloatingVariableWindowContext } from '../../../store/floatingVariableWindowState';
import { useSetQueryBody } from '../../../hooks/apiHooks/updateTestPropsHooks';
import { useGetCurrentTest } from '../../../hooks/apiHooks';
import { ApiDialogsAndModalsContext } from '../../../store/apiState/apiDialogsAndModalsContext';
const StyledSelect = styled(Select)({
  width: '80px',
  height: '20px',
  fontSize: '10px'
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`
  }
}));

// function to extract file via file reader
const rawFileImport = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    fileReader.onerror = (error) => {
      reject(toast.error('failed to load file'));
    };
    fileReader.readAsText(file);
  });
};
export const fileParser2 = async (file) => {
  // const textMap = ['text', 'document', 'plain', 'application/json', 'javascript', 'html'];
  // const otherMap = ['text', 'document', 'plain', 'application/json', 'javascript', 'html','image', 'pdf', 'video', 'jpeg', 'jpg', 'png'];
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    fileReader.onerror = (error) => {
      reject(toast.error('failed to load file'));
    };
    fileReader.readAsDataURL(file);
  });
};

const FormDataTable = () => {
  const { handleVariableFloatingWindow } = useFocusedState();
  const {
    testPropsForRequest: { formData }
  } = useGetCurrentTest();
  const { handleSetFormData, handleFormDataRowDelete } = useSetQueryBody();
  return (
    <Table sx={{ width: '98%' }} size="small" aria-label={'a Form Data Table'}>
      <TableHead>
        <TableRow>
          <StyledTableCell>{''}</StyledTableCell>
          <StyledTableCell>Key</StyledTableCell>
          <StyledTableCell>Value</StyledTableCell>
          <StyledTableCell>Description</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {formData.map((data, index) => (
          <TableRow key={index}>
            <StyledTableCell>{''}</StyledTableCell>
            <StyledTableCell>
              <Flex>
                <TextField
                  size={'small'}
                  fullWidth
                  placeholder={'key'}
                  value={data.key}
                  onChange={(e) => {
                    handleVariableFloatingWindow(e, () => {
                      handleSetFormData(e, index, 'key');
                    });
                    handleSetFormData(e, index, 'key');
                  }}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    style: {
                      height: '12px',
                      outline: 'none',
                      fontSize: '12px',
                      marginTop: '4px'
                    }
                  }}
                />
                <StyledSelect
                  value={data.type}
                  onChange={(e) => {
                    handleSetFormData(e, index, 'type');
                  }}>
                  <MenuItem value="text"> Text </MenuItem>
                  <MenuItem value="file"> File</MenuItem>
                </StyledSelect>
              </Flex>
            </StyledTableCell>
            <StyledTableCell
              sx={
                {
                  // width: '30%'
                }
              }>
              {data.type === 'text' ? (
                <TextField
                  size={'small'}
                  fullWidth
                  placeholder={'value'}
                  value={data.value}
                  onChange={(e) => {
                    handleVariableFloatingWindow(e, () => {
                      handleSetFormData(e, index, 'value');
                    });
                    handleSetFormData(e, index, 'value');
                  }}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    style: {
                      height: '12px',
                      outline: 'none',
                      fontSize: '12px',
                      marginTop: '4px'
                    }
                  }}
                />
              ) : (
                <FileInputComponent
                  data={data}
                  index={index}
                  // handleInputChange={handleInputChange}
                />
              )}
            </StyledTableCell>
            <StyledTableCell>
              <Flex>
                <TextField
                  size={'small'}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  fullWidth
                  placeholder={'description'}
                  value={data.description}
                  onChange={(e) => handleSetFormData(e, index, 'description')}
                  inputProps={{
                    style: {
                      height: '12px',
                      outline: 'none',
                      fontSize: '12px',
                      marginTop: '4px'
                    }
                  }}
                />
                {(!!data.key || !!data.value) && (
                  <Tooltip title={'Delete'}>
                    <Box
                      sx={{ cursor: 'pointer', flex: '2%' }}
                      onClick={() => {
                        handleFormDataRowDelete(index);
                      }}>
                      <DeleteSvg width={16} height={18} />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
const FormUrlEncodedTable = () => {
  const { handleVariableFloatingWindow } = useFocusedState();
  const {
    testPropsForRequest: { formUrlEncoded }
  } = useGetCurrentTest();
  const { handleSetFormUrlEncoded, handleFormUrlEncodedRowDelete } = useSetQueryBody();

  return (
    <Table sx={{ width: '98%' }} size="small" aria-label={'a Query Param Table'}>
      <TableHead>
        <TableRow>
          <StyledTableCell>{''}</StyledTableCell>
          <StyledTableCell>Key</StyledTableCell>
          <StyledTableCell>Value</StyledTableCell>
          <StyledTableCell>Description</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {formUrlEncoded?.map((data, index) => (
          <TableRow key={index}>
            <StyledTableCell>{''}</StyledTableCell>
            <StyledTableCell>
              <Flex>
                <TextField
                  size={'small'}
                  fullWidth
                  placeholder={'key'}
                  value={data.key}
                  onChange={(e) => {
                    handleVariableFloatingWindow(e, () => {
                      handleSetFormUrlEncoded(e, index, 'key');
                    });
                    handleSetFormUrlEncoded(e, index, 'key');
                  }}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    style: {
                      height: '12px',
                      outline: 'none',
                      fontSize: '12px',
                      marginTop: '4px'
                    }
                  }}
                />
              </Flex>
            </StyledTableCell>
            <StyledTableCell
              sx={
                {
                  // width: '30%'
                }
              }>
              <TextField
                size={'small'}
                fullWidth
                placeholder={'value'}
                value={data.value}
                onChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleSetFormUrlEncoded(e, index, 'value');
                  });
                  handleSetFormUrlEncoded(e, index, 'value');
                }}
                variant={'standard'}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  style: {
                    py: '2px',
                    height: '12px',
                    outline: 'none',
                    fontSize: '12px',
                    marginTop: '4px'
                  }
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Flex>
                <TextField
                  size={'small'}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  fullWidth
                  placeholder={'description'}
                  value={data.description}
                  onChange={(e) => handleSetFormUrlEncoded(e, index, 'description')}
                  inputProps={{
                    style: {
                      height: '12px',
                      outline: 'none',
                      fontSize: '12px',
                      marginTop: '4px'
                    }
                  }}
                />
                {(!!data.key || !!data.value) && (
                  <Tooltip title={'Delete'}>
                    <Box
                      sx={{ cursor: 'pointer', flex: '2%' }}
                      onClick={() => {
                        handleFormUrlEncodedRowDelete(index);
                      }}>
                      <DeleteSvg width={16} height={18} />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const SelectRawFileType = () => {
  const { handleSetRawLanguage, handleAddContentTypeHeader } = useSetQueryBody();
  const {
    testPropsForRequest: { rawBodyLanguage }
  } = useGetCurrentTest();

  ///////////////////////////////////////////////////////////////////////////
  const { triggerProps, menuProps } = useMenu();
  const onSelect = (str) => {
    // setLanguage(str);
    handleAddContentTypeHeader(str);
    handleSetRawLanguage(str);
    menuProps.onClose();
  };
  const textMap = {
    javascript: 'Javascript',
    json: 'JSON',
    xml: 'XML',
    html: 'HTML',
    plaintext: 'Text',
    graphql: 'GraphQL'
  };
  return (
    <Box>
      <Button
        {...triggerProps}
        disableRipple={true}
        sx={{
          py: 0,
          px: 0,
          minWidth: 0,
          textTransform: 'none',
          maxWidth: 'max-content',
          '&: hover': {
            background: 'transparent'
          }
        }}>
        {textMap[rawBodyLanguage] || ''}
        <ExpandMore ml={1} color={'inherit'} />
      </Button>
      <Menu
        id="action-menu"
        elevation={1}
        {...menuProps}
        PaperProps={{
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        <MenuItem onClick={() => onSelect('json')}>JSON</MenuItem>
        <MenuItem onClick={() => onSelect('plaintext')}>Text</MenuItem>
        <MenuItem onClick={() => onSelect('javascript')}>Javascript</MenuItem>
        <MenuItem onClick={() => onSelect('html')}>HTML</MenuItem>
        <MenuItem onClick={() => onSelect('xml')}>XML</MenuItem>
        <MenuItem onClick={() => onSelect('graphql')}>GraphQL</MenuItem>
      </Menu>
    </Box>
  );
};
const QueryBody = () => {
  const {
    handleSetRequestBodyMode,
    handleAddContentTypeHeader,
    handleSetRawRequestBody,
    handleSetGraphQlVariables
  } = useSetQueryBody();
  const {
    testPropsForRequest: { rawBodyLanguage, requestBodyMode, graphQLVariables, rawRequestBody }
  } = useGetCurrentTest();
  ///////////////////////////////////
  const { mode } = useContext(ColorContext);
  const {
    editorRef,
    showVariableFloatingWindow,

    variableFloatingWindowRef,
    setIsEditorFocused,
    setFocusedInputPosition
  } = useContext(FloatingVariableWindowContext);
  const { requestVariablesSuggestionList, closeVariablesSuggestionList } = useContext(
    ApiDialogsAndModalsContext
  );
  const rawFileUpload = useRef(null);
  const [rawFileName, setRawFileName] = useState('');
  const handleFileSelect = async (e) => {
    let selectedRawFile = e.target.files[0] || null;
    // const fileExtension = selectedRawFile?.name.split('.').pop();
    const validFileType = ['text/plain', 'application/json', 'text/javascript', 'text/html'];
    if (!!selectedRawFile && !validFileType.includes(selectedRawFile.type)) {
      return toast.error('invalid file type', {
        autoClose: 3
      });
    }
    setRawFileName((prev) => {
      return e.target.files[0]?.name || prev;
    });
    const readFile = await rawFileImport(selectedRawFile);
    handleSetRawRequestBody(readFile);
  };
  const beautifyRawBody = (content, type) => {
    if (type === 'json') {
      try {
        const parsedJSON = JSON.parse(content);
        const beautifiedJSON = JSON.stringify(parsedJSON, null, 2);
        return beautifiedJSON;
      } catch (error) {
        return content; // Return as is if JSON parsing fails
      }
    } else if (type === 'xml') {
      // Beautify XML content using basic formatting logic
      try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, 'text/xml');
        const beautifiedXML = new XMLSerializer().serializeToString(xmlDoc);
        return beautifiedXML;
      } catch (error) {
        return content; // Return as is if XML parsing fails
      }
    } else if (type === 'text') {
      // For plain text or unknown types, return as is
      return content;
    } else if (type === 'html') {
      // Beautify HTML content using an HTML parser (e.g., js-beautify)
      try {
        const beautifiedHTML = window.html_beautify(content, {
          indent_size: 2
        });
        return beautifiedHTML;
      } catch (error) {
        return content; // Return as is if HTML parsing fails
      }
    } else if (type === 'javascript') {
      // Beautify JavaScript content using a JavaScript beautifier (e.g., js-beautify)
      try {
        const beautifiedJS = window.js_beautify(content, {
          indent_size: 2
        });
        return beautifiedJS;
      } catch (error) {
        return content; // Return as is if JavaScript parsing fails
      }
    } else {
      return content; // Return as is for unknown types
    }
  };

  const handleBeautify = () => {
    const beautified = beautifyRawBody(rawRequestBody, rawBodyLanguage);
    handleSetRawRequestBody(beautified);
    // setRawContent(beautified);
  };

  const handleEditorFocus = () => {
    setIsEditorFocused(true);
  };
  const handleCursorPositionChanged = (event) => {
    let position = event.position;
    let layoutInfo = editorRef.current.getLayoutInfo();
    // const coordinates = editorRef.current.getOffsetForPosition(position);
    // Get the editor's offset position
    let editorRect = editorRef.current.getDomNode().getBoundingClientRect();
    // console.log(lineHeight);
    // const cursorTop = lineTop * (position.lineNumber - 1);
    // const cursorLeft = columnOffset + layoutInfo.glyphMarginWidth;
    // const cursorLeft = editorRef.current.getOffsetForColumn(position.lineNumber, position.column);
    // Convert to window coordinates
    let top = position.lineNumber * 16;
    let left = 100 + position.column * 7;
    const cursorTopInWindow = editorRect.top + top;
    const cursorLeftInWindow = editorRect.left + left;
    setFocusedInputPosition({
      top: cursorTopInWindow,
      left: cursorLeftInWindow
    });
  };
  const handleModelContentChange = useCallback(() => {
    if (editorRef.current) {
      const { column, lineNumber } = editorRef.current.getPosition();
      const currentValueAtLine = editorRef.current.getModel().getLineContent(lineNumber);
      // const checkBrackets =
      //   currentValueAtLine.slice(column - 3, column - 1) +
      //   currentValueAtLine.slice(column - 1, column + 1);
      const checkBrackets = currentValueAtLine.slice(column - 3, column - 1);
      if (checkBrackets === '{{') {
        requestVariablesSuggestionList();
      } else {
        closeVariablesSuggestionList(false);
      }
    }
  }, []);
  const handleEditorMount = (editor, monaco) => {
    editorRef.current = editor;
    // editorRef.current.onDidBlurEditorText(handleBlur);
    editorRef.current.onDidFocusEditorText(handleEditorFocus);
    editorRef.current.onDidChangeModelContent(handleModelContentChange);
    editorRef.current.onDidChangeCursorPosition(handleCursorPositionChanged);
  };
  useEffect(() => {
    variableFloatingWindowRef.current = showVariableFloatingWindow;
    // console.log('ref is now' + variableFloatingWindowRef.current);
  }, [showVariableFloatingWindow]);
  const editorOptions = {
    fontSize: 12,
    formatOnPaste: true,
    renderIndentGuides: true,
    formatOnType: true,
    inlineSuggest: true,
    autoClosingBrackets: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };
  ////////////////////////////////////

  return (
    <Box mt={2}>
      <Flex justifyContent={'space-between'}>
        <Flex columnGap={0} pl={'1px'}>
          <FormControl>
            <RadioGroup
              row
              value={requestBodyMode}
              onChange={(event) => {
                handleAddContentTypeHeader(event.target.value);
                // setRequestBodyType(event.target.value);
                handleSetRequestBodyMode(event.target.value);
              }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group">
              <FormControlLabel
                value={'none'}
                control={<Radio size={'small'} />}
                label={
                  <Typography variant="body2" color="textSecondary">
                    none
                  </Typography>
                }
              />
              <FormControlLabel
                value={'formData'}
                control={<Radio size={'small'} />}
                label={
                  <Typography variant="body2" color="textSecondary">
                    form-data
                  </Typography>
                }
              />
              <FormControlLabel
                value={'urlencoded'}
                control={<Radio size={'small'} />}
                label={
                  <Typography variant="body2" color="textSecondary">
                    x-www-form-urlencoded
                  </Typography>
                }
              />
              <FormControlLabel
                value={'raw'}
                control={<Radio size={'small'} />}
                label={
                  <Typography variant="body2" color="textSecondary">
                    raw
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          {requestBodyMode === 'raw' && (
            <>
              <SelectRawFileType
              // handleUpdateQueryHeaders={handleUpdateQueryHeaders}
              // setLanguage={setLanguage}
              // activeTest?.testPropsForRequest?.rawBodyLanguage={activeTest?.testPropsForRequest?.rawBodyLanguage}
              />
              <Tooltip title={'Upload a file content'}>
                <IconButton
                  aria-label="upload file"
                  size="small"
                  onClick={() => {
                    rawFileUpload.current.click();
                  }}>
                  <UploadFileSvg />
                </IconButton>
              </Tooltip>
              <Input
                type="file"
                sx={{ display: 'none' }}
                inputRef={rawFileUpload}
                onChange={(e) => {
                  handleFileSelect(e);
                }}
              />
              {!!rawFileName && (
                <Chip
                  label={rawFileName}
                  variant="outlined"
                  size={'small'}
                  color={'primary'}
                  onDelete={() => {
                    rawFileUpload.current.value = null;
                    handleSetRawRequestBody('');
                    setRawFileName('');
                  }}
                  IconDelete={<CloseIcon />}
                  sx={{
                    borderRadius: '0.3rem',
                    py: 1,
                    ml: 1
                  }}
                />
              )}
            </>
          )}
        </Flex>
        {requestBodyMode === 'raw' && rawBodyLanguage !== 'plaintext' && (
          <Button
            onClick={handleBeautify}
            sx={{
              minWidth: 0,
              mr: 3,
              textTransform: 'none',
              maxWidth: 'max-content'
            }}>
            Beautify
          </Button>
        )}
      </Flex>
      {requestBodyMode === 'none' && (
        <Flex justifyContent={'center'} mt={3}>
          <Typography variant={'body2'}>The request does not have a body</Typography>
        </Flex>
      )}
      {requestBodyMode === 'formData' && <FormDataTable />}
      {requestBodyMode === 'urlencoded' && <FormUrlEncodedTable />}
      {requestBodyMode === 'raw' && (
        <Flex
          sx={{
            mr: 2
            // maxWidth: activeTool && '600px'
          }}>
          <Box
            sx={{
              display: 'column',
              flex: rawBodyLanguage === 'graphql' && 2,
              width: rawBodyLanguage !== 'graphql' && '100%'
            }}>
            {rawBodyLanguage === 'graphql' && (
              <Typography
                sx={{
                  ml: 4,
                  mb: 1,
                  fontWeight: 'medium',
                  fontSize: 12
                }}>
                QUERY
              </Typography>
            )}
            <Editor
              height={'250px'}
              theme={mode === 'dark' && 'vs-dark'}
              value={rawRequestBody}
              onChange={(value, event) => {
                handleSetRawRequestBody(value);
                // setRawContent(value);
              }}
              onMount={handleEditorMount}
              language={rawBodyLanguage}
              options={editorOptions}
            />
          </Box>
          {rawBodyLanguage === 'graphql' && (
            <>
              <Divider orientation="vertical" flexItem />
              <Box
                sx={{
                  display: 'column',
                  flex: 1
                }}>
                <Typography
                  sx={{
                    ml: 4,
                    mb: 1,
                    fontSize: 12,
                    fontWeight: 'medium'
                  }}>
                  GRAPHQL VARIABLES
                </Typography>
                <Editor
                  height={'250px'}
                  theme={mode === 'dark' && 'vs-dark'}
                  value={graphQLVariables}
                  onChange={(value) => handleSetGraphQlVariables(value)}
                  language={'json'}
                  options={editorOptions}
                />
              </Box>
            </>
          )}
        </Flex>
      )}
    </Box>
  );
};

const FileInputComponent = ({ data, index, handleInputChange }) => {
  let filename = data?.filename || data.fileName;
  if (filename?.length > 27) {
    filename = filename.slice(0, 27) + '...';
  }
  const { handleSetFormData } = useSetQueryBody();
  const formDataFileUpload = useRef(null);
  return (
    <Flex>
      <Typography>{filename}</Typography>
      <Input
        inputRef={formDataFileUpload}
        sx={{ display: 'none' }}
        onClick={() => {}}
        id="file-input"
        type="file"
        disableUnderline
        onChange={(e) => {
          handleSetFormData(e, index, 'value');
        }}
      />
      {!data.filename && (
        <>
          <Typography sx={{ opacity: 0.5 }}>select file</Typography>
          <Tooltip
            title={'Upload a file'}
            sx={{
              ml: 'auto'
            }}>
            <IconButton
              aria-label="upload file"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                // formDataFileUpload.current = e.target;
                formDataFileUpload.current.click();
              }}>
              <UploadFileSvg />
            </IconButton>
          </Tooltip>
        </>
      )}
      {!!data.filename && (
        <CloseIcon
          color="disabled"
          sx={{
            ml: 'auto',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={(e) => {
            formDataFileUpload.current.value = null;
            handleSetFormData(e, index, 'clearValues');
          }}
        />
      )}
    </Flex>
  );
};

export default QueryBody;
