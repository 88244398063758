import { useContext } from 'react';
import { useScandiumQuery } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { useDebounceSearchValue } from '../useDebounce';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';
import { useParams } from 'react-router-dom';

const useFetchApiFoldersAndTests = () => {
  const { projectId } = useParams();
  const activeProject = useActiveProject();
  const { setFoldersState } = useContext(ApiTestContext);

  const { debounceSearchValue } = useDebounceSearchValue();
  let endpoint = !!debounceSearchValue
    ? `/projects/${activeProject?.id}/api-tests/all/?search=${debounceSearchValue}`
    : `/projects/${activeProject?.id}/api-tests/all`;
  const {
    isSuccess: successfullyFetchedFoldersAndTests,
    data: allFoldersAndTests = { folders: { data: [] }, test_cases: { data: [] } },
    isLoading: isFetchingAllFoldersAndTests,
    error: fetchFolderAndTestError,
    refetch: refetchFoldersAndTests
  } = useScandiumQuery(endpoint, {
    queryKey: ['get-apiFoldersAndTests', debounceSearchValue, projectId],
    enabled: !!activeProject?.id,
    select: (data) => data.data,
    onSuccess: (data) => {
      // console.log(data?.test_cases?.meta);
      setFoldersState((prev) => {
        return {
          ...prev,
          foldersList: data?.folders?.data,
          testsList: data?.test_cases?.data,
          paginate: data?.test_cases?.meta
        };
      });
    }
  });
  return {
    successfullyFetchedFoldersAndTests,
    allFoldersAndTests,
    isFetchingAllFoldersAndTests,
    fetchFolderAndTestError,
    refetchFoldersAndTests
  };
};

export default useFetchApiFoldersAndTests;
