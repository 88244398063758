import { useMemo } from 'react';
import { useGetCurrentTest } from '../../../../../hooks/apiHooks';
import CustomScrollbar from '../../../../TestPage/components/CustomScrollbar';
import Flex from '../../../../../components/base/Flex';
import NoTestAssertionsSvg from '../../../../../components/svg/NoTestAssertionsSvg';
import { Chip, Typography } from '@mui/material/node';

import {
  AllAssertionResults,
  FailedAssertionResults,
  PassedAssertionResults
} from '../../AssertionResults';
import { useSetUIProps } from '../../../../../hooks/apiHooks/uiPropsHooks';
import { useTheme } from '@emotion/react';
import { TabPanel } from '../../../NewApi';

export const AssertionsResultTab = () => {
  const theme = useTheme();
  const { handleAssertionsFilterTabChange } = useSetUIProps();
  const {
    testRunResponse: { responseAssertions },
    uiProps: { assertionsFilterTab }
  } = useGetCurrentTest();
  const assertionResultsNotNull = useMemo(() => {
    return Object.keys(responseAssertions).length > 0
      ? Object.values(responseAssertions).some((value) => value !== null)
      : false;
  }, [responseAssertions]);

  return (
    <>
      {!assertionResultsNotNull && (
        <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '200px' }}>
          <Flex justifyContent={'center'} flexDirection={'column'} mt={2}>
            <NoTestAssertionsSvg />
            <Typography mt={2} fontSize={'0.9rem'} textAlign={'center'}>
              There are no tests for this request
            </Typography>
            <Typography mt={1} textAlign={'center'} fontSize={'0.75rem'}>
              Make assertions to automate debugging
            </Typography>
          </Flex>
        </CustomScrollbar>
      )}
      {!!assertionResultsNotNull && (
        <>
          <Flex columnGap={0} my={'5px'}>
            <Chip
              label={'All'}
              variant={'outlined'}
              onClick={() => handleAssertionsFilterTabChange(0)}
              sx={{
                borderRadius: 0,
                border: 'none',
                bgcolor:
                  assertionsFilterTab === 0
                    ? 'rgba(220,220,220, 0.5)'
                    : theme.palette.background.lightGrey_dark,
                color: assertionsFilterTab === 0 ? '#1958B8' : 'inherit',
                py: 1,
                px: 1.5,
                '&: hover': {
                  color: '#1958B8',
                  backgroundColor: 'rgba(220,220,220, 0.6)'
                }
              }}
            />
            <Chip
              label={'Passed'}
              variant={'outlined'}
              onClick={() => handleAssertionsFilterTabChange(1)}
              sx={{
                border: 'none',
                borderRadius: 0,
                bgcolor:
                  assertionsFilterTab === 1
                    ? 'rgba(220,220,220, 0.5)'
                    : theme.palette.background.lightGrey_dark,
                color: assertionsFilterTab === 1 ? '#1958B8' : 'inherit',
                py: 1,
                px: 1,
                '&: hover': {
                  color: '#1958B8',
                  backgroundColor: 'rgba(220,220,220, 0.6)'
                }
              }}
            />
            <Chip
              label={'Failed'}
              variant={'outlined'}
              onClick={() => handleAssertionsFilterTabChange(2)}
              sx={{
                borderRadius: 0,
                border: 'none',
                bgcolor:
                  assertionsFilterTab === 2
                    ? 'rgba(220,220,220, 0.5)'
                    : theme.palette.background.lightGrey_dark,
                color: assertionsFilterTab === 2 ? '#1958B8' : 'inherit',
                py: 1,
                px: 1,
                '&: hover': {
                  color: '#1958B8',
                  backgroundColor: 'rgba(220,220,220, 0.6)'
                }
              }}
            />
          </Flex>
          <TabPanel value={assertionsFilterTab} index={0}>
            <AllAssertionResults />
          </TabPanel>
          <TabPanel value={assertionsFilterTab} index={1}>
            <PassedAssertionResults />
          </TabPanel>
          <TabPanel value={assertionsFilterTab} index={2}>
            <FailedAssertionResults results={responseAssertions} />
          </TabPanel>
        </>
      )}
    </>
  );
};

export default AssertionsResultTab;
