import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CustomInput from './customInput';
import CustomButton from './customButton';
import CheckboxItem from './checkboxItem';
import { useMenu } from './base/useMenu';
import Flex from './base/Flex';
import useLogout from '../hooks/useLogout';
import { useScandiumMutation } from '../data-layer/utils';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useCookies } from 'react-cookie';
import CopySvg from './svg/CopySvg';
import { IconButton, styled, Switch } from '@mui/material';
import { ColorContext } from '../AppTheme';
import { useTheme } from '@emotion/react';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import { resetMixpanelData } from '../mixpanel.constants';
import { useActiveProject } from '../store/projectState';
import { DataUsage } from '@mui/icons-material';

export const CircularImage = ({ name = 'Scandium', enterprise = false, ...props }) => {
  const activeProject = useActiveProject();
  const { mode, setMode } = useContext(ColorContext);
  const [cookies] = useCookies([
    'token',
    'email',
    'name',
    'local_features',
    'company',
    'active_company',
    'role',
    'designation'
  ]);
  const logout = useLogout();
  const { triggerProps, menuProps } = useMenu();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const showSettingsMenu =
    cookies?.active_company?.type === 'enterprise' &&
    (cookies?.role === 'owner' || cookies?.role === 'admin');

  const { mutateAsync: apiLogout } = useScandiumMutation('/auth/logout', {
    method: 'GET'
  });

  const handleLogout = async () => {
    try {
      await apiLogout();
      logout();
      queryClient.clear();
      resetMixpanelData();
    } catch (error) {
      toast.error('An error occurred, please try again');
    }
  };

  return (
    <Box>
      <Box component={'div'} className="profileImageContainer" {...props} {...triggerProps}>
        {cookies?.active_company?.avatar && enterprise ? (
          <img src={cookies?.active_company?.avatar} alt={'enterprise avatar'} />
        ) : (
          name
            .split(' ')
            .map((c) => c[0])
            .slice(0, 2)
        )}
      </Box>
      <Menu
        id="action-menu"
        {...menuProps}
        PaperProps={{
          elevation: 1,
          style: {
            maxWidth: 320,
            width: 320,
            paddingLeft: 10,
            paddingRight: 5,
            paddingBottom: 5,
            paddingTop: 5
          },
          sx: {
            overflow: 'visible',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 148,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}>
        <Typography
          fontSize={'1.1rem'}
          color={'primary'}
          fontStyle={'underline'}
          sx={{ px: 0.5, fontWeight: 500 }}>
          {name}
        </Typography>
        <Typography
          fontSize={'0.9rem'}
          fontStyle={'underline'}
          sx={{
            px: 0.5,
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.text.black_grey
          }}>
          <WorkIcon sx={{ mr: 1, color: theme.palette.svg.primary }} />{' '}
          {cookies?.active_company?.name || cookies?.company?.name || ''}
        </Typography>
        <Typography
          fontSize={'0.9rem'}
          fontStyle={'underline'}
          sx={{
            mb: 1,
            px: 0.5,
            fontWeight: 500,
            color: theme.palette.text.black_grey,
            display: 'flex',
            alignItems: 'center'
          }}>
          <EmailIcon sx={{ mr: 1, color: theme.palette.svg.primary }} />{' '}
          <Typography
            fontSize={'0.9rem'}
            sx={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all'
            }}
            component={'span'}>
            {cookies?.email || ''}
          </Typography>
        </Typography>
        <Flex
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.background.lightGrey_dark
            },
            textDecoration: 'none',
            justifyContent: 'space-between'
          }}
          p={0.5}
          component={Link}
          onClick={() => menuProps.onClose()}
          to={`/settings/profile`}>
          <Typography
            fontSize={'0.85rem'}
            color={theme.palette.text.custom}
            fontStyle={'underline'}>
            Profile
          </Typography>
          <ManageAccountsIcon sx={{ color: theme.palette.svg.primary }} fontSize={'1.2rem'} />
        </Flex>
        <Flex
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.background.lightGrey_dark
            },
            textDecoration: 'none',
            justifyContent: 'space-between'
          }}
          p={0.5}
          component={Link}
          onClick={() => menuProps.onClose()}
          to={`projects/${activeProject?.id}/accountUsage`}>
          <Typography
            fontSize={'0.85rem'}
            color={theme.palette.text.custom}
            fontStyle={'underline'}>
            Account Usage
          </Typography>
          <DataUsage sx={{ color: theme.palette.svg.primary }} fontSize={'1.2rem'} />
        </Flex>
        <Divider my={1} />
        {showSettingsMenu && (
          <>
            <Flex
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.background.lightGrey_dark
                },
                textDecoration: 'none',
                justifyContent: 'space-between'
              }}
              p={0.5}
              component={Link}
              onClick={() => menuProps.onClose()}
              to={`/organizations`}>
              <Typography
                fontSize={'0.85rem'}
                color={theme.palette.text.custom}
                fontStyle={'underline'}>
                Settings
              </Typography>
              <SettingsOutlinedIcon sx={{ color: theme.palette.svg.primary }} fontSize={'1.2rem'} />
            </Flex>

            <Divider my={1} />
          </>
        )}
        <Flex p={0.5}>
          <Typography variant="body2" color={theme.palette.text.black_grey}>
            {' '}
            Dark mode{' '}
            <Typography variant="body2" display={'inline'} color={theme.palette.text.black_grey}>
              {mode === 'light' ? 'off' : 'on'}
            </Typography>{' '}
          </Typography>
          <Switch
            checked={mode === 'light' ? false : true}
            sx={{
              ml: 'auto'
            }}
            size={'small'}
            label={'Click me'}
            value={mode === 'light' ? false : true}
            onChange={() => {
              setMode((prev) => {
                let newMode = prev === 'light' ? 'dark' : 'light';
                localStorage.setItem('scandium-ui-mode', newMode);
                return newMode;
              });
            }}
          />
        </Flex>
        <Divider my={1} />
        <Flex
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.background.lightGrey_dark
            },
            textDecoration: 'none',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
          p={1}
          onClick={() => {
            handleLogout();
            menuProps.onClose();
          }}>
          <Typography fontSize={'0.85rem'} color={'red'}>
            Logout
          </Typography>
          <LogoutIcon color={'error'} fontSize={'1.2rem'} />
        </Flex>
      </Menu>
    </Box>
  );
};
export const ImageComponent = ({ name, setProfile }) => {
  return (
    <Box sx={{ position: 'relative', width: 'max-content' }}>
      <CircularImage name={name} />
      {/*<IconButton*/}
      {/*  aria-label="upload picture"*/}
      {/*  component="label"*/}
      {/*  sx={{*/}
      {/*    position: 'absolute',*/}
      {/*    bottom: '8px',*/}
      {/*    right: '8px',*/}
      {/*    color: 'black',*/}
      {/*    backgroundColor: '#EBEBEB'*/}
      {/*  }}>*/}
      {/*  <input*/}
      {/*    hidden*/}
      {/*    accept="image/*"*/}
      {/*    type="file"*/}
      {/*    onChange={(e) => {*/}
      {/*      setProfile(e.target.files[0]);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <PhotoCamera sx={{ fontSize: 14 }} color={'inherit'} />*/}
      {/*</IconButton>*/}
    </Box>
  );
};

export const MainProfile = ({
  setFullName,
  fullName,
  setCompanyMail,
  companyMail,
  setEmail,
  email,
  subscription,
  setSubscription,
  setDetails,
  apiToken
}) => {
  const copyApiTokenToClipBoard = () => {
    navigator.clipboard.writeText(apiToken);

    toast.success('copied to clipboard');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        alignItems: 'flex-start',
        // backgroundColor: 'blue',
        // width: '60%',
        width: { xs: '100%', sm: '90%', md: '70%' }
      }}>
      <CustomInput
        variant="standard"
        fullWidth
        readOnly
        required
        onChange={(event) => {
          setFullName(event.target.value);
        }}
        label="Full Name"
        value={fullName}
        error={false}
        helperText=" "
        formControlStyle={{
          mt: { xs: 4, sm: 4 },
          width: '100%'
        }}
        placeholder="Simon Okafor"
      />
      <CustomInput
        variant="standard"
        type="email"
        required
        readOnly
        onChange={(event) => {
          setCompanyMail(event.target.value);
        }}
        label="Company Name"
        value={companyMail}
        error={false}
        helperText=" "
        formControlStyle={{
          mt: { xs: 3, sm: 3 },
          width: '100%'
        }}
        placeholder="monskywalker@gmail.com"
      />
      <CustomInput
        variant="standard"
        type="email"
        required
        readOnly
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        label="Email address"
        value={email}
        error={false}
        helperText=" "
        formControlStyle={{
          mt: { xs: 3, sm: 3 },
          width: '100%'
        }}
        placeholder="monskywalker@gmail.com"
      />
      <CustomInput
        variant="standard"
        type="email"
        required
        readOnly
        label="API Token"
        value={apiToken}
        error={false}
        helperText=" "
        formControlStyle={{
          mt: { xs: 3, sm: 3 },
          width: '100%'
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={copyApiTokenToClipBoard}>
              <CopySvg />
            </IconButton>
          </InputAdornment>
        }
      />
      {!!subscription && (
        <CustomInput
          variant="standard"
          required
          readOnly
          onChange={(event) => {
            setSubscription(event.target.value);
          }}
          label="Subscription plan"
          formHelperText=" "
          value={subscription}
          formControlStyle={{
            mt: { xs: 3, sm: 3 },
            width: '100%'
          }}
          error={false}
          helperText="ojfalse"
          endAdornment={
            <InputAdornment position="end">
              <CustomButton
                onClick={() => setDetails(true)}
                disableRipple={true}
                disableFocusRipple
                fullWidth
                disableElevation={true}
                label="View plan"
                sx={{
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  typography: { xs: 'caption', sm: 'b1' },
                  borderRadius: '5rem',
                  bgcolor: '#DEEAFD',
                  color: 'black',
                  // mt: { xs: 0, sm: 5.0, },
                  width: { xs: 18, sm: 20 },
                  height: { xs: 18, sm: 20 },
                  '&:hover': {
                    backgroundColor: '#DEEAFD'
                  }
                }}
              />
            </InputAdornment>
          }
        />
      )}
    </Box>
  );
};

export const EditProfile = ({
  setFullName,
  fullName,
  setCompanyMail,
  companyMail,
  setEditProfile,
  handleEditProfile,
  error
}) => {
  const theme = useTheme();
  const { mode } = useContext(ColorContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        alignItems: 'flex-start',
        // backgroundColor: { xs: 'blue', sm: 'hotpink', md: 'beige' },
        width: { xs: '100%', sm: '100%', md: '100%' }
      }}>
      <CustomInput
        required
        onChange={(event) => {
          setFullName(event.target.value);
        }}
        label="Full Name"
        value={fullName}
        error={false}
        helperText=" "
        formControlStyle={{
          mt: { xs: 6, sm: 4 },
          width: { xs: '100%', sm: '100%', md: '27.6rem' }
        }}
        placeholder="Simon Okafor"
      />
      <CustomInput
        type="email"
        required
        onChange={(event) => {
          setCompanyMail(event.target.value);
        }}
        label="Company"
        value={companyMail}
        error={false}
        helperText=" "
        formControlStyle={{
          mt: { xs: 6, sm: 3 },
          width: { xs: '100%', sm: '100%', md: '27.6rem' }
          // width: '21rem'
        }}
        placeholder="monskywalker@gmail.com"
      />
      {/* <CustomInput
        type='email'
        required
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        label="Email address"
        value={email}
        error={false}
        helperText=" "
        formControlStyle={{
          mt: { xs: 6, sm: 3, },
          width: { xs: '100%', sm: '100%', md: '27.6rem' },
        }}
        placeholder="monskywalker@gmail.com"
      /> */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          // backgroundColor: 'pink',
          width: { xs: '100%', md: '27.6rem' },
          mt: { xs: 6, sm: 5 }
        }}>
        <CustomButton
          onClick={handleEditProfile}
          disableRipple={true}
          disableFocusRipple={true}
          fullWidth
          disableElevation={true}
          label="Update changes"
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            typography: { xs: 'caption', sm: 'caption' },
            borderRadius: '0.4rem',
            color: theme.palette.button.textContained,
            border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
            background: mode === 'dark' ? 'none' : 'primary',
            py: { xs: 1.2, sm: 1.2 },
            width: { xs: '9.6rem', sm: '10.6rem', md: '10.6rem' }
          }}
        />
        <CustomButton
          onClick={() => setEditProfile(false)}
          disableRipple={true}
          disableFocusRipple={true}
          fullWidth
          disableElevation={true}
          label="Discard changes"
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            typography: { xs: 'caption', sm: 'caption' },
            borderRadius: '0.4rem',
            border: `1px solid ${theme.palette.button.border}`,
            background: mode === 'dark' ? 'none' : '#fff',
            color: mode === 'light' ? 'blue' : 'white',
            py: { xs: 0.97, sm: 0.97 },
            width: { xs: '9.6rem', sm: '10.6rem' },
            '&:hover': {
              backgroundColor: mode === 'light' && '#fff'
            }
          }}
        />
      </Box>
      {error && (
        <Typography align="center" color="error">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export const Details = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        alignItems: 'flex-start',
        mt: { xs: 6, sm: 2 }
      }}>
      <CheckboxItem
        title="Dedicated customer-success manager."
        marginTopLength={{ xs: 0, sm: 0 }}
      />
      <CheckboxItem title="Slack-based support." />
      <CheckboxItem title="Unlimited users." />
      <CheckboxItem title="Unlimited test cases." />
      <CheckboxItem title="On-premise available." />
      <CheckboxItem title="SSO." />
      <CheckboxItem title="SLA." />
      <CustomButton
        // onClick={handleOpen}
        disableRipple={true}
        disableFocusRipple={true}
        fullWidth
        disableElevation={true}
        label="Cancel Subscription"
        sx={{
          textAlign: 'center',
          textTransform: 'capitalize',
          typography: { xs: 'caption', sm: 'caption' },
          borderRadius: '0.4rem',
          border: '0.09rem solid #FF0000',
          bgcolor: '#fff',
          color: '#FF0000',
          width: { xs: '11rem', sm: '13rem' },
          // height: { xs: 3, sm: 20, },
          mt: { xs: 3, sm: 3 },
          '&:hover': {
            backgroundColor: '#fff'
          }
        }}
      />
    </Box>
  );
};
