import { Box, Menu, Tooltip } from '@mui/material';
// import { Tab, Tabs, TabList } from 'react-tabs';
import { ApiTestContext } from '../../../../store/apiState/apiTestContext';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useActiveProject } from '../../../../store/projectState';
import { Add, AddIcCallOutlined, CloseOutlined, CloseSharp, MoreVert } from '@mui/icons-material';
import Flex from '../../../../components/base/Flex';
import { useGetCurrentTest } from '../../../../hooks/apiHooks';
import { methodColorMap } from '../../utils';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  useCloseTestTab,
  useTabsFunctions
} from '../../../../hooks/apiHooks/trackTabsAndFetchTest';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
// import 'react-tabs/style/react-tabs.css';
import './Tabs.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTheme } from '@emotion/react';
import Divider from '@mui/material/Divider';
import { IconButton, MenuItem, Typography } from '@mui/material/node';
import { useMenu } from '../../../../components/base/useMenu';

const TestsTabs = () => {
  const theme = useTheme();
  const {
    testsTab: { testsList, currentTabIndex },
    setTestsTab
  } = useContext(ApiTestContext);
  const activeProject = useActiveProject();
  const navigate = useNavigate();
  const { testId, exampleId, newTestId } = useParams();
  const handleTestTabChange = (e, index) => {
    setTestsTab((prev) => {
      return { ...prev, currentTabIndex: index };
    });
  };
  const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))({
    borderBottom: `2px solid ${theme.palette.table.main}`,
    margin: 0,

    minHeight: 'fit-content',
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height: '3px'
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: '100%',
      width: '50%',
      // marginBottom: '2px',
      backgroundColor: '#24C3D9'
    }
  });
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    padding: '10px',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.text.black_grey,
    minHeight: 'fit-content',
    margin: '2px',
    marginBottom: '3px',
    padding: '2px',
    '&.Mui-selected': {
      borderBottom: 'none',
      bottom: '-1px',
      borderTopLeftRadius: '10px', // Add border-radius for top-left corner
      borderTopRightRadius: '10px',
      borderColor: theme.palette.text.custom,
      color: theme.palette.primary.main
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)'
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
  }));
  return (
    <Flex
      gap={0}
      sx={{
        alignItems: 'center'
      }}>
      <StyledTabs
        value={currentTabIndex}
        onChange={handleTestTabChange}
        aria-label="test tabs container"
        variant="scrollable">
        {testsList.map((testCase, index) => {
          return (
            <StyledTab
              key={testCase.testDetails.id}
              label={
                <SingleTestTab key={testCase.testDetails.id} testCase={testCase} index={index} />
              }></StyledTab>
          );
        })}
      </StyledTabs>
      <IconButton
        aria-label="add-new-test"
        color="primary"
        sx={{
          ml: -1
        }}>
        <Add
          onClick={() => {
            if (!newTestId && !testId && !exampleId) {
              return;
            }
            navigate(`/projects/${activeProject?.id}/api-suites/new`);
          }}
        />
      </IconButton>
      <TabActionMenu />
    </Flex>
  );
};

const SingleTestTab = ({ testCase, index }) => {
  const theme = useTheme();
  const activeProject = useActiveProject();
  const navigate = useNavigate();
  const {
    testsTab: { testsList, currentTabIndex },
    testToCloseRef
  } = useContext(ApiTestContext);
  const { hasNewChanges } = useGetCurrentTest();
  const { handleCloseTestTab } = useCloseTestTab();
  const { requestSaveTestChangesDialog } = useContext(ApiDialogsAndModalsContext);
  const closeTest = () => {
    testToCloseRef.current = {
      index,
      data: testCase
    };
    if (testToCloseRef.current.data.hasNewChanges) {
      requestSaveTestChangesDialog();
      return;
    }
    handleCloseTestTab();
  };
  const [isHover, setIsHover] = useState(false);
  return (
    <Flex
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}>
      {testCase.hasNewChanges && (
        <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '4px' }} />
      )}
      <Box
        sx={{
          maxWidth: '120px',
          whiteSpace: 'nowrap',
          // outline: '1px solid black',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        onClick={(e) => {
          if (testCase.testType === 'new-test') {
            navigate(`/projects/${activeProject?.id}/api-suites/new/${testCase?.testDetails?.id}`);
          } else if (testCase.testType === 'api-test') {
            navigate(`/projects/${activeProject?.id}/api-suites/${testCase?.testDetails?.id}/edit`);
          } else if (testCase.testType === 'api-test-example') {
            navigate(
              `/projects/${activeProject?.id}/api-suites/${testCase?.testDetails?.parentId}/example/${testCase?.testDetails?.id}`
            );
          }
        }}>
        <Typography
          display={'inline'}
          fontSize={10}
          fontWeight={'bold'}
          color={
            methodColorMap[testCase?.testPropsForRequest?.requestMethod.toLowerCase()] || 'black'
          }>
          {' '}
          {testCase?.testPropsForRequest?.requestMethod}{' '}
        </Typography>
        <Typography fontSize={12} display={'inline'}>
          {testCase?.testDetails?.name}
        </Typography>
      </Box>

      {/* <CloseSharp
        onClick={(e) => {
          e.stopPropagation();
          closeTest();
        }}
        sx={{
          display: currentTabIndex === index && 'none',
          cursor: 'pointer',
          fontSize: '13px'
        }}
      /> */}

      <CloseOutlined
        onClick={(e) => {
          e.stopPropagation();
          closeTest();
        }}
        sx={{
          display: testsList.length === 1 && 'none',
          cursor: 'pointer',
          fontSize: '13px'
        }}
      />

      {index !== testsList.length - 1 && <Divider orientation="vertical" flexItem />}
    </Flex>
  );
};

const TabActionMenu = () => {
  const { triggerProps, menuProps } = useMenu();
  const { handleCloseInactiveTabs, handleCloseSavedTabs } = useTabsFunctions();
  return (
    <Box marginLeft={-2}>
      <Tooltip>
        <IconButton
          onClick={(e) => {
            triggerProps.onClick(e);
          }}>
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        id="tab-action-menu"
        {...menuProps}
        PaperProps={{
          style: {
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        <MenuItem
          onClick={() => {
            menuProps.onClose();
            handleCloseInactiveTabs();
          }}>
          Close inactive tabs
        </MenuItem>
        <MenuItem
          onClick={() => {
            menuProps.onClose();
            handleCloseSavedTabs();
          }}>
          Close saved tabs
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TestsTabs;
