import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTheme } from '@emotion/react';

const CustomModal = ({ open, onClose, children, sx, ...rest }) => {
  const theme = useTheme();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    border: `1px solid ${theme.palette.outline.modal}`,
    borderRadius: '0.5rem',
    boxShadow: 24,
    px: 2,
    py: 2
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0.4)'
      }}
      {...rest}>
      <Box sx={{ ...style, ...sx }}>{children}</Box>
    </Modal>
  );
};

export default CustomModal;
