import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TextField,
  Tooltip,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFocusedState } from '../../../hooks/useFocusedState';
import Flex from '../../../components/base/Flex';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import InfoIcon from '@mui/icons-material/Info';
import { FloatingVariableWindowContext } from '../../../store/floatingVariableWindowState';
import { useSetTestQueryHeaders } from '../../../hooks/apiHooks/updateTestPropsHooks';
import { useGetCurrentTest } from '../../../hooks/apiHooks';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`,
    padding: '5px'
  }
}));

const Headers = () => {
  const rowStyle = {
    height: '9px',
    outline: 'none',
    fontSize: '12px',
    marginTop: '4px'
  };
  const { handleVariableFloatingWindow } = useFocusedState();
  /////////////////////////////////////////////////////////////
  const activeTest = useGetCurrentTest();
  const { handleSetQueryHeaders, handleHeadersDelete } = useSetTestQueryHeaders();
  return (
    <Table sx={{ width: '98%' }} size="small" aria-label={'a Query Param Table'}>
      <TableHead>
        <TableRow>
          <StyledTableCell>{''}</StyledTableCell>
          <StyledTableCell>Key</StyledTableCell>
          <StyledTableCell>Value</StyledTableCell>
          <StyledTableCell>Description</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activeTest?.testPropsForRequest?.headers.map((param, index) => (
          <TableRow key={index}>
            <StyledTableCell
              sx={{
                textAlign: 'center',
                width: '50px'
              }}>
              {param?.src === 'authorization' ? (
                <InformationIcon
                  toolTipText={
                    'This field is added automatically based on the authorization method, modifications can only be made from the authorization tab'
                  }
                />
              ) : (
                ''
              )}
            </StyledTableCell>
            <StyledTableCell width={'30%'}>
              <TextField
                disabled={param?.src === 'auto'}
                size={'small'}
                fullWidth
                placeholder={'key'}
                value={param.key}
                onChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleSetQueryHeaders(e, index, 'key');
                  });
                  handleSetQueryHeaders(e, index, 'key');
                }}
                variant={'standard'}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  opacity: param?.src === 'authorization' ? 0.3 : 1,
                  readOnly: param?.src === 'authorization',
                  style: { ...rowStyle, opacity: param?.src === 'authorization' ? 0.6 : 1 }
                }}
              />
            </StyledTableCell>
            <StyledTableCell
              sx={{
                position: 'relative',
                minWidth: '200px'
              }}>
              <ValueTextField
                param={param}
                index={index}
                handleSetQueryHeaders={handleSetQueryHeaders}
                handleVariableFloatingWindow={handleVariableFloatingWindow}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Flex>
                <TextField
                  size={'small'}
                  fullWidth
                  placeholder={'description'}
                  value={param.description}
                  onChange={(e) => {
                    handleSetQueryHeaders(e, index, 'description');
                  }}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    readOnly: param?.src === 'authorization',
                    style: { ...rowStyle, opacity: param?.src === 'authorization' ? 0.6 : 1 }
                  }}
                />
                {param.src !== 'authorization' &&
                  param.src !== 'auto' &&
                  (!!param.key || !!param.value) && (
                    <Tooltip title={'Delete'}>
                      <Box
                        sx={{ cursor: 'pointer', flex: '2%' }}
                        onClick={() => {
                          handleHeadersDelete(index);
                        }}>
                        <DeleteSvg width={16} height={18} />
                      </Box>
                    </Tooltip>
                  )}
              </Flex>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const InformationIcon = ({ toolTipText }) => {
  return (
    <Tooltip title={toolTipText} arrow>
      {/* <IconButton> */}
      <InfoIcon color="primary" />
      {/* </IconButton> */}
    </Tooltip>
  );
};
const ValueTextField = ({ param, index, handleSetQueryHeaders, handleVariableFloatingWindow }) => {
  const { showVariableFloatingWindow } = useContext(FloatingVariableWindowContext);
  const [expanded, setExpanded] = useState(false);
  return (
    <TextField
      disabled={param?.src === 'auto'}
      autoFocus={expanded}
      size={'small'}
      multiline={expanded}
      fullWidth
      placeholder={'value'}
      value={param.value}
      onClick={(e) => {
        setExpanded(true);
      }}
      onMouseOut={(e) => {
        if (showVariableFloatingWindow) {
          return;
        } else {
          setExpanded(false);
        }
      }}
      onChange={(e) => {
        handleVariableFloatingWindow(e, () => {
          handleSetQueryHeaders(e, index, 'value');
        });
        handleSetQueryHeaders(e, index, 'value');
      }}
      variant={'standard'}
      InputProps={{
        disableUnderline: true
      }}
      inputProps={{
        style: {
          opacity: param?.src === 'authorization' ? 0.6 : 1,
          width: '100%',
          fontSize: '12px',
          marginTop: '4px',
          background: expanded ? 'rgba(255, 255,255)' : '',
          top: -4,
          left: 0
        }
      }}
    />
  );
};

export default Headers;
