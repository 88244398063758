import React, { useContext } from 'react';
import { useScandiumQuery } from '../data-layer/utils';
import { useActiveProject } from './projectState';

export const GlobalVariablesContext = React.createContext({
  globalVariables: [],
  globalVariablesError: false,
  fetchedGlobalVariables: false,
  errorFetchingVariables: false,
  IsLoadingVariables: false,
  refetchVariables: () => {},
  featureFlagsError: null,
  isFeatureFlagsLoading: false
});

export const GlobalVariablesContextProvider = ({ children }) => {
  const activeProject = useActiveProject();
  const {
    data: globalVariables = [],
    isSuccess: fetchedGlobalVariables,
    isError: errorFetchingVariables,
    error: globalVariablesError,
    isLoading: IsLoadingVariables,
    refetch: refetchVariables
  } = useScandiumQuery(`/projects/${activeProject?.id}/variables`, {
    queryKey: ['fetch-global-variables'],
    enabled: !!activeProject?.id,
    select: (data) => data?.data
  });

  return (
    <GlobalVariablesContext.Provider
      value={{
        globalVariables,
        fetchedGlobalVariables,
        errorFetchingVariables,
        globalVariablesError,
        IsLoadingVariables,
        refetchVariables
      }}>
      {children}
    </GlobalVariablesContext.Provider>
  );
};

export const useGlobalVariables = () => useContext(GlobalVariablesContext);
