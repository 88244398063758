import { Box, IconButton, Tooltip, Typography } from '@mui/material/node';
import Flex from '../../../../components/base/Flex';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import CopySvg from '../../../../components/svg/CopySvg';
import { toast } from 'react-toastify';
import { CloseRounded } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';
import { useGetCurrentTest } from '../../../../hooks/apiHooks';
import { useSetActiveTool } from '../../../../hooks/apiHooks';

const TestDetails = () => {
  const activeTest = useGetCurrentTest();
  const { handleSetActiveTool } = useSetActiveTool();
  // const { testDetails } = activeTest;
  const theme = useTheme();
  return (
    <Flex
      sx={{
        display: activeTest?.uiProps?.toolBar?.activeTool === 0 ? 'flex' : 'none',
        flexDirection: 'column',
        minWidth: 'fit-content',
        // position: 'absolute',
        borderRadius: 2,
        p: 2,
        pt: 0
      }}>
      <IconButton
        sx={{
          alignSelf: 'flex-end'
        }}
        onClick={() => {
          handleSetActiveTool(null);
        }}>
        <CloseRounded
          sx={{
            fontSize: 18
          }}
        />
      </IconButton>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: 1,
          alignItems: 'flex-start'
        }}>
        {!!activeTest?.testDetails &&
          Object.entries(activeTest?.testDetails).map(([field, value]) => {
            if (field === 'id') {
              field = 'ID';
            }
            if (field === 'parentId') {
              return;
            }
            return <SingleField key={field} field={field} value={value} />;
          })}
      </Flex>
    </Flex>
  );
};

const SingleField = ({ field, value }) => {
  const theme = useTheme();
  const copyResponseToClipBoard = () => {
    navigator.clipboard.writeText(value);
    toast.success('copied to clipboard', {
      autoClose: 1000
    });
  };
  return (
    <Flex>
      <Typography fontSize={12} color="primary">
        {field} :{' '}
      </Typography>
      <Typography fontSize={10} color="disabled">
        {value}{' '}
      </Typography>
      {field === 'ID' && (
        <Tooltip title={'Copy'}>
          <IconButton size={'medium'} onClick={copyResponseToClipBoard}>
            <CopySvg />
          </IconButton>
        </Tooltip>
      )}
    </Flex>
  );
};

export default TestDetails;
