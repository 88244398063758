import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import OutlinedButton from '../../../../components/base/OutlinedButton';
import ContainedButton from '../../../../components/base/ContainedButton';
import React, { useContext } from 'react';
import useSaveApiTest from '../../../../hooks/apiHooks/queryHooks/useSaveApiTest';
import { ApiDialogsAndModalsContext } from '../../../../store/apiState/apiDialogsAndModalsContext';
import { useCloseTestTab } from '../../../../hooks/apiHooks/trackTabsAndFetchTest';
import { ApiTestContext } from '../../../../store/apiState/apiTestContext';

export const SaveChangesToApiTestDialog = () => {
  const { handleSaveApiTest, isSavingApiTest } = useSaveApiTest();
  const { openSaveTestChangesDialog, closeSaveTestChangesDialog } = useContext(
    ApiDialogsAndModalsContext
  );
  const { testToCloseRef } = useContext(ApiTestContext);
  const { handleCloseTestTab } = useCloseTestTab();
  const handleTabCloseNoSave = async () => {
    closeSaveTestChangesDialog();
    handleCloseTestTab();
  };
  const handleSaveBeforeTabClose = async () => {
    const result = await handleSaveApiTest();
    if (result?.savedTest) {
      //  close dialog, then close the tab
      closeSaveTestChangesDialog();
      handleCloseTestTab();
    }
  };
  return (
    <Dialog
      open={openSaveTestChangesDialog}
      onClose={closeSaveTestChangesDialog}
      aria-labelledby="alert-dialog-title"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{'Save Test Changes'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`You have unsaved changes in ${testToCloseRef?.current?.data?.testDetails?.name}, are you sure you want to close this test tab ?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 2 }}>
        <OutlinedButton onClick={closeSaveTestChangesDialog}>Cancel</OutlinedButton>
        <ContainedButton color="primary" onClick={handleTabCloseNoSave} autoFocus isLoading={false}>
          Proceed, do not save
        </ContainedButton>
        <ContainedButton
          sx={{
            border: 'none'
          }}
          color="secondary"
          onClick={async () => {
            handleSaveBeforeTabClose();
            // const result = await handleSaveApiTest();
            // if (!!result) {
            //   // then close the tab
            // }
          }}
          autoFocus
          isLoading={isSavingApiTest}>
          Save before close
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
